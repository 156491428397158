import React from "react";

const StarPath = ({ lighted, index }: { lighted: boolean; index: number }) => {
  return (
    <g transform={`translate(0,0) scale(0.1)`}>
      <path
        fill={lighted ? "#FFCF53" : "#C7C9CE"}
        d="M100,35.954l15.897,48.927l51.445,-0l-41.62,30.238l15.898,48.927l-41.62,-30.238l-41.62,30.238l15.898,-48.927l-41.62,-30.238l51.445,-0l15.897,-48.927Z"
      />
    </g>
  );
};
export default StarPath;
