import React, { useState } from "react";
import "./HelpObjectives.css";
import { useRecoilState } from "recoil";
import {
  toggleModalHelpObjectivesAtom,
} from "../../../../../recoil/atoms";
import SVGShield from "../../../../icons/SVGShield";
<link 
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap" 
  rel="stylesheet" 
/>;

function HelpObjectives() {
  const [isModalHelpObjectivesOpen, setModalHelpObjectivesOpen] = useRecoilState(
    toggleModalHelpObjectivesAtom
  );
  const [isClickedCircle, setIsClickedCircle] = useState(false);

  return (
    <div className={`helpObjectives-modal ${isModalHelpObjectivesOpen ? "helpObjectives-open" : ""}`}>
      <div className="helpObjectives-header">
        <div className="helpObjectives-title">Objectifs pédagogiques</div>
        <span 
          className="helpObjectives-close" 
          onClick={() => setModalHelpObjectivesOpen(false)}
        >
          <svg
            width={20}
            height={20}
            viewBox="0 0 50 50"
          >
            <path
              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
              stroke="rgba(0, 0, 0, 0.36)"
              fill="rgba(0, 0, 0, 0.36)"
              strokeWidth={7}
            />
          </svg>
        </span>
      </div>
      <div className="helpObjectives-intro">
      Les objectifs pédagogiques énumèrent l’ensemble des apprentissages visés par l’unité d’enseignement.
      </div>
      <div className="helpObjectives-body">
        <div className="helpObjectives-section">
          <div className="helpObjectives-section-title">Noeuds</div>
          <div className="helpObjectives-section-objective">
            <svg
              height="66px" width="76px"
              className="node"
            >
              <g
                transform={"translate(0,-15)"}
                className="gIcon svg-node-icon shield"
              >
                <g transform="scale(1.2)">
                  <SVGShield />
                </g>
              </g>
            </svg>
            <span className="helpObjectives-section-objective-name">Nom de l'objectif</span>
            <svg
              height="66px" width="76px"
              className="node"
            >
              <g
                transform={"translate(50,-30)"}
                className={`collapser ${isClickedCircle ? "opened" : "collapsed"}`}
                cursor="pointer"
                onClick={() => { isClickedCircle ? setIsClickedCircle(false) : setIsClickedCircle(true) }}>
                <circle cx="0" cy="50" r="15"></circle>
                {isClickedCircle ? 
                  <path d="M0,50 L-5,50 M 0,50 L5,50"></path> : 
                  <path d="M0,45 L0,50 M0,50 L-5,50 M0,50 L0,55 M 0,50 L5,50"></path>
                }
              </g>
            </svg>
            <svg className="helpObjectives-section-objective-progression">

            </svg>
            <div className="helpObjectives-section-objective-progression-text">
              Progression
            </div>
          </div>
          <div className="helpObjectives-intro-nodes">
            Un <span className="helpObjectives-bold">objectif</span> est <span className="helpObjectives-bold">validé</span> lorsque tu as rempli les <span className="helpObjectives-bold">pré-requis</span> ainsi que toutes les <span className="helpObjectives-bold">activités obligatoires</span> de l’objectif.
          </div>
        </div>
        <div className="helpObjectives-section">
          <div className="helpObjectives-section-title">Statut</div>
          <div className="helpObjectives-section-status">
            <div className="helpObjectives-section-status-item not-started">
              <svg
                height="66px" 
                width="66px"
                className="node"
              >
                <g
                  transform={"translate(-10,-10)"}
                  className="gIcon svg-node-icon shield"
                >
                  <g transform="scale(1.2)">
                    <SVGShield />
                  </g>
                </g>
              </svg>
              <span className="helpObjectives-status">Non commencé</span>
            </div>
            <div className="helpObjectives-section-status-item being-acquired">
              <svg
                height="66px" 
                width="66px"
                className="node started"
              >
                <g
                  transform={"translate(-10,-10)"}
                  className="gIcon svg-node-icon shield"
                >
                  <g transform="scale(1.2)">
                    <SVGShield />
                  </g>
                </g>
              </svg>
              <span className="helpObjectives-status">En cours d'acquisition</span>
            </div>
            <div className="helpObjectives-section-status-item acquired">
              <svg
                height="66px" 
                width="66px"
                className="node done"
              >
                <g
                  transform={"translate(-10,-10)"}
                  className="gIcon svg-node-icon shield"
                >
                  <g transform="scale(1.2)">
                    <SVGShield />
                  </g>
                </g>
              </svg>
              <span className="helpObjectives-status">Acquis</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpObjectives;