import "./PopUp.css"
import {useRecoilState} from "recoil";
import {SettingPopUp} from "../../../../recoil/atoms";
import {ReactElement} from "react";

const PopUp  = (
    props
    :
    { config:
        {

            message: string,
            html?: ReactElement,
            arrBtn :
                {
                    text: string,
                    action: any,
                    type: "danger" | "primary" | "secondary"
                }[]
        }
    }
 ) => {
    const [settingPopUp,setSettingPopUp] = useRecoilState(
        SettingPopUp
    );

    const clickBtn = (action) => {
        setSettingPopUp({
            isOpen : false,
            message :"",
            arrBtn: [],
        });
        action();
    };

    return (
        <div className="PopUpValidation">
            <div className="PopUpContent">
                <h3>{props.config.message}</h3>
                { props.config.html != undefined && props.config.html}
                {props.config.arrBtn.map(btn => {
                    return(
                        <button className={"btn btn-"+btn.type} onClick={() => {clickBtn(btn.action)}}>
                            {btn.text}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

export default PopUp;