import React from "react";
import "./Home.css";
import HelpPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import AdminMenu from "../../PageElement/AdminMenu/AdminMenu";
import Breadcrumb from "../../PageElement/Breadcrumb/Breadcrumb";
import Sidebar from "../../PageElement/Sidebar/Sidebar";
import { rolesAtom, userAtom } from "../../../../recoil/atoms";
import { useRecoilState } from "recoil";
import { teacherTeachingUnitsAtom } from "../../../../recoil/graphFilters";
import { Link } from "react-router-dom";

const Home = () => {

  const [userRoles,] = useRecoilState(rolesAtom);
  const [user,] = useRecoilState(userAtom);
  const [teachingUnits,] = useRecoilState(
    teacherTeachingUnitsAtom
  );

  return (
    <div className="admin">
      <Sidebar />
      <div className="admin-body">
        <div className="admin-content">
          <Breadcrumb />
          <AdminMenu />

          <div className="home-body">

            <h2 className="home-title">
              Bienvenue
              {user !== undefined &&
                <i>{" " + user?._prenom + " " + user?._nom?.toUpperCase()}</i>
              }
            </h2>

            <div className="home-content">

              <span>
                Votre rôle est : <b>{userRoles[0]}</b>
              </span>

              <div>
                {"Depuis cette interface d'administration du Tuteur Virtuel, vous pouvez "}
                {userRoles.includes("admin") ?
                  <Link to="/unites-enseignement">
                    éditer vos unités d'enseignement
                  </Link>
                  :
                  (teachingUnits[0] && teachingUnits[0]._nom !== undefined) ?
                    <Link to={`/unites-enseignement/${teachingUnits[0]._nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`}>
                      éditer vos unités d'enseignement
                    </Link> : "éditer vos unités d'enseignement"
                }
                {" et "}
                <Link to="/etudiants">suivre la progression des étudiants</Link>.
              </div>

            </div>

          </div>

          <HelpPanel />
          <AccountPanel />
          <NotificationPanel />
        </div>
      </div>
    </div>
  );
};
export default Home;
