import React from "react";
import SVGWebLink from "../icons/SVGWebLink";
import { INode } from "../../interface/graph";
import BadgeNew from "./BadgeNew";

interface IProps {
  node: INode;
  open?: boolean;
}

const WebLink = ({ node }: IProps) => (
  <g className="svgIcon" transform="translate(100,0)">
    <text textAnchor={"left"} fill={node.done ? "white" : "grey"} opacity={0}>
      <tspan x="-20" dy="-7">
        Lien
      </tspan>
      <tspan x="-20" dy="1.6em">
        {node.shortTitle}
      </tspan>
    </text>
    <g className={"gIcon"} transform="scale(2)">
      <g transform="translate(-65,-15)">
        <circle
          cx={15.5}
          cy={15}
          r={23}
          fill={`white`}
          stroke={"white"}
          className="selected-highlight"
        />
        <circle cx={15.5} cy={15} r={20} className="circle" />
        <SVGWebLink />
      </g>
    </g>
    <BadgeNew node={node} />
  </g>
);
export default WebLink;
