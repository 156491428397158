import React from "react";
import { useRecoilState } from "recoil";
import { selectedLayerAtom } from "../../recoil/atoms";
import { NEWS } from "../../interface/graph";

const BadgeNew = ({ node }: any) => {
  const [selectedLayers] = useRecoilState(selectedLayerAtom);
  const displayNew = selectedLayers.includes(NEWS);
  return (
    (node.new && displayNew && (
      <g transform="translate(-60,-65)">
        <circle fill="white" r={18} cx={24} cy={25} />
        <svg width="50px" height="50px" viewBox="0 0 512 512">
          <path
            fill="#FFB000"
            className="st0"
            d="M359.09,283.56c-14.14-82.86-84.42-62.46-99.18-172.62c-0.69-5.18-7.85-7.77-10.11-3.06
	c-47.76,99.46,37.54,119.45,1.12,155.87c-29.83,29.83-72.84,0.63-73.83-28.68c-0.19-5.75-7.8-7.7-10.55-2.64
	c-11.35,20.93-15.4,46.36-15.61,70.93C155.65,454.65,384.51,432.48,359.09,283.56z"
          />
        </svg>
      </g>
    )) || <></>
  );
};
export default BadgeNew;
