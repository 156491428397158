export const SVGPresentation = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="30" height="30" viewBox="0 0 30 30" className="svg-node-icon">
      <g>
        <g>
          <g transform="translate(-5591.81 -2885.495)">
            <path stroke-width="2" stroke-linejoin="round" d="M5608.9,2888.4h-11.1v24.2h18v-17.3L5608.9,2888.4z"/>
            <path stroke-width="2" stroke-linejoin="round" d="M5608.9,2895.3h6.9l-6.9-6.9V2895.3z"/>
          </g>
        </g>
        <g>
          <line fill="none" stroke-linecap="round" stroke-linejoin="round" x1="13.9" y1="15.1" x2="13.9" y2="21.3"/>
          <path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.9,15.1h1c0.7,0,1.2,0.5,1.2,1.2V17c0,0.7-0.5,1.2-1.2,1.2h-1"/>
          <line fill="none" stroke-linecap="round" stroke-linejoin="round" x1="10" y1="15.1" x2="10" y2="21.3"/>
          <path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M10,15.1h1c0.7,0,1.2,0.5,1.2,1.2V17c0,0.7-0.5,1.2-1.2,1.2h-1"/>
          <line fill="none" stroke-linecap="round" stroke-linejoin="round" x1="17.5" y1="15.1" x2="20" y2="15.1"/>
          <line fill="none" stroke-linecap="round" stroke-linejoin="round" x1="18.7" y1="15.1" x2="18.7" y2="21.3"/>
        </g>
      </g>
    </svg>
  );
};
