import * as React from 'react'
import "./TeachingUnitGraph.css";
import LoaderEnseignantGraph from "./LoaderEnseignantGraph";
import Sidebar from "../../PageElement/Sidebar/Sidebar";
import Breadcrumb from "../../PageElement/Breadcrumb/Breadcrumb";
import AdminMenu from "../../PageElement/AdminMenu/AdminMenu";
import HelpPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import {useRecoilState} from "recoil";
import {selectedTeachingUnitAtom} from "../../../../recoil/graphFilters";
import BottomControls from "../../../VirtualTutor/BottomGraphControls/BottomControls";
import LayerSelector from "../../../VirtualTutor/LayerSelector";
import SidePanel from "../../../VirtualTutor/SidePanel";
import ResourcesForm from "../../../VirtualTutor/ResourcesForm";
import QuestionForm from "../../../VirtualTutor/QuestionForm";
import Menus from "../../../VirtualTutor/Menus";
import RefreshGraph from "../../../VirtualTutor/Menus/RefreshGraph";
import {
    ConfigContextMenu,
    EyeContextMenu,
    TeachingUnitDraftMenu
} from "../../PageElement/AdminMenu/ContextMenu";


<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

const TeachingUnitGraph = ({}: {}) => {

  const [selectedTeachingUnit] = useRecoilState(
      selectedTeachingUnitAtom
  );

  return (
      <div className="admin">
          <Sidebar />
          <div className="admin-body">
              <div className="admin-content">
                  <AdminMenu  ContextMenu={
                      <>
                          <TeachingUnitDraftMenu />
                          { selectedTeachingUnit && <EyeContextMenu link={"/unites-enseignement/"+selectedTeachingUnit._nom!.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/>}
                      </>
                  }/>
                  <div className="main_container">
                      <div className="graph_container">
                          <LoaderEnseignantGraph
                              imposedStudentId={undefined}
                          />
                      </div>
                      <BottomControls />
                      <LayerSelector />
                      <SidePanel />
                      <ResourcesForm />
                      <QuestionForm />
                      <Menus />
                      <RefreshGraph />
                  </div>
                  <HelpPanel />
                  <AccountPanel />
                  <NotificationPanel />
              </div>
          </div>
      </div>

  );
};
export default TeachingUnitGraph;
