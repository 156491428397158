export const SVGExcel = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="30" height="30" viewBox="0 0 30 30" className="svg-node-icon">
      <g transform="translate(-5591.81 -2885.495)">
        <path stroke-width="2" stroke-linejoin="round" d="M5608.9,2888.3h-11.1v24.2h18v-17.3L5608.9,2888.3z"/>
        <path stroke-width="2" stroke-linejoin="round" d="M5608.9,2895.2h6.9l-6.9-6.9V2895.2z"/>
      </g>
      <g>
        <line stroke-linecap="round" stroke-linejoin="round" x1="9.7" y1="15.1" x2="12.2" y2="21.2"/>
        <line stroke-linecap="round" stroke-linejoin="round" x1="12.2" y1="15.1" x2="9.7" y2="21.2"/>
        <polyline stroke-linecap="round" stroke-linejoin="round" points="14.3,15.1 14.3,21.3 16.4,21.3 	"/>
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.8,15.3c-1.3-0.5-1.8,0.4-1.8,1.2c0,1.7,2.2,1.6,2.2,3.4c0,1.3-1.4,1.6-2.3,1.2"/>
      </g>
    </svg>
  );
};
