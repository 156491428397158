import { useState, useEffect } from "react";
import "./ObjectiveEditPanel.css";
import "./ContributoryObjectives.css";
import { ObjectifPedagogique } from "../../../../../interface/objectifPedagogique";
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Spinner } from "reactstrap";
import { toggleObjectiveEditModalAtom, toggleTeachingUnitModeLiveAtom } from "../../../../../recoil/atoms";
import { useRecoilState } from "recoil";
import ContributoryObjectivesImport from "./ContributoryObjectivesImport";
import { ApiService } from "../../../../LoginHandler/restServices";
import { ConditionObtentionObjectifPedagogique } from "../../../../../interface/conditionObtentionObjectifPedagogique";
import { selectedObjectiveAtom, selectedTeachingUnitObjectivesAtom } from "../../../../../recoil/graphFilters";
import IconFullStar from "../../../../icons/SVGIconFullStar";
import IconEmptyStar from "../../../../icons/SVGIconEmptyStar";
import IconSurroundedDownArrow from "../../../../icons/SVGIconSurroundedDownArrow";
import IconEdit from "../../../../icons/SVGIconEdit";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ContributoryObjectives() {

  const [objectiveEditModalOpen,] =
    useRecoilState(toggleObjectiveEditModalAtom);
  const [teachingUnitModeLive,] =
    useRecoilState(toggleTeachingUnitModeLiveAtom);
  const [objectiveAtom, setObjectiveAtom] = useRecoilState(
    selectedObjectiveAtom
  );
  const [teachingUnitObjectives, setTeachingUnitObjectives] = useRecoilState(
    selectedTeachingUnitObjectivesAtom
  );

  const [currentObjectiveContributoryObjectivesLoad, setCurrentObjectiveContributoryObjectivesLoad] = useState<boolean>(false);
  const [currentObjectiveContributoryObjectives, setCurrentObjectiveContributoryObjectives] = useState<ObjectifPedagogique[]>([]);
  const [currentObjectiveConditionnalObjectives, setCurrentObjectiveConditionnalObjectives] = useState<ObjectifPedagogique[]>([]);
  const [isApiFetched, setIsApiFetched] = useState<boolean>(false);


  // Récupération et tri des objectifs contributifs de l'unité d'enseignement
  useEffect(() => {
    if (objectiveAtom != undefined && teachingUnitObjectives != undefined && objectiveEditModalOpen) {

      setCurrentObjectiveContributoryObjectivesLoad(false);

      if (objectiveAtom._contributionObjectifPourObjectif != undefined) {

        // Filtrage de tous les objectifs de l'unité d'enseignement pour récupérer les contributifs
        var contributoryObjectives: Array<ObjectifPedagogique> = teachingUnitObjectives.filter(ueObjective =>
          Array.isArray(objectiveAtom._contributionObjectifPourObjectif) ?
            objectiveAtom._contributionObjectifPourObjectif.some(contribObjective => {
              return contribObjective._pourObjectif._URI === ueObjective._URI;
            })
            :
            objectiveAtom._contributionObjectifPourObjectif._pourObjectif._URI === ueObjective._URI
        );

        if (objectiveAtom._aCombinaisonConditions != undefined && objectiveAtom._aCombinaisonConditions._aConditionObtention != undefined) {

          // Récupération des conditions d'obtention de type objectif pédagogique
          var objectivesConditionObtention: ConditionObtentionObjectifPedagogique[] = [];
          if (Array.isArray(objectiveAtom._aCombinaisonConditions._aConditionObtention)) {
            objectivesConditionObtention = objectiveAtom._aCombinaisonConditions._aConditionObtention.filter(conditionsObtention =>
              conditionsObtention.hasOwnProperty('_necessiteAcquisitionObjectifPedagogique')
            );
          } else {
            if (objectiveAtom._aCombinaisonConditions._aConditionObtention.hasOwnProperty('_necessiteAcquisitionObjectifPedagogique')) {
              objectivesConditionObtention = [objectiveAtom._aCombinaisonConditions._aConditionObtention];
            }
          }

          // Filtrage des objectifs pédagogiques obligatoires pour l'obtention de l'objectif pédagogique
          var mandatoryObjectives: Array<ObjectifPedagogique> = contributoryObjectives.filter(objective =>
            objectivesConditionObtention.some(conditonObtention => {
              return conditonObtention._necessiteAcquisitionObjectifPedagogique._URI === objective._estUneVersionDe._URI;
            })
          );

          setCurrentObjectiveConditionnalObjectives(mandatoryObjectives);

          // Tri des objectifs pédagogiques contributifs pour places les objectifs conditionnels en haut
          contributoryObjectives.sort((a: ObjectifPedagogique, b: ObjectifPedagogique) => {
            if (mandatoryObjectives.some(objective => { return objective._URI === a._URI }) &&
              !mandatoryObjectives.some(objective => { return objective._URI === b._URI })) {
              return -1;
            }
            if (!mandatoryObjectives.some(objective => { return objective._URI === a._URI }) &&
              mandatoryObjectives.some(objective => { return objective._URI === b._URI })) {
              return 1;
            }
            return 0;
          });

        }

        setCurrentObjectiveContributoryObjectives(contributoryObjectives);
      } else {

        setCurrentObjectiveConditionnalObjectives([]);
        setCurrentObjectiveContributoryObjectives([]);

      }

      setCurrentObjectiveContributoryObjectivesLoad(true);

    }
  }, [objectiveAtom]);


  function removeObjectiveFromContributive(e: ObjectifPedagogique) {

    // Suppression d'un objectif contributif
    setIsApiFetched(true);

    ApiService.removeObjectiveContributionToObjectif(objectiveAtom!._URI, e._URI).then(mObjective => {

      // MAJ des objectifs de l'UE avec l'objectif sans la contribution
      mObjective._contributionObjectifPourObjectif = mObjective._contributionObjectifPourObjectif.filter(c => c._pourObjectif._URI !== e._URI);
      var modifTUObjectives = [...teachingUnitObjectives.filter(o => o._URI !== mObjective._URI)];
      modifTUObjectives.push(mObjective);
      setTeachingUnitObjectives(modifTUObjectives);

      // Supression de la contribution de la liste des objectifs contributifs
      var modifiedObjectives: Array<ObjectifPedagogique> = currentObjectiveContributoryObjectives.filter(objective =>
        !(objective._URI === e._URI)
      );
      setCurrentObjectiveContributoryObjectives(modifiedObjectives);
      setCurrentObjectiveContributoryObjectivesLoad(false);
      setCurrentObjectiveContributoryObjectivesLoad(true);
      setIsApiFetched(false);

    });

  }


  function addObjectiveFromConditional(e: ObjectifPedagogique) {

    // Ajout de la condition d'obtention de l'objectif pédagogique cliqué
    setIsApiFetched(true);

    ApiService.addObjectiveConditionToObjectif(objectiveAtom!._URI, e._URI).then(objective => {

      setCurrentObjectiveContributoryObjectivesLoad(false);
      var modifiedObjectives: Array<ObjectifPedagogique> = currentObjectiveConditionnalObjectives;
      modifiedObjectives.push(e);
      setCurrentObjectiveConditionnalObjectives(modifiedObjectives);
      setCurrentObjectiveContributoryObjectivesLoad(true);
      setIsApiFetched(false);

    });

  }


  function removeObjectiveFromConditional(e: ObjectifPedagogique) {

    // Suppression de la condition d'obtention de l'objectif pédagogique cliqué
    setIsApiFetched(true);

    ApiService.removeObjectiveConditionToObjectif(objectiveAtom!._URI, e._URI).then(objective => {

      setCurrentObjectiveContributoryObjectivesLoad(false);
      var modifiedObjectives: Array<ObjectifPedagogique> = currentObjectiveConditionnalObjectives.filter(objective =>
        !(objective._URI === e._URI)
      );
      setCurrentObjectiveConditionnalObjectives(modifiedObjectives);
      setCurrentObjectiveContributoryObjectivesLoad(true);
      setIsApiFetched(false);

    });

  }


  var currentGraphObjectivesColumns: TableColumn<ObjectifPedagogique>[] = [
    {
      id: 1,
      name: 'Titre court',
      selector: e => e._titreCourt != undefined && e._titreCourt,
      sortable: true,
      grow: 1,
    },
    {
      id: 2,
      name: 'Titre',
      selector: e => e._titre != undefined && e._titre,
      sortable: true,
      grow: 4,
    },
    {
      id: 3,
      name: "Condition d'obtention",
      button: true,
      width: "160px",
      cell: (e) => (currentObjectiveConditionnalObjectives.some(objective => { return JSON.stringify(objective._URI) === JSON.stringify(e._URI) }) ?
        <div
          className={`${!teachingUnitModeLive ? "objective-edit-panel-conditional-objective-button" : ""} `}
          onClick={() => !teachingUnitModeLive && !isApiFetched && removeObjectiveFromConditional(e)}
        >
          <IconFullStar width={"24px"} />
        </div>
        :
        <div
          className={`${!teachingUnitModeLive ? "objective-edit-panel-conditional-objective-button" : ""} `}
          onClick={() => !teachingUnitModeLive && !isApiFetched && addObjectiveFromConditional(e)}
        >
          <IconEmptyStar width={"24px"} />
        </div>
      ),
    },
    // Possibilité de détacher un objectif pédagogique contributif en mode draft
    {
      id: 4,
      name: 'Détacher',
      button: true,
      width: "80px",
      cell: (e) => (e._URI != "" && !currentObjectiveConditionnalObjectives.some(objective => { return JSON.stringify(objective._URI) === JSON.stringify(e._URI) }) &&
        <a className="teaching-units-index-link" onClick={() => !isApiFetched && removeObjectiveFromContributive(e)}>
          <IconSurroundedDownArrow />
        </a>
      ),
      omit: teachingUnitModeLive ? true : false,
    },
    {
      id: 5,
      name: 'Éditer',
      button: true,
      width: "80px",
      cell: (e) => (
        <a className="teaching-units-index-link" onClick={() => { setObjectiveAtom(e); }}>
          <IconEdit />
        </a>
      )
    },
    {
      width: "0px",
    }
  ];


  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page :',
    rangeSeparatorText: 'de',
  };


  return (
    <>
      <div className="objective-edit-panel-index-table objective-edit-pantel-contributory-objectives">
        {currentObjectiveContributoryObjectivesLoad ?
          <DataTableExtensions
            columns={currentGraphObjectivesColumns}
            data={currentObjectiveContributoryObjectives}
            print={false}
            export={false}
            responsive
            filterPlaceholder={'Recherche...'}
          >
            <DataTable
              title={'Objectifs contributifs'}
              noDataComponent={"Pas d'objectif à afficher"}
              columns={currentGraphObjectivesColumns}
              data={currentObjectiveContributoryObjectives}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[3, 5, 10, 25, 50]}
              paginationComponentOptions={paginationComponentOptions}
              responsive
              highlightOnHover
              striped
            />
          </DataTableExtensions>
          :
          <div className="objective-edit-panel-index-loader loader">
            <Spinner> </Spinner>
          </div>
        }
      </div>
      <ContributoryObjectivesImport
        currentObjectiveContributoryObjectives={currentObjectiveContributoryObjectives}
        setCurrentObjectiveContributoryObjectives={setCurrentObjectiveContributoryObjectives}
        currentObjectiveContributoryObjectivesLoad={currentObjectiveContributoryObjectivesLoad}
        setCurrentObjectiveContributoryObjectivesLoad={setCurrentObjectiveContributoryObjectivesLoad}
      />
    </>
  );
}

export default ContributoryObjectives;
