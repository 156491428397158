export const DomaineConaissance = {
    sciences_humaines: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/1",
    economie: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/2",
    droit: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/3",
    developpement_durable: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/4",
    energie: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/5",
    mathematiques: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/6",
    developpement_professionnel_et_personnel: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/7",
    ingenierie_formation_pedagogie: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/8",
    droit_fiscal: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/9",
    langues: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/10",
    psychologie: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/11",
    science_politique: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/12",
    chimie: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/13",
    mecanique_theorique: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/14",
    physique: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/15",
    sciences_de_la_terre: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/16",
    sciences_naturelles: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/17",
    art: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/18",
    artisanat_art: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/19",
    audiovisuel_multimedia: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/20",
    communication_et_information: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/21",
    industrie_graphique_imprimerie: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/22",
    informatique_et_systemes_d_information: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/23",
    langages_informatiques: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/24",
    telecommunication: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/25",
    genie_industriel: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/26",
    manutention: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/27",
    qualite_industrielle: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/28",
    transport: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/29",
    agriculture_production_vegetale: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/30",
    environnement_amenagement: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/31",
    peche_aquaculture: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/32",
    agroalimentaire: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/33",
    cuir_peau: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/34",
    habillement: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/35",
    materiau_produit_chimique: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/36",
    textile: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/37",
    travail_materiau: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/38",
    batiment_gros_oeuvre: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/39",
    batiment_second_oeuvre: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/40",
    btp_conception_organisation: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/41",
    travaux_publics: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/42",
    automatisme_informatique_industrielle: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/43",
    electronique: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/44",
    mecanique_construction_reparation: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/45",
    encadrement_management: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/46",
    gestion_financiere_comptabilite: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/47",
    gestion_organisations: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/48",
    gestion_qualite: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/49",
    ressources_humaines: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/50",
    secretariat_assistanat: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/51",
    banque_assurance: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/52",
    commerce: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/53",
    commerce_international: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/54",
    gestion_commerciale_achats: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/55",
    immobilier: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/56",
    services_commerces_proximite: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/57",
    action_sociale: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/58",
    medecine: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/59",
    prevention_securite: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/60",
    sante: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/61",
    activite_physique_et_sportive: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/62",
    hotellerie_restauration: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/63",
    tourisme: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/64",
    electricite: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/65",
    genie_climatique: "https://data.dauphine.psl.eu/vocabulary/domaine-connaissance/66",
};

export default DomaineConaissance