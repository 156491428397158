import "./HelpNotifUserMenu.css";
import { useRecoilState } from "recoil";
import {
    rolesAtom,
    toggleModalAccountAtom,
    toggleModalHelpAtom,
    toggleModalNotificationAtom,
} from "../../../recoil/atoms";

function HelpMenu() {
  const [, setIsModalHelpOpen] = useRecoilState(toggleModalHelpAtom);

  return (
    <div
      className="helpNotifUserMenu-Btn question"
      onClick={() => {
        setIsModalHelpOpen(true);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="currentColor"
        className="bi bi-question-circle"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
      </svg>
    </div>
  );
}

function NotifMenu() {
  const [, setIsModalNotificationOpen] = useRecoilState(
    toggleModalNotificationAtom
  );

  return(
    <div
      className="helpNotifUserMenu-Btn bell"
      onClick={() => {
        setIsModalNotificationOpen(true);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="currentColor"
        className="bi bi-bell"
        viewBox="0 0 16 16"
      >
        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
      </svg>
    </div>
  );
}

function UserMenu() {
  const [, setIsModalAccountOpen] = useRecoilState(toggleModalAccountAtom);
  
  return(
    <div 
      className="helpNotifUserMenu-Btn person"
      onClick={() => {
          setIsModalAccountOpen(true);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={38}
        fill="currentColor"
        className="bi bi-person-circle"
        viewBox="0 0 16 16"
      >
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
        <path
          fillRule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
        />
      </svg>
    </div>
  );
}


function HelpNotifUserMenu() {
  const [rolesUser, ] = useRecoilState(rolesAtom);

  return (
    <>
      {rolesUser.includes('etudiant') &&
        <div className="helpNotifUserMenu-content">
          <HelpMenu />
          <NotifMenu />
          <UserMenu />
        </div>
      }
    </>
  );
}

export { HelpNotifUserMenu, HelpMenu, NotifMenu, UserMenu };
