import React from "react";
import "./SidebarListElement.css";
import { useRecoilState } from "recoil";
import { match } from "react-router-dom";
import {toggleSidebarAtom} from "../../../../recoil/atoms";


const SidebarListElement = ({
  label, 
  image,
  isActivated,
} : {
  label?: string;
  image?: any;
  isActivated: match<{}> | null,
}) => {
  const [isFullyOpenedSidebar, ] = useRecoilState(
    toggleSidebarAtom
  );

  return (
    <div className="sidebar-list-element">
      <div className={`sidebar-list-element-link ${isActivated != null ? "sidebar-list-element-link-active" : ""}`}>
        <span>
          <svg className={`sidebar-list-element-link-logo ${isActivated != null ? "sidebar-list-element-link-logo-active" : ""}`} viewBox="0 0 24 24">
            {image}
          </svg>
        </span>
        {isFullyOpenedSidebar && 
          <div className="sidebar-list-element-link-label">
            {label}
          </div>
        }
      </div>
    </div>
  );
};
export default SidebarListElement;
