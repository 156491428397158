import React from "react";

import "./VirtualTutor.css";
import SidePanel from "./SidePanel";
import LayerSelector from "./LayerSelector";
import ApiLoader from "./ApiLoader";
import ResourcesForm from "./ResourcesForm";
import QuestionForm from "./QuestionForm";
import Menus from "./Menus";
import "./Nodes.css";
import PollingHandler from "./PollingHandler/PollingHandler";
import BottomControls from "./BottomGraphControls/BottomControls";
import {useRecoilState} from "recoil";
import {selectedTeachingUnitAtom} from "../../recoil/graphFilters";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;
const VirtualTutor = ({
  useStudentId,
}: {
  useApi?: boolean;
  useParam?: boolean;
  useStudentId?: boolean;
}) => {
  return (
    <div className="main_container">
      <div className="graph_container">
        <ApiLoader
          imposedStudentId={useStudentId ? "u21tactimage" : undefined}
        />
      </div>
      <BottomControls />
      <LayerSelector />
      <SidePanel />
      <ResourcesForm />
      <QuestionForm />
      <Menus />
      <PollingHandler />
    </div>
  );
};
export default VirtualTutor;
