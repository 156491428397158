import { useEffect, useState } from "react";
import { api } from "../VirtualTutor/ApiLoader";
import { useParams } from "react-router-dom";
import "./LoginHandler.css";
import { Redirect } from "react-router-dom";
import { useRecoilState } from "recoil";
import {configsAtom, rolesAtom, userAtom} from "../../recoil/atoms";
import Loader from "./Loader";
import {ApiService} from "./restServices";
import FoxLogo from "../../images/fox.png";
import Personne from "../../interface/personne";


const LoginHandler = (props: any) => {

  const [loginIsLoading, setloginIsLoading] = useState(false);

  const [isLogin, setIsLogin] = useState(false);
  const [userRoles,setUserRoles] = useRecoilState(rolesAtom);
  const [, setUser] = useRecoilState(userAtom);
  const [configs, setConfigs] = useRecoilState(configsAtom);

  const { studentId } = useParams<{ studentId: string }>();

  useEffect(() => {

    if(!loginIsLoading){
      if(process.env.REACT_APP_OFFLINE  == "true"){
        return setIsLogin(true);
      }

      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        setloginIsLoading(true);
        (async function initTuteur() {

          const resultAuthentification = await fetch(
              `${api}/authentification?passport=token`,
              {
                method: "GET",
                headers: { "Content-Type": "application/json" },
              }
          );

          const token = (await resultAuthentification.json()) as {
            user: Personne;
            error: string;
            accessToken: string;
            refreshToken: string;
            expireAt: string;
            role:string,
          };

          if (token.error) {
            switch (token.error) {
              case "NO_SESSION_PASSPORT":
                window.location.replace(
                    process.env.REACT_APP_ORCHESTRATEUR_ENDPOINT +
                    "/cas/login?target=" +
                    process.env.REACT_APP_ORCHESTRATEUR_ENDPOINT +
                    "/api/authentification?passport=redirection"
                );
            }
          } else {
            localStorage.setItem("accessToken", token.accessToken);
            localStorage.setItem("refreshToken", token.refreshToken);
            localStorage.setItem("accessTokenExpireAt", ""+new Date(token.expireAt).getTime());
            setUserRoles([token.role]);
            setUser(token.user);
            setIsLogin(true);

            //Chargement des configurations du site.
            if(configs.length == 0){
              setConfigs(await ApiService.getConfigs());
            }
            setloginIsLoading(false);
          }
        })().then(() => {});

      } else {

        if (parseInt(localStorage.getItem("accessTokenExpireAt")!) < Date.now()) {
          setloginIsLoading(true);
          (async function initTuteur (){

            var refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken) {
              const tokenRequest = await fetch(`${api}/token`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  refreshToken: refreshToken,
                }),
              });
              const token = await tokenRequest.json();
              if (!token.error) {
                localStorage.setItem("role", token.accessToken);
                localStorage.setItem("accessToken", token.accessToken);
                localStorage.setItem("accessTokenExpireAt", ""+new Date(token.expireAt).getTime());
                setUserRoles([token.role]);
                setUser(token.user);
                setIsLogin(true);

                //Chargement des configurations du site.
                if(configs.length == 0){
                  setConfigs(await ApiService.getConfigs());
                }
                setloginIsLoading(false);
              } else {
                //Pas de session Passport OU Mauvais refresh token = redirection login
                localStorage.removeItem("accessTokenExpireAt");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                setIsLogin(false);
                switch (token.error) {
                  case "NO_SESSION_PASSPORT":
                    window.location.replace(
                        process.env.REACT_APP_ORCHESTRATEUR_ENDPOINT +
                        "/cas/login?target=" +
                        process.env.REACT_APP_ORCHESTRATEUR_ENDPOINT +
                        "/api/authentification?passport=redirection"
                    );
                }
              }
            } else {
              //Pas de refreshtoken = redirection login
              localStorage.removeItem("accessTokenExpireAt");
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              setIsLogin(false);
              setloginIsLoading(false);
            }
          })().then(() => {});
        }

        else {
          setloginIsLoading(true);

          (async function initTuteur(){
            //Chargement des configurations du site.
            if(configs.length == 0){
              setConfigs(await ApiService.getConfigs());
            }

            if(userRoles.length == 0){
              setUserRoles([await ApiService.getRoleUser()]);
              setIsLogin(true);
              setUser(await ApiService.getUser());
            }
          })().then(() => {setloginIsLoading(false);});

        }
      }
    }

  });

  return isLogin && configs.length > 0 ? props.children :
     <Loader/>

};
export default LoginHandler;
