import React from "react";

const IconSurroundedUpArrow = () => {
  return (
    <svg fill="#6371B0" width="24px" viewBox="0 0 32 32">
      <path d="m16.71 8.29a1 1 0 0 0 -.33-.21 1 1 0 0 0 -.76 0 1 1 0 0 0 -.33.21l-4 4a1 1 0 0 0 1.42 1.42l2.29-2.3v11.59a1 1 0 0 0 2 0v-11.59l2.29 2.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
      <path d="m16 1a15 15 0 1 0 15 15 15 15 0 0 0 -15-15zm0 28a13 13 0 1 1 13-13 13 13 0 0 1 -13 13z" />
    </svg>
  );
};
export default IconSurroundedUpArrow;
