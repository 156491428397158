import React from "react";

const SVGGroupProgress = (props : any) => {
  return (
    <g transform={props.scale}>
      <path
        d="M321.7 71.3012C321.7 110.68 289.777 142.602 250.399 142.602C211.02 142.602 179.097 110.68 179.097 71.3012C179.097 31.9227 211.02 0 250.399 0C289.777 0 321.7 31.9227 321.7 71.3012Z"
        fill={props.color}
      />
      <path
        d="M393.001 298.54H107.796V227.239C112.59 192.308 174.768 164.672 250.399 164.672C326.03 164.672 388.208 192.308 393.001 227.239V298.54Z"
        fill={props.color}
      />
      <path
        d="M487.935 136.749C487.935 163.05 466.614 184.371 440.312 184.371C414.011 184.371 392.69 163.05 392.69 136.749C392.69 110.448 414.011 89.1266 440.312 89.1266C466.614 89.1266 487.935 110.448 487.935 136.749Z"
        fill={props.color}
      />
      <path
        d="M108.107 136.749C108.107 163.05 86.7861 184.371 60.4851 184.371C34.1841 184.371 12.8629 163.05 12.8629 136.749C12.8629 110.448 34.1841 89.1266 60.4851 89.1266C86.7861 89.1266 108.107 110.448 108.107 136.749Z"
        fill={props.color}
      />
      <path
        d="M419.671 298.54V226.396C418.938 221.062 416.726 215.872 413.854 210.877C422.254 209.739 431.132 209.129 440.313 209.129C490.827 209.129 496.799 227.587 500 250.918V298.54H419.671Z"
        fill={props.color}
      />
      <path
        d="M80.9903 298.54V226.396C81.7242 221.056 83.9397 215.86 86.8171 210.86C78.4542 209.733 69.6198 209.129 60.4851 209.129C9.97103 209.129 3.20135 227.587 0 250.918V298.54H80.9903Z"
        fill={props.color}
      />
    </g>
  );
};
export default SVGGroupProgress;
