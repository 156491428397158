import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {studentAtom, studentsList} from "../../../../../recoil/atoms";
import {ApiService} from "../../../../LoginHandler/restServices";
import {Link} from "react-router-dom";
import {Spinner} from "reactstrap";
import "./StudentSelector.css"
import IconEye from "../../../../icons/SVGIconEye";
import IEtudiant from "../../../../../interface/Etudiant.interface";


const StudentSelector = () => {

    const [isModalCourseListOpen,setModalStudentListOpen] = useState<Boolean>(false);
    const [studentList, setListStudent] = useRecoilState(studentsList);
    const [student] = useRecoilState(studentAtom);
    const [dataIsLoad, setDataIsLoad] = useState<boolean>(false);

    const [filterStudent, setFilterStudent] =  useState<IEtudiant[]>([]);

    const filterAvailableUser = (event) => {
        setFilterStudent(
            event.target.value != "" ?
                studentList.filter(x => (
                        x.idPassport != student?.id
                        && ( (x.nom.toUpperCase()+' '+x.prenom.toUpperCase()).indexOf(event.target.value.toUpperCase()) != -1 )
                    )
                ) :
                studentList.filter(x => (
                        x.idPassport != student?.id
                    )
                )
        );
    };

    useEffect(() => {
        if(studentList.length == 0){
            ApiService.getAllStudent().then( (etudiants) => {
                setListStudent((Array.isArray(etudiants) ? etudiants : [etudiants]).filter(x => x.idPassport != undefined) );
                setFilterStudent((Array.isArray(etudiants) ? etudiants : [etudiants]).filter(x => x.idPassport != undefined));
                setDataIsLoad(true);
            });
        } else {
            setFilterStudent(studentList);
            setDataIsLoad(true);
        }
    },[student]);

    return dataIsLoad ?
        (
            <div className="studentSelector">
                <div className="currentStudent" onClick={() => {setModalStudentListOpen(!isModalCourseListOpen)}}>
                    <IconEye className={"svgEye"} fill={"white"} />
                    <span>{student?.lastName} {student?.firstName}</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi-chevron-down"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                    </svg>
                </div>
                <div className={`listStudentSelector ${isModalCourseListOpen ? "modalOpen" : "modalClose"} `} >
                    <input type="text" name="searchStudent" onChange={(value) => {filterAvailableUser(value)}} placeholder="Nom prénom..."/>
                    <div className="availableStudent">
                        {
                            filterStudent.filter(x => x.idPassport != student?.id).sort(function (x, y) {
                                return x.toString().localeCompare(y.toString());
                            }).map(x => {
                                return (
                                    <Link to={`/etudiants/${x.idPassport}/graphe`}
                                          className="teaching-units-index-link"
                                    >
                                        <p>{x.nom.toUpperCase()} {x.prenom}</p>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
        :
        (<></>)
};

export default StudentSelector;