import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  graphSelectorPO,
  lockedAtom,
  settingsAtom,
  zoomAtom,
  zoomAtomT,
} from "../../recoil/atoms";

import BackgroundSvgGrid from "../KnowledgeGraph/BackgroundSvgGrid";
import PositionGraph from "../PositionsForceGraph/PositionGraph";
import { AGGREGATOR, INode, TOPIC } from "../../interface/graph";

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const PositionSimulation = () => {
  const width = 900;
  const height = 900;

  const [locked, setLocked] = useRecoilState(lockedAtom);
  const d3Container = useRef(null);
  const data = useRecoilValue(graphSelectorPO);
  const [currentZoom, setZoom] = useRecoilState(zoomAtom);
  const [zoomUpdate] = useRecoilState(zoomAtomT);

  const simulation = useRef<any>(d3.forceSimulation([]));
  const [graph, setGraph] = useState<any>({ nodes: [], links: [] });
  const pg: any = usePrevious(graph);
  const [
    {
      linkDistanceActivity,
      linkDistanceTopic,
      linkStrengthActivity,
      linkStrengthTopic,
      centeringStrength,
      forceX,
      forceXX,
      forceY,
      forceYY,
      alpha,
      alphaDecay,
      velocityDecay,
      startingTicks,
      collideStrength,
      collideRadiusActivity,
      collideRadiusTopic,
      chargeStrengthActivity,
      chargeStrengthTopic,
    },
  ] = useRecoilState(settingsAtom);
  useEffect(() => {
    setLocked(false);
  }, []);
  useEffect(() => {
    const links = data.links.map((d) => ({ ...d }));
    const nodes = data.nodes.map((d, i) => ({ ...d }));
    let prev: any;
    prev = simulation.current
      .nodes()
      .map((d: any) => ({ ...d }))
      .reduce(
        (a: any, b: any, i: number) => ({ ...a, [pg?.nodes[i]?.id]: b }),
        {}
      );
    // }

    if (!locked) {
      simulation.current
        .nodes(nodes)
        .force(
          "link",
          d3
            // @ts-ignore
            .forceLink(links)
            .id((d: any) => d.id)
            .distance((l: any) => {
              return isActivity(l.source)
                ? linkDistanceActivity
                : linkDistanceTopic;
            })
            .strength((l: any) => {
              return isActivity(l.source)
                ? linkStrengthActivity
                : linkStrengthTopic;
            })
        )
        .force(
          "charge",
          d3
            .forceManyBody()
            .strength((d: any) => {
              return isActivity(d)
                ? chargeStrengthActivity
                : chargeStrengthTopic;
            })
            .distanceMax(200)
        )
        .force(
          "collide",
          d3
            .forceCollide((d: any) => {
              return isActivity(d) ? collideRadiusActivity : collideRadiusTopic;
            })
            .strength(collideStrength)
        )
        .force(
          "center",
          d3.forceCenter(width / 2, height / 2).strength(centeringStrength)
        )
        .force("x", d3.forceX(forceXX).strength(forceX))
        .force("y", d3.forceY(forceYY).strength(forceY))
        .velocityDecay(velocityDecay)
        .alphaDecay(alphaDecay)
        .alpha(alpha)
        .tick(startingTicks)
        .restart();
    } else if (pg) {
      simulation.current
        .nodes(
          // nodes
          nodes.map((n: any) => {
            let prevElement = prev[n.id];
            if (prevElement) {
              n.fx = prevElement.x;
              n.fy = prevElement.y;
            } else {
              let parent: any | undefined;
              parent = data.nodes.find(
                (k: any) => k.cluster && k.cluster.includes(getId(n))
              )?.id;
              parent =
                parent || data.links.find((l) => l.source === n.id)?.target;
              if (parent && prev[parent]) {
                n.x = prev[parent].fx;
                n.y = prev[parent].fy;
              }
            }

            return n;
          })
        )
        .force(
          "link",
          d3
            // @ts-ignore
            .forceLink(links)
            .id((d: any) => d.id)
            .distance((l: any) => {
              return isActivity(l.source)
                ? linkDistanceActivity
                : linkDistanceTopic;
            })
            .strength((l: any) => {
              return isActivity(l.source)
                ? linkStrengthActivity
                : linkStrengthTopic;
            })
        )
        .force(
          "charge",
          d3
            .forceManyBody()
            .strength((d: any) => {
              return isActivity(d)
                ? chargeStrengthActivity
                : chargeStrengthTopic;
            })
            .distanceMax(200)
        )
        .force(
          "collide",
          d3
            .forceCollide((d: any) => {
              return isActivity(d) ? collideRadiusActivity : collideRadiusTopic;
            })
            .strength(collideStrength)
        )
        .force(
          "center",
          d3.forceCenter(width / 2, height / 2).strength(centeringStrength)
        )
        .force("x", d3.forceX(forceXX).strength(forceX))
        .force("y", d3.forceY(forceYY).strength(forceY))
        .velocityDecay(velocityDecay)
        .alphaDecay(alphaDecay)
        .alpha(alpha)
        .tick(startingTicks)
        .restart();
    } else {
      simulation.current
        .nodes(
          // nodes
          nodes.map((n: any) => {
            let prevElement = prev[n.id];
            if (prevElement) {
              n.fx = prevElement.x;
              n.fy = prevElement.y;
              n.x = prevElement.x;
              n.y = prevElement.y;
            } else {
              let parent: any | undefined;
              parent =
                parent ||
                data.nodes.find(
                  (k: any) => k.cluster && k.cluster.includes(getId(n))
                )?.id;
              parent =
                parent || data.links.find((l) => l.source === n.id)?.target;
              if (parent && prev[parent]) {
                n.x = prev[parent].x;
                n.y = prev[parent].y;
              }
            }

            return n;
          })
        )
        .force(
          "link",
          d3
            // @ts-ignore
            .forceLink(links)
            .id((d: any) => d.id)
            .distance((l: any) => {
              return isActivity(l.source)
                ? linkDistanceActivity
                : linkDistanceTopic;
            })
            .strength((l: any) => {
              return isActivity(l.source)
                ? linkStrengthActivity
                : linkStrengthTopic;
            })
        )
        .force(
          "charge",
          d3.forceManyBody().strength((d: any) => {
            return isActivity(d) ? chargeStrengthActivity : chargeStrengthTopic;
          })
        )
        .force(
          "collide",
          d3
            .forceCollide((d: any) => {
              return isActivity(d) ? collideRadiusActivity : collideRadiusTopic;
            })
            .strength(collideStrength)
        )
        .force(
          "center",
          d3.forceCenter(width / 2, height / 2).strength(centeringStrength)
        )
        .force("x", d3.forceX(forceXX).strength(forceX))
        .force("y", d3.forceY(forceYY).strength(forceY))
        .velocityDecay(velocityDecay)
        .alphaDecay(alphaDecay)
        .alpha(alpha)
        .tick(startingTicks)
        .restart();
    }
    setGraph({
      links,
      nodes: nodes.map((n: any) => {
        let prevElement = prev[n.id];
        if (prevElement) {
          n.px = prevElement.x;
          n.py = prevElement.y;
        } else {
          let parent: any | undefined;
          parent = data.nodes.find(
            (k: any) => k.cluster && k.cluster.includes(getId(n))
          )?.id;
          parent = parent || data.links.find((l) => l.source === n.id)?.target;
          if (parent && prev[parent]) {
            n.px = prev[parent].x;
            n.py = prev[parent].y;
          }
        }
        return n;
      }),
    });
    // /
    // }
  }, [locked, data]);

  useEffect(() => {
    if (d3Container.current) {
      const svg = d3.select(d3Container.current);
      let zoomBehavior = d3.zoom();
      svg
        .call(
          // @ts-ignore
          zoomBehavior
            .on("zoom", function ({ transform }) {
              svg.select(".zoomable").attr("transform", transform);
            })
            .on("end", function (event) {
              const { transform } = event;
              setZoom(transform);
            })
            .scaleExtent([0.3, 3])
        )
        .on("dblclick.zoom", null)
        // @ts-ignore
        .call(zoomBehavior.transform, zoomUpdate);
    }
    return () => {};
  }, [setZoom, zoomUpdate]);
  return (
    <svg
      className="d3-component"
      width="100vw"
      height="100vh"
      viewBox={`0, 0, ${width}, ${height}`}
      ref={d3Container}
    >
      {" "}
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="0"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="#717884" />
        </marker>{" "}
        <marker
          id="arrowhead-done"
          markerWidth="10"
          markerHeight="7"
          refX="0"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="#1e9b7e" />
        </marker>
        <marker
          id="arrowhead-mine"
          markerWidth="10"
          markerHeight="7"
          refX="0"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="#f08482" />
        </marker>
        <marker
          id="arrowhead-started"
          markerWidth="10"
          markerHeight="7"
          refX="0"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="#314587" />
        </marker>
      </defs>
      <g
        className="zoomable"
        style={{ cursor: "all-scroll" }}
        transform={currentZoom.toString()}
      >
        <BackgroundSvgGrid />
        {/*{simulation && graph && (*/}
        {graph && <PositionGraph data={graph} />}
        {/*)}*/}
      </g>
    </svg>
  );
};
export default PositionSimulation;

export const getId = (n: INode) => {
  let match = n.id.match(/[a-z0-9A-Z-_]*\/[a-z0-9A-Z-_]*$/);
  return match && match[0].replace("/", "_");
};
export const isActivity = (n: INode) =>
  n && n.type !== AGGREGATOR && n.type !== TOPIC;
