import NavigationMenu from "./Menus/NavigationMenu";
import ActivityFilter from "./Menus/ActivityFilter";
import { HelpNotifUserMenu } from "./Menus/HelpNotifUserMenu";
import HelpPanel from "./Menus/HelpNotifUserMenu/HelpPanel";
import NotificationPanel from "./Menus/HelpNotifUserMenu/NotificationPanel";
import AccountPanel from "./Menus/HelpNotifUserMenu/AccountPanel";
import {useRecoilState} from "recoil";
import {rolesAtom} from "../../recoil/atoms";
import RefreshGraph from "./Menus/RefreshGraph";

function Menus() {
  const [rolesUser, ] = useRecoilState(rolesAtom);

  return (
    <>
      <NavigationMenu />
      <ActivityFilter />
      <HelpNotifUserMenu />
      <HelpPanel />
      <AccountPanel />
      <NotificationPanel />
    </>
  );
}

export default Menus;