import { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { useRecoilState } from "recoil";
import { Redirect, useParams } from "react-router-dom";
import * as d3 from "d3";
import {
  graphAtom,
  openNodesAtom, refreshGraphState,
  rolesAtom,
  selectedNodeIDAtom,
  studentAtom,
  toggleTeachingUnitModeLiveAtom
} from "../../../../recoil/atoms";
import {
  activityDurationFilterAtom,
  activityDurationOptionsDefault,
  activityProgressFilterAtom,
  activityProgressOptions,
  activityTypeFilterAtom, activityTypesOptions,
  selectedTeachingUnitAtom,
  teachingUnitsAtom
} from "../../../../recoil/graphFilters";
import {ApiService} from "../../../LoginHandler/restServices";
import {IFetchState, IStudent} from "../../../../interface/graph";
import {jsonToForceGraph} from "../../../../extractor/newDataExtractor";

import {TeachingUnit} from "../../../../interface/teachingUnits";
import PositionGraphEnseignant from "./PositionGraphEnseignant"; 

const parseStudentDate: (dateParam: any) => Date = (dateParam: string) => {
  return (
    d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ")(
      Array.isArray(dateParam) ? dateParam[0] : dateParam
    ) || new Date()
  );
};

export const api = process.env.REACT_APP_USE_REDIRECT
  ? process.env.REACT_APP_USE_REDIRECT
  : process.env.REACT_APP_ORCHESTRATEUR_ENDPOINT + "/api";
function LoaderEnseignantGraph({
  useApi,
  imposedStudentId,
}: {
  useApi?: boolean;
  imposedStudentId?: string;
}) {

  const [fetchState, setFetchState] = useState<IFetchState>("loading");
  const [, setGraphData] = useRecoilState(graphAtom);
  const [, setOpenNodes] = useRecoilState(openNodesAtom);
  const [activityType, setActivityType] = useRecoilState(activityTypeFilterAtom);

  const [, setTeachingUnits] = useRecoilState(teachingUnitsAtom);
  const [student, setStudent] = useRecoilState(studentAtom);

  const [teachingUnit, setSelectedTeachingUnit] = useRecoilState(
    selectedTeachingUnitAtom
  );

  const [activityProgress, setActivityProgressType] = useRecoilState(
    activityProgressFilterAtom
  );
  const [activityDuration, setActivityDurationFilter] = useRecoilState(
    activityDurationFilterAtom
  );

  const [refreshGraph,] = useRecoilState(refreshGraphState);

  const { studentId } = useParams<{ studentId: string }>();
  const [teachingUnitModeLive,] = useRecoilState(toggleTeachingUnitModeLiveAtom);
  const { teachingUnitTitle } = useParams<{ teachingUnitTitle: string }>();

  useEffect(() => {
    if(teachingUnit == undefined){
      ApiService.getTeachingUnits().then(teachingUnits => {
        var currentTeachingUnit: TeachingUnit = teachingUnits.find(teachingUnit => 
          teachingUnit._nom && teachingUnit._nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == teachingUnitTitle
        );
        if (currentTeachingUnit != undefined) {
          setSelectedTeachingUnit(currentTeachingUnit);
        } else {
          <Redirect to="/"></Redirect>
        }
      });
    }
  },[teachingUnitTitle])
  
  useEffect(() => {
    const fetchStudentAndCourse = async () => {
      try {
        const [teachingUnits] = await Promise.all([
          ApiService.getTeachingUnits(),
        ]);

        setTeachingUnits(teachingUnits);

      } catch (e) {
        setFetchState("error");
      }
    };

    fetchStudentAndCourse();
  }, [setActivityType, setGraphData, setOpenNodes, studentId, useApi]);


  useEffect(() => {

    const fetchData = async () => {

      if (teachingUnit) {
        setFetchState("loading");

        // reset activities filters
        setActivityType(activityTypesOptions[0]);
        setActivityProgressType(activityProgressOptions[0]);
        setActivityDurationFilter(activityDurationOptionsDefault[0]);
        try {

          const student = imposedStudentId != undefined ?
              await ApiService.getStudent(imposedStudentId )
              :
              {URI: "", lastConnection: new Date()} as IStudent;

          const [
            objectives,
            activities,
            stats,
          ] = await Promise.all([
            teachingUnitModeLive ? ApiService.getAllObjectives(teachingUnit._URI, student.id) : ApiService.getObjectifsStatutDraft(teachingUnit._URI, student._idPassport),
            ApiService.getAllActivitiesFromUE(teachingUnit._URI, student._idPassport),
            ApiService.getObjectiveStatistics(teachingUnit._URI, student._idPassport)
          ]);

          const studentActivities = imposedStudentId != undefined ?
              await ApiService.getAllStudentActivities(teachingUnit._URI, student._idPassport)
              :
              [];

          const studentObjectives = imposedStudentId != undefined ?
              await ApiService.getAllStudentObjectives(teachingUnit._URI, student._idPassport)
              :
              [];

          const extracted = jsonToForceGraph(
            objectives,
            activities,
            studentActivities,
            studentObjectives,
            student ,
            stats,
            {
                activityDuration: activityDuration,
                activityProgress: activityProgress.status,
                activityTpe: activityType.type
            },
          );

          setGraphData(Object.create(extracted));

          setFetchState("done");
        } catch (e) {
          setFetchState("error");
        }
      }
    };
    if (teachingUnit) {
      fetchData();
    }
  }, [
    teachingUnit,
    setActivityType,
    setGraphData,
    setOpenNodes,
    studentId,
    useApi,
    student,
    setActivityProgressType,
    setActivityDurationFilter,
    teachingUnitModeLive,
    refreshGraph
  ]);
  return (
    <>
      {fetchState === "loading" && (
        <div className="loader">
          <Spinner> </Spinner>
        </div>
      )}
      {fetchState === "done" && <PositionGraphEnseignant />}
      {fetchState === "build" && (
          <div className="error">
            <h2>La construction de votre graphe de connaissances est en cours...</h2>
            <h3>Veuillez patienter quelques instants.</h3>
          </div>
      )}
      {fetchState === "error" && (
        <div className="error">
          <h2>Impossible d'afficher le graphe : des erreurs sont présentes, veuillez éditer et corriger le graphe.</h2>
        </div>
      )}
    </>
  );
}

export default LoaderEnseignantGraph;
