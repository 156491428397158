export const SVGJeux = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="30" height="30" viewBox="0 0 30 30" className="svg-node-icon">
      <g>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M19.4,27c0.8,0,1.4-0.9,1-1.6l-2.6-4c0.8,0.4,1.9,1.1,3.4,1.1c3,0,4.6-2.5,4.6-5.5c0-6.1-10.1-12.5-10.1-12.5
          S5.6,10.9,5.6,17c0,3,1.5,5.5,4.6,5.5c1.5,0,2.6-0.7,3.4-1.1l-2.6,4c-0.4,0.7,0.2,1.6,1,1.6H19.4z"/>
      </g>
    </svg>
  );
};
