import React, {useEffect, useState} from "react";
import "./Config.css";
import HelpPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import AdminMenu from "../../PageElement/AdminMenu/AdminMenu";
import Breadcrumb from "../../PageElement/Breadcrumb/Breadcrumb";
import Sidebar from "../../PageElement/Sidebar/Sidebar";
import {useRecoilState} from "recoil";
import {configsAtom} from "../../../../recoil/atoms";
import {ApiService} from "../../../LoginHandler/restServices";
import IConfiguration from "../../../../interface/Configuration.interface";


const ConfigInput = (props : {config : IConfiguration}) => {

    const [value, setValue] = useState(props.config.valeur);
    const [configs, setConfigs] = useRecoilState(configsAtom);

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onBlur = (e) => {
        setValue(e.target.value);
        ApiService.editConfig(props.config.nom,value);

        // Ajout de la nouvelle configuration dans le recoil state
        var newConfigs: IConfiguration[] = configs.filter(c => c.URI !== props.config.URI);
        newConfigs.push(
            {
                URI: props.config.URI,
                nom: props.config.nom,
                valeur: e.target.value
            }
        );
        setConfigs(newConfigs);
    };

    return (
        <input
            value={value}
            className="objective-edit-panel-form-input"
            onChange={(e) => {onChange(e)}}
            onBlur={(e) => {onBlur(e)}}
        />
    )
}

const Config = (props: any) => {

  const [configs, setConfigs] = useRecoilState(configsAtom);

  useEffect(() => {
    if(configs.length == 0){
        ApiService.getConfigs().then( c => {
            setConfigs(c);
        })
    }
  },[]);

  const configHidden = ['roleDebug'];
  return (
    <div className="admin">
      <Sidebar />
        <div className="admin-body">
            <div className="admin-content">
                <Breadcrumb/>
                <AdminMenu/>
                { configs.length != 0 &&
                    <div className="objective-edit-panel-form">
                        {configs.filter(x => !configHidden.includes(x.nom)).map(config => {
                            switch (config.nom) {
                                case "categorieIdCoursMoodleEligible" :
                                    return(
                                        <div className="objective-edit-panel-form-titre-court">
                                            <label className="objective-edit-panel-form-label">
                                                <span>Identifiant de catégorie cours Moodle</span>
                                            </label>
                                            <ConfigInput config={config}/>
                                            <i>Catégorie utilisée pour identifier les cours Moodle éligibles au Tuteur Virtuel.</i>
                                        </div>
                                    )
                                default:
                                    return(
                                        <div className="objective-edit-panel-form-titre-court">
                                            <label className="objective-edit-panel-form-label">
                                                <span>{config.nom}</span>
                                                <span className="objective-edit-panel-form-label-max-length">(5 caractères maximum)</span>
                                            </label>
                                            <ConfigInput config={config}/>
                                        </div>
                                    )
                            }
                        })}
                    </div>
                }
                <HelpPanel />
                <AccountPanel />
                <NotificationPanel />
            </div>
        </div>
    </div>
  );
};
export default Config;
