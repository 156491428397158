import { useEffect, useState } from "react";
import Select from 'react-select';
import "./ObjectiveCreationForm.css";
import { useRecoilState } from "recoil";
import { toggleObjectiveEditModalAtom } from "../../../../../recoil/atoms";
import { selectedObjectiveAtom, selectedTeachingUnitAtom } from "../../../../../recoil/graphFilters";
import { ApiService } from "../../../../LoginHandler/restServices";
import { ObjectifPedagogique } from "../../../../../interface/objectifPedagogique";
import { Button } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { ObjectifPedagogiqueSerie } from "../../../../../interface/objectifPedagogiqueSerie";
import domaineConnaissance from "../../../../../data/domaineConaissance";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ObjectiveCreationForm() {

  const [selectedTeachingUnit,] = useRecoilState(
    selectedTeachingUnitAtom
  );
  const [, setObjectiveAtom] = useRecoilState(
    selectedObjectiveAtom
  );
  const [objectiveEditModalOpen,] =
    useRecoilState(toggleObjectiveEditModalAtom);
  const [valueTitreCourtInput, setValueTitreCourtInput] = useState<string>();
  const [valueTitreInput, setValueTitreInput] = useState<string>();

  const { register, reset, handleSubmit, control } = useForm({ mode: "onChange" });

  // S'active à l'ouverture/fermeture de la fenêtre
  useEffect(() => {
    if (objectiveEditModalOpen) {
      setValueTitreCourtInput('');
      setValueTitreInput('');
      reset({
        valueTitreCourt: '',
        valueTitre: '',
        valueDomaineConnaissance: '',
        valueDescription: '',
      });
    }
  }, [objectiveEditModalOpen]);


  const onSubmit = async (data: any) => {
    var domaineConaissance: string[] = Array.isArray(data.valueDomaineConnaissance) ?
      data.valueDomaineConnaissance.map(domaineConnaissance => domaineConnaissance.value) :
      undefined
    var objectiveToCreate: ObjectifPedagogique = {
      _URI: '',
      _titreCourt: data.valueTitreCourt,
      _titre: data.valueTitre,
      _domaineConnaissance: domaineConaissance != undefined ? domaineConaissance : [],
      _description: data.valueDescription,
      _estUneVersionDe: { _URI: '' } as ObjectifPedagogiqueSerie,
    }
    selectedTeachingUnit && ApiService.createObjective(objectiveToCreate, selectedTeachingUnit._URI).then(newObjective => {
      setObjectiveAtom(newObjective);
    });
  };

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data))}>
      <div className="objective-edit-panel-form">
        <div className="objective-edit-panel-form-titre-court">
          <label className="objective-edit-panel-form-label">
            <span>Titre court </span>
            <span className="objective-edit-panel-form-label-max-length">(5 caractères maximum)</span>
          </label>
          <input
            {...register("valueTitreCourt", { required: "Ce champ est requis" })}
            className="objective-edit-panel-form-input"
            onChange={(e) => { setValueTitreCourtInput(e.target.value) }}
            maxLength={5}
          />
        </div>
        <div className="objective-edit-panel-form-titre">
          <label className="objective-edit-panel-form-label">Titre</label>
          <input
            {...register("valueTitre", { required: "Ce champ est requis" })}
            className="objective-edit-panel-form-input"
            onChange={(e) => { setValueTitreInput(e.target.value) }}
          />
        </div>
        <div className="objective-edit-panel-form-domaine">
          <label className="objective-edit-panel-form-label">Domaine de connaissance</label>
          <Controller
            name="valueDomaineConnaissance"
            control={control}
            render={({ field }) => (
              <Select
                isMulti
                isClearable={false}
                placeholder="Sélection..."
                options={domaineConnaissance}
                className="basic-multi-select"
                classNamePrefix="objective-edit-panel-form-domaine-select"
                {...field}
              />
            )}
          />
        </div>
        <div className="objective-edit-panel-form-description">
          <label className="objective-edit-panel-form-label">Description</label>
          <input
            {...register("valueDescription")}
            className="objective-edit-panel-form-input"
          />
        </div>
      </div>
      <div className="objective-edit-panel-form-creation">
        <Button
          className={`btn ${valueTitreCourtInput != '' && valueTitreInput != '' ?
            "objective-edit-panel-form-creation-button-submit" : "btn-secondary objective-edit-panel-form-creation-button-nosubmit"}`}
        >
          Créer l'objectif
        </Button>
      </div>
    </form>
  );
}

export default ObjectiveCreationForm;
