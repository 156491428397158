import { useState, useEffect } from "react";
import "./ObjectiveEditPanel.css";
import "./ContributoryActivities.css";
import { useRecoilState } from "recoil";
import {
  currentObjectiveActivitiesAtom,
  selectedTeachingUnitAtom,
} from "../../../../../recoil/graphFilters";
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { ApiService } from "../../../../LoginHandler/restServices";
import { Spinner } from "reactstrap";
import { toggleObjectiveEditModalAtom, toggleTeachingUnitModeLiveAtom } from "../../../../../recoil/atoms";
import { MoodleActivity } from "../../../../../interface/moodleActivity";
import { ActivitePedagogique } from "../../../../../interface/activitePedagogique";
import IconSurroundedUpArrow from "../../../../icons/SVGIconSurroundedUpArrow";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ContributoryActivitiesImport(props) {

  const [objectiveEditModalOpen,] = useRecoilState(toggleObjectiveEditModalAtom);
  const [selectedTeachingUnit,] = useRecoilState(selectedTeachingUnitAtom);
  const [teachingUnitModeLive,] = useRecoilState(toggleTeachingUnitModeLiveAtom);
  const [currentObjectiveActivities, setCurrentObjectiveActivities] = useRecoilState(currentObjectiveActivitiesAtom);

  const [currentObjectiveContributoryActivitiesImportableLoad, setCurrentObjectiveContributoryActivitiesImportableLoad] = useState(false);
  const [currentObjectiveContributoryActivitiesImportable, setCurrentObjectiveContributoryActivitiesImportable] = useState<MoodleActivity[]>([]);
  const [teachingUnitActivitiesLoad, setTeachingUnitActivitiesLoad] = useState(false);
  const [isApiFetched, setIsApiFetched] = useState<boolean>(false);


  // Récupération de toutes les activités de l'unité d'enseignement
  useEffect(() => {
    if (selectedTeachingUnit != undefined && props.currentObjectiveActivitiesLoad && objectiveEditModalOpen) {
      setCurrentObjectiveContributoryActivitiesImportableLoad(false);
      setTeachingUnitActivitiesLoad(false);
      ApiService.getAllMoodleActivitiesOfTeachingUnit(selectedTeachingUnit._URI).then(activities => {
        // Création d'un tableau sans les activités déjà présentes dans l'objectif
        var activitiesOfTeachingUnitNotInObjective = activities.filter(activity =>
          !props.currentObjectiveActivities.some(activityIntoObjective => {
            return activity.id === parseInt(activityIntoObjective._idMoodle, 10);
          })
        );
        setCurrentObjectiveContributoryActivitiesImportable(activitiesOfTeachingUnitNotInObjective);
        setCurrentObjectiveContributoryActivitiesImportableLoad(true);
      });
    }
  }, [props.currentObjectiveActivitiesLoad]);


  // Récupération de toutes les activités de l'unité d'enseignement pour récupérer les titres
  useEffect(() => {
    if (selectedTeachingUnit != undefined && currentObjectiveContributoryActivitiesImportableLoad) {
      ApiService.getAllActivitiesFromUE(selectedTeachingUnit._URI).then(activities => {
        if (activities.length > 0) {
          var modifiedCurrentObjectiveContributoryActivitiesImportable = currentObjectiveContributoryActivitiesImportable;
          modifiedCurrentObjectiveContributoryActivitiesImportable.forEach(moodleActivity => {
            // Vérification de l'existence d'une activité en base de données avec cet idMoodle
            var activityMoodleFindInDatabase = activities.find(activity => moodleActivity.id == activity._idMoodle);
            if (activityMoodleFindInDatabase != undefined) {
              // Changement du titre de l'activité dans le tableau si une activité existe déjà en base
              if (moodleActivity.name !== activityMoodleFindInDatabase._titre) {
                moodleActivity.name = activityMoodleFindInDatabase._titre;
              };
            };
          });
          setCurrentObjectiveContributoryActivitiesImportable(modifiedCurrentObjectiveContributoryActivitiesImportable);
        }
        setTeachingUnitActivitiesLoad(true);
      });
    }
  }, [currentObjectiveContributoryActivitiesImportableLoad]);


  const ActivityImportation = (e: MoodleActivity) => {
    setIsApiFetched(true);
    ApiService.addActivityToObjectif(props.selectedObjective._URI, e).then(newContributoryActivity => {
      props.setCurrentObjectiveActivitiesLoad(false);
      var modifiedImportableContributoryActivities: Array<MoodleActivity> = currentObjectiveContributoryActivitiesImportable.filter(activity =>
        !(activity.id === e.id)
      );
      setCurrentObjectiveContributoryActivitiesImportable(modifiedImportableContributoryActivities);
      var modifiedContributoryActivities: Array<ActivitePedagogique> = [...currentObjectiveActivities];
      modifiedContributoryActivities.push(newContributoryActivity);
      setCurrentObjectiveActivities(modifiedContributoryActivities);
      props.setCurrentObjectiveActivitiesLoad(true);
      setIsApiFetched(false);
    })
  };


  var teachingUnitsActivitiesColumns: TableColumn<MoodleActivity>[] = [
    {
      id: 1,
      name: "Titre",
      selector: e => e.name,
      cell: (e) => (e.name !== undefined && (
        e.url !== undefined ?
          <a
            className="contributory-activities-title-link"
            onClick={async () => { window?.open(e.url, "_blank")?.focus(); }}
          >
            {e.name}
          </a> :
          <div>
            {e.name}
          </div>
      )),
      sortable: true,
      grow: 3,
    },
    {
      id: 2,
      name: "Type",
      selector: e => e.modname,
      sortable: true,
      grow: 1,
    },
    // Possibilité de lier une activité contributive en mode draft
    {
      id: 3,
      name: 'Lier',
      button: true,
      width: "80px",
      cell: (e) => (
        <a className="teaching-units-index-link" onClick={() => !isApiFetched && ActivityImportation(e)}>
          <IconSurroundedUpArrow />
        </a>
      ),
      omit: teachingUnitModeLive ? true : false,
    },
    {
      width: "0px",
    }
  ];


  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page :',
    rangeSeparatorText: 'de',
  };


  return (
    <div className="objective-edit-panel-index-table objective-edit-panel-contributory-activities">
      {teachingUnitActivitiesLoad ?
        <DataTableExtensions
          columns={teachingUnitsActivitiesColumns}
          data={currentObjectiveContributoryActivitiesImportable}
          print={false}
          export={false}
          responsive
          filterPlaceholder={'Recherche...'}
        >
          <DataTable
            title={"Activités disponibles"}
            noDataComponent={"Pas d'activité à afficher"}
            columns={teachingUnitsActivitiesColumns}
            data={currentObjectiveContributoryActivitiesImportable}
            defaultSortFieldId={1}
            pagination
            paginationPerPage={3}
            paginationRowsPerPageOptions={[3, 5, 10, 25, 50]}
            paginationComponentOptions={paginationComponentOptions}
            responsive
            highlightOnHover
            striped
          />
        </DataTableExtensions> :
        <div className="objective-edit-panel-index-loader loader">
          <Spinner> </Spinner>
        </div>
      }
    </div>
  );
}

export default ContributoryActivitiesImport;
