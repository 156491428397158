import { api } from "../VirtualTutor/ApiLoader";
import { INode } from "../../interface/graph";
import { ActivitePedagogique } from "../../interface/activitePedagogique";
import { ObjectifPedagogique } from "../../interface/objectifPedagogique";
import { TeachingUnit } from "../../interface/teachingUnits";
import { MoodleActivity } from "../../interface/moodleActivity";
import Personne from "../../interface/personne";
import {useRecoilState} from "recoil";
import {rolesAtom} from "../../recoil/atoms";
import IEtudiant from "../../interface/Etudiant.interface";
import IEtudiantActivite from "../../interface/EtudiantActivite.interface";
import IActivitePedagogique from "../../interface/ActivitePedagogique.interface";
import IEtudiantObjectif from "../../interface/EtudiantObjectif.interface";
import IConfiguration from "../../interface/Configuration.interface";
import IEnseignant from "../../interface/Enseignant.interface";
import IRecommandation from "../../interface/Recommandation.interface";
import {IRecommandationCraftAi} from "../../interface/IRecommandationCraftAi.interface";
import EtudiantActivite from "../../interface/etudiantActivite";

function CleaningParameter(object : {}[] | {}) : {}[] | {} | undefined{
  if(object == null || object == undefined){
    return undefined;
  }

  if( Array.isArray(object)){

    let cleaningArrObject : {}[] = [];
    object.forEach( (x: {}) => {
      let cleaningObject = {};
      Object.keys(x).forEach( key => {
        if(typeof  x[key] === 'object'){
          var newkey = key[0] == "_" ? key.substring(1) : key;
          cleaningObject[newkey] = CleaningParameter(x[key]);
        } else {
          var newkey = key[0] == "_" ? key.substring(1) : key;
          cleaningObject[newkey] = x[key]
        }
      });
      cleaningArrObject.push(cleaningObject);
    });
    return cleaningArrObject;

  } else {
    let cleaningObject = {};
    Object.keys(object).forEach( key => {
      if(typeof  object[key] === 'object'){
        if(Array.isArray(object[key]) && object[key][0] != undefined && typeof object[key][0] != 'object'){
          var newkey = key[0] == "_" ? key.substring(1) : key;
          cleaningObject[newkey] = object[key]
        } else {
          var newkey = key[0] == "_" ? key.substring(1) : key;
          cleaningObject[newkey] = CleaningParameter(object[key]);
        }
      } else {
        var newkey = key[0] == "_" ? key.substring(1) : key;
        cleaningObject[newkey] = object[key]
      }
    });
    return cleaningObject;
  }
}

export async function getBearerToken() {
  if (process.env.REACT_APP_OFFLINE == "true") {
    return "OFFLINE_MODE"
  }

  if (parseInt(localStorage.getItem("accessTokenExpireAt")!) < Date.now() + 10000) {
    var refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      const tokenRequest = await fetch(`${api}/token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
      });

      const token = await tokenRequest.json();
      if (!token.error) {
        localStorage.setItem("accessToken", token.accessToken);
        localStorage.setItem(
          "accessTokenExpireAt",
          "" + new Date(token.expireAt).getTime()
        );
        return localStorage.getItem("accessToken");
      } else {
        //Pas de session Passport OU Mauvais refresh token = redirection login
        localStorage.removeItem("accessTokenExpireAt");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
    } else {
      //Pas de refreshtoken = redirection login
      localStorage.removeItem("accessTokenExpireAt");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
  } else {
    return localStorage.getItem("accessToken");
  }
}

export const request = async (
    method: "POST" | "GET" | "DELETE",
    url: string,
    body?: any,
    headers?: any) => {

  if (process.env.REACT_APP_OFFLINE == "true") {
    switch (url) {
      case "/etudiant/start/activite":
      case "/email":
      case "/activite/create":
      case "/etudiant/end/activite":
        return true;
      case "/objectifs/statistiques":
        var result = await fetch("mock_data/objectifsStatistiques.json");
        return result.json();
      case "/activite/statistiques":
        var result = await fetch("mock_data/activiteStatistique.json");
        return result.json();
      case "/etudiant":
        var result = await fetch("mock_data/etudiant.json");
        return result.json();
      case "/api/etudiant/uniteEnseignement":
        var result = await fetch("mock_data/uniteEnseignement.json");
        return result.json();
      case "/objectifs":
        var result = await fetch("mock_data/objectifPedagogique.json");
        return result.json();
      case "/etudiant/objectifs":
        var result = await fetch("mock_data/etudiantObjectif.json");
        return result.json();
      case "/ue/activites":
        var result = await fetch("mock_data/activitePedagogique.json");
        return result.json();
      case "/etudiant/activites/ue":
        var result = await fetch("mock_data/etudiantActivite.json");
        return result.json();
      default:
        break;
    }
  }

  return await (
    await fetch(`${api}${url}`, {
      method: method,
      headers: headers ? headers : {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + (await getBearerToken()),
      },
      body: body && body instanceof FormData ? body : JSON.stringify(body),
    })
  ).json();

};

export const getStudent = (idPassport: string) => {
  return request("POST", "/etudiant", { idPassport });
};

export const getUser = async () => {
  const user = await request("POST","/user", {})

  return user;
}

export const getStudentRecommendations = async (idPassport: string, limit?: number): Promise<IRecommandation[]> => {
  const recommendations = CleaningParameter(await request("POST", "/recommandations", {
    idPassport: idPassport,
    limit: limit
  }));

  return Array.isArray(recommendations) ? recommendations as IRecommandation[] : [recommendations] as IRecommandation[];
}

export const getLastStudentsRecommendations = async(students: IEtudiant[]): Promise<IRecommandation[]> => {
  const recommenations = CleaningParameter(await request("POST", "/etudiants/last/recommandation", {
    etudiants: students
  }));

  return Array.isArray(recommenations) ? recommenations as IRecommandation[]: [recommenations] as IRecommandation[];
}

export const getTeachingUnits = async (idPassport? : string) => {
  const teachingUnitRequest = await request("POST","/uniteEnseignement/all", {
    idPassport:idPassport
  });
  return teachingUnitRequest;
};

export const getStudentTeachingUnits = async (idPassport? : string) => {
  const teachingUnitRequest = await request("POST","/student/uniteEnseignement/all", {
    idPassport:idPassport
  });
  return teachingUnitRequest;
};

export const getStudentObjectives = async (idPassport?: string): Promise<IEtudiantObjectif[]> => {
  const studentObjectives = CleaningParameter(await request("POST", "/etudiant/objectifs", {
    idPassport: idPassport
  }));
  return Array.isArray(studentObjectives) ? studentObjectives as IEtudiantObjectif[] : [studentObjectives] as IEtudiantObjectif[];
};

export const getStudentActivities = async (idPassport?: string): Promise<IEtudiantActivite[]> => {
  const studentActivities = CleaningParameter(await request("POST", "/etudiant/activites", {
    idPassport: idPassport
  }));
  return Array.isArray(studentActivities) ? studentActivities as IEtudiantActivite[] : [studentActivities] as IEtudiantActivite[];
};

export const getAllMoodleTeachingUnits = async (field: string, value: number) => {
  const teachingUnitsRequest = await request("POST","/moodle/courses", {
    field: field,
    value: value,
  });
  return Array.isArray(teachingUnitsRequest)
  ? teachingUnitsRequest.map((tu) => (
    {
      _URI: tu.id, 
      _nom: tu.shortname, 
      _idMoodle: tu.id,
      _nomComplet: tu.fullname,
      _idUniteEnseignement: tu.shortname?.split('_')[2] != undefined ? tu.shortname.split('_')[2] : tu.shortname,
      _estEditable:false,
      _delaiDeQuarantaineDesActivitesConsultees: tu._delaiDeQuarantaineDesActivitesConsultees,
      _delaiDeQuarantaineDesActivitesProposees: tu._delaiDeQuarantaineDesActivitesProposees,
      _nombreDeRecommandationParDefault: tu._nombreDeRecommandationParDefault,
    }
  )) : [{
    _URI: teachingUnitsRequest.id,
    _nom: teachingUnitsRequest.shortname,
    _idMoodle: teachingUnitsRequest.id,
    _nomComplet: teachingUnitsRequest.fullname,
    _idUniteEnseignement: teachingUnitsRequest.shortname?.split('_')[2] != undefined ? teachingUnitsRequest.shortname.split('_')[2] : teachingUnitsRequest.shortname,
    _estEditable:false,
    _delaiDeQuarantaineDesActivitesConsultees: teachingUnitsRequest._delaiDeQuarantaineDesActivitesConsultees,
    _delaiDeQuarantaineDesActivitesProposees: teachingUnitsRequest._delaiDeQuarantaineDesActivitesProposees,
    _nombreDeRecommandationParDefault: teachingUnitsRequest._nombreDeRecommandationParDefault,
  }
  ];
};

export const editTeachingUnit = async(teachingUnit: TeachingUnit) => {
  const teachingUnitRequest: TeachingUnit = await request("POST","/uniteEnseignement/edit", {
    ...teachingUnit,
  });
  return teachingUnitRequest;
};

export const createTeachingUnit = async(teachingUnit: TeachingUnit) => {
  const teachingUnitRequest: TeachingUnit = await request("POST","/uniteEnseignement/create", {
    ...teachingUnit,
  });
  return teachingUnitRequest;
};

export const deactivateTeachingUnit = async(teachingUnit: TeachingUnit) => {
  const teachingUnitRequest: TeachingUnit = await request("POST","/uniteEnseignement/deactivate", {
    ...teachingUnit,
  });
  return teachingUnitRequest;
};

export const getCurrentObjective = async (idPassport: string, ueUri?: string) => {
  const objective = await request("POST","/etudiant/current/objectif", {
    idPassport : idPassport,
    ueUri : ueUri,
  });
  return objective;
};

export const getAllObjectives = async (teachingUnitId: string, objectifsStatut?: string, idPassport? : string) => {
  const objectives = await request("POST","/objectifs", {
    ueUri: teachingUnitId,
    statut: objectifsStatut,
    idPassport: idPassport == undefined ? undefined : idPassport
  });
  return Array.isArray(objectives) ? objectives : [objectives];
};

export const getObjectifsStatutDraft = async (teachingUnitId: string, idPassport? : string) => {
  const objectives: ObjectifPedagogique[] | ObjectifPedagogique = await request("POST","/objectifs/statut/draft", {
    ueUri: teachingUnitId,
    idPassport: idPassport == undefined ? undefined : idPassport
  });
  return Array.isArray(objectives) ? objectives : [objectives];
};

export const editObjective = async(objectif: ObjectifPedagogique) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/edit", {
    ...objectif,
  });
  return objective;
};

export const addActivityToObjectif = async (objectifUri: string, activity: MoodleActivity) => {
  const newContributoryActivity: ActivitePedagogique = await request("POST","/objectif/activity/add", {
    objectiveURI: objectifUri,
    ...activity
  });
  return newContributoryActivity;
};

export const addObjectiveContributionToObjectif = async (objectifUri: string, conditonelObjectifURI: string) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/contribution/addObjective", {
    _objectiveURI: objectifUri,
    _conditonalObjectiveURI: conditonelObjectifURI,
  });
  return objective;
};

export const removeObjectiveContributionToObjectif = async (objectifUri: string, conditonelObjectifURI: string) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/contribution/removeObjective", {
    _objectiveURI: objectifUri,
    _conditonalObjectiveURI: conditonelObjectifURI,
  });
  return objective;
};

export const addObjectiveConditionToObjectif = async (objectifUri: string, conditonelObjectifURI: string) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/condition/addObjective", {
    _objectiveURI: objectifUri,
    _conditonalObjectiveURI: conditonelObjectifURI,
  });
  return objective;
};

export const removeObjectiveConditionToObjectif = async (objectifUri: string, conditonelObjectifURI: string) => {
  const objective: ObjectifPedagogique = await request("POST", "/objectif/condition/removeObjective", {
    _objectiveURI: objectifUri,
    _conditonalObjectiveURI: conditonelObjectifURI,
  });
  return objective;
};

export const addActivityConditionToObjectif = async (objectifUri: string, activityUri: string, pourcentageReussiteAttendu: number | undefined) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/condition/addActivity", {
    _objectiveURI: objectifUri,
    _activityURI: activityUri,
    pourcentageReussiteAttendu: pourcentageReussiteAttendu,
  });
  return objective;
};

export const removeActivityConditionToObjectif = async (objectifUri: string, activityUri: string) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/condition/removeActivity", {
    _objectiveURI: objectifUri,
    _activityURI: activityUri,
  });
  return objective;
};

export const removeActivityContributionToObjectif = async (objectifUri: string, activityUri: string) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/contribution/removeActivity", {
    objectiveURI: objectifUri,
    activityURI: activityUri,
  });
  return objective;
};

export const createObjective = async(objectif: ObjectifPedagogique, teachingUnitUri: string) => {
  const objective: ObjectifPedagogique = await request("POST","/objectif/create", {
    ...objectif,
    ueUri: teachingUnitUri, 
  });
  return objective;
};

export const getAllActivitiesFromUE = async (teachingUnitId: string, idPassport?: string) => {
  const activities = await request("POST","/ue/activites", {
    ueUri: teachingUnitId,
    idPassport: idPassport == undefined ? undefined : idPassport
  });

  return Array.isArray(activities) ? activities : [activities];
};

export const getAllMoodleActivitiesOfTeachingUnit = async (teachingUnitURI: string) => {
  const activities = await request("POST","/moodle/course/activities", {
    teachingUnitURI: teachingUnitURI,
  });

  return Array.isArray(activities) ? activities : [activities];
};

export const getMoodleActivity = async (idMoodleActivity: number) => {
  const activity = await request("POST","/moodle/course/activity", {
    id: idMoodleActivity,
  });

  return activity;
};

export const getRoleUser = async () : Promise<string> => {
  var refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    const userRole : {role:string} = await request("POST","/user/role", {
      refreshToken: refreshToken,
    });
    return userRole.role;
  } else
    return "unknown";
};

export const getAllActivitiesFromObjectif = async (objectifId: string, isMandatory: boolean = false) => {
  const activities = await request("POST","/objectif/activites", {
    objectifUri: objectifId,
    isMandatory: isMandatory,
  });

  return Array.isArray(activities) ? activities : [activities];
};

export const getStatistiqueActivite = async (idPassport: string, activitePedagogiqueId: string, ueId: string) => {
  const statistiques = await request("POST","/activite/statistiques", {
    idPassport: idPassport,
    activitePedagogiqueUri: activitePedagogiqueId,
    ueUri: ueId,
  });

  return statistiques;
};


export const sendEmailToAdmin = async (subject: string, message: string, firstName: string, lastName: string, email: string) => {

  const result = await request("POST","/email/admin", {
    subject: "Tuteur virtuel - "+subject,
    message: {
      text :
          "Email en provenance du formulaire de contact du Tuteur Virtuel : \r\n \r\n" +
          "Sujet : \r\n" +
          subject + " \r\n \r\n" +

          "Message : \r\n" +
          message + " \r\n \r\n" +

          "Informations du contact : \r\n" +
          "Envoyé par : "+ firstName+" "+lastName+ " \r\n"+
          "Email : "+ email+ " \r\n"+
          "Tuteur Virtuel - Université Paris Dauphine-PSL",
      html :
          {
            message: message,
            user: firstName+" "+lastName,
            email : email,
            sujet : subject
          }
    }
    ,
  });
  return result;
};

export const sendEmail = async (ueId: string, subject: string, message: string, firstName: string, lastName: string, email: string, node: INode) => {

  var stringsubject = "";

  switch (subject) {
    case "precision":
      stringsubject = "Précision sur un concept";
      break;
    case "complementary_activity":
      stringsubject = "Demande d'activité complémentaire";
      break;
    case "other":
      stringsubject = "Prise de contact";
      break;
    case "experience_validation":
      stringsubject = "Demande de validation d'acquis";
      break;
    default:
      stringsubject = "Prise de contact";
      break;
  }

  const result = await request("POST","/email", {
    ueId: ueId,
    subject: "Tuteur virtuel - "+stringsubject,
    message: {
      text :
          "Email en provenance du formulaire de contact du Tuteur Virtuel : \r\n \r\n" +
          "Sujet : \r\n" +
          stringsubject + " \r\n \r\n" +

          "Message : \r\n" +
          message + " \r\n \r\n" +

          "Informations du contact : \r\n" +
          "Envoyé par : "+ firstName+" "+lastName+ " \r\n"+
          "Email : "+ email+ " \r\n"+
          "Objectif lié : "+ node.title+ " \r\n \r\n"+
          "Tuteur Virtuel - Université Paris Dauphine-PSL",
      html :
          {
            message: message,
            user: firstName+" "+lastName,
            email : email,
            objectif : node.title,
            sujet : stringsubject
          }
    }
    ,
  });
  return result;
};


export const etudiantStartActivite = async (idPassport: string, activitePedagogiqueId: string) => {
  const result = await request("POST","/etudiant/start/activite", {
    idPassport: idPassport,
    activiteId: activitePedagogiqueId,
    event: "frontend",
  });
  return result;
};

export const etudiantEndActivite = async (idPassport: string, activitePedagogiqueId: string, score?:number) => {
  const result = await request("POST","/etudiant/end/activite", {
    idPassport: idPassport,
    activiteId: activitePedagogiqueId,
    score: score,
    event: "frontend",
  });
  return result;
};

export const etudiantResetActivite = async (idPassport: string, activiteUri: string) => {
  const result = await request("POST","/etudiant/activite/reset", {
    idPassport: idPassport,
    activiteUri: activiteUri,
  });
  return result;
};


export const removeObjectifPedagogique = async (objectifUri: string) => {
  const result = await request("DELETE","/objectif/delete", {
    objectifUri: objectifUri,
  });
  return result;
};

export const etudiantCreateActivite = async (
  type: string,
  name: string,
  duration: string,
  information: string,
  courseId: number,
  objectifPedagogiqueURI: string,
  link?: string,
  file?: any) => {

  var categorie: string;
  switch (type) {
    case "file":
      categorie = "https://data.dauphine.psl.eu/vocabulary/categorie-activite/1";
      break;
    case "url":
      categorie = "https://data.dauphine.psl.eu/vocabulary/categorie-activite/3";
      break;
    default:
      categorie = "https://data.dauphine.psl.eu/vocabulary/categorie-activite/3";
      break;
  }

  var data = new FormData();
  data.append('name', name);
  data.append('description', information);
  data.append('courseId', courseId.toString());
  data.append('isVisible', '1');
  data.append('isVisibleOnCoursePage', "0");
  data.append('introEditorText', information);
  data.append('introEditorFormat', "1");
  data.append('type', type);
  if (link != undefined) {
    data.append('externalurl', link);
  }
  data.append('categorie',categorie);
  data.append('duration',duration);
  data.append('arrObjectifPedagogiqueUri',objectifPedagogiqueURI);
  if(file != undefined){
    data.append('file',file, file.name);
  }

  const result = await request("POST","/activite/create", data, { "Authorization": "Bearer " + (await getBearerToken()) });
  return result;
};

export const editActivity = async(activity: ActivitePedagogique) => {
  const modifiedActivity: ActivitePedagogique = await request("POST","/activite/edit", {
    ...activity,
  });

  return modifiedActivity;
};

export const deleteActivity = async(activity : ActivitePedagogique) => {
  const deletedActivity: ActivitePedagogique = await request("POST", "/activite/delete", {
    ...activity,
  });

  return deletedActivity
}

export const getAllStudentObjectives = async (
  teachingUnitId: string,
  studentID: string
) => {
  const objectives = await request("POST","/etudiant/objectifs", {
    idPassport: studentID,
    ueUri: teachingUnitId,
  });
  return Array.isArray(objectives) ? objectives : [objectives];
};

export const getAllStudentActivities = async (
  teachingUnitId: string,
  studentID: string
) => {
  const activities = await request("POST","/etudiant/activites/ue", {
    idPassport: studentID,
    ueUri: teachingUnitId,
  });
  return Array.isArray(activities) ? activities : [activities];
};

export const editStudentActivite = async (etudiantActivite: EtudiantActivite) => {
  const etudiantActiviteRequest: EtudiantActivite = await request("POST", "/etudiant/activite/edit", {
    ...etudiantActivite,
  });

  return etudiantActiviteRequest;
};

export const getAllStudent = async () : Promise<IEtudiant[] | IEtudiant> => {
  const etudiants = CleaningParameter(await request("POST","/etudiants"));
  return Array.isArray(etudiants) ? etudiants as IEtudiant[] : [etudiants] as IEtudiant[];
};

export const getStudentByCourse = async (ArrUeId : string[]) : Promise<IEtudiant[] | IEtudiant> => {
  const etudiants = CleaningParameter(await request("POST","/unite-enseignement/etudiants",{
    arrUeId : ArrUeId
  }));
  return Array.isArray(etudiants) ? etudiants as IEtudiant[] : [etudiants] as IEtudiant[];
};


export const getObjectiveStatistics = async (teachingUnitId: string, idPassport?: string) => {
  const objStats = await request("POST","/objectifs/statistiques", {
    ueUri: teachingUnitId,
    idPassport: idPassport == undefined ? undefined : idPassport
  });
  return Array.isArray(objStats) ? objStats : [objStats];
};

export const getAllActivities = async (): Promise<ActivitePedagogique[]> => {
  const result = await request("POST","/activites");

  var arrResult: ActivitePedagogique[] = [];
  if (Array.isArray(result)) {
    result.forEach(e => {
      arrResult.push(e as ActivitePedagogique);
    });
  } else {
    arrResult.push(result as ActivitePedagogique);
  }

  return arrResult;
};

export const resetGraph = async (teachingUnitId: string, idPassport?: string) => {
  const objStats = await request("POST","/etudiant/graphe/reset", {
    ueUri: teachingUnitId,
    idPassport: idPassport == undefined ? undefined : idPassport
  });
  return Array.isArray(objStats) ? objStats : [objStats];
};


export const deleteGraphDraftVersion = async (teachingUnitId: string) => {
  const objStats = await request("POST","/graphe/draft/to/delete", {
    ueUri: teachingUnitId,
  });
  return Array.isArray(objStats) ? objStats : [objStats];
};

export const uploadGraphDraftVersion = async (teachingUnitId: string) => {
  const objStats = await request("POST","/graphe/draft/to/use", {
    ueUri: teachingUnitId,
  });
  return Array.isArray(objStats) ? objStats : [objStats];
};

export const getConfigs = async () : Promise<IConfiguration[]> => {
  const configs = CleaningParameter(await request("POST","/configs"));
  return Array.isArray(configs) ? configs as  IConfiguration[] : [configs as IConfiguration];
};

export const simulateRecommandation = async (
    idPassport: string,
    ueUri: string,
    nombreRecommandation:number,
    objectifPedagogiqueUri?:string[],
    tempsDisponible?:number,
    category?:string,
    controleConnaissanceUri?:string,
) : Promise<IRecommandationCraftAi> => {
  const recommandations  = CleaningParameter(await request("POST","/recommandation", {
    idPassport:idPassport,
    ueUri:ueUri,
    nombreRecommandation:nombreRecommandation,
    objectifPedagogiqueUri:objectifPedagogiqueUri,
    controleConnaissanceUri:controleConnaissanceUri,
    tempsDisponible:tempsDisponible,
    category:category,
    sauvegarderRecommandation:false
  }));
  return recommandations as IRecommandationCraftAi;
};

export const editConfig = async (nom: string, valeur:string) : Promise<IConfiguration> => {
  const config = CleaningParameter(await request("POST","/config/edit", {
    nom:nom,
    valeur:valeur
  }));
  return config as IConfiguration;
};

export const getAdmins = async () : Promise<IEnseignant[]> => {
  const admins = CleaningParameter(await request("POST","/admins"));
  return Array.isArray(admins) ? admins as  IEnseignant[] : [admins as IEnseignant];
};

export const getInfoEtudiantActivite = async (activiteUri,studentID): Promise< {
  etudiantActivite :  IEtudiantActivite | undefined,
  activitePedagogique : IActivitePedagogique,
  moodleActivite: any
} > => {
  const result = await request("POST","/etudiant/activite/info", {
    activiteUri: activiteUri,
    idPassport: studentID
  });

  return {
    etudiantActivite : result.etudiantActivite != null ? CleaningParameter(result.etudiantActivite) as IEtudiantActivite : undefined,
    activitePedagogique : CleaningParameter(result.activitePedagogique) as IActivitePedagogique,
    moodleActivite : result.moodleActivite
  };
};

export const ApiService = {
  request,
  getStudent,
  getUser,
  getStudentRecommendations,
  getLastStudentsRecommendations,
  getTeachingUnits,
  getAllMoodleTeachingUnits,
  editTeachingUnit,
  createTeachingUnit,
  deactivateTeachingUnit,
  getAllObjectives,
  getObjectifsStatutDraft,
  editObjective,
  addActivityToObjectif,
  addObjectiveContributionToObjectif,
  removeObjectiveContributionToObjectif,
  addObjectiveConditionToObjectif,
  removeObjectiveConditionToObjectif,
  addActivityConditionToObjectif,
  removeActivityConditionToObjectif,
  createObjective,
  getAllActivitiesFromUE,
  getAllMoodleActivitiesOfTeachingUnit,
  getMoodleActivity,
  getAllActivitiesFromObjectif,
  getAllActivities,
  editActivity,
  deleteActivity,
  getAllStudentObjectives,
  getAllStudentActivities,
  editStudentActivite,
  getStatistiqueActivite,
  etudiantStartActivite,
  etudiantCreateActivite,
  etudiantEndActivite,
  sendEmail,
  getObjectiveStatistics,
  getCurrentObjective,
  getRoleUser,
  removeObjectifPedagogique,
  removeActivityContributionToObjectif,
  getAllStudent,
  getStudentTeachingUnits,
  getStudentObjectives,
  getStudentActivities,
  getInfoEtudiantActivite,
  etudiantResetActivite,
  getStudentByCourse,
  resetGraph,
  deleteGraphDraftVersion,
  uploadGraphDraftVersion,
  getConfigs,
  editConfig,
  getAdmins,
  sendEmailToAdmin,
  simulateRecommandation,
};

