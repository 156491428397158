import React from "react";

const IconFullStar = (props) => {
  return (
    <svg viewBox="0 0 50 50" width={props.width}>
      <path fill="#1C977A" d="m25.962 3.13 6.006 13.381c.154.342.471.578.837.623l14.261 1.73c.895.109 1.256 1.237.595 1.864l-10.555 10.014c-.269.255-.389.634-.318 1.001l2.805 14.465c.176.905-.77 1.604-1.557 1.151l-12.517-7.2c-.322-.185-.716-.185-1.039 0l-12.517 7.2c-.787.453-1.733-.246-1.557-1.151l2.805-14.465c.071-.367-.049-.746-.318-1.001l-10.554-10.014c-.66-.626-.3-1.755.595-1.864l14.261-1.73c.366-.044.683-.28.837-.623l6.006-13.381c.377-.84 1.547-.84 1.924 0z" />
    </svg>
  );
};
export default IconFullStar;
