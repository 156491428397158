import { useState, useEffect } from "react";
import Select from 'react-select';
import "./ObjectiveEditPanel.css";
import { ObjectifPedagogique } from "../../../../../interface/objectifPedagogique";
import { ApiService } from "../../../../LoginHandler/restServices";
import domaineConnaissance from "../../../../../data/domaineConaissance";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ObjectiveEditionForm(props) {

  const [selectedObjective, setSelectedObjective] = useState({} as ObjectifPedagogique);
  const [initialDomaineConnaissanceObjective, setInitialDomaineConnaissanceObjective] = useState<{ value: string, label: string }[]>();

  function ObjectiveEditableField(params) {
    const [objectiveData, setObjectiveData] = useState(params.initialValue);
    const onChange = e => {
      setObjectiveData(e.target.value)
    }
    const onChangeSelect = selectedOption => {
      setObjectiveData(selectedOption);
    }
    const onBlur = () => {
      // Test pour tenter d'empêcher le bug de suppression des données occasionnelle
      if (props.selectedObjective != undefined) {
        var objective: ObjectifPedagogique = {
          _URI: params.objective._URI,
          _estUneVersionDe: params.objective._estUneVersionDe._URI,
        }
        if (params.isDomaineConnaissance) {
          objective[`${params.changingProperty}`] = objectiveData.map(domaineConnaissance => domaineConnaissance.value);
        } else {
          objective[`${params.changingProperty}`] = objectiveData;
        }

        if (
          selectedObjective![`${params.changingProperty}`] != objectiveData
          && ((params.required && objectiveData != null && objectiveData != "") || !params.required)
        ) {
          ApiService.editObjective(objective).then(editedObj => {
            props.setSelectedObjective(editedObj);
          });
        } else {
          setObjectiveData(selectedObjective![`${params.changingProperty}`]);
        }
      }
    }
    if (params.isDomaineConnaissance) {
      return <Select
        defaultValue={objectiveData}
        isMulti
        isClearable={false}
        placeholder="Sélection..."
        options={domaineConnaissance}
        className="basic-multi-select"
        classNamePrefix="objective-edit-panel-form-domaine-select"
        onBlur={onBlur}
        onChange={onChangeSelect}
      />
    } else {
      return <input
        value={objectiveData}
        className="objective-edit-panel-form-input"
        onChange={onChange}
        onBlur={onBlur}
        maxLength={params.changingProperty === "_titreCourt" ? 5 : -1}
      />
    }
  }

  // Récupération de l'objectif
  useEffect(() => {
    if (props.selectedObjective != undefined) {
      setSelectedObjective(props.selectedObjective);
      var initialDomaineConnaissance = Array.isArray(props.selectedObjective._domaineConnaissance) ?
        domaineConnaissance.filter(domaine => props.selectedObjective._domaineConnaissance.some(objectiveDomaine => {
          return JSON.stringify(objectiveDomaine) === JSON.stringify(domaine.value);
        }))
        :
        domaineConnaissance.filter(domaine => JSON.stringify(props.selectedObjective._domaineConnaissance) === JSON.stringify(domaine.value));
      setInitialDomaineConnaissanceObjective(initialDomaineConnaissance);
    }
  }, [props.selectedObjective]);

  return (
    <div className="objective-edit-panel-form">
      <div className="objective-edit-panel-form-titre-court">
        <label className="objective-edit-panel-form-label">
          <span>Titre court </span>
          <span className="objective-edit-panel-form-label-max-length">(5 caractères maximum)</span>
        </label>
        <ObjectiveEditableField
          required={true}
          objective={selectedObjective}
          initialValue={selectedObjective._titreCourt}
          changingProperty={"_titreCourt"}
        />
      </div>
      <div className="objective-edit-panel-form-titre">
        <label className="objective-edit-panel-form-label">Titre</label>
        <ObjectiveEditableField
          required={true}
          objective={selectedObjective}
          initialValue={selectedObjective._titre}
          changingProperty={"_titre"}
        />
      </div>
      <div className="objective-edit-panel-form-domaine">
        <label className="objective-edit-panel-form-label">Domaine de connaissance</label>
        <ObjectiveEditableField
          required={true}
          objective={selectedObjective}
          initialValue={initialDomaineConnaissanceObjective}
          changingProperty={"_domaineConnaissance"}
          isDomaineConnaissance={true}
        />
      </div>
      <div className="objective-edit-panel-form-description">
        <label className="objective-edit-panel-form-label">Description</label>
        <ObjectiveEditableField
          required={true}
          objective={selectedObjective}
          initialValue={selectedObjective._description}
          changingProperty={"_description"}
        />
      </div>
    </div>
  );
}

export default ObjectiveEditionForm;
