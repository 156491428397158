import { atom, selector } from "recoil";
import {
  EXTENDED,
  IGraphData,
  ILink,
  INode,
  IStudent,
  LayerKey,
  PREFILTER,
  TOPIC,
} from "../interface/graph";
import * as d3 from "d3";
import {
  activityDurationFilterAtom,
  activityProgressFilterAtom,
  activityTypeFilterAtom,
  addAggregator,
  filterActivityDuration,
  filterActivityProgress,
  filterActivityType,
  preFilter,
} from "./graphFilters";
import IEtudiant from "../interface/Etudiant.interface";
import {ReactElement} from "react";
import personne from "../interface/personne";
import IConfiguration from "../interface/Configuration.interface";

export const zoomAtom = atom({
  key: "zoomAtom",
  default: d3.zoomIdentity,
});
export const zoomAtomT = atom({
  key: "zoomAtomT",
  default: d3.zoomIdentity,
});
export const lockedAtom = atom({
  key: "lockedAtom",
  default: true,
});

export const toggleObjectiveEditModalAtom = atom<any>({
  key: "toggleObjectiveEditModalAtom",
  default: false,
});
export const toggleActivityEditModalAtom = atom<any>({
  key: "toggleActivityEditModalAtom",
  default: false,
});
export const toggleSidebarAtom = atom<any>({
  key: "toggleSidebarAtom",
  default: true,
});
export const toggleModalResourcesFormAtom = atom<any>({
  key: "toggleModalResourcesFormAtom",
  default: false,
});
export const toggleModalAtom = atom<any>({
  key: "toggleModalAtomi",
  default: false,
});

export const toggleModalQuestionFormAtom = atom<any>({
  key: "toggleModalQuestionFormAtomi",
  default: false,
});

export const toggleModalCourseListAtom = atom<any>({
  key: "toggleModalCourseListAtomi",
  default: false,
});
export const areNodeExpendedAtom = atom<boolean>({
  key: "areNodeExpendedAtom",
  default: true,
});

export const toggleModalActivityFilterTypeAtom = atom<any>({
  key: "toggleModalActivityFilterTypeAtom",
  default: false,
});

export const toggleModalActivityFilterStateAtom = atom<any>({
  key: "toggleModalActivityFilterStateAtom",
  default: false,
});

export const toggleModalActivityFilterTimeAtom = atom<any>({
  key: "toggleModalActivityFilterTimeAtom",
  default: false,
});

export const toggleModalHelpAtom = atom<any>({
  key: "toggleModalHelpAtom",
  default: false,
});

export const toggleModalAccountAtom = atom<any>({
  key: "toggleModalAccountAtom",
  default: false,
});

export const toggleModalHelpLegendsAtom = atom<any>({
  key: "toggleModalHelpLegendsAtom",
  default: false,
});

export const toggleModalHelpObjectivesAtom = atom<any>({
  key: "toggleModalHelpObjectivesAtom",
  default: false,
});

export const toggleModalHelpActivitiesAtom = atom<any>({
  key: "toggleModalHelpActivitiesAtom",
  default: false,
});

export const toggleModalHelpContributionsAtom = atom<any>({
  key: "toggleModalHelpContributionsAtom",
  default: false,
});

export const toggleModalNotificationAtom = atom<any>({
  key: "toggleModalNotificationAtom",
  default: false,
});

export const toggleModalTeachingUnitSettingsAtom = atom<any>({
  key: "toggleModalTeachingUnitSettingsAtom",
  default: false,
});

export const toggleTeachingUnitModeLiveAtom = atom<any>({
  key: "toggleTeachingUnitModeLiveAtom",
  default: false,
});

export const selectedNodeAtom = atom<INode | undefined>({
  key: "selectedNodeAtom",
  default: undefined,
});

export const selectedTopic = atom<string[]>({
  key: "selectedTopic",
  default: [],
});

export const selectedNodeIDAtom = atom<string | undefined>({
  key: "selectedNodeIDAtom",
  default: undefined,
});

export const getPositions = selector<any>({
  key: "getPositions",
  get: () => {
    return JSON.parse(localStorage.getItem("positions") || "{}");
  },
  set: (_, newValue: IGraphData) => {
    const p = JSON.parse(localStorage.getItem("positions") || "{}");
    localStorage.setItem(
      "positions",
      JSON.stringify(
        newValue.nodes
          .filter((n: any) => n.fx && n.fy)
          .reduce((a, b) => ({ ...a, [b.id]: { x: b.x, y: b.y } }), { ...p })
      )
    );
  },
});
export const graphSelectorPO = selector<IGraphData>({
  key: "graphSelectorPOi",
  get: ({ get }) => {

    const graphData = get(nPOSPreFilterSelector);

    const clusterify = addAggregator(
      removeOpen(graphData, get(openNodesAtom)),
      get(graphAtom)
    );
    return {
      nodes: clusterify.nodes.filter(
        (n) =>
          n.type !== "AGGREGATOR" ||
          clusterify.links.some(
            (k: any) => k.source === n.id || k.target === n.id
          )
      ),
      links: clusterify.links,
    };
  },
  // set: ({ set }, newValue) => set(graphAtom, newValue),
});

export const defaultValues = {
  addAggregator: true,
  linkDistanceActivity: 100,
  linkDistanceTopic: 300,
  linkStrengthActivity: 1,
  linkStrengthTopic: 1,
  collideRadiusActivity: 100,
  collideRadiusTopic: 250,
  collideStrength: 1,
  centeringStrength: 1,
  chargeStrengthActivity: -2500,
  chargeStrengthTopic: -2500,
  alpha: 1,
  alphaDecay: 1 - Math.pow(0.001, 1 / 300),
  velocityDecay: 0.4,
  startingTicks: 300,
  forceX: 0,
  forceXX: 0,
  forceY: 0,
  forceYY: 0,
};

export const settingsAtom = atom({
  key: "settingsAtom",
  default: defaultValues,
});

export const pollingState = atom<string>({
  key: "PollingState",
  default: "done",
});

export const refreshGraphState = atom<boolean>({
  key: "PollingState",
  default: true,
});

export const SettingPopUp = atom<{
  message: string,
  html? : ReactElement,
  arrBtn : {
    text: string,
    action: any,
    type: "danger" | "primary" | "secondary"
  }[],
  isOpen: boolean,
}>({
  key: "SettingPopUp",
  default: { message: "", arrBtn: [], isOpen:false},
});



export const graphAtom = atom<IGraphData>({
  key: "graphAtom",
  default: { nodes: [], links: [] },
});
export const enhancedNodes = atom<any>({
  key: "enhancedNodes",
  default: {},
});
export const getEnhancedGraphSelector = selector({
  key: "getEnhancedGraphSelector",
  get: ({ get }) => {
    let graph = get(graphAtom);

    return {
      nodes: graph.nodes.map((n, i) => ({
        ...n,
        ...get(enhancedNodes)[n.id],
      })),
      links: graph.links,
    };
  },
});

export const openNodesAtom = atom<string[]>({
  key: "openNodesAtom",
  default: [],
});

export const studentAtom = atom<IStudent | undefined>({
  key: "studentAtom",
  default: undefined,
});

export const studentsList = atom<IEtudiant[]>({
  key: "studentsList",
  default: [],
});

export const rolesAtom = atom<string[]>({
  key: "rolesAtom",
  default: [],
});

export const userAtom = atom<personne |undefined>({
  key: "userAtom",
  default: undefined,
});

export const selectedLayerAtom = atom<LayerKey[]>({
  key: "selectedLayerAtom",
  default: [EXTENDED, PREFILTER],
});

export const selectNode = selector<INode>({
  key: "selectNodei",
  get: ({ get }) =>
    get(getEnhancedGraphSelector)?.nodes.find(
      (n) => n.id === get(selectedNodeIDAtom)
    ),
});

export const configsAtom = atom<IConfiguration[]>({
  key: "configsAtom",
  default: [],
});

function removeOpen(iGraphData: IGraphData, openNodes: string[]) {
  let nodes = iGraphData.nodes.filter(
    (n) =>
      n.type === TOPIC ||
      iGraphData.links.some(
        (l: any) => l.source === n.id && openNodes.includes(l.target)
      )
  );
  const nodeMap: any = nodes.reduce((a, b) => ({ ...a, [b.id]: b }), {});

  return {
    nodes: nodes,
    links: iGraphData.links.filter(
      (l: any) => nodeMap[l.target] && nodeMap[l.source]
    ),
  };
}

export const nPOSPreFilterSelector = selector({
  key: "nPOSPreFilterSelector",
  get: ({ get }) => {
    let g = get(getEnhancedGraphSelector);
    return get(selectedLayerAtom).includes(PREFILTER)
      ? preFilter({ graph: g })
      : g;
  },
});

export const selectRelatedNodes = selector<any>({
  key: "selectRelatedNodesi",
  get: ({ get }) => {
    const selectedID = get(selectedNodeIDAtom);
    let graph = get(getEnhancedGraphSelector);
    return graph?.links
      .reduce((a: INode[], b: ILink) => {
        // @ts-ignore
        if (b.source === selectedID) {
          return [...a, b.target]; // @ts-ignore
        } else if (b.target === selectedID) {
          return [...a, b.source];
        }
        return a;
      }, []) // @ts-ignore
      .map((id) => graph?.nodes.find((n) => n.id === id));
  },
});
export const selectRelatedActivities = selector<any>({
  key: "selectRelatedActivities",
  get: ({ get }) => {
    const selectedID = get(selectedNodeIDAtom);
    let graph = get(graphSelectorPO);
    return graph?.links
      .reduce((a: INode[], b: ILink) => {
        // @ts-ignore
        if (b.source === selectedID) {
          return [...a, b.target]; // @ts-ignore
        } else if (b.target === selectedID) {
          return [...a, b.source];
        }
        return a;
      }, []) // @ts-ignore
      .map((id) => graph?.nodes.find((n) => n.id === id));
  },
});
