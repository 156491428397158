import DomaineConaissance from "../vocabulary/domaineConnaissance";

export const domaineConnaissance = [
    { value: DomaineConaissance.sciences_humaines, label: "Sciences humaines" },
    { value: DomaineConaissance.economie, label: "Économie" },
    { value: DomaineConaissance.droit, label: "Droit" },
    { value: DomaineConaissance.developpement_durable, label: "Développement durable" },
    { value: DomaineConaissance.energie, label: "Énergie" },
    { value: DomaineConaissance.mathematiques, label: "Mathématiques" },
    { value: DomaineConaissance.developpement_professionnel_et_personnel, label: "Développement professionnel et personnel" },
    { value: DomaineConaissance.ingenierie_formation_pedagogie, label: "Ingénierie formation pédagogie" },
    { value: DomaineConaissance.droit_fiscal, label: "Droit fiscal" },
    { value: DomaineConaissance.langues, label: "Langues" },
    { value: DomaineConaissance.psychologie, label: "Psychologie" },
    { value: DomaineConaissance.science_politique, label: "Science politique" },
    { value: DomaineConaissance.chimie, label: "Chimie" },
    { value: DomaineConaissance.mecanique_theorique, label: "Mécanique théorique" },
    { value: DomaineConaissance.physique, label: "Physique" },
    { value: DomaineConaissance.sciences_de_la_terre, label: "Sciences de la terre" },
    { value: DomaineConaissance.sciences_naturelles, label: "Sciences naturelles" },
    { value: DomaineConaissance.art, label: "Art" },
    { value: DomaineConaissance.artisanat_art, label: "Artisanat art" },
    { value: DomaineConaissance.audiovisuel_multimedia, label: "Audiovisuel multimédia" },
    { value: DomaineConaissance.communication_et_information, label: "Communication et information" },
    { value: DomaineConaissance.industrie_graphique_imprimerie, label: "Industrie graphique imprimerie" },
    { value: DomaineConaissance.informatique_et_systemes_d_information, label: "Informatique et systèmes d'information" },
    { value: DomaineConaissance.langages_informatiques, label: "Langages informatiques" },
    { value: DomaineConaissance.telecommunication, label: "Télécommunication" },
    { value: DomaineConaissance.genie_industriel, label: "Génie industriel" },
    { value: DomaineConaissance.manutention, label: "Manutention" },
    { value: DomaineConaissance.qualite_industrielle, label: "Qualité industrielle" },
    { value: DomaineConaissance.transport, label: "Transport" },
    { value: DomaineConaissance.agriculture_production_vegetale, label: "Agriculture production végétale" },
    { value: DomaineConaissance.environnement_amenagement, label: "Environnement aménagement" },
    { value: DomaineConaissance.peche_aquaculture, label: "Pêche aquaculture" },
    { value: DomaineConaissance.agroalimentaire, label: "Agroalimentaire" },
    { value: DomaineConaissance.cuir_peau, label: "Cuir peau" },
    { value: DomaineConaissance.habillement, label: "Habillement" },
    { value: DomaineConaissance.materiau_produit_chimique, label: "Matériau produit chimique" },
    { value: DomaineConaissance.textile, label: "Textile" },
    { value: DomaineConaissance.travail_materiau, label: "Travail matériau" },
    { value: DomaineConaissance.batiment_gros_oeuvre, label: "Bâtiment gros oeuvre" },
    { value: DomaineConaissance.batiment_second_oeuvre, label: "Bâtiment second oeuvre" },
    { value: DomaineConaissance.btp_conception_organisation, label: "Btp conception organisation" },
    { value: DomaineConaissance.travaux_publics, label: "Travaux publics" },
    { value: DomaineConaissance.automatisme_informatique_industrielle, label: "Automatisme informatique industrielle" },
    { value: DomaineConaissance.electronique, label: "Électronique" },
    { value: DomaineConaissance.mecanique_construction_reparation, label: "Mécanique construction réparation" },
    { value: DomaineConaissance.encadrement_management, label: "Encadrement management" },
    { value: DomaineConaissance.gestion_financiere_comptabilite, label: "Gestion financière comptabilité" },
    { value: DomaineConaissance.gestion_organisations, label: "Gestion organisations" },
    { value: DomaineConaissance.gestion_qualite, label: "Gestion qualité" },
    { value: DomaineConaissance.ressources_humaines, label: "Ressources humaines" },
    { value: DomaineConaissance.secretariat_assistanat, label: "Secrétariat assistanat" },
    { value: DomaineConaissance.banque_assurance, label: "Banque assurance" },
    { value: DomaineConaissance.commerce, label: "Commerce" },
    { value: DomaineConaissance.commerce_international, label: "Commerce international" },
    { value: DomaineConaissance.gestion_commerciale_achats, label: "Gestion commerciale achats" },
    { value: DomaineConaissance.immobilier, label: "Immobilier" },
    { value: DomaineConaissance.services_commerces_proximite, label: "Services commerces proximité" },
    { value: DomaineConaissance.action_sociale, label: "Action sociale" },
    { value: DomaineConaissance.medecine, label: "Médecine" },
    { value: DomaineConaissance.prevention_securite, label: "Prévention sécurité" },
    { value: DomaineConaissance.sante, label: "Santé" },
    { value: DomaineConaissance.activite_physique_et_sportive, label: "Activité physique et sportive" },
    { value: DomaineConaissance.hotellerie_restauration, label: "Hôtellerie restauration" },
    { value: DomaineConaissance.tourisme, label: "Tourisme" },
    { value: DomaineConaissance.electricite, label: "Électricité" },
    { value: DomaineConaissance.genie_climatique, label: "Génie climatique" },
];

export default domaineConnaissance