import React from "react";

const Aggregator = () => {
  return (
    <g>
        <svg xmlns="http://www.w3.org/2000/svg" id="Composant_6_5" data-name="Composant 6 – 5" width="94.982" height="94.983" viewBox="0 0 94.982 94.983">
            <circle id="Ellipse_1" data-name="Ellipse 1" cx="34" cy="34" r="34" transform="matrix(0.809, 0.588, -0.588, 0.809, 39.969, 0)" fill="#b5b8bc"/>
            <line id="Ligne_29" data-name="Ligne 29" x2="15.871" y2="11.604" transform="matrix(0.326, 0.946, -0.946, 0.326, 51.604, 27.967)" fill="none" stroke="#fff" stroke-width="1"/>
            <line id="Ligne_30" data-name="Ligne 30" x1="14.847" y1="12.799" transform="matrix(0.326, 0.946, -0.946, 0.326, 46.282, 46.585)" fill="none" stroke="#fff" stroke-width="1"/>
            <line id="Ligne_31" data-name="Ligne 31" y1="18.942" x2="2.73" transform="matrix(0.326, 0.946, -0.946, 0.326, 56.924, 58.622)" fill="none" stroke="#fff" stroke-width="1"/>
            <line id="Ligne_32" data-name="Ligne 32" x2="18.009" y2="5.524" transform="matrix(0.809, 0.588, -0.588, 0.809, 46.284, 46.585)" fill="none" stroke="#fff" stroke-width="1"/>
            <line id="Ligne_33" data-name="Ligne 33" x1="13.353" y2="14.713" transform="matrix(0.809, 0.588, -0.588, 0.809, 40.801, 20.119)" fill="none" stroke="#fff" stroke-width="1"/>
            <line id="Ligne_34" data-name="Ligne 34" x1="20" y1="4" transform="matrix(0.809, 0.588, -0.588, 0.809, 32.153, 32.022)" fill="none" stroke="#fff" stroke-width="1"/>
            <line id="Ligne_35" data-name="Ligne 35" y1="16" x2="16" transform="matrix(0.809, 0.588, -0.588, 0.809, 55.387, 34.07)" fill="none" stroke="#fff" stroke-width="1"/>
            <line id="Ligne_36" data-name="Ligne 36" x1="2" y1="21" transform="matrix(0.809, 0.588, -0.588, 0.809, 68.333, 43.474)" fill="none" stroke="#fff" stroke-width="1"/>
            <circle id="Ellipse_14" data-name="Ellipse 14" cx="5" cy="5" r="5" transform="matrix(0.326, 0.946, -0.946, 0.326, 54.762, 21.555)" fill="#fff"/>
            <circle id="Ellipse_15" data-name="Ellipse 15" cx="5" cy="5" r="5" transform="matrix(0.326, 0.946, -0.946, 0.326, 49.571, 40.265)" fill="#fff"/>
            <circle id="Ellipse_18" data-name="Ellipse 18" cx="5" cy="5" r="5" transform="matrix(0.326, 0.946, -0.946, 0.326, 71.583, 36.768)" fill="#fff"/>
            <circle id="Ellipse_19" data-name="Ellipse 19" cx="5" cy="5" r="5" transform="matrix(0.326, 0.946, -0.946, 0.326, 35.04, 25.507)" fill="#fff"/>
            <circle id="Ellipse_16" data-name="Ellipse 16" cx="5" cy="5" r="5" transform="matrix(0.326, 0.946, -0.946, 0.326, 61.104, 55.331)" fill="#fff"/>
            <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="5.5" cy="5" rx="5.5" ry="5" transform="matrix(0.326, 0.946, -0.946, 0.326, 41.839, 57.735)" fill="#fff"/>
        </svg>
    </g>
  );
};
export default Aggregator;
