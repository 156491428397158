import React from "react";
import VirtualTutor from "./components/VirtualTutor/VirtualTutor";
import {RecoilRoot, useRecoilState} from "recoil";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginHandler from "./components/LoginHandler/LoginHandler";
import {rolesAtom} from "./recoil/atoms";
import RouterAdmin from "./components/Admin/RouterAdmin";

const App = () => {
  return (
    <RecoilRoot>
      <title>Tuteur Virtuel</title>
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Navigation/>
          </Switch>
        </div>
      </BrowserRouter>
    </RecoilRoot>
  );
};

 const Navigation = () => {
    const [rolesUser] = useRecoilState(rolesAtom);
    return(
    <LoginHandler>
        { rolesUser.includes("etudiant") &&
          <>
            <Route path="/">
                <VirtualTutor useStudentId={true} />
            </Route>
            <Redirect to="/" />
          </>
        }
        { rolesUser.includes("enseignant") &&
        <Route path="/">
            <RouterAdmin />
        </Route>
        }
        { rolesUser.includes("admin") &&
        <Route path="/">
            <RouterAdmin />
        </Route>
        }
    </LoginHandler>
    );
};

export default App;
