import './FormRecommandation.css'
import React, {useEffect, useState} from "react";
import Select from 'react-select';
import {ObjectifPedagogique} from "../../../../../interface/objectifPedagogique";
import {ApiService} from "../../../../LoginHandler/restServices";
import StatutEvenement from "../../../../../vocabulary/statutEvenement";
import {useRecoilState} from "recoil";
import {selectedTeachingUnitAtom} from "../../../../../recoil/graphFilters";
import {Button, Spinner} from "reactstrap";
import {useParams} from "react-router-dom";
import IRecommandation from "../../../../../interface/Recommandation.interface";
import IAlgorithme from "../../../../../interface/Algorithme.interface";
import IActivitePedagogique from "../../../../../interface/ActivitePedagogique.interface";
import IEtudiant from "../../../../../interface/Etudiant.interface";
import IEtudiantActivite from "../../../../../interface/EtudiantActivite.interface";
import IEtudiantObjectif from "../../../../../interface/EtudiantObjectif.interface";
import IInfoRecommandation from "../../../../../interface/InfoRecommandation.interface";
import IUniteEnseignement from "../../../../../interface/UniteEnseignement.interface";
import IObjectifPedagogique from "../../../../../interface/ObjectifPedagogique.interface";
import {IRecommandationCraftAi} from "../../../../../interface/IRecommandationCraftAi.interface";
import Flag from "../../../../svg/Flag";

const FormRecommandation = () => {

    const { studentId } = useParams<{ studentId: string }>();
    const [isOpen,setOpen] = useState<boolean>(false);
    const [isLoad,setLoad] = useState<boolean>(false);

    const [result,setResult] = useState<IRecommandationCraftAi[] | undefined>(undefined);
    const [resultLoading,setResultLoading] = useState<boolean>(false);

    const [teachingUnit] = useRecoilState(
        selectedTeachingUnitAtom
    );

    let indexRecommandation = result != undefined ? result.length + 1 : 2;

    const [currentGraphObjectives, setCurrentGraphObjectives] = useState<{label: string, value: string, selected: boolean}[]>([]);
    const [categories, setCategories] = useState<{label: string, value: string, selected: boolean}[]>(
        [
            {label:"Quiz", value:"quiz", selected:false},
            {label:"Document", value:"document", selected:false},
            {label:"Vidéo", value:"video", selected:false},
            {label:"Lien", value:"link", selected:false},
        ]
    );

    const [nombreRecommandation,setNombreRecommandation] = useState<number>(3);
    const [tempsDisponible,setTempsDisponible] = useState<number>(15);
    const [tempActif,setTempActif] = useState<boolean>(true);

    // Récupération des objectifs de l'unité d'enseignement
    useEffect(() => {
        if(teachingUnit != undefined){
            ApiService.getAllObjectives(teachingUnit!._URI, StatutEvenement.inUse ).then(objectives => {
                setCurrentGraphObjectives(objectives.map( x => ({label: x._titreCourt, value: x._URI, selected: false})));
                setLoad(true);
            });

        }
    }, [teachingUnit]);

    const simulateRecommandation = () => {
        setResultLoading(true);
        ApiService.simulateRecommandation(
            studentId,
            teachingUnit!._URI,
            nombreRecommandation,
            currentGraphObjectives.filter(x => x.selected).length > 0 ? currentGraphObjectives.filter(x => x.selected).map(x => x.value) : undefined,
            tempActif ? tempsDisponible : undefined,
            categories.find(x => x.selected) != undefined ? categories.find(x => x.selected)!.value : undefined
        ).then(recommandations => {
            setResult(result == undefined ? [recommandations] : [recommandations].concat(result));
            indexRecommandation = result != undefined ? result.length + 1 : 2;
        })
    };



    return (
        <div className={ `simulateurRecommandation ${isOpen ? 'open' : ''} ${result || resultLoading ? 'openResult' : ''}`}>

            <button className="simulateurRecommandationBtn" onClick={() => {setOpen(!isOpen)}}>
                <span>Simulateur de recommandation</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi-chevron-down"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                </svg>
            </button>
            { isLoad ?
                <div className={"contentForm"}>
                    <form className={result || resultLoading ? "formSent" : ""}>
                        <label>Nombre de recommandation :</label>
                        <input type="number" value={nombreRecommandation} onChange={(e) => {setNombreRecommandation(parseInt(e.target.value))}} />

                        <label>Objectif(s) pédagogique ciblé(s) :</label>
                        <Select
                            isMulti={true}
                            defaultValue={currentGraphObjectives.filter(x => x.selected)}
                            isClearable={false}
                            placeholder="Sélection..."
                            options={currentGraphObjectives.filter(x => !x.selected)}
                            className="basic-multi-select"
                            classNamePrefix="objective-edit-panel-form-domaine-select"
                            onChange={
                                (e) => {
                                    if(e != undefined){
                                        setCurrentGraphObjectives(
                                            currentGraphObjectives.map(x => (
                                                e.find( y => y.value == x.value) ?
                                                    {value:x.value, label: x.label, selected:true}
                                                    :
                                                    {value:x.value, label: x.label, selected:false}
                                            ))
                                        )
                                    } else {
                                        currentGraphObjectives.map(x => (
                                            {value:x.value, label: x.label, selected:false}
                                        ))
                                    }
                                }
                            }
                        />

                        <label>Catégorie d'activité :</label>
                        <Select
                            defaultValue={categories.filter(x => x.selected)}
                            isClearable={false}
                            placeholder="Sélection..."
                            options={categories.filter(x => !x.selected)}
                            className="basic-multi-select"
                            classNamePrefix="objective-edit-panel-form-domaine-select"
                            onChange={
                                (e) => {
                                    if(e != undefined){
                                        setCategories(
                                            categories.map(x => (
                                                e.value == x.value) ?
                                                {value:x.value, label: x.label, selected:true}
                                                :
                                                {value:x.value, label: x.label, selected:false}
                                            )
                                        )
                                    } else {
                                        categories.map(x => (
                                            {value:x.value, label: x.label, selected:false}
                                        ))
                                    }
                                }
                            }
                        />

                        <label>Temps disponibles (en minutes) :</label>
                        <div>
                            <input className="tempsDisponibleCheckbox" type="checkbox" checked={tempActif} onChange={(e) => { setTempActif(!tempActif)}} />
                            <input className="tempsDisponibleNumber" disabled={!tempActif} type="number" value={tempsDisponible} onChange={(e) => {setTempsDisponible(parseInt(e.target.value))}} />
                            <i>En décochant la case le temps disponible<br/> ne sera pas pris en compte.</i>
                        </div>

                        <Button className={"submitBtn"} onClick={() => {simulateRecommandation()}}>
                            Simuler une recommandation
                        </Button>

                    </form>

                    {
                        resultLoading &&
                        <div className="resultRecommandation">
                            { result ?
                                result.map( reco => {
                                    indexRecommandation--;
                                    return (
                                        reco.error == undefined ?
                                            <div className="recoStatus">
                                                <h5>Recommandation N°{indexRecommandation} :</h5>
                                                <ol>
                                                    {
                                                        reco.recommendations.map(proposition => {
                                                            return (
                                                                <li>Activité <b>{proposition.activitePedagogique!.titreCourt != undefined ? proposition.activitePedagogique!.titreCourt : proposition.activitePedagogique!.titre}</b> pour
                                                                    objectif <b>{proposition.objectifPedagogique!.titreCourt}</b>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ol>
                                                { (reco.flags.missing_prerequisite ||
                                                    reco.flags.not_enough_activity ||
                                                    reco.flags.not_enough_matching_category ||
                                                    reco.flags.not_enough_new_activity ||
                                                    reco.flags.not_enough_undone_activity) &&
                                                    <div className={"recoFlag"}>
                                                        <hr/>
                                                        {reco.flags.missing_prerequisite &&
                                                            <div className="flag">
                                                                <Flag/>
                                                                <span>Des prérequis sont manquants.</span>
                                                            </div>
                                                        }

                                                        {reco.flags.not_enough_activity &&
                                                        <div className="flag">
                                                            <Flag/>
                                                            <span>Pas assez d'activité correspondante.</span>
                                                        </div>
                                                        }

                                                        {reco.flags.not_enough_matching_category &&
                                                        <div className="flag">
                                                            <Flag/>
                                                            <span>Pas assez d'activité dans la catégorie souhaité.</span>
                                                        </div>
                                                        }

                                                        {reco.flags.not_enough_new_activity &&
                                                        <div className="flag">
                                                            <Flag/>
                                                            <span>Pas assez d'activité correspondante hors quarantaine.</span>
                                                        </div>
                                                        }

                                                        {reco.flags.not_enough_undone_activity &&
                                                        <div className="flag">
                                                            <Flag/>
                                                            <span>Pas assez d'activité non terminé.</span>
                                                        </div>
                                                        }
                                                    </div>
                                                }

                                                <div className="infoReco">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        fill="currentColor"
                                                        className="bi bi-question-circle"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                                    </svg>
                                                </div>

                                                <div className="infoRecoContent">
                                                    { reco.request.availableTime &&
                                                    <p>Temps indiqué : <b>{reco.request.availableTime}</b></p>
                                                    }
                                                    { reco.request.category &&
                                                    <p>Catégorie demandée : <b>{reco.request.category}</b></p>
                                                    }
                                                    { reco.request.cc &&
                                                    <p>Révision : <b>{reco.request.cc}</b></p>
                                                    }
                                                    { reco.request.number &&
                                                    <p>Nombre de recommandation demandé : <b>{reco.request.number}</b></p>
                                                    }
                                                    { reco.request['objectif-pedagogique'] &&
                                                    <p>Objectif(s) demandé(s) :
                                                        {
                                                            reco.request['objectif-pedagogique'].map( x => {
                                                                return (
                                                                    <b className={"objReco"}>
                                                                        {" "} { (x.split('/'))[(x.split('/').length) -1] }
                                                                    </b>
                                                                )
                                                            })
                                                        }
                                                    </p>
                                                    }

                                                </div>


                                            </div>
                                            :
                                            <div className="recoStatus">
                                                <h5 className="error">Recommandation N°{indexRecommandation} :</h5>
                                                <b> ❌ Une erreur est survenue...</b>
                                                <p>{reco.error}</p>
                                            </div>
                                    )
                                })
                                :
                                <div className="spinner-center">
                                    <Spinner> </Spinner>
                                </div>
                            }
                        </div>
                    }

                </div>

                :
                <Spinner> </Spinner>
            }

        </div>
    )
}

export default FormRecommandation;