import React from "react";

const SVGVideo = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 200 200" className="svg-node-icon">
      <g transform="scale(1.3) translate(-20,-20)">
        <path strokeWidth={10} d="M144.5,100l-69,40l-0,-80l69,40Z" />
      </g>
    </svg>
  );
};
export default SVGVideo;
