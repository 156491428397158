import React from "react";

const IconOffButton = () => {
  return (
    <svg width="42px" viewBox="0 0 512 512" >
      <path fill="#6371B0" d="M372.364,349.091c-51.33,0-93.091-41.761-93.091-93.091s41.761-93.091,93.091-93.091
        c12.853,0,23.273-10.42,23.273-23.273c0-12.853-10.42-23.273-23.273-23.273H139.636C62.641,116.364,0,179.005,0,256
        s62.641,139.636,139.636,139.636h232.727c12.853,0,23.273-10.42,23.273-23.273S385.216,349.091,372.364,349.091z"/>
      <circle fill="#FFFFFF" cx="372.364" cy="256" r="116.364" />
      <path fill="#6371B0" d="M372.364,395.636c-76.995,0-139.636-62.641-139.636-139.636s62.641-139.636,139.636-139.636
        S512,179.005,512,256S449.359,395.636,372.364,395.636z M372.364,162.909c-51.33,0-93.091,41.761-93.091,93.091
        s41.761,93.091,93.091,93.091S465.455,307.33,465.455,256S423.694,162.909,372.364,162.909z"/>
    </svg>
  );
};
export default IconOffButton;
