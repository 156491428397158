import React from "react";

const SVGUsers = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 32 32" version="1.1">
            <g>
                <path xmlns="http://www.w3.org/2000/svg" d="m11.429 16a5.715 5.715 0 1 0 -5.715-5.714 5.72 5.72 0 0 0 5.715 5.714z"
                      data-original="#000000" className=""></path>
                <path xmlns="http://www.w3.org/2000/svg"
                      d="m18.905 20.721a10.434 10.434 0 0 0 -17.905 7.279 1 1 0 0 0 1 1h18.86a1 1 0 0 0 1-1 10.067 10.067 0 0 0 -.485-3.124 10.36 10.36 0 0 0 -2.47-4.155z"
                      data-original="#000000" className=""></path>
                <circle xmlns="http://www.w3.org/2000/svg" cx="23.5" cy="12.25" r="4.25" data-original="#000000"
                        className=""></circle>
                <path xmlns="http://www.w3.org/2000/svg"
                      d="m23.5 17.67a7.482 7.482 0 0 0 -3.806 1.057c.217.194.436.385.641.595a12.388 12.388 0 0 1 2.952 4.966 11.488 11.488 0 0 1 .437 1.882h6.276a1 1 0 0 0 1-1 7.508 7.508 0 0 0 -7.5-7.5z"
                      data-original="#000000" className=""></path>
            </g>
        </svg>
    );
};
export default SVGUsers;
