import "./HelpContributions.css";
import { useRecoilState } from "recoil";
import { Button } from "reactstrap";
import {
  toggleModalHelpContributionsAtom,
} from "../../../../../recoil/atoms";
<link 
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap" 
  rel="stylesheet" 
/>;

function HelpContributions() {
  const [isModalHelpContributionsOpen, setModalHelpContributionsOpen] = useRecoilState(
    toggleModalHelpContributionsAtom
  );

  return (
    <div className={`helpContributions-modal ${isModalHelpContributionsOpen ? "helpContributions-open" : ""}`}>
      <div className="helpContributions-header">
        <div className="helpContributions-title">Contributions</div>
        <span 
          className="helpContributions-close" 
          onClick={() => setModalHelpContributionsOpen(false)}
        >
          <svg 
            width={20} 
            height={20} 
            viewBox="0 0 50 50"
          >
            <path
              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
              stroke="rgba(0, 0, 0, 0.36)"
              fill="rgba(0, 0, 0, 0.36)"
              strokeWidth={7}
            />
          </svg>
        </span>
      </div>
      <div className="helpContributions-intro">
        Tu peux soumettre une activité pertinente depuis le formulaire accessible en cliquant sur « Proposer une activité » depuis le volet d'un objectif.
        <div className="helpContributions-intro-br">Après validation de tes enseignants, cette activité sera accessible à l’ensemble de ta promo.</div>
      </div>
      <div className="helpContributions-body">
        <Button className="helpContributions-body-button">
          Proposer une activité
        </Button>
      </div>
    </div>
  );
}

export default HelpContributions;