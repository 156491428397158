import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import {
    getEnhancedGraphSelector,
    graphSelectorPO,
    lockedAtom,
    selectedNodeIDAtom,
    zoomAtom,
    zoomAtomT
} from "../../../../recoil/atoms";
import BackgroundSvgGrid from "../../../KnowledgeGraph/BackgroundSvgGrid";
import {TOPIC} from "../../../../interface/graph";
import {useRecoilState, useRecoilValue} from "recoil";
import PositionGraph from "../../../PositionsForceGraph/PositionGraph";
import {getId} from "../../../KnowledgeGraph/PositionSimulationSettings";


function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const PositionGraphEnseignant = () => {

  const width = 600;
  const height = 600;

  const [locked] = useRecoilState(lockedAtom);
  const [s] = useRecoilState(selectedNodeIDAtom);
  const d3Container = useRef(null);
  const data = useRecoilValue(getEnhancedGraphSelector);
  const [currentZoom, setZoom] = useRecoilState(zoomAtom);
  const simulation = useRef<any>(d3.forceSimulation([]));
  const [graph, setGraph] = useState<any>(undefined);
  const pg: any = usePrevious(graph);
  const [zoomUpdate] = useRecoilState(zoomAtomT);

  // Déplacement du graph
  useEffect(() => {
     
    const links = data.links
        .filter(x => x.type != "linkActivityToAggregator")
        .map((d) => ({ ...d }));

    const nodes = data.nodes.map((d) => ({ ...d }));

    const prev = simulation.current
      .nodes()
      .map((d: any) => ({ ...d }))
      .reduce(
        (a: any, b: any, i: number) => ({ ...a, [pg?.nodes[i]?.id]: b }),
        {}
      );
    // }
    if(!locked) {
        //free to move render
        simulation.current
            .nodes(
                nodes.map((n: any) => {
                    let prevElement = prev[n.id];
                    if (prevElement) {
                        if (s === n.id) {
                            n.fx = prevElement.x;
                            n.fy = prevElement.y;
                        }
                    }
                    return n;
                })
            )
            //Distance link objectif
            .force(
                "linkObjectif",
                d3
                // @ts-ignore
                    .forceLink(links.filter(x => {
                        var source = nodes.find( n => n.id == x.source.toString());
                        var target = nodes.find( n => n.id == x.target.toString());
                        return (
                            ( source != undefined && source.type == TOPIC )
                            &&
                            ( target != undefined && target.type == TOPIC )
                        );
                    }))
                    .id((d: any) => d.id)
                    .distance(80)
                    .strength(1)
            )
            //Distance link activité
            .force(
                "linkActivite",
                d3
                // @ts-ignore
                    .forceLink(links.filter(x => {
                        var source = nodes.find( n => n.id == x.source.toString());
                        var target = nodes.find( n => n.id == x.target.toString());
                        return (
                            ( source != undefined && source.type != TOPIC )
                            ||
                            ( target != undefined && target.type != TOPIC )
                        );
                    }))
                    .id((d: any) => d.id)
                    .distance(2)
                    .strength(1)
            )
            // Charge
            .force("charge", d3.forceManyBody().strength(400))
            //Collide objectif
            .force(
                "collideObjectif",
                d3
                // @ts-ignore
                    .forceCollide(nodes.filter(x => {
                        return (
                            x.type == TOPIC
                        );
                    }))
                    .radius(230)
            )
            //Collide activité
            .force(
                "collideActivite",
                d3
                // @ts-ignore
                    .forceCollide(nodes.filter(x => {
                        return (
                            x.type != TOPIC
                        );
                    }))
                    .radius(10)
            )
            .force("center", d3.forceCenter(0, 0).strength(1))
            .alpha(1)
            .tick(500)
            .restart();
    } else if (pg) {
      // constrained position render
      simulation.current
        .nodes(
          nodes
              .map((n: any) => {
            let prevElement = prev[n.id];
            if (prevElement) {
              n.fx = prevElement.x;
              n.fy = prevElement.y;
            } else {
              let parent: any;
              parent = pg.nodes.find(
                (k: any) => k.cluster && k.cluster.includes(getId(n))
              )?.id;

              parent =
                parent || data.links.find((l) => l.source === n.id)?.target;
              if (parent && prev[parent]) {
                n.x = prev[parent].fx;
                n.y = prev[parent].fy;
              }
            }

            return n;
          })
        )
        //Distance link objectif
          .force(
              "linkObjectif",
              d3
              // @ts-ignore
                  .forceLink(links.filter(x => {
                      var source = nodes.find( n => n.id == x.source.toString());
                      var target = nodes.find( n => n.id == x.target.toString());
                      return (
                          ( source != undefined && source.type == TOPIC )
                          &&
                          ( target != undefined && target.type == TOPIC )
                      );
                  }))
                  .id((d: any) => d.id)
                  .distance(80)
                  .strength(1)
          )
          //Distance link activité
          .force(
              "linkActivite",
              d3
              // @ts-ignore
                  .forceLink(links.filter(x => {
                      var source = nodes.find( n => n.id == x.source.toString());
                      var target = nodes.find( n => n.id == x.target.toString());
                      return (
                          ( source != undefined && source.type != TOPIC )
                          ||
                          ( target != undefined && target.type != TOPIC )
                      );
                  }))
                  .id((d: any) => d.id)
                  .distance(2)
                  .strength(1)
          )
          // Charge
          .force("charge", d3.forceManyBody().strength(400))
          //Collide objectif
          .force(
              "collideObjectif",
              d3
              // @ts-ignore
                  .forceCollide(nodes.filter(x => {
                      return (
                          x.type == TOPIC
                      );
                  }))
                  .radius(230)
          )
          //Collide activité
          .force(
              "collideActivite",
              d3
              // @ts-ignore
                  .forceCollide(nodes.filter(x => {
                      return (
                          x.type != TOPIC
                      );
                  }))
                  .radius(10)
          )
          .force("center", d3.forceCenter(0, 0).strength(1))
        .alpha(1)
        .tick(500)
        .restart();
    } else {
      // first render
      simulation.current
        .nodes(
          nodes
              .map((n: any) => {
            let prevElement = prev[n.id];
            if (prevElement) {
              n.fx = prevElement.x;
              n.fy = prevElement.y;
              n.x = prevElement.x;
              n.y = prevElement.y;
            } else {
              let parent: any | undefined;
              parent = data.nodes.find(
                (k: any) => k.cluster && k.cluster.includes(getId(n))
              )?.id;
              parent =
                parent || data.links.find((l) => l.source === n.id)?.target;
              if (parent && prev[parent]) {
                n.x = prev[parent].x;
                n.y = prev[parent].y;
              }
            }

            return n;
          })
        )
        //Distance link objectif
          .force(
              "linkObjectif",
              d3
              // @ts-ignore
                  .forceLink(links.filter(x => {
                      var source = nodes.find( n => n.id == x.source.toString());
                      var target = nodes.find( n => n.id == x.target.toString());
                      return (
                          ( source != undefined && source.type == TOPIC )
                          &&
                          ( target != undefined && target.type == TOPIC )
                      );
                  }))
                  .id((d: any) => d.id)
                  .distance(80)
                  .strength(1)
          )
          //Distance link activité
          .force(
              "linkActivite",
              d3
              // @ts-ignore
                  .forceLink(links.filter(x => {
                      var source = nodes.find( n => n.id == x.source.toString());
                      var target = nodes.find( n => n.id == x.target.toString());
                      return (
                          ( source != undefined && source.type != TOPIC )
                          ||
                          ( target != undefined && target.type != TOPIC )
                      );
                  }))
                  .id((d: any) => d.id)
                  .distance(2)
                  .strength(1)
          )
          // Charge
          .force("charge", d3.forceManyBody().strength(400))
          //Collide objectif
          .force(
              "collideObjectif",
              d3
              // @ts-ignore
                  .forceCollide(nodes.filter(x => {
                      return (
                          x.type == TOPIC
                      );
                  }))
                  .radius(200)
          )
          //Collide activité
          .force(
              "collideActivite",
              d3
              // @ts-ignore
                  .forceCollide(nodes.filter(x => {
                      return (
                          x.type != TOPIC
                      );
                  }))
                  .radius(10)
          )
          .force("center", d3.forceCenter(0, 0).strength(1))
        .alpha(1)
        .tick(500)
        .restart();
    }
    // @ts-ignore
    if (links.length > 0 && links[0].source != undefined && links[0].source?.x) {
      setGraph({
        links,
        nodes: nodes.map((n: any) => {
          let prevElement = prev[n.id];
          if (prevElement) {
            n.px = prevElement.x;
            n.py = prevElement.y;
          } else {
            let parent: any | undefined;
            parent = pg?.nodes.find(
              (k: any) => k.cluster && k.cluster.includes(getId(n))
            )?.id;
            parent =
              parent || data.links.find((l) => l.source === n.id)?.target;
            if (parent && prev[parent]) {
              n.px = prev[parent].x;
              n.py = prev[parent].y;
            }
          }
          n.py = n.py || 0;
          n.px = n.px || 0;
          return n;
        }),
      });
    }
  }, [locked, data]);

  useEffect(() => {
    if (d3Container.current) {
      const svg = d3.select(d3Container.current);
      let zoomBehavior = d3.zoom();
      svg
        .call(
          // @ts-ignore
          zoomBehavior
            .on("zoom", function ({ transform }) {
              svg.select(".zoomable").attr("transform", transform);
            })
            .on("end", function (event) {
                const { transform } = event;
                setZoom(transform);
            })
            .scaleExtent([0.3, 3])
        )
        .on("dblclick.zoom", null)
        // @ts-ignore
        .call(zoomBehavior.transform, zoomUpdate)
    }
    return () => {};
  }, [setZoom, zoomUpdate]);

  return (
    <svg
      className="d3-component"
      width="100vw"
      height="100vh"
      viewBox={`0, 0, ${width}, ${height}`}
      ref={d3Container}
    >
      <defs>
        <marker
          id="arrowhead"
          markerWidth="12"
          markerHeight="8"
          refX="10"
          refY="4"
          orient="auto"
        >
          <polygon points="0 0, 12 4, 0 8" fill="#717884" />
        </marker>

        <marker
          id="arrowhead-done"
          markerWidth="12"
          markerHeight="8"
          refX="10"
          refY="4"
          orient="auto"
        >
          <polygon points="0 0, 12 4, 0 8" fill="#1e9b7e" />
        </marker>
        <marker
          id="arrowhead-mine"
          markerWidth="12"
          markerHeight="8"
          refX="10"
          refY="4"
          orient="auto"
        >
          <polygon points="0 0, 12 4, 0 8" fill="#f08482" />
        </marker>
        <marker
          id="arrowhead-started"
          markerWidth="12"
          markerHeight="8"
          refX="10"
          refY="4"
          orient="auto"
        >
          <polygon points="0 0, 12 4, 0 8" fill="#314587" />
        </marker>
      </defs>
      <g
        className="zoomable"
        style={{ cursor: "all-scroll" }}
        transform={currentZoom.toString()}
      >
        <BackgroundSvgGrid />
        {/*{simulation && graph && (*/}
        {graph && <PositionGraph data={graph} simulation={simulation}/>}
        {/*)}*/}
      </g>
    </svg>
  );
};
export default PositionGraphEnseignant;
