export const CategorieActivite = {
    document: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/1",
    video: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/2",
    lien_web: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/3",
    quiz: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/4",
    jeu: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/5",
    podcast: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/6",
    exercice: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/7",
    recherche_type: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/8",
    module_e_learning: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/9",
    presentation: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/10",
    feuille_de_calcul: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/11",
    cours: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/12",
    sondage: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/13",
    forum: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/14",
    wiki: "https://data.dauphine.psl.eu/vocabulary/categorie-activite/15",
};

export default CategorieActivite