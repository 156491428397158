import * as React from 'react'
import "./ActivityBox.css";
import * as d3 from "d3";
import { SvgDocument } from '../../../../icons/SVGDocument';
import 'react-circular-progressbar/dist/styles.css';
import StatutEtudiantPourActivite from '../../../../../vocabulary/statutEtudiantPourActivite.vocabulary';
import CategorieActivite from '../../../../../vocabulary/categorieActivite';
import SVGResearch from '../../../../icons/SVGResearch';
import SVGVideo from '../../../../icons/SVGVideo';
import SVGWebLink from '../../../../icons/SVGWebLink';
import SVGQuestionnaire from '../../../../icons/SVGQuestionnaire';
import { SVGJeux } from '../../../../icons/SVGJeux';
import { SVGPodcast } from '../../../../icons/SVGPodcast';
import { SVGExercice } from '../../../../icons/SVGExercice';
import { SVGElearning } from '../../../../icons/SVGElearning';
import { SVGPresentation } from '../../../../icons/SVGPresentation';
import { SVGExcel } from '../../../../icons/SVGExcel';
import { SVGCours } from '../../../../icons/SVGDauphineCours';

function ActivityBox(props: {
  status: string | undefined,
  type: string | undefined,
  course: string | undefined,
  title: string | undefined
}) {

  var category: string = "Type inconnu";
  var logo:JSX.Element = <SvgDocument />;
  switch (props.type) {
    case CategorieActivite.document:
      category = "Document";
      break;
    case CategorieActivite.video:
      category = "Vidéo";
      logo = <SVGVideo />;
      break;
    case CategorieActivite.lien_web:
      category = "Lien web";
      logo = <SVGWebLink />;
      break;
    case CategorieActivite.quiz:
      category = "Quiz";
      logo = <SVGQuestionnaire />;
      break;
    case CategorieActivite.jeu:
      category = "Jeu";
      logo = <SVGJeux />;
      break;
    case CategorieActivite.podcast:
      category = "Podcast";
      logo = <SVGPodcast />;
      break;
    case CategorieActivite.exercice:
      category = "Exercice";
      logo = <SVGExercice />;
      break;
    case CategorieActivite.recherche_type:
      category = "Recherche type";
      logo = <SVGResearch />;
      break;
    case CategorieActivite.module_e_learning:
      category = "Module e-learning";
      logo = <SVGElearning />;
      break;
    case CategorieActivite.presentation:
      category = "Présentation";
      logo = <SVGPresentation />;
      break;
    case CategorieActivite.feuille_de_calcul:
      category = "Feuille de calcul";
      logo = <SVGExcel />;
      break;
    case CategorieActivite.cours:
      category = "Cours";
      logo = <SVGCours />;
      break;
    case CategorieActivite.sondage:
      category = "Sondage";
      break;
    case CategorieActivite.forum:
      category = "Forum";
      break;
    case CategorieActivite.wiki:
      category = "Wiki";
      break;
  }

  var status: string = "Statut inconnu";
  var fill: string = "rgb(199, 201, 206)";
  var stroke: string = `${d3.color("rgb(199, 201, 206)")?.darker()}`;
  switch (props.status) {
    case StatutEtudiantPourActivite.notStarted:
      status = "Non commencé";
      break;
    case StatutEtudiantPourActivite.doing:
      status = "En cours";
      break;
    case StatutEtudiantPourActivite.validate:
      status = "Validé";
      break;
    case StatutEtudiantPourActivite.noValidate:
      status = "Non validé";
      break;
  }


  return (
    <div className="student-profile-activity-box">
      <div className="student-profile-activity-box-logo">
        <svg
          height="60px"
          width="60px"
          className="node"
        >
          <g transform="translate(10, 12)">
            <circle
              fill={fill}
              cx={15.5}
              cy={15}
              r={20}
              stroke={stroke}
              className="circle"
            />
            {logo}
          </g>
        </svg>
      </div>
      <div className="student-profile-activity-box-info student-profile-activity-box-status">
        {status}
      </div>
      <div className="student-profile-activity-box-info student-profile-activity-box-type">
        {category}
      </div>
      <div className="student-profile-activity-box-info student-profile-activity-box-course">
        {props.course ? props.course : "Unité d'enseignement inconnue"}
      </div>
      <div className="student-profile-activity-box-title">
        {props.title ? props.title : "Nom inconnu"}
      </div>
    </div>
  );
};

export default ActivityBox;
