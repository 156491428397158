export const SVGPodcast = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
      width="30" height="30" viewBox="0 0 30 30" className="svg-node-icon">
      <g>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M15,16.9L15,16.9c-2.3,0-4.2-1.9-4.2-4.2V6.3C10.8,3.9,12.7,2,15,2h0c2.3,0,4.2,1.9,4.2,4.2v6.4
          C19.3,15,17.4,16.9,15,16.9z"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="11.6" y1="28" x2="18.4" y2="28"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="15" y1="20.9" x2="15" y2="27.8"/>
        <path fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M22.7,13.1c0,4.2-3.4,7.7-7.7,7.7s-7.7-3.4-7.7-7.7"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="17.2" y1="6.4" x2="19.3" y2="6.4"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="17.2" y1="9.3" x2="19.3" y2="9.3"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="17.2" y1="12.3" x2="19.3" y2="12.3"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="10.8" y1="6.4" x2="12.9" y2="6.4"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="10.8" y1="9.3" x2="12.9" y2="9.3"/>
        <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="10.8" y1="12.3" x2="12.9" y2="12.3"/>
      </g>
    </svg>
  );
};
