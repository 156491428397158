import React from "react";

const SVGShield = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 200 200" version="1.1">
      <g>
        <path
          d="M98.695,45.509c-0,0 25.853,24.334 46.623,18.159c3.097,-0.921 7.156,68.271 -46.623,90.823c-53.779,-22.552 -49.721,-91.744 -46.623,-90.823c20.769,6.175 46.623,-18.159 46.623,-18.159Z"
          stroke="#fff7"
          strokeWidth={16}
          fill="none"
        />
        <path d="M98.695,45.509c-0,0 25.853,24.334 46.623,18.159c3.097,-0.921 7.156,68.271 -46.623,90.823c-53.779,-22.552 -49.721,-91.744 -46.623,-90.823c20.769,6.175 46.623,-18.159 46.623,-18.159Z" />
        <path
          d="M146.902,74.261c-0.017,5.446 -0.6,12.584 -2.243,20.434l-59.336,15.899c-2.454,0.657 -5.068,0.313 -7.268,-0.957c-2.2,-1.27 -3.805,-3.362 -4.463,-5.816c0,-0 0,-0 0,-0.001c-0.657,-2.453 -0.313,-5.068 0.957,-7.268c1.27,-2.2 3.362,-3.805 5.816,-4.463l66.537,-17.828Z"
          fill="white"
          stroke={"white"}
          strokeOpacity={0.4}
          fillOpacity={0.4}
        />
        <path
          d="M143.198,100.772c-1.975,7.287 -4.897,14.946 -9.124,22.278l-56.027,15.012c-1.052,0.282 -2.134,0.38 -3.199,0.298c-3.34,-3.418 -6.245,-7.07 -8.768,-10.857c0.166,-1.216 0.567,-2.399 1.192,-3.482c1.271,-2.2 3.363,-3.806 5.816,-4.463l70.11,-18.786Z"
          fill="white"
          stroke={"white"}
          strokeOpacity={0.4}
          fillOpacity={0.4}
        />
        <path
          d="M98.695,45.509c-0,0 25.853,24.334 46.623,18.159c3.097,-0.921 7.156,68.271 -46.623,90.823c-53.779,-22.552 -49.721,-91.744 -46.623,-90.823c20.769,6.175 46.623,-18.159 46.623,-18.159Z"
          strokeWidth={4}
          fill="none"
        />
      </g>
    </svg>
  );
};
export default SVGShield;
