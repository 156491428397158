import React from "react";

const IconOnButton = () => {
  return (
    <svg width="42px" viewBox="0 0 512 512">
      <path fill="#77757F" d="M372.364,116.364H139.636C62.641,116.364,0,179.005,0,256s62.641,139.636,139.636,139.636h232.727
        C449.359,395.636,512,332.995,512,256S449.359,116.364,372.364,116.364z"/>
      <circle fill="#FFFFFF" cx="139.636" cy="256" r="116.364" />
      <path fill="#77757F" d="M139.636,395.636C62.641,395.636,0,332.995,0,256s62.641-139.636,139.636-139.636
        S279.273,179.005,279.273,256S216.632,395.636,139.636,395.636z M139.636,162.909c-51.33,0-93.091,41.761-93.091,93.091
        s41.761,93.091,93.091,93.091S232.727,307.33,232.727,256S190.967,162.909,139.636,162.909z"/>
    </svg>
  );
};
export default IconOnButton;
