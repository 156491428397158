import React from "react";

const SVGResearch = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 200 200"
      className="svg-node-icon research"
    >
      <g transform="scale(1.5) translate(-30,-30)">
        <path
          fillRule="evenodd"
          // fill={`${d3.color(color.mainColor)?.brighter()}`}
          strokeWidth={10}
          // stroke={`${d3.color(color.mainColor)?.darker()}`}
          d="M111.97,127.057c-6.299,3.71 -13.637,5.838 -21.468,5.838c-23.398,-0 -42.393,-18.996 -42.393,-42.393c-0,-23.398 18.995,-42.393 42.393,-42.393c23.397,-0 42.393,18.995 42.393,42.393c-0,7.831 -2.128,15.169 -5.838,21.468l21.71,21.709c2,2.001 3.124,4.714 3.124,7.544c0,2.829 -1.124,5.543 -3.124,7.543c-0,0 -0.001,0.001 -0.001,0.001c-2,2 -4.714,3.124 -7.543,3.124c-2.83,0 -5.543,-1.124 -7.544,-3.124l-21.709,-21.71Zm-21.468,-57.752c11.698,0 21.196,9.498 21.196,21.197c0,11.698 -9.498,21.196 -21.196,21.196c-11.699,0 -21.197,-9.498 -21.197,-21.196c0,-11.699 9.498,-21.197 21.197,-21.197Z"
        />
      </g>
    </svg>
  );
};
export default SVGResearch;
