import React from "react";
import { Button } from "reactstrap";
import { ZoomTransform } from "d3-zoom";
import { useRecoilState } from "recoil";
import { zoomAtom, zoomAtomT, lockedAtom } from "../../../recoil/atoms";

const BottomControls = () => {
  const [transform] = useRecoilState(zoomAtom);
  const [, setZoom] = useRecoilState(zoomAtomT);
  const [locked, setLocked] = useRecoilState(lockedAtom);
  return (
    <div className="bottom_controls_container">
      <div className="bottom_controls_rectangle">
        <Button
          className="plus_minus_button"
          style={{ marginRight: "5px" }}
          onClick={() => {
            setLocked(!locked);
          }}
        >
          <img
            height={"20px"}
            alt={"lock icon"}
            src={locked ? "/img/locked.png" : "/img/lock.png"}
          />
        </Button>
        <Button
          className="plus_minus_button"
          onClick={() => {
            let k = Math.max(0.3, transform.k - 0.25);
            const zoom = new ZoomTransform(k, transform.x, transform.y);

            setZoom(zoom);
          }}
        >
          -
        </Button>
        <span className="zoom_value">{Math.round(transform.k * 100)}%</span>
        <Button
          className="plus_minus_button"
          onClick={() => {
            let k = Math.min(3, transform.k + 0.25);
            const zoom = new ZoomTransform(k, transform.x, transform.y);

            setZoom(zoom);
          }}
        >
          +
        </Button>{" "}
      </div>
    </div>
  );
};
export default BottomControls;
