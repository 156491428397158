import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { Button } from "reactstrap";
import { useRecoilState } from "recoil";
import { ApiService } from "../../../../../../components/LoginHandler/restServices";
import { ConditionObtentionControleConnaissance } from "../../../../../../interface/conditionObtentionControleConnaissance";
import { ConditionObtentionMedia } from "../../../../../../interface/conditionObtentionMedia";
import {
  SettingPopUp,
  toggleActivityEditModalAtom,
  toggleTeachingUnitModeLiveAtom,
  userAtom
} from "../../../../../../recoil/atoms";
import { selectedActivityAtom, currentObjectiveActivitiesAtom } from "../../../../../../recoil/graphFilters";
import Select, { StylesConfig } from 'react-select';
import categorieActivite from "../../../../../../data/categorieActivite";
import CategorieActivite from "../../../../../../vocabulary/categorieActivite";
import { ActivitePedagogique } from "../../../../../../interface/activitePedagogique";
import "./ActivityEditForm.css";
import IconDelete from "../../../../../icons/SVGIconDelete";
import Enseignant from "../../../../../../interface/enseignant";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ActivityEditForm(props) {

  const [activityAtom, setActivityAtom] = useRecoilState(
    selectedActivityAtom
  );
  const [user,] = useRecoilState(userAtom);
  const [, setSettingPopUp] = useRecoilState(SettingPopUp);
  const [, setActivityEditModalOpen] = useRecoilState(toggleActivityEditModalAtom);
  const [currentObjectiveActivities, setCurrentObjectiveActivities] = useRecoilState(currentObjectiveActivitiesAtom);
  const [teachingUnitModeLive,] = useRecoilState(toggleTeachingUnitModeLiveAtom);
  const [isContributiveChecked, setIsContributiveChecked] = useState<boolean>();
  const [isQuizCompletion, setIsQuizCompletion] = useState<boolean>();
  const [categorieFieldValue, setCategorieFieldValue] = useState<string | undefined>();
  const [canBeDeleted, setCanBeDeleted] = useState<boolean>();

  const isStudentContribution: boolean = activityAtom !== undefined && activityAtom._auteur !== undefined && (
    Array.isArray(activityAtom._auteur) ? activityAtom._auteur[0]._URI.includes("etudiant") : activityAtom._auteur._URI.includes("etudiant")
  );

  // Ajout de l'utilisateur connecté en tant qu'option du champ "validé par" (cas contribution étudiante)
  if (user !== undefined) {
    var optionsValidBy: { value: string, label: string, isFixed: boolean } = { value: user?._URI, label: user._nom + ' ' + user?._prenom, isFixed: false };
  }

  // Ajout des enseignant ayant déjà validés l'activité (cas contribution étudiante)
  const defaultValidBy = activityAtom?._validePar !== undefined ?
    (Array.isArray(activityAtom?._validePar) ? activityAtom?._validePar : [activityAtom?._validePar])
      .map(teacher => ({ value: teacher._URI, label: teacher._nom + ' ' + teacher._prenom, isFixed: teacher._URI !== user?._URI }))
    :
    [];
  // Styles des autres enseignants ne pouvant pas être supprimés par celui connecté
  const styles: StylesConfig<{ value: string, label: string, isFixed: boolean }, true> = {
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    }
  }

  const { register, reset, handleSubmit, formState: { errors }, control } = useForm();


  useEffect(() => {
    if (activityAtom !== undefined) {

      // Est-ce que l'activité peut être supprimée (cas contribution étudiante)
      if (Array.isArray(activityAtom?._validePar)) {
        setCanBeDeleted(activityAtom._validePar.length < 0)
      } else {
        setCanBeDeleted(activityAtom._validePar === undefined)
      }

      // Récupération de l'état de complétion du quiz et de la catégorie
      setIsQuizCompletion(true);
      if (activityAtom._idMoodle) {
        ApiService.getMoodleActivity(Number(activityAtom._idMoodle)).then(activity => {
          if (activity.cm.completion === 1 || activity.cm.completion === 2) {
            setIsQuizCompletion(true);
          } else {
            setIsQuizCompletion(false);
          }
        });
      }

      // Récupération de la catégorie de l'activité
      const defaultCategoryActivity: { value: string; label: string; } | undefined = categorieActivite.find(
        categorie => categorie.value === activityAtom?._categorieActivite
      );
      setCategorieFieldValue(defaultCategoryActivity?.value);

    }
  }, [activityAtom]);

  // Réinitialisation du formulaire : condition d'obtention et pourcentage de réussite
  useEffect(() => {
    if (activityAtom !== undefined) {
      reset({
        isContributive: props.isActivityMandatory,
        quizMark: props.pourcentageReussiteAttendu,
      });
      setIsContributiveChecked(props.isActivityMandatory);
    }
  }, [props.isActivityMandatory, props.pourcentageReussiteAttendu]);


  // Fonction de soumission du formulaire
  const onSubmit = async (data: any) => {

    // Changement de la catégorie de l'activité
    if (data.categoryController !== undefined) {
      let editedActivity = { ...activityAtom };
      editedActivity._categorieActivite = data.categoryController;
      setActivityAtom(editedActivity as ActivitePedagogique);
      ApiService.editActivity(editedActivity as ActivitePedagogique);
    }

    // Changement de validé par (n'entre pas dans le if si le champ est inchangé)
    if (data.validBy !== undefined) {
      let editedTeachers: Enseignant[] | undefined = data.validBy.length > 0 ?
        data.validBy.map(v => ({ _URI: v.value, _nom: v.label.split(" ")[0], _prenom: v.label.split(" ")[1] }))
        :
        undefined
        ;
      let editedActivity = { ...activityAtom };
      editedActivity._validePar = editedTeachers;
      setActivityAtom(editedActivity as ActivitePedagogique);
      ApiService.editActivity(editedActivity as ActivitePedagogique);
    }

    if (props.isActivityMandatory) {
      // L'activité conditionne l'obtention de l'objectif pédagogique
      if (!data.isContributive) {
        // Rendre l'activité non conditionnelle pour l'obtention de l'objectif pédagogique
        props.setIsApiConditionObtentionActivitiesFetched(false);
        ApiService.removeActivityConditionToObjectif(props.selectedObjective._URI, activityAtom!._URI).then(objective => {
          // Suppression de la condition d'obtention correspondante
          var modifiedConditionObtentionActivites: Array<ConditionObtentionControleConnaissance | ConditionObtentionMedia> =
            props.conditionObtentionActivities.filter(conditionObtention =>
              !(conditionObtention._conditionObtentionDeActivitePedagogique._URI === activityAtom!._URI)
            );
          props.setConditionObtentionActivities(modifiedConditionObtentionActivites);
          props.setIsApiConditionObtentionActivitiesFetched(true);
        });
      } else {
        if (activityAtom!._categorieActivite === "https://data.dauphine.psl.eu/vocabulary/categorie-activite/4") {
          if (props.pourcentageReussiteAttendu !== data.quizMark) {
            // Modifier la note de réussite du quiz pour l'obtention de l'objectif pédagogique
            ApiService.addActivityConditionToObjectif(props.selectedObjective._URI, activityAtom!._URI, Number(data.quizMark));
          }
        }
      }
    } else {
      // L'activité ne conditionne pas l'obtention de l'objectif pédagogique
      if (data.isContributive) {
        // Rendre l'activité conditionnelle pour l'obtention de l'objectif pédagogique
        props.setIsApiConditionObtentionActivitiesFetched(false);
        ApiService.addActivityConditionToObjectif(props.selectedObjective._URI, activityAtom!._URI, Number(data.quizMark)).then(objective => {
          // Ajout d'une nouvelle condition d'obtention
          var newConditionObtentionActivitiy: ConditionObtentionControleConnaissance | ConditionObtentionMedia;
          if (activityAtom!._categorieActivite === "https://data.dauphine.psl.eu/vocabulary/categorie-activite/4") {
            newConditionObtentionActivitiy = { _URI: "", _conditionObtentionDeActivitePedagogique: activityAtom!, _pourcentageReussiteAttendu: data.quizMark }
          } else {
            newConditionObtentionActivitiy = { _URI: "", _conditionObtentionDeActivitePedagogique: activityAtom! }
          };
          var modifiedConditionObtentionActivites: Array<ConditionObtentionControleConnaissance | ConditionObtentionMedia> = props.conditionObtentionActivities;
          modifiedConditionObtentionActivites.push(newConditionObtentionActivitiy);
          props.setConditionObtentionActivities(modifiedConditionObtentionActivites);
          props.setIsApiConditionObtentionActivitiesFetched(true);
        });
      }
    }

    setActivityEditModalOpen(false);
  };

  const deleteStudentContribution = () => {

    ApiService.deleteActivity(activityAtom!);
    setCurrentObjectiveActivities(currentObjectiveActivities.filter(activity => (activity._URI !== activityAtom?._URI)));
    
    setActivityEditModalOpen(false);
  };


  return (
    <form className="activity-edit-panel-contributive-form-form" onSubmit={handleSubmit(data => onSubmit(data))}>
      <label className="activity-edit-panel-contributive-form-asking">
        <div className="activity-edit-panel-contributive-form-asking-information">
          L'activité conditionne la validation de l'objectif :
        </div>
        <input
          disabled={teachingUnitModeLive}
          type="checkbox"
          {...register("isContributive")}
          checked={isContributiveChecked}
          onChange={(e) => setIsContributiveChecked(e.target.checked)}
        />
      </label>
      <label className="activity-edit-panel-contributive-form-asking">
        <div className="activity-edit-panel-contributive-form-asking-categorie">
          Catégorie de l'activité :
        </div>
        <Controller
          name="categoryController"
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              isClearable={false}
              options={categorieActivite}
              classNamePrefix="activity-edit-panel-contributive-form-asking-categorie-select"
              value={categorieActivite.find(c => c.value === categorieFieldValue)}
              onChange={(c) => { setCategorieFieldValue(c?.value); onChange(c?.value); }}
            />
          )}
        />
      </label>
      {categorieFieldValue === CategorieActivite.quiz && isContributiveChecked && isQuizCompletion &&
        <>
          <label className="activity-edit-panel-contributive-form-quiz">
            <div>
              Pourcentage nécessaire pour la validation :
            </div>
            <div className="activity-edit-panel-contributive-form-quiz-mark">
              <input
                disabled={teachingUnitModeLive}
                type="number"
                {...register("quizMark", { required: "Ce champ est requis" })}
                className="activity-edit-panel-contributive-form-quiz-mark-input"
                min="0"
                max="100"
              />
              <div className="activity-edit-panel-contributive-form-errors">
                {errors.quizMark &&
                  <span>{errors.quizMark.message}</span>
                }
              </div>
            </div>
          </label>
          <label className="activity-edit-panel-contributive-form-quiz">
            <div className="activity-edit-panel-contributive-form-quiz-mark-no-completion">
              <b>Vous devez aussi renseigner la note requise en accédant à l'activité Moodle. </b>
              Pour cela, modifiez le test puis renseignez le champ "Note pour passer" dans la section Note.
            </div>
          </label>
        </>
      }
      {categorieFieldValue === CategorieActivite.quiz && !isQuizCompletion &&
        <label className="activity-edit-panel-contributive-form-quiz">
          <div className="activity-edit-panel-contributive-form-quiz-mark-no-completion">
            <b>Vous devez renseigner la note requise pour valider le test Moodle. </b>
            Pour cela, modifiez le test puis renseignez le champ "Note pour passer" dans la section Note.
          </div>
        </label>
      }
      {isStudentContribution &&
        <div className="activity-edit-panel-student-contribution">
          Liste des enseignants ayant validés l'activité :
          <Controller
            name="validBy"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                defaultValue={defaultValidBy}
                isMulti
                isClearable={false}
                styles={styles}
                placeholder="Sélection..."
                options={[optionsValidBy]}
                className="basic-multi-select"
                classNamePrefix="objective-edit-panel-form-domaine-select"
                onChange={(teachers) => {
                  if (teachers.length === 0) {
                    setCanBeDeleted(true);
                  } else {
                    setCanBeDeleted(false)
                  }
                  onChange(teachers);
                }}
              />
            )}
          />
          {canBeDeleted &&
            <span className="activity-edit-panel-student-contribution-message">
              <div className="activity-edit-panel-student-contribution-message-warning">
                Supprimer cette contribution
              </div>
              <div
                className="activity-edit-panel-student-contribution-button"
                onClick={() => setSettingPopUp({
                  isOpen: true,
                  message: "Voulez-vous supprimer définitivement cette contribution ?",
                  arrBtn: [
                    {
                      text: "Confirmer",
                      action: () => {
                        deleteStudentContribution();
                      },
                      type: "secondary"
                    },
                    {
                      text: "Annuler",
                      action: () => { },
                      type: "primary"
                    }
                  ]
                })}
              >
                <IconDelete />
              </div>
            </span>
          }
        </div>
      }
      <div className="activity-edit-panel-contributive-form-submit">
        <Button className="activity-edit-panel-contributive-form-submit-button">
          Valider les modifications
        </Button>
      </div>
    </form>
  );
}

export default ActivityEditForm;
