import React, { useState, useEffect } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import "./Sidebar.css"
import { useRecoilState } from "recoil";
import SidebarListElement from "./SidebarListElement";
import FoxLogo from "../../../../images/fox.png";
import leftArrows from "../../../../images/left-arrows.png";
import rightArrows from "../../../../images/right-arrows.png";
import {selectedTeachingUnitAtom, teacherTeachingUnitsAtom} from "../../../../recoil/graphFilters";
import { TeachingUnit } from "../../../../interface/teachingUnits";
import { rolesAtom, toggleSidebarAtom } from "../../../../recoil/atoms";
import { ApiService } from "../../../LoginHandler/restServices";
import SVGUsers from "../../../icons/SVGUsers";
import IconSettings from "../../../icons/SVGIconSettings";

const Sidebar = () => {

  const [isFullyOpenedSidebar, setIsFullyOpenedSidebar] = useRecoilState(
    toggleSidebarAtom
  );
  const [selectedTeachingUnit, setSelectedTeachingUnit] = useRecoilState(
    selectedTeachingUnitAtom
  );
  const [existingGraphsTeachingUnits, setExistingGraphsTeachingUnits] = useRecoilState(
      teacherTeachingUnitsAtom
  );

  const [rolesUser,] = useRecoilState(rolesAtom);
  const location: string = useLocation().pathname;

  useEffect(() => {
    if (rolesUser.includes("enseignant") && existingGraphsTeachingUnits.length == 0) {
      ApiService.getTeachingUnits().then(teachingUnits => {
        setExistingGraphsTeachingUnits(teachingUnits);
      });
      const storedSelectedTeachingUnit = localStorage.getItem('selectedTeachingUnit');
      if (storedSelectedTeachingUnit != "undefined" && storedSelectedTeachingUnit != undefined) {
        setSelectedTeachingUnit(JSON.parse(storedSelectedTeachingUnit));
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedTeachingUnit', JSON.stringify(selectedTeachingUnit));
  }, [selectedTeachingUnit]);

  return (
    <div className={`sidebar ${isFullyOpenedSidebar ? "sidebar-full" : "sidebar-collapse"}`}>
      <div className="sidebar-content">
        <div className="sidebar-scroll">
          <div className="sidebar-head">
            <Link to='/' className="sidebar-head-link" onClick={() => setSelectedTeachingUnit(undefined)}>
              <span className="sidebar-head-logo">
                <img className="sidebar-head-img" src={FoxLogo} />
              </span>
              {isFullyOpenedSidebar &&
                <div className="sidebar-head-title">
                  Tuteur Virtuel
                </div>
              }
            </Link>
          </div>
          <div className="list-delimiter">
          </div>
          <>
            {rolesUser.includes("admin") &&
              <div className="sidebar-list">
                {isFullyOpenedSidebar && <div className="sidebar-list-title">MANAGEMENT</div>}
                <Link to="/unites-enseignement" className="sidebar-list-link" onClick={() => setSelectedTeachingUnit(undefined)}>
                  <SidebarListElement
                    label="Unités d'enseignements"
                    image={
                      <g>
                        <path d="M5 5h15v3H5zm12 5h3v9h-3zm-7 0h5v9h-5zm-5 0h3v9H5z" opacity=".3"></path>
                        <path d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM8 19H5v-9h3v9zm7 0h-5v-9h5v9zm5 0h-3v-9h3v9zm0-11H5V5h15v3z"></path>
                      </g>
                    }
                    isActivated={matchPath(location, {
                      path: "/unites-enseignement",
                    })}
                  />
                </Link>
              </div>
            }
          </>
          <>
            {rolesUser.includes("enseignant") &&
              <div className="sidebar-list">
                {isFullyOpenedSidebar && <div className="sidebar-list-title">UNITÉS D'ENSEIGNEMENT</div>}
                {existingGraphsTeachingUnits.map(tu =>
                  <>
                    {tu._nom != undefined &&
                      <Link
                        to={`/unites-enseignement/${tu._nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`}
                        className="sidebar-list-link"
                        onClick={() => { setSelectedTeachingUnit(tu); }}
                      >
                        <SidebarListElement
                          label={tu._nom}
                          image={
                            <g>
                              <path d="M5 5h15v3H5zm12 5h3v9h-3zm-7 0h5v9h-5zm-5 0h3v9H5z" opacity=".3"></path>
                              <path d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM8 19H5v-9h3v9zm7 0h-5v-9h5v9zm5 0h-3v-9h3v9zm0-11H5V5h15v3z"></path>
                            </g>
                          }
                          isActivated={(selectedTeachingUnit != undefined && selectedTeachingUnit._nom === tu._nom) ? matchPath(location, {
                            path: "/unites-enseignement",
                          }) : null}
                        />
                      </Link>
                    }
                  </>
                )}
              </div>
            }
          </>
          <>
            <div className="sidebar-list">
              {isFullyOpenedSidebar && <div className="sidebar-list-title">UTILISATEURS</div>}
              <>
                <Link to={`/etudiants`} className="sidebar-list-link">
                  <SidebarListElement
                      label={"Étudiants"}
                      image={
                        <SVGUsers/>
                      }
                      isActivated={ matchPath(location, {
                        path: "/etudiants",
                      })}
                  />
                </Link>
              </>
            </div>
          </>

          {rolesUser.includes("admin") &&
          <div className="list-delimiter">
          </div>
          }
          <>
            { rolesUser.includes("admin") &&
              <div className="sidebar-list">
                <>
                  <Link to={`/configs`} className="sidebar-list-link">
                    <SidebarListElement
                        label={"Configuration"}
                        image={
                          <IconSettings />
                        }
                        isActivated={ matchPath(location, {
                          path: "/configs",
                        })}
                    />
                  </Link>
                </>
              </div>
             }
          </>
          <div className="sidebar-list sidebar-list-collapser" onClick={() => isFullyOpenedSidebar ? setIsFullyOpenedSidebar(false) : setIsFullyOpenedSidebar(true)}>
            <div className="sidebar-list-element">
              <div className="sidebar-collapser-element-link">
                {isFullyOpenedSidebar ?
                  <>
                    <span>
                      <img width="26px" height="26px" src={leftArrows} />
                    </span>
                    <div className="sidebar-list-element-link-label">
                      Réduire
                    </div>
                  </>
                  :
                  <span>
                    <img width="26px" height="26px" src={rightArrows} />
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
