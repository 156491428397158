import * as React from 'react'
import HelpPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import AdminMenu from "../../../PageElement/AdminMenu/AdminMenu";
import "./StudentsGraph.css";
import Sidebar from "../../../PageElement/Sidebar/Sidebar";
import {useParams} from "react-router-dom";
import BottomControls from "../../../../VirtualTutor/BottomGraphControls/BottomControls";
import LayerSelector from "../../../../VirtualTutor/LayerSelector";
import ResourcesForm from "../../../../VirtualTutor/ResourcesForm";
import QuestionForm from "../../../../VirtualTutor/QuestionForm";
import Menus from "../../../../VirtualTutor/Menus";
import PollingHandler from "../../../../VirtualTutor/PollingHandler/PollingHandler";
import ApiLoader from "../../../../VirtualTutor/ApiLoader";
import StudentSelector from "./StudentSelector";
import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {
    SettingPopUp,
    studentAtom,
} from "../../../../../recoil/atoms";
import {ApiService} from "../../../../LoginHandler/restServices";
import * as d3 from "d3";
import {Spinner} from "reactstrap";
import SidePanelAdmin from "./SidePanelAdmin";
import PopUp from "../../../PageElement/PopUp/PopUp";
import {selectedTeachingUnitAtom} from "../../../../../recoil/graphFilters";
import {
    ConfigContextMenu,
    EyeContextMenu,
} from "../../../PageElement/AdminMenu/ContextMenu";
import {IStudent} from "../../../../../interface/graph";
import FormRecommandation from "../../../PageElement/Form/FormRecommandation/FormRecommandation";

const StudentsGraph = ( props ) => {

  let {studentId} = useParams<{ studentId: string }>();
  const [student,setStudent] = useRecoilState(studentAtom);
  const [isLoad, setIsLoad] = useState(false);
  const [settingPopUp,setSettingPopUp] = useRecoilState(
      SettingPopUp
  );
  const [selectedTeachingUnit] = useRecoilState(
    selectedTeachingUnitAtom
  );

  const parseStudentDate: (dateParam: any) => Date = (dateParam: string) => {
      return (
           d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ")(
              Array.isArray(dateParam) ? dateParam[0] : dateParam
          ) || new Date()
      );
  };

  useEffect( () => {
      ApiService.getStudent(studentId).then(currentStudent => {
          setStudent({
              id: currentStudent._idPassport,
              uri: currentStudent._URI,
              lastConnection: parseStudentDate(currentStudent._derniereConnexion),
              firstName: currentStudent._prenom,
              lastName: currentStudent._nom,
              URI: currentStudent._URI,
              email: currentStudent._email,
              isSync: currentStudent._moodleIsSync!
          });
          setIsLoad(true);
      });
  }, []);


  return (
          <div className="admin">
              {settingPopUp.isOpen &&
              <PopUp
                  config={settingPopUp}
              />
              }
              <Sidebar/>
              <div className="admin-body">
                  <div className="admin-content">
                      <AdminMenu  ContextMenu={
                          <>
                              { selectedTeachingUnit && <EyeContextMenu link={"/etudiants"}/>}
                              <ConfigContextMenu
                                  configPanelContent={
                                      {
                                          groupPanel : [
                                              {
                                                  inputs: [
                                                      {
                                                          type:"link",
                                                          text:"Réinitialiser le graphe de l'étudiant",
                                                          callback : () => {
                                                              setSettingPopUp({
                                                                  isOpen : true,
                                                                  message : "Confirmer la réinitialisation du graphe de l'étudiant",
                                                                  arrBtn: [
                                                                      {
                                                                          type:"secondary",
                                                                          text:"Confirmer",
                                                                          action: () => {
                                                                              let newStudent = {...student};
                                                                              newStudent.isSync = false;
                                                                              setStudent(newStudent as IStudent);
                                                                              ApiService.resetGraph(selectedTeachingUnit!._URI,studentId);
                                                                          }
                                                                      },
                                                                      {
                                                                          type:"primary",
                                                                          text:"Annuler",
                                                                          action: () => {}
                                                                      }
                                                                  ],
                                                              });
                                                          }
                                                      }
                                                  ],
                                              }
                                          ]
                                      }
                                  }
                              />
                          </>
                      }/>
                      <div className="main_container">
                          <div className="graph_container">
                              {isLoad ?
                                  <ApiLoader
                                      imposedStudentId={studentId}
                                  />
                              :
                                  <div className="teaching-units-index-loader loader">
                                      <Spinner> </Spinner>
                                  </div>
                              }
                          </div>
                          <BottomControls/>
                          <LayerSelector/>
                          <SidePanelAdmin/>
                          <ResourcesForm/>
                          <QuestionForm/>
                          <Menus/>
                          {isLoad &&
                              <>
                                  <StudentSelector/>
                                  <PollingHandler/>
                              </>
                          }

                          <FormRecommandation/>

                      </div>
                      <HelpPanel/>
                      <AccountPanel/>
                      <NotificationPanel/>
                  </div>
              </div>
          </div>

  );
};


export default StudentsGraph;
