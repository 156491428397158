import { useState, useEffect } from "react";
import "./ObjectiveEditPanel.css";
import { toggleActivityEditModalAtom, toggleObjectiveEditModalAtom } from "../../../../../recoil/atoms";
import ObjectiveCreationForm from "./ObjectiveCreationForm";
import { useRecoilState } from "recoil";
import ObjectiveEditionForm from "./ObjectiveEditionForm";
import { selectedObjectiveAtom } from "../../../../../recoil/graphFilters";
import ContributoryObjectives from "./ContributoryObjectives";
import ContributoryActivities from "./ContributoryActivities";
import ActivityEditPanel from "./ActivityEditPanel/ActivityEditPanel";
import { ConditionObtentionControleConnaissance } from "../../../../../interface/conditionObtentionControleConnaissance";
import { ObjectifPedagogique } from "../../../../../interface/objectifPedagogique";
import { ConditionObtentionMedia } from "../../../../../interface/conditionObtentionMedia";


<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

export function ObjectiveEditPanel(props) {

  const [objectiveAtom,] = useRecoilState(
    selectedObjectiveAtom
  );
  const [objectiveEditModalOpen, setObjectiveEditModalOpen] =
    useRecoilState(toggleObjectiveEditModalAtom);
  const [activityEditModalOpen,] =
    useRecoilState(toggleActivityEditModalAtom);

  const [selectedObjective, setSelectedObjective] = useState({} as ObjectifPedagogique);

  // Transition condition obtention activite pédagogique
  const [conditionObtentionActivities, setConditionObtentionActivities] = useState<(ConditionObtentionControleConnaissance | ConditionObtentionMedia)[]>();
  const [isApiConditionObtentionActivitiesFetched, setIsApiConditionObtentionActivitiesFetched] = useState<boolean>(false);

  // Récupération de l'objectif
  useEffect(() => {
    if (objectiveEditModalOpen) {
      document.getElementById('objective-edit-panel-modal-id')?.scrollTo({ top: 0, behavior: 'smooth' });
      setSelectedObjective(props.selectedObjective);
    }
  }, [props.selectedObjective, objectiveEditModalOpen]);

  return (
    <div
      id={"objective-edit-panel-modal-id"}
      className={`objective-edit-panel-modal ${objectiveEditModalOpen && !activityEditModalOpen && "objective-edit-panel-open"} ${activityEditModalOpen && "objective-activity-edit-panel-open"}`}
    >
      <div className="objective-edit-panel-header">
        <div className="objective-edit-panel-title">
          {selectedObjective != undefined ?
            selectedObjective?._titre
            :
            "Créer un objectif pédagogique"
          }
        </div>
        {!activityEditModalOpen &&
          <span className="objective-edit-panel-close" onClick={() => { setObjectiveEditModalOpen(false); }}>
            <svg width={20} height={20} viewBox="0 0 50 50">
              <path
                d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
                stroke="#FFFFFF"
                fill="#FFFFFF"
                strokeWidth={7}
              />
            </svg>
          </span>
        }
      </div>
      <div className={`objective-edit-panel-body ${objectiveAtom == undefined && "objective-edit-panel-body-fixed"}`}>
        {props.selectedObjective != undefined ?
          <ObjectiveEditionForm
            selectedObjective={selectedObjective}
            setSelectedObjective={setSelectedObjective}
          />
          :
          <ObjectiveCreationForm />
        }
        {props.selectedObjective != undefined &&
          <div className="objective-edit-panel-index">
            <ContributoryActivities
              selectedObjective={props.selectedObjective}
              conditionObtentionActivities={conditionObtentionActivities}
              setConditionObtentionActivities={setConditionObtentionActivities}
              isApiConditionObtentionActivitiesFetched={isApiConditionObtentionActivitiesFetched}
            />
            <hr />
            <ContributoryObjectives />
          </div>
        }
        <ActivityEditPanel
          selectedObjective={objectiveAtom}
          conditionObtentionActivities={conditionObtentionActivities}
          setConditionObtentionActivities={setConditionObtentionActivities}
          setIsApiConditionObtentionActivitiesFetched={setIsApiConditionObtentionActivitiesFetched}
        />
      </div>
    </div>
  );
}

export default ObjectiveEditPanel;
