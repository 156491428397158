import React, { useState, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { selectedTeachingUnitAtom } from "../../../../recoil/graphFilters";
import { TeachingUnit } from "../../../../interface/teachingUnits";
import { ApiService } from "../../../LoginHandler/restServices";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import IconEdit from "../../../icons/SVGIconEdit";
import IconEye from "../../../icons/SVGIconEye";
import IconOffButton from "../../../icons/SVGIconOffButton";
import IconOnButton from "../../../icons/SVGIconOnButton";
import { configsAtom } from "../../../../recoil/atoms";


function TeachingUnitsGraphs() {

  const [, setSelectedTeachingUnit] = useRecoilState(
    selectedTeachingUnitAtom
  );

  const [possibleGraphsTeachingUnits, setPossibleGraphsTeachingUnits] = useState<TeachingUnit[]>([]);
  const [existingGraphsTeachingUnits, setExistingGraphsTeachingUnits] = useState<TeachingUnit[]>([]);
  const [allTeachingUnits, setAllTeachingUnits] = useState<TeachingUnit[]>([]);

  const [allTeachingUnitsLoad, setAllTeachingUnitsLoad] = useState<boolean>(false);
  const [isApiFetched, setIsApiFetched] = useState<boolean>(false);
  const [configs] = useRecoilState(configsAtom);

  useEffect(() => {

    if (allTeachingUnits.length == 0) {

      setAllTeachingUnitsLoad(false);

      if (!isApiFetched) {

        (async function loadTeachingUnit() {

          // Récupération des cours Moodle
          let courseMoodle = await ApiService.getAllMoodleTeachingUnits("category", Number(configs.find(c => c.nom == "categorieIdCoursMoodleEligible")!.valeur));

          // Récupération des graph existants, actifs ou non
          let ue = await ApiService.getTeachingUnits();

          // Changement de type pour reconnaître le statut des graphs
          ue.forEach(teachingUnit =>
            teachingUnit._estEditable == "true" ?
              teachingUnit._estEditable = true : teachingUnit._estEditable = false
          );

          // Tri des unités d'enseignements pour places les actifs en haut
          ue.sort((a: TeachingUnit, b: TeachingUnit) => {
            if (a._estEditable === true && b._estEditable === false) {
              return -1;
            }
            if (a._estEditable === false && b._estEditable === true) {
              return 1;
            }
            return 0;
          });

          // Ajout des noms complets aux unités d'enseignement
          ue.forEach(existingGraphTeachingUnit =>
            existingGraphTeachingUnit._nomComplet = courseMoodle.find(courseMoodle => courseMoodle._idMoodle == existingGraphTeachingUnit._idMoodle)?._nomComplet
          );

          // Suppression des cours Moodle ayant le même id qu'un graph existant
          courseMoodle = courseMoodle.filter(courseMoodle =>
            !ue.some(teachingUnit => {
              return teachingUnit._idMoodle == courseMoodle._idMoodle;
            })
          );

          setPossibleGraphsTeachingUnits(courseMoodle);
          setExistingGraphsTeachingUnits(ue);

          const tu = ue.concat(courseMoodle)
          setAllTeachingUnits(tu);

        })().then(() => {
          setAllTeachingUnitsLoad(true);
        })
      }
    } else {
      setAllTeachingUnitsLoad(true);
    }
  }, []);

  function createTeachingUnit(moodleCourse: TeachingUnit) {
    setIsApiFetched(true);
    ApiService.createTeachingUnit(moodleCourse).then(newTeachingUnit => {

      moodleCourse._estEditable = true
      setAllTeachingUnitsLoad(false);

      // Ajout à la liste des unités d'enseignement existants
      var modifiedExistingGraphsTeachingUnits: Array<TeachingUnit> = existingGraphsTeachingUnits.filter(existingTeachingUnit =>
        existingTeachingUnit._URI != moodleCourse._URI
      );
      modifiedExistingGraphsTeachingUnits.push(moodleCourse);
      setExistingGraphsTeachingUnits(modifiedExistingGraphsTeachingUnits);

      // Suppression de la liste des cours Moodle
      var modifiedPossibleGraphsTeachingUnits: Array<TeachingUnit> = possibleGraphsTeachingUnits.filter(possibleTeachingUnit =>
        possibleTeachingUnit._URI != moodleCourse._URI
      );
      setPossibleGraphsTeachingUnits(modifiedPossibleGraphsTeachingUnits);

      const tu = modifiedExistingGraphsTeachingUnits.concat(modifiedPossibleGraphsTeachingUnits)
      setAllTeachingUnits(tu);
      setIsApiFetched(false);
      setAllTeachingUnitsLoad(true);
    });
  }

  function deactivateTeachingUnit(teachingUnit: TeachingUnit) {
    setIsApiFetched(true);
    ApiService.deactivateTeachingUnit(teachingUnit).then(oldTeachingUnit => {

      teachingUnit._estEditable = false;
      setAllTeachingUnitsLoad(false);

      // Suppression de la liste des unités d'enseignement existants
      var modifiedExistingGraphsTeachingUnits: Array<TeachingUnit> = existingGraphsTeachingUnits.filter(existingTeachingUnit =>
        existingTeachingUnit._URI != teachingUnit._URI
      );
      setExistingGraphsTeachingUnits(modifiedExistingGraphsTeachingUnits);

      // Ajout à la liste des cours Moodle
      var modifiedPossibleGraphsTeachingUnits: Array<TeachingUnit> = possibleGraphsTeachingUnits;
      modifiedPossibleGraphsTeachingUnits.push(teachingUnit);
      setPossibleGraphsTeachingUnits(modifiedPossibleGraphsTeachingUnits);

      const tu = modifiedExistingGraphsTeachingUnits.concat(modifiedPossibleGraphsTeachingUnits)
      setAllTeachingUnits(tu);

      setAllTeachingUnitsLoad(true);
      setIsApiFetched(false);
    });
  }


  const allTeachingUnitsColumns: TableColumn<TeachingUnit>[] = [
    {
      id: 1,
      name: 'Nom abrégé',
      selector: e => e._nom != undefined && e._nom,
      sortable: true,
      grow: 1,
    },
    {
      id: 2,
      name: 'Nom complet',
      selector: e => e._nomComplet != undefined && e._nomComplet,
      sortable: true,
      grow: 2,
    },
    {
      id: 3,
      name: 'Actions',
      button: true,
      width: "140px",
      cell: (e) => (e._estEditable == true &&
        <>
          <Link to={`/unites-enseignement/${e._nom != undefined && e._nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/graphe`}
            className="teaching-units-index-link"
            onClick={() => { setSelectedTeachingUnit(e); }}
            target={"_blank"}
          >
            <IconEye fill={"#6371B0"} />
          </Link>
          <Link to={`/unites-enseignement/${e._nom != undefined && e._nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`}
            className="teaching-units-index-link"
            onClick={() => { setSelectedTeachingUnit(e); }}
          >
            <IconEdit />
          </Link>
        </>
      )
    },
    {
      id: 4,
      name: 'Activer ou désactiver',
      button: true,
      width: "120px",
      cell: (e) => (e._estEditable == true ?
        <div className="teaching-units-index-link" onClick={() => { !isApiFetched && deactivateTeachingUnit(e) }}>
          <IconOffButton />
        </div>
        :
        <div className="teaching-units-index-link" onClick={() => { !isApiFetched && createTeachingUnit(e) }}>
          <IconOnButton />
        </div>
      )
    },
    {
      id: 5,
      name: 'Graphe existant',
      button: true,
      width: "120px",
      cell: (e) => (existingGraphsTeachingUnits.includes(e) ?
        <div className="teaching-units-index-existing-logo">✓</div>
        :
        <div className="teaching-units-index-unexisting-logo">✗</div>
      )
    },
    {
      width: "0px",
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page :',
    rangeSeparatorText: 'de',
  };

  return (
    <div className="teaching-units-index-table">
      {allTeachingUnitsLoad ?
        <DataTableExtensions
          columns={allTeachingUnitsColumns}
          data={allTeachingUnits}
          print={false}
          export={false}
          responsive
          filterPlaceholder={'Recherche...'}
        >
          <DataTable
            title={'Liste des unités d\'enseignement et cours éligibles'}
            noDataComponent={"Pas de données à afficher"}
            columns={allTeachingUnitsColumns}
            data={allTeachingUnits}
            pagination
            paginationPerPage={15}
            paginationComponentOptions={paginationComponentOptions}
            responsive
            highlightOnHover
            striped
          />
        </DataTableExtensions> :
        <div className="teaching-units-index-loader loader">
          <Spinner> </Spinner>
        </div>
      }
    </div>
  );
};

export default TeachingUnitsGraphs;
