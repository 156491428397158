import { atom, selector } from "recoil";
import {
  ActivityFilters,
  ActivityProgressFilters,
  AGGREGATOR,
  ALL,
  COURSE,
  DOCUMENT,
  ELEARNING,
  EXERCICE,
  GAME,
  IGraphData,
  IN_PROCESS,
  LINK,
  NOT_ACQUIRED,
  OTHER,
  PODCAST,
  PRESENTATION,
  QUESTIONNAIRE,
  RESEARCH,
  SPREADSHEET,
  TODO,
  TOPIC,
  VALIDATED,
  VIDEO,
} from "../interface/graph";
import { nPOSPreFilterSelector } from "./atoms";
import { getId } from "../helpers/domainDataHelpers";
import { TeachingUnit } from "../interface/teachingUnits";
import { ObjectifPedagogique } from "../interface/objectifPedagogique";
import { ActivitePedagogique } from "../interface/activitePedagogique";

export interface IActivityTypeOption {
  type: ActivityFilters;
  name: string;
}

interface IDuration {
  min: number;
  max: number;
}

export interface IActivityDurationOption {
  name: string;
  duration: IDuration;
}
export interface IActivityProgressOption {
  status: ActivityProgressFilters;
  name: string;
}

export const activityTypesOptions: IActivityTypeOption[] = [
  { type: ALL, name: "Tous les types" },
  { type: DOCUMENT, name: "Document" },
  { type: VIDEO, name: "Vidéo" },
  { type: LINK, name: "Lien web" },
  { type: QUESTIONNAIRE, name: "Quiz" },
  { type: GAME, name: "Jeu" },
  { type: PODCAST, name: "Podcast" },
  { type: EXERCICE, name: "Exercice" },
  { type: RESEARCH, name: "Recherche type" },
  { type: ELEARNING, name: "Module e-learning" },
  { type: PRESENTATION, name: "Présentation" },
  { type: SPREADSHEET, name: "Feuille de calcul" },
  { type: COURSE, name: "Cours" },
  { type: OTHER, name: "Autres" },
];
export const activityDurationOptionsDefault: IActivityDurationOption[] = [
  { name: "Toutes les durées", duration: { min: -Infinity, max: Infinity } },
  { name: "Moins de 5 min.", duration: { min: 0, max: 300 } },
  { name: "Entre 5 et 10 min.", duration: { min: 300, max: 600 } },
  { name: "Entre 10 et 15 min.", duration: { min: 600, max: 900 } },
  { name: "Entre 15 et 30 min.", duration: { min: 900, max: 1800 } },
  { name: "Entre 30 min. et 1 h.", duration: { min: 1800, max: 3600 } },
  { name: "Plus d'une heure", duration: { min: 3600, max: Infinity } },
];
export const activityProgressOptions: IActivityProgressOption[] = [
  { status: ALL, name: "Ma progression" },
  { status: TODO, name: "Non commencé" },
  { status: IN_PROCESS, name: "En cours" },
  { status: VALIDATED, name: "Validé" },
  { status: NOT_ACQUIRED, name: "Non acquis" },
];


export const teachingUnitsAtom = atom<TeachingUnit[]>({
  key: "teachingUnitsAtom",
  default: [],
});
export const teacherTeachingUnitsAtom = atom<TeachingUnit[]>({
  key: "teacherTeachingUnitsAtom",
  default: [],
});

export const selectedTeachingUnitAtom = atom<TeachingUnit | undefined
>({
  key: "selectedTeachingUnitAtom",
  default: undefined,
});

export const selectedObjectiveAtom = atom<ObjectifPedagogique | undefined
>({
  key: "selectedObjectiveAtom",
  default: undefined,
});

export const selectedTeachingUnitObjectivesAtom = atom<ObjectifPedagogique[]>({
  key: "selectedTeachingUnitObjectivesAtom",
  default: [],
});

export const selectedActivityAtom = atom<ActivitePedagogique | undefined
>({
  key: "selectedActivityAtom",
  default: undefined,
});

export const currentObjectiveActivitiesAtom = atom<ActivitePedagogique[]>
({
  key: "currentObjectiveActivitiesAtom",
  default: [],
})

export const activityDurationFilterAtom = atom<IActivityDurationOption>({
  key: "activityDurationFilterAtom",
  default: activityDurationOptionsDefault[0],
});
export const activityDurationSelector = selector<IActivityDurationOption[]>({
  key: "activityDurationSelector",
  get: ({ get }) => {
    return activityDurationOptionsDefault.filter((a) =>
      filterActivityProgress({
        graph: filterActivityType({
          graph: get(nPOSPreFilterSelector),
          filter: get(activityTypeFilterAtom).type,
        }),
        filter: get(activityProgressFilterAtom).status,
      }).nodes.some(
        (n) =>
          a.name === activityDurationOptionsDefault[0].name ||
          (n.duration >= a.duration.min && n.duration <= a.duration.max)
      )
    );
  },
});

export const activityProgressFilterAtom = atom<IActivityProgressOption>({
  key: "activityProgressFilterAtom",
  default: activityProgressOptions[0],
});
export const activityProgressSelector = selector<IActivityProgressOption[]>({
  key: "activityProgressSelector",
  get: ({ get }) => {
    return activityProgressOptions.filter(
      (a) =>
        a.status === ALL ||
        filterActivityDuration({
          graph: filterActivityType({
            graph: get(nPOSPreFilterSelector),
            filter: get(activityTypeFilterAtom).type,
          }),
          filter: get(activityDurationFilterAtom),
        }).nodes.some((n) => {
              return n.type !== TOPIC
                  && (
                      n.status === a.status
                      ||
                      (a.status == IN_PROCESS && n.status == NOT_ACQUIRED)
                  )
            }
        )
    )
  },
});

export const activityTypeFilterAtom = atom<IActivityTypeOption>({
  key: "activityTypeFilterAtom",
  default: activityTypesOptions[0],
});

export const activityTypesSelector = selector<IActivityTypeOption[]>({
  key: "activityTypesSelector",
  get: ({ get }) => {
    return activityTypesOptions.filter(
      (a) =>
        a.type === ALL ||
        filterActivityDuration({
          graph: filterActivityProgress({
            graph: get(nPOSPreFilterSelector),
            filter: get(activityProgressFilterAtom).status,
          }),
          filter: get(activityDurationFilterAtom),
        }).nodes.find((n) => n.type === a.type)
    );
  },
});

export const addAggregator: (x: IGraphData, prev: IGraphData) => IGraphData = (
  { nodes, links }: IGraphData,
  { nodes: pNodes, links: plinks }: IGraphData
) => {
  const nodeMap: any = nodes.reduce((a, b) => ({ ...a, [b.id]: b }), {});
  const deletedNodes = pNodes.filter((p) => !nodeMap[p.id]);
  const deletedNodesMap: any = deletedNodes.reduce(
    (a, b) => ({ ...a, [b.id]: b }),
    {}
  );
  const deletedNodesLinks = plinks.filter(
    // @ts-ignore
    (d) => deletedNodesMap[d.target] && deletedNodesMap[d.source]
  );
  const deletedRimLinks = plinks.filter(
    // @ts-ignore
    (d: any) =>
      (deletedNodesMap[d.target] || deletedNodesMap[d.source]) &&
      !deletedNodesLinks.find(
        (t) => t.target === d.target && t.source === d.source
      )
  );
  let cluster: any = [];
  deletedNodes.forEach((d) => {
    if (!isLeaf(d.id, plinks)) {
      if (cluster.length === 0) {
        cluster = [{ id: `${AGGREGATOR}/0`, nodes: [d] }];
      } else {
        let visit: any = deletedNodes.reduce(
          (a, b) => ({ ...a, [b.id]: false }),
          {}
        );
        let stop = false;
        let neighbor: string[] = [];
        let current = d.id;
        while (!stop) {
          const cc = current;
          visit[current] = true;
          deletedNodesLinks
            .filter((p: any) => p.source === cc || p.target === cc)
            .forEach((l: any) => {
              neighbor.push(l.source === cc ? l.target : l.source);
            });
          if (neighbor.every((n) => visit[n])) {
            const find = cluster.find((x: any) =>
              x.nodes.find((n: any) => neighbor.some((k) => k === n.id))
            );
            if (find) {
              cluster = cluster.map((c: any) =>
                c.nodes.find((j: any) => neighbor.some((k) => k === j.id))
                  ? { ...c, nodes: [...c.nodes, d] }
                  : c
              );
            } else {
              cluster.push({
                id: `${AGGREGATOR}/${cluster.length}`,
                nodes: [d],
              });
            }

            stop = true;
          } else {
            // @ts-ignore
            current = neighbor.find((n) => !visit[n]);
          }
        }
      }
    }
  });
  cluster = cluster.map((c: any) => ({
    ...c,
    id: c.nodes.length === 1 ? `${AGGREGATOR}/${getId(c.nodes[0])}` : c.id,
  }));
  const rimToCluster = deletedRimLinks
    .filter((d: any) => {
      return (
        (deletedNodesMap[d.target] && !isLeaf(d.target, plinks)) ||
        (deletedNodesMap[d.source] && !isLeaf(d.source, plinks))
      );
    })
    .map((d) => {
      const t = cluster.find((c: any) =>
        c.nodes.find((n: any) => n.id === d.target)
      );
      const s = cluster.find((c: any) =>
        c.nodes.find((n: any) => n.id === d.source)
      );
      return t ?
          { ...d,
              target: t.id,
              type: t.type != "TOPIC" ? "linkActivityToAggregator" : "linkActivityToAggregator"
          }
          :
          { ...d,
              source: s.id,
              type: s.type != "TOPIC" ? "linkActivityToAggregator" : "linkActivityToAggregator"
          };
    })
    .reduce(
      (a: any[], b) =>
        a.find((g: any) => g.target === b.target && g.source === b.source)
          ? a
          : [...a, b],
      []
    );
  const clusterNodes = cluster.map((c: any) => ({
    id: c.id,
    type: AGGREGATOR,
    cluster: c.nodes.map((n: any) => getId(n)),
  }));
  return {
    nodes: [...nodes, ...clusterNodes],
    links: [...links, ...rimToCluster],
  };
};

const isLeaf = (nodeID: string, links: any[]) => {
  return (
    links.filter((l: any) => l.target === nodeID || l.source === nodeID)
      .length === 1
  );
};

export const filterActivityType: (x: {
  graph: IGraphData;
  filter: ActivityFilters;
}) => IGraphData = ({
  graph: { nodes, links },
  filter,
}: {
  graph: IGraphData;
  filter: ActivityFilters;
}) => {
  if (filter === ALL) {
    return { nodes, links };
  }

  const filteredNodes = nodes.filter((n) => {
    return (
      n.type === filter ||
      (n.type === TOPIC &&
        links
          .filter((l: any) => l.target.id === n.id)
          .some((l: any) => l.source.type === filter))
    );
  });

  return {
    nodes: filteredNodes,
    links: links
      .filter(
        (l: any) =>
          filteredNodes.find((f) => f.id === l.target.id) &&
          filteredNodes.find((f) => f.id === l.source.id)
      )
      .map((p) => ({ ...p })),
  };
};

export const preFilter: (x: { graph: IGraphData }) => IGraphData = ({
  graph: { nodes, links },
}: {
  graph: IGraphData;
}) => {

  const nodeMap: any = nodes.reduce((a, b) => ({ ...a, [b.id]: b }), {});

  const fNodes = nodes.filter(
    (n) =>

      //Affichage des objectifs sans conditions d'objectifs
      (n.type === TOPIC &&
        n.requirementCompletion!.filter(x => (x.type == "OBJECTIF")).length == 0
      ) ||

      //Affichage des objectifs avec conditions d'objectifs  mais validé
      (n.type === TOPIC &&
            n.requirementCompletion!.filter(x =>
                (x.type == "OBJECTIF" && !x.isComplete)).length == 0
      )
        ||
      (n.type === TOPIC &&
        !links.some(
          (l: any) =>
            l.target === n.id &&
            nodeMap[l.source].type === TOPIC &&
            nodeMap[l.source].status !== VALIDATED
        ))
  );

  let filteredNodeMap: any = fNodes.reduce((a, b) => ({ ...a, [b.id]: b }), {});
  const jNode = [
    ...fNodes,
    ...nodes.filter(
      (n) =>
        n.type !== TOPIC &&
        links.some((l: any) => filteredNodeMap[l.target] && l.source === n.id)
    ),
  ];
  filteredNodeMap = jNode.reduce((a, b) => ({ ...a, [b.id]: b }), {});
  return {
    nodes: jNode,
    links: links.filter(
      (l: any) => filteredNodeMap[l.source] && filteredNodeMap[l.target]
    ),
  };
};

export const filterActivityProgress: (x: {
  graph: IGraphData;
  filter: ActivityProgressFilters;
}) => IGraphData = ({
  graph: { nodes, links },
  filter,
}: {
  graph: IGraphData;
  filter: ActivityProgressFilters;
}) => {

  if (filter === ALL) {
    return { nodes, links };
  }

  const filteredNodes = nodes.filter((n) => {
    return (
      (n.type !== TOPIC && n.status === filter) ||
      (filter == IN_PROCESS && n.type !== TOPIC && n.status === NOT_ACQUIRED) ||
      (n.type === TOPIC &&
        links
          .filter((l: any) => l.target.id === n.id)
          .some(
            (l: any) =>
              l.source.type !== TOPIC &&
                (
                    l.source.status === filter
                    ||
                    (filter == IN_PROCESS && l.source.status && NOT_ACQUIRED)
                )
          ))
    );
  });
  const filteredNodeMap: any = filteredNodes.reduce(
    (a, b) => ({ ...a, [b.id]: b }),
    {}
  );

  const filteredLinks = links.filter(
    (l: any) => filteredNodeMap[l.source.id] && filteredNodeMap[l.target.id]
  );

  return { nodes: filteredNodes, links: filteredLinks };
};

export const filterActivityDuration: (x: {
  graph: IGraphData;
  filter: { name: string; duration: IDuration };
}) => IGraphData = ({
  graph: { nodes, links },
  filter,
}: {
  graph: IGraphData;
  filter: { name: string; duration: IDuration };
}) => {
  if (filter.name === activityDurationOptionsDefault[0].name) {
    return { nodes, links };
  }

  const { duration } = filter;
  const filteredNodes = nodes.filter(
    (n) =>
      (n.type === TOPIC &&
        links
          .filter((l: any) => l.target.id === n.id)
          .some(
            (l: any) =>
              l.source !== TOPIC &&
              l.source.duration >= duration.min &&
              l.source.duration <= duration.max
          )) ||
      (n.type !== TOPIC &&
        n.duration >= duration.min &&
        n.duration <= duration.max)
  );

  const filteredNodeMap: any = filteredNodes.reduce(
    (a, b) => ({ ...a, [b.id]: b }),
    {}
  );

  const filteredLinks = links.filter(
    (l: any) => filteredNodeMap[l.source.id] && filteredNodeMap[l.target.id]
  );

  return { nodes: filteredNodes, links: filteredLinks };
};
