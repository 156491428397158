import { useState, useEffect } from "react";
import "./ObjectiveEditPanel.css";
import "./ContributoryObjectives.css";
import { ObjectifPedagogique } from "../../../../../interface/objectifPedagogique";
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Spinner } from "reactstrap";
import { toggleObjectiveEditModalAtom, toggleTeachingUnitModeLiveAtom } from "../../../../../recoil/atoms";
import { useRecoilState } from "recoil";
import { selectedObjectiveAtom, selectedTeachingUnitAtom, selectedTeachingUnitObjectivesAtom } from "../../../../../recoil/graphFilters";
import { ApiService } from "../../../../LoginHandler/restServices";
import IconSurroundedUpArrow from "../../../../icons/SVGIconSurroundedUpArrow";
import IconEdit from "../../../../icons/SVGIconEdit";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ContributoryObjectivesImport(props: {
  currentObjectiveContributoryObjectives: ObjectifPedagogique[],
  setCurrentObjectiveContributoryObjectives: React.Dispatch<React.SetStateAction<ObjectifPedagogique[]>>,
  currentObjectiveContributoryObjectivesLoad: boolean,
  setCurrentObjectiveContributoryObjectivesLoad: React.Dispatch<React.SetStateAction<boolean>>
}) {

  const [selectedTeachingUnit,] = useRecoilState(
    selectedTeachingUnitAtom
  );
  const [objectiveEditModalOpen,] =
    useRecoilState(toggleObjectiveEditModalAtom);
  const [teachingUnitModeLive,] =
    useRecoilState(toggleTeachingUnitModeLiveAtom);
  const [objectiveAtom, setObjectiveAtom] = useRecoilState(
    selectedObjectiveAtom
  );
  const [teachingUnitObjectives, setTeachingUnitObjectives] = useRecoilState(
    selectedTeachingUnitObjectivesAtom
  );

  const [currentObjectiveContributoryObjectivesImportableLoad, setCurrentObjectiveContributoryObjectivesImportableLoad] = useState<boolean>(false);
  const [currentObjectiveContributoryObjectivesImportable, setCurrentObjectiveContributoryObjectivesImportable] = useState<ObjectifPedagogique[]>([]);
  const [isApiFetched, setIsApiFetched] = useState<boolean>(false);


  // Récupération des objectifs importables (en tant qu'objectifs contributifs) de l'unité d'enseignement
  useEffect(() => {

    if (selectedTeachingUnit != undefined && props.currentObjectiveContributoryObjectivesLoad && objectiveEditModalOpen) {

      setCurrentObjectiveContributoryObjectivesImportableLoad(false);

      if (objectiveAtom != undefined) {
        
        // Filtrage des objectifs de l'ue pour récupérer ceux pouvant être importés en tant que contributifs
        var objectiveContributoryObjectivesImportable: Array<ObjectifPedagogique> = teachingUnitObjectives.filter(ueObjective =>
          // Enlever l'objectif actuel
          objectiveAtom._URI !== ueObjective._URI
          // Enlever ceux dont l'objectif actuel est déjà contributif
          && (ueObjective._contributionObjectifPourObjectif != undefined ?
            (Array.isArray(ueObjective._contributionObjectifPourObjectif) ?
              ueObjective._contributionObjectifPourObjectif.find(contribOfUeObj =>
                contribOfUeObj._pourObjectif && contribOfUeObj._pourObjectif._URI === objectiveAtom._URI) == undefined :
              ueObjective._contributionObjectifPourObjectif._pourObjectif._URI !== objectiveAtom._URI) :
            true
          )
          // Enlever ceux qui contribuents à l'objectif actuel
          && !props.currentObjectiveContributoryObjectives.some(contribOfCurrentObj => {
            return contribOfCurrentObj._URI === ueObjective._URI;
          })
        );

        setCurrentObjectiveContributoryObjectivesImportable(objectiveContributoryObjectivesImportable);

      } else {

        setCurrentObjectiveContributoryObjectivesImportable(teachingUnitObjectives);

      }

      setCurrentObjectiveContributoryObjectivesImportableLoad(true);

    }

  }, [props.currentObjectiveContributoryObjectives]);


  function importObjectiveToBeContributive(e: ObjectifPedagogique) {

    // Ajout d'un objectif contributif
    setIsApiFetched(true);

    ApiService.addObjectiveContributionToObjectif(objectiveAtom!._URI, e._URI).then(mObjective => {

      // MAJ des objectifs de l'UE avec l'objectif et sa nouvelle contribution
      mObjective._contributionObjectifPourObjectif.push({ _URI: '', _pourObjectif: e });
      var modifTUObjectives = [...teachingUnitObjectives.filter(o => o._URI !== mObjective._URI)];
      modifTUObjectives.push(mObjective);
      setTeachingUnitObjectives(modifTUObjectives);

      // Modification de la liste des objectifs importables
      var modifiedImportableObjectives: Array<ObjectifPedagogique> = currentObjectiveContributoryObjectivesImportable.filter(objective =>
        !(objective._URI === e._URI)
      );
      setCurrentObjectiveContributoryObjectivesImportable(modifiedImportableObjectives);
      props.setCurrentObjectiveContributoryObjectivesLoad(false);

      // Modification de la liste des objectifs contributifs
      var modifiedContributoryObjectives: Array<ObjectifPedagogique> = props.currentObjectiveContributoryObjectives;
      modifiedContributoryObjectives.push(e);
      props.setCurrentObjectiveContributoryObjectives(modifiedContributoryObjectives);
      props.setCurrentObjectiveContributoryObjectivesLoad(true);
      setIsApiFetched(false);

    });

  }


  var currentGraphObjectivesColumns: TableColumn<ObjectifPedagogique>[] = [
    {
      id: 1,
      name: 'Titre court',
      selector: e => e._titreCourt != undefined && e._titreCourt,
      sortable: true,
      grow: 1,
    },
    {
      id: 2,
      name: 'Titre',
      selector: e => e._titre != undefined && e._titre,
      sortable: true,
      grow: 5,
    },
    // Possibilité de lier un objectif pédagogique contributif en mode draft
    {
      id: 3,
      name: 'Lier',
      button: true,
      width: "80px",
      cell: (e) => (
        <a className="teaching-units-index-link" onClick={() => !isApiFetched && importObjectiveToBeContributive(e)}>
          <IconSurroundedUpArrow />
        </a>
      ),
      omit: teachingUnitModeLive ? true : false,
    },
    {
      id: 4,
      name: 'Éditer',
      button: true,
      width: "80px",
      cell: (e) => (
        <a className="teaching-units-index-link" onClick={() => { setObjectiveAtom(e); }}>
          <IconEdit />
        </a>
      )
    },
    {
      width: "0px",
    }
  ];


  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page :',
    rangeSeparatorText: 'de',
  };


  return (
    <>
      <div className="objective-edit-panel-index-table objective-edit-pantel-contributory-objectives">
        {currentObjectiveContributoryObjectivesImportableLoad ?
          <DataTableExtensions
            columns={currentGraphObjectivesColumns}
            data={currentObjectiveContributoryObjectivesImportable}
            print={false}
            export={false}
            responsive
            filterPlaceholder={'Recherche...'}
          >
            <DataTable
              title={'Objectifs disponibles'}
              noDataComponent={"Pas d'objectif à afficher"}
              columns={currentGraphObjectivesColumns}
              data={currentObjectiveContributoryObjectivesImportable}
              defaultSortFieldId={1}
              pagination
              paginationPerPage={3}
              paginationRowsPerPageOptions={[3, 5, 10, 25, 50]}
              paginationComponentOptions={paginationComponentOptions}
              responsive
              highlightOnHover
              striped
            />
          </DataTableExtensions>
          :
          <div className="objective-edit-panel-index-loader loader">
            <Spinner> </Spinner>
          </div>
        }
      </div>
    </>
  );
}

export default ContributoryObjectivesImport;
