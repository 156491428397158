import * as React from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import { useRecoilState } from "recoil";
import { rolesAtom } from "../../recoil/atoms";
import Home from "./Page/Home/Home";
import TeachingUnitsIndex from "./Page/TeachingUnitsIndex/TeachingUnitsIndex";
import TeachingUnitEdit from "./Page/TeachingUnitEdit/TeachingUnitEdit";
import './Admin.css';
import TeachingUnitGraph from "./Page/TeachingUnitGraph/TeachingUnitGraph";
import StudentsIndex from "./Page/Student/StudentIndex/StudentsIndex";
import StudentsGraph from "./Page/Student/StudentGraph/StudentsGraph";
import StudentProfile from './Page/Student/StudentProfile/StudentProfile';
import Config from "./Page/Config/Config";

const RouterAdmin = () => {
  const [rolesUser] = useRecoilState(rolesAtom);

  return (
    <Switch>

      <Route exact path="/" component={Home} />

      {rolesUser.includes("admin") &&
        <Route exact path="/unites-enseignement" component={TeachingUnitsIndex} />
      }
      <Route exact path="/unites-enseignement/:teachingUnitTitle" component={TeachingUnitEdit} />
      <Route path="/unites-enseignement/:teachingUnitTitle/graphe" component={TeachingUnitGraph} />

      <Route exact path="/etudiants" component={StudentsIndex} />
      <Route path="/etudiants/:studentId/graphe" component={StudentsGraph} />
      <Route path="/etudiants/:studentId/profil" component={StudentProfile} />

      {rolesUser.includes("admin") &&
        <Route exact path="/configs" component={Config} />
      }
      <Redirect to="/" />

    </Switch>
  );
};
export default RouterAdmin;
