import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  graphAtom,
  openNodesAtom,
  pollingState, rolesAtom,
  selectedLayerAtom,
  selectedNodeIDAtom,
  studentAtom
} from "../../../recoil/atoms";

import {
  activityDurationFilterAtom,
  activityProgressFilterAtom,
  activityTypeFilterAtom,
  selectedTeachingUnitAtom,
  teachingUnitsAtom
} from "../../../recoil/graphFilters";
import { jsonToForceGraph } from "../../../extractor/newDataExtractor";
import styles from "./PollingHandler.module.css";
import classNames from "classnames";
import { ApiService } from "../../LoginHandler/restServices";
import { IFetchState } from "../../../interface/graph";
import * as d3 from "d3";

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

const PollingHandler = () => {
  const [pollingstate, setPollingState] = useRecoilState(pollingState);
  const [, setGraphData] = useRecoilState(graphAtom);
  const [rolesUser, ] = useRecoilState(rolesAtom);

  const [student,setStudent] = useRecoilState(studentAtom);
  const [activityType] = useRecoilState(activityTypeFilterAtom);
  const [activityProgress] = useRecoilState(
      activityProgressFilterAtom
  );
  const [activityDuration] = useRecoilState(
      activityDurationFilterAtom
  );
  const [selectedNodeID] = useRecoilState<string | undefined>(
      selectedNodeIDAtom
  );
  const [selectedLayers] = useRecoilState(selectedLayerAtom);
  const teachingUnit = useRecoilValue(selectedTeachingUnitAtom);
  const parseStudentDate: (dateParam: any) => Date = (dateParam: string) => {
    return (
        d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ")(
            Array.isArray(dateParam) ? dateParam[0] : dateParam
        ) || new Date()
    );
  };

  useInterval(async () => {
    if (teachingUnit && student) {
      setPollingState("loading");
      try {

        if(!student.isSync){
          var refreshStudent = await ApiService.getStudent(student.id);
          if(refreshStudent._moodleIsSync){
            setStudent({
              id: refreshStudent._idPassport,
              uri: refreshStudent._URI,
              lastConnection: parseStudentDate(refreshStudent._derniereConnexion),
              firstName: refreshStudent._prenom,
              lastName: refreshStudent._nom,
              URI: refreshStudent._URI,
              email: refreshStudent._email,
              isSync: refreshStudent._moodleIsSync
            });
          }
        }

        const [
          objectives,
          activities,
          studentObjectives,
          studentActivities,
          stats,
        ] = await Promise.all([
          ApiService.getAllObjectives(teachingUnit._URI),
          ApiService.getAllActivitiesFromUE(teachingUnit._URI),
          ApiService.getAllStudentObjectives(teachingUnit._URI, student.id),
          ApiService.getAllStudentActivities(teachingUnit._URI, student.id),
          ApiService.getObjectiveStatistics(teachingUnit._URI),
        ]);

        const extracted = jsonToForceGraph(
          objectives,
          activities,
          studentActivities.filter(
            (s: any) => !!s._activitePedagogiqueUtilisee
          ),
          studentObjectives.filter((s: any) => !!s._objectifPedagogique),
          student,
          stats,
          {
            activityDuration: activityDuration,
            activityProgress: activityProgress.status,
            activityTpe: activityType.type
          },
            selectedLayers,
            selectedNodeID
        );
        setGraphData(Object.create(extracted));

        setTimeout(() => setPollingState("done"), 500);
      } catch (e) {
        setPollingState("error");
      }
    }
  }, Number(process.env.REACT_APP_GRAPH_REFRESH) || 1000 * 60);
  return (
    <div className={styles.container}>
      {pollingstate === "loading" && (
        <img
          className={classNames(styles.flashingIcon)}
          src={"/img/polling.png"}
          alt={"download icon"}
          title={"rechargement des données"}
        />
      )}
      {pollingstate === "error" && (
        <img
          className={classNames(styles.flashingErrorIcon)}
          src={"/img/polling_error.png"}
          alt={"download icon"}
          title={"Une erreur est survenue lors du rechargement des données"}
        />
      )}
    </div>
  );
};
export default PollingHandler;
