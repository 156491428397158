import './RefreshGraph.css';
import {useRecoilState} from "recoil";
import {refreshGraphState} from "../../../recoil/atoms";

const RefreshGraph = () => {

    const [stateRefresh,refreshGraph] = useRecoilState(refreshGraphState);

    const refresh = () => {
        document.getElementsByClassName('refreshGraphButton')[0].classList.add("rotate-center");
        setTimeout( function(){
            document.getElementsByClassName('refreshGraphButton')[0].classList.remove("rotate-center");
        },600);

        refreshGraph(!stateRefresh);
    };

    return (
          <button className="refreshGraphButton " onClick={refresh}>
              <svg width="25" viewBox="0 0 512.449 512.449">
                  <g>
                      <g xmlns="http://www.w3.org/2000/svg">
                          <path d="m152.083 286.805c7.109-8.155 1.318-20.888-9.501-20.888h-32.392c-.211-3.205-.329-6.435-.329-9.692 0-80.706 65.658-146.364 146.363-146.364 38.784 0 74.087 15.168 100.304 39.877l45.676-53.435c-39.984-36.577-91.44-56.612-145.98-56.612-57.838 0-112.214 22.524-153.112 63.421-40.897 40.898-63.421 95.274-63.421 153.112 0 3.243.081 6.473.222 9.692h-27.284c-10.819 0-16.611 12.733-9.501 20.888l61.549 70.6 12.928 14.829 46.416-53.242z" fill="#000000" data-original="#000000" className=""></path>
                          <path d="m509.321 245.614-45.907-52.658-28.57-32.771-40.791 46.789-33.686 38.64c-7.109 8.155-1.318 20.888 9.501 20.888h32.354c-5.293 75.928-68.748 136.086-145.997 136.086-33.721 0-64.811-11.469-89.586-30.703l-45.679 53.439c38.267 30.731 85.479 47.434 135.266 47.434 57.838 0 112.214-22.523 153.112-63.421 38.466-38.466 60.672-88.856 63.177-142.834h27.306c10.818-.001 16.609-12.734 9.5-20.889z" fill="#000000" data-original="#000000" className=""></path>
                      </g>
                  </g>
              </svg>
          </button>
    );
};

export default RefreshGraph;