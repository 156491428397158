import {useRecoilState} from "recoil";
import "./TeachingUnitSettingsPanel.css";
import {rolesAtom, toggleTeachingUnitModeLiveAtom} from "../../../../recoil/atoms";
import IconOffButton from "../../../icons/SVGIconOffButton";
import IconOnButton from "../../../icons/SVGIconOnButton";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import IconEye from "../../../icons/SVGIconEye";
import IconSettings from "../../../icons/SVGIconSettings";


export const TeachingUnitDraftMenu = () => {
    const [teachingUnitModeLive, setTeachingUnitModeLive] =
        useRecoilState(toggleTeachingUnitModeLiveAtom);

    return (
        <div className="context-menu-element">
            <div className="admin-menu-button">
                {teachingUnitModeLive ?
                    <div onClick={() => setTeachingUnitModeLive(false)}>
                        <IconOffButton />
                    </div>
                    :
                    <div onClick={() => setTeachingUnitModeLive(true)}>
                        <IconOnButton />
                    </div>
                }
                <span className={`admin-menu-content-teaching-unit-mode ${teachingUnitModeLive ? "admin-menu-content-teaching-unit-mode-draft" : "admin-menu-content-teaching-unit-mode-live"}`}>
                    {teachingUnitModeLive ? "LIVE" : "DRAFT"}
                </span>
            </div>
        </div>
    );
};

const ConfigElement = (
    props : {
        input : {
            role?: "admin" | "enseignant" | "etudiant",
            type : "link" | "radio" | "number"
            text : string,
            description? : string,
            callback : Function,
            value?: string | number | boolean,
            options?: {name:string,value:string}[];
        }
    }
) => {

    const options = props.input.options != undefined ? props.input.options : [];

    switch (props.input.type) {
        case "link":
            return (
                <div className="teaching-unit-settings-section-content">
                    <div
                        {...options}
                        className="teaching-unit-settings-section-content-link"
                        onClick={(e) => {{props.input.callback(e)}}}>{props.input.text}
                    </div>
                    {props.input.description != undefined &&
                        <>
                            <div className="infoLink">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    fill="currentColor"
                                    className="bi bi-question-circle"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                </svg>
                            </div>
                            <i>{props.input.description}</i>
                        </>
                    }
                </div>
            );
        case "number":
            return (
                <>
                    <div className="teaching-unit-settings-section-content">{props.input.text}</div>
                    <input
                        {...options}
                        type="number"
                        value={props.input.value?.toString()}
                        className="teaching-unit-settings-section-content-select-number"
                        onChange={(e) => {{props.input.callback(e)}}}>
                    </input>
                    {props.input.description != undefined && <i>{props.input.description}</i>}
                </>
            );
        case "radio":
            var value = props.input.value;
            return (
                <div className="teaching-unit-settings-section-content">
                    <label htmlFor="moderation">{props.input.text}</label>
                    <input
                        {...options}
                        checked={value == false || false ? value : true}
                        type="checkbox"
                        name="moderation"
                        onChange={ (e) => {{props.input.callback(e)}} }
                    />
                </div>
            )
        default :
            return (<></>)
    }
};

export const ConfigContextMenu = ( props : {
    configPanelTitle? : string
    configPanelContent : {
        groupPanel : {
            title? : string,
            inputs : {
                role?: "admin" | "enseignant" | "etudiant",
                type : "link" | "radio" | "number"
                text : string,
                description? : string,
                callback : Function,
                value?: string | number | boolean
            }[]
        }[]
    }
}) => {

    const [rolesUser] = useRecoilState(rolesAtom);
    const [ConfigContextMenuIsOpen, setConfigContextMenuIsOpen] = useState(false);
    const title = props.configPanelTitle == undefined ? "Configuration" : props.configPanelTitle;

    return (
        <>
            <div className="context-menu-element">
                <div className="admin-menu-button" onClick={() => setConfigContextMenuIsOpen(true)}>
                    <IconSettings />
                </div>
            </div>
            <div>
                <div className={`teaching-unit-settings-modal ${ConfigContextMenuIsOpen ? "teaching-unit-settings-open" : ""}`}>
                    
                    <div className="teaching-unit-settings-header">
                        <div className="teaching-unit-settings-title">{title}</div>
                        <span className="teaching-unit-settings-close" onClick={() => setConfigContextMenuIsOpen(false)}>
                        <svg width={20} height={20} viewBox="0 0 50 50">
                          <path
                              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
                              stroke="#FFFFFF"
                              fill="#FFFFFF"
                              strokeWidth={7}
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="teaching-unit-settings-body">
                        {props.configPanelContent.groupPanel
                            .filter(g => g.inputs.filter(x => x.role == undefined || rolesUser.includes(x.role)).length > 0)
                            .map(g => (
                            <div className="teaching-unit-settings-section">
                                {g.inputs.filter(x => x.role == undefined || rolesUser.includes(x.role)).map(i => (
                                    <ConfigElement input={i}/>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export const EyeContextMenu = (props: {link : string, callBack?: Function}) => {

    const clickEvent = (e) => {
        if(props.callBack != undefined){
            props.callBack(e);
        }
    }

    return (
        <div className="context-menu-element">
            <Link 
                to={props.link == undefined ? "#" : props.link } onClick={(e) => {clickEvent(e)}}
                target={"_blank"}
            >
                <IconEye fill={"#212529"} />
            </Link>
        </div>
    );
}