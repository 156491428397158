import React, { useState } from "react";
import "./LayerSelector.css";
import img from "../../images/layer_icon.png";
import progress from "../../images/layer_progress.png";
import layer_new from "../../images/layer_new.png";
import group from "../../images/layer_group.png";
import piece from "../../images/layer_piece.png";
import global from "../../images/layer_global.png";
import extended from "../../images/layer_extended.png";
import pre from "../../images/layer_pre.png";
import { useRecoilState } from "recoil";
import {areNodeExpendedAtom, rolesAtom, selectedLayerAtom} from "../../recoil/atoms";
import {
  CONTRIBUTIONS,
  EXTENDED,
  GLOBAL,
  GROUP,
  LayerKey,
  NEWS,
  PREFILTER,
  PROGRESS,
} from "../../interface/graph";

const LayerIcon = () => (
  <svg width={15} height={15} viewBox="0 0 50 50">
    <path
      stroke="white"
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      d="M25,5 L45,20 L25,35 L5,20 L25,5 M5,30 L25,45 L45,30"
    />
  </svg>
);




const LayerSelector = () => {
  const [selectedLayers, setSelectedLayers] = useRecoilState(selectedLayerAtom);
  const [isListOpen, setListOpen] = useState(false);
  const [rolesUser] = useRecoilState(rolesAtom);
  const [, setOpen] = useRecoilState(areNodeExpendedAtom);

  const items: {
        key: LayerKey;
        src: string;
        name: string;
      }[] = rolesUser.includes('etudiant') ? [
        {
          key: PROGRESS,
          src: progress,
          name: "Ma progression",
        },
        {
          key: NEWS,
          src: layer_new,
          name: "Les nouveautés",
        },
        {
          key: GROUP,
          src: group,
          name: "Ma promo",
        },
        {
          key: CONTRIBUTIONS,
          src: piece,
          name: "Mes contributions",
        },

        {
          key: EXTENDED,
          src: extended,
          name: "Vue détaillée",
        },
        {
          key: GLOBAL,
          src: global,
          name: "Vue simplifiée",
        },
        {
          key: PREFILTER,
          src: pre,
          name: "Prérequis",
        },
      ] :

      [
        {
          key: EXTENDED,
          src: extended,
          name: "Vue détaillée",
        },
        {
          key: GLOBAL,
          src: global,
          name: "Vue simplifiée",
        },
        {
          key: PREFILTER,
          src: pre,
          name: "Prérequis",
        },
      ]
  ;

  return (
    <div className="layer_selector" onMouseLeave={() => setListOpen(false)}>
      <div className="hoverer" />
      <div className="layer_button" onMouseOver={() => setListOpen(true)}>
        <img alt="graph-icon" className="icon_image" src={img} />
        <div className="icon_text">
          <LayerIcon /> Calques
        </div>
      </div>
      {isListOpen && (
        <span className="layer_list" style={{ width: items.length * 100 }}>
          {items.map((item) => (
            <span
              key={item.key}
              onClick={() => {
                if (item.key === "EXTENDED") {
                  if (!selectedLayers.includes(item.key)) {
                    setOpen(true);
                    setSelectedLayers([
                      ...selectedLayers.filter((s) => s !== "GLOBAL"),
                      item.key,
                    ]);
                  }
                } else if (item.key === "GLOBAL") {
                  setOpen(false);
                  setSelectedLayers([
                    ...selectedLayers.filter((s) => s !== "EXTENDED"),
                    item.key,
                  ]);
                } else {
                  if (selectedLayers.includes(item.key)) {
                    setSelectedLayers(
                      selectedLayers.filter((s) => s !== item.key)
                    );
                  } else {
                    setSelectedLayers([...selectedLayers, item.key]);
                  }
                }
              }}
              className={`layer_item ${
                selectedLayers.includes(item.key) ? "selected" : ""
              }`}
            >
              <span className="item_image">
                <img alt={item.key} src={item.src} />
              </span>
              <div className="item_text">{item.name}</div>
            </span>
          ))}
        </span>
      )}
    </div>
  );
};
export default LayerSelector;
