import React from "react";

const SVGQuestionnaire = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 200 200" className="svg-node-icon">
      <g transform="translate(-35,-25) scale(1.3)">
        <path
          strokeWidth={10}
          d="M93.211,141.82c-0,-3.135 1.245,-6.141 3.462,-8.358c2.216,-2.217 5.223,-3.462 8.358,-3.462c0.245,0 0.491,0 0.736,0c3.135,-0 6.141,1.245 8.358,3.462c2.217,2.217 3.462,5.223 3.462,8.358c-0,3.135 -1.245,6.141 -3.462,8.358c-2.217,2.217 -5.223,3.462 -8.358,3.462c-0.245,0 -0.491,0 -0.736,0c-3.135,0 -6.142,-1.245 -8.358,-3.462c-2.217,-2.217 -3.462,-5.223 -3.462,-8.358Zm-12.925,-96.421c6.435,-4.145 14.342,-6.217 23.722,-6.217c12.325,0 22.563,2.945 30.716,8.834c8.153,5.89 12.229,14.615 12.229,26.176c-0,7.09 -1.772,13.061 -5.317,17.914c-2.072,2.945 -6.053,6.708 -11.943,11.289l-5.808,4.499c-3.162,2.454 -5.262,5.317 -6.298,8.589c-0.296,0.937 -0.531,2.109 -0.704,3.515c-0.555,3.514 -3.583,6.102 -7.14,6.102c-2.463,0.035 -5.259,0.035 -7.782,0.035c-2.027,0 -3.962,-0.847 -5.338,-2.337c-1.375,-1.49 -2.065,-3.487 -1.902,-5.508c0.307,-4.524 0.82,-7.771 2.334,-11.255c2.174,-5.002 5.072,-7.567 10.961,-12.147l5.972,-4.663c1.963,-1.472 3.544,-3.081 4.744,-4.826c2.181,-3 3.272,-6.299 3.272,-9.898c0,-4.144 -1.213,-7.921 -3.64,-11.329c-2.427,-3.409 -6.858,-5.113 -13.292,-5.113c-6.326,0 -10.812,2.1 -13.456,6.299c-1.549,2.459 -2.645,4.974 -3.286,7.545c-0.794,3.248 -3.704,5.534 -7.048,5.534c-2.839,0.009 -6.216,0.009 -9.228,0.009c-2.169,-0 -4.224,-0.969 -5.604,-2.642c-1.38,-1.672 -1.94,-3.874 -1.528,-6.003c2.058,-11.053 7.171,-19.188 15.364,-24.402Z"
        />
      </g>
    </svg>
  );
};
export default SVGQuestionnaire;
