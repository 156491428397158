import "./AccountPanel.css";
import { useRecoilState } from "recoil";
import {
  studentAtom,
  toggleModalAccountAtom,
  userAtom,
} from "../../../../recoil/atoms";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function AccountPanel() {
  const [isModalAccountOpen, setModalAccountOpen] =
    useRecoilState(toggleModalAccountAtom);
  const [student, ] =
    useRecoilState(studentAtom);
  const [user, ] =
    useRecoilState(userAtom);
 
  return (
    <div>
      {/* <HelpToolsNav /> */}
      <div className={`account-modal ${isModalAccountOpen ? "account-open" : ""}`}>
        <div className="account-header">
          <div className="account-title">Bonjour {student ? student.firstName : (user && user._prenom)}</div>
          <span className="account-close" onClick={() => setModalAccountOpen(false)}>
            <svg width={20} height={20} viewBox="0 0 50 50">
              <path
                d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
                stroke="rgba(0, 0, 0, 0.36)"
                fill="rgba(0, 0, 0, 0.36)"
                strokeWidth={7}
              />
            </svg>
          </span>
        </div>
        <div className="account-body">

          <div
            className="account-section short-section"
            onClick={() => {
              window.location.replace(
                  process.env.REACT_APP_ORCHESTRATEUR_ENDPOINT+"/cas/logout"
              );
            }}
          >
            <div>Déconnexion</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={25}
              height={25}
              fill="currentColor"
              className="bi bi-door-closed"
              viewBox="0 0 16 16"
            >
              <path
                  fillRule="evenodd"
                  d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z"
              />
              <path
                  fillRule="evenodd"
                  d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPanel;
