import React, { useEffect, useState } from "react";
import {
    AGGREGATOR,
    COURSE,
    DOCUMENT,
    ELEARNING,
    EXERCICE,
    GAME,
    IAdvancement, IFetchState, IGraphData, ILink,
    IN_PROCESS,
    INode,
    LINK,
    NOT_ACQUIRED,
    PODCAST,
    PRESENTATION,
    QUESTIONNAIRE,
    RESEARCH,
    SPREADSHEET, TODO,
    TOPIC, VALIDATED,
    VIDEO,
} from "../../../../../interface/graph";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    getEnhancedGraphSelector, graphAtom, graphSelectorPO, rolesAtom, selectedNodeAtom,
    selectedNodeIDAtom,
    selectNode,
    selectRelatedNodes, SettingPopUp,
    toggleModalAtom,
    toggleModalQuestionFormAtom,
    toggleModalResourcesFormAtom,
} from "../../../../../recoil/atoms";

import "./SidePanelAdmin.css";
import {Button, Col, Container, Row, Spinner} from "reactstrap";

import CertifiedSvg from "../../../../icons/CertifiedSvg";
import * as d3 from "d3";
import { useParams } from "react-router-dom";

import classNames from "classnames";
import {
    ApiService,
    etudiantEndActivite,
    etudiantStartActivite,
    getStatistiqueActivite
} from "../../../../LoginHandler/restServices";
import SVGGroupProgress from "../../../../icons/SVGGroupProgress";
import {selectedTeachingUnitAtom, teachingUnitsAtom} from "../../../../../recoil/graphFilters";

import Document from "../../../../svg/Document";
import Video from "../../../../svg/Video";
import WebLink from "../../../../svg/WebLink";
import Questionnaire from "../../../../svg/Questionnaire";
import Game from "../../../../svg/Game";
import Podcast from "../../../../svg/Podcast"
import Exercice from "../../../../svg/Exercice";
import Research from "../../../../svg/Research";
import Elearning from "../../../../svg/Elearning";
import Presentation from "../../../../svg/Presentation";
import Spreadsheet from "../../../../svg/Spreadsheet";
import Course from "../../../../svg/Course";
import Topic from "../../../../svg/Topic";
import IEtudiantActivite from "../../../../../interface/EtudiantActivite.interface";
import IActivitePedagogique from "../../../../../interface/ActivitePedagogique.interface";
import PopUp from "../../../PageElement/PopUp/PopUp";

const nodeType = {
    [DOCUMENT]: "Activité - Document",
    [VIDEO]: "Activité - Vidéo",
    [LINK]: "Activité - Lien",
    [QUESTIONNAIRE]: "Activité - Quiz",
    [GAME]: "Activité - Jeu",
    [PODCAST]: "Activité - Podcast",
    [EXERCICE]: "Activité - Exercice",
    [RESEARCH]: "Activité - Recherche type",
    [ELEARNING]: "Activité - E-learning",
    [PRESENTATION]: "Activité - Présentation",
    [SPREADSHEET]: "Activité - Feuille de calcul",
    [COURSE]: "Activité - Cours",
    [TOPIC]: "Objectif pédagogique",
    [AGGREGATOR]: "...",
};
function SidePanelAdmin() {
    const [isModalOpen, setModalOpen] = useRecoilState(toggleModalAtom);
    const node = useRecoilValue(selectNode);

    return (
        <div className={`modal ${isModalOpen ? "open" : ""}`}>
            <div
                className={classNames("header", {
                    done: node && node.done,
                    activity: node?.type !== TOPIC,
                    started:
                        node &&
                        (node.status === IN_PROCESS ||
                            (node?.type !== TOPIC && node?.status === NOT_ACQUIRED)),
                })}
            >
        <span className="close" onClick={() => setModalOpen(false)}>
          <svg width={20} height={20} viewBox="0 0 50 50">
            <path
                d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
                stroke="rgba(0, 0, 0, 0.36)"
                fill="rgba(0, 0, 0, 0.36)"
                strokeWidth={7}
            />
          </svg>
        </span>
                <svg
                    className={classNames("icon", "node", {
                        done: node && node.done,
                        started: node && node.status === IN_PROCESS,
                    })}
                    viewBox={`${node?.type === TOPIC ? "-10 -14 120 120" : "0 0 100 100"}`}
                >
                    <g transform="translate(108,50)">
                        {node?.type === TOPIC && <Topic node={node} />}
                    </g>
                    <g transform="translate(50,50)">
                        {node?.type === DOCUMENT && <Document node={node} />}
                        {node?.type === VIDEO && <Video node={node} />}
                        {node?.type === LINK && <WebLink node={node} />}
                        {node?.type === QUESTIONNAIRE && <Questionnaire node={node} />}
                        {node?.type === GAME && <Game node={node} />}
                        {node?.type === PODCAST && <Podcast node={node} />}
                        {node?.type === EXERCICE && <Exercice node={node} />}
                        {node?.type === RESEARCH && <Research node={node} />}
                        {node?.type === ELEARNING && <Elearning node={node} />}
                        {node?.type === PRESENTATION && <Presentation node={node} />}
                        {node?.type === SPREADSHEET && <Spreadsheet node={node} />}
                        {node?.type === COURSE && <Course node={node} />}
                    </g>
                </svg>
                <div className="title">{node?.title}</div>
                <div className="type" title={node?.title}>
                    {nodeType[node?.type || TOPIC]}
                </div>

                {node?.type != TOPIC &&
                <div className="creator" title={node?.title}>
                    {node?.creators?.find(x => x.isStudent) != undefined ? (
                        <span>Activité proposée par un étudiant.</span>
                    ) : (
                        <span>Activité proposée par vos enseignants.</span>
                    )}
                </div>
                }

            </div>

            <div
                className={classNames("body", {
                    objective: node?.type === TOPIC,
                    activity: node?.type !== TOPIC,
                })}
            >
                {node?.type === TOPIC ? <TopicBody /> : <ActivityBody />}
            </div>
        </div>
    );
}

export default SidePanelAdmin;

const TopicBody = () => {
    const [, setSelectedNodeIDAtom] = useRecoilState(selectedNodeIDAtom);
    const node = useRecoilValue(selectNode);
    const [, setIsModalQuestionFormOpen] = useRecoilState(
        toggleModalQuestionFormAtom
    );
    const [, setIsModalResourcesFormOpen] = useRecoilState(
        toggleModalResourcesFormAtom
    );
    const relatedNodes = useRecoilValue(selectRelatedNodes).filter(
        (r: INode) => r.type !== TOPIC
    );

    // @ts-ignore
    const conditions = Array.isArray(node._aCombinaisonConditions._aConditionObtention) ?
        // @ts-ignore
        node._aCombinaisonConditions._aConditionObtention.map(x => {
            return x._conditionObtentionDeActivitePedagogique != undefined ? x._conditionObtentionDeActivitePedagogique._URI : false
        })
        :
        // @ts-ignore
        node._aCombinaisonConditions._aConditionObtention._conditionObtentionDeActivitePedagogique != undefined ? [node._aCombinaisonConditions._aConditionObtention._conditionObtentionDeActivitePedagogique._URI] : [false];

    const [rolesUser] = useRecoilState(rolesAtom);

    return (
        <>
            {node?.advancement && <div>Aquisition de la promo</div>}
            {node?.advancement && (
                <div className="advancement">
                    {node?.advancement?.map((a: IAdvancement, index: number) => (
                        <div key={`adv-${index}`}>
                            <p>{a.title}</p>
                            <PercentageBar percent={a.value} />
                        </div>
                    ))}
                </div>
            )}
            {/*<div className="spaced">{node?.text}</div>*/}
            {relatedNodes?.length > 0 && (
                <div className={"scrollbox scrollbox-activities"}>
                    {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) != -1 ).length > 0 && (
                        <>
                            <h6>
                                Activité{relatedNodes.length > 1 ? "s " : " "}
                                Requise{relatedNodes.length > 1 ? "s " : " "}
                            </h6>
                            <Container
                                style={{ overflow: "scroll", bottom: "10px" }}
                                fluid
                            >
                                {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) != -1 ).map((r: any, i: number) => {
                                    return (
                                        <Row
                                            className="relatedNode"
                                            key={`related-${i}`}
                                            onClick={() => {
                                                setSelectedNodeIDAtom(r.id);
                                            }}
                                        >
                                            <Col className="spaced">
                                                <Row>
                                                    <Col xs={3}>
                                                        <svg
                                                            className={classNames("icons", "node", {
                                                                done: r?.done,
                                                                started:
                                                                    r?.status === NOT_ACQUIRED ||
                                                                    r?.status === IN_PROCESS,
                                                            })}
                                                            viewBox={"0 0 100 100"}
                                                        >
                                                            <g transform="translate(50,50)">
                                                                {r?.type === QUESTIONNAIRE && (
                                                                    <Questionnaire node={r} />
                                                                )}
                                                                {r?.type === DOCUMENT && <Document node={r}  />}
                                                                {r?.type === VIDEO && <Video node={r} />}
                                                                {r?.type === LINK && <WebLink node={r} />}
                                                                {r?.type === QUESTIONNAIRE && <Questionnaire node={r} />}
                                                                {r?.type === GAME && <Game node={r} />}
                                                                {r?.type === PODCAST && <Podcast node={r} />}
                                                                {r?.type === EXERCICE && <Exercice node={r} />}
                                                                {r?.type === RESEARCH && <Research node={r} />}
                                                                {r?.type === ELEARNING && <Elearning node={r} />}
                                                                {r?.type === PRESENTATION && <Presentation node={r} />}
                                                                {r?.type === SPREADSHEET && <Spreadsheet node={r} />}
                                                                {r?.type === COURSE && <Course node={r} />}
                                                                {r?.type === TOPIC && <Topic node={r} />}
                                                            </g>
                                                        </svg>
                                                    </Col>
                                                    <Col>
                        <span className="" title={r?.text}>
                          {r?.text}
                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {/*<span>{r.shortTitle}</span>*/}
                                        </Row>
                                    );
                                })}
                            </Container>
                        </>
                    )}
                    {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) == -1 ).length > 0 && (
                        <>
                            <h6>
                                Activité{relatedNodes.length > 1 ? "s " : " "}
                                Associée{relatedNodes.length > 1 ? "s " : " "}
                            </h6>
                            <Container
                                style={{ overflow: "scroll", bottom: "10px" }}
                                fluid
                            >
                                {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) == -1 ).map((r: any, i: number) => {
                                    return (
                                        <Row
                                            className="relatedNode"
                                            key={`related-${i}`}
                                            onClick={() => {
                                                setSelectedNodeIDAtom(r.id);
                                            }}
                                        >
                                            <Col className="spaced">
                                                <Row>
                                                    <Col xs={3}>
                                                        <svg
                                                            className={classNames("icons", "node", {
                                                                done: r?.done,
                                                                started:
                                                                    r?.status === NOT_ACQUIRED ||
                                                                    r?.status === IN_PROCESS,
                                                            })}
                                                            viewBox={"0 0 100 100"}
                                                        >
                                                            <g transform="translate(50,50)">
                                                                {r?.type === QUESTIONNAIRE && (
                                                                    <Questionnaire node={r} />
                                                                )}
                                                                {r?.type === DOCUMENT && <Document node={r}  />}
                                                                {r?.type === VIDEO && <Video node={r} />}
                                                                {r?.type === LINK && <WebLink node={r} />}
                                                                {r?.type === QUESTIONNAIRE && <Questionnaire node={r} />}
                                                                {r?.type === GAME && <Game node={r} />}
                                                                {r?.type === PODCAST && <Podcast node={r} />}
                                                                {r?.type === EXERCICE && <Exercice node={r} />}
                                                                {r?.type === RESEARCH && <Research node={r} />}
                                                                {r?.type === ELEARNING && <Elearning node={r} />}
                                                                {r?.type === PRESENTATION && <Presentation node={r} />}
                                                                {r?.type === SPREADSHEET && <Spreadsheet node={r} />}
                                                                {r?.type === COURSE && <Course node={r} />}
                                                                {r?.type === TOPIC && <Topic node={r} />}
                                                            </g>
                                                        </svg>
                                                    </Col>
                                                    <Col>
                        <span className="" title={r?.text}>
                          {r?.text}
                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {/*<span>{r.shortTitle}</span>*/}
                                        </Row>
                                    );
                                })}
                            </Container>
                        </>
                    )}
                </div>
            )}{" "}
            <Container fluid className="buttons">
                {/* <Row>
          <Col>
            <Button className="second spaced">
              Demande de validation d'acquis
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="second spaced">
              Demande d'activité complémentaire
            </Button>
          </Col>
        </Row> */}
                { rolesUser.includes('etudiant') && <Row>
                    <Col>
                        <Button
                            className="second spaced"
                            onClick={() => {
                                setIsModalQuestionFormOpen(true);
                            }}
                        >
                            Question pour l'enseignant
                        </Button>
                    </Col>
                </Row> }
                { rolesUser.includes('etudiant') && <Row>
                    <Col>
                        <Button
                            className="primary spaced"
                            onClick={() => {
                                setIsModalResourcesFormOpen(true);
                            }}
                        >
                            Proposer une activité
                        </Button>
                    </Col>
                </Row> }

                {
                    ( node
                    &&
                    <AdminButtons node={node}/>
                    )
                }
            </Container>
        </>
    );
};


const ActivityBody = () => {
    const [, setSelectedNodeIDAtom] = useRecoilState(selectedNodeIDAtom);
    const [graphData, setGraphData] = useRecoilState(graphAtom);
    const {nodes, links} = graphData;
    const node = useRecoilValue(selectNode);
    const currentNode = node != undefined ? nodes.find( x => x.id == node.id)! : undefined;
    const relatedNodes = useRecoilValue(selectRelatedNodes);
    const rNode = relatedNodes && relatedNodes[0];

    const [, setIsModalOpen] = useRecoilState(toggleModalAtom);
    const [teachingUnitAtom, setTeachingUnit] = useRecoilState(selectedTeachingUnitAtom);

    const [grades, setGrades] = useState({
        activiteValidee: false,
        avisEtudiant: 0,
        avisPromo: 0,
        seuilReussite: 0,
    });
    useEffect( ()  => {
        setGrades({
            activiteValidee: false,
            avisEtudiant: 0,
            avisPromo: 0,
            seuilReussite: 0,
        });
        if (node) {
            getStatistiqueActivite(studentId,node?.id,teachingUnitAtom!._URI).then((e) =>
                {
                    setGrades(e);
                }
            );
        }
    }, [node]);
    useEffect(() => {
        if (!node) {
            setIsModalOpen(false);
        }
    }, [node, setIsModalOpen]);
    let { studentId } = useParams<{ studentId: string }>();

    return (
        <>
            <Container fluid>
                <Row className="spaced">
                    <Col
                        xs={7}
                        className="carded yellow"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            rNode && setSelectedNodeIDAtom(rNode.id);
                        }}
                    >
                        <Row>
                            <Col className="title">Progression de ma promo</Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <svg
                                    height="60px"
                                    className={classNames("node", {
                                        done: rNode && rNode.done,
                                        started: rNode && rNode.status === IN_PROCESS,
                                    })}
                                >
                                    <g
                                        height="60px"
                                        transform={"translate(20,15)"}
                                        className=" gIcon svg-node-icon shield"
                                    >
                                        <SVGGroupProgress color="#e6b049" scale="scale(0.1)" />
                                    </g>
                                </svg>
                            </Col>
                            <Col xs={8} className="percent">
                                {grades?.seuilReussite || 0} %
                            </Col>
                        </Row>
                    </Col>
                    {grades?.activiteValidee && (
                        <Col xs={4} className="carded blue" style={{ height: "95px" }}>
                            <>
                                <Row>
                                    <Col>Confiance</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CertifiedSvg />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>Activité validée par votre enseignant</Col>
                                </Row>
                            </>
                        </Col>
                    )}
                </Row>
                {/*<Row className="spaced">*/}
                {/*  <Col>Date de péremption (facultatif)</Col>*/}
                {/*</Row>*/}
                {/*<Row className="spaced">*/}
                {/*  <Col>Obligatoir ou non pour acquisition de l'objectif</Col>*/}
                {/*</Row>*/}

                <hr />

            </Container>
            <Container fluid className="buttons">

                {
                    currentNode
                    &&
                    (
                        <AdminButtons node={currentNode}/>
                    )
                }

                <Row className="spaced">
                    <Col>
                        {node?.url && (
                            <Button
                                color="primary"
                                onClick={async () => {
                                    if (node && node.url) {
                                        window?.open(node.url, "_blank")?.focus();
                                    }
                                }}
                            >
                                Voir L'activité
                            </Button>
                        )}
                    </Col>
                </Row>

            </Container>
        </>
    );
};
const PercentageBar = ({ percent }: { percent: number }) => (
    <svg width="120px" height="15px">
        <rect x="0.5" width={119} rx={5} fill="#DFDFDF" height="10" />
        <rect width={percent * 1.2} rx={5} fill="#E8B149" height="10" />
        <rect
            x={5}
            y={2}
            width={Math.max(0, percent * 1.2 - 10)}
            rx={3}
            fill="#FFCF53"
            height="4"
        />
    </svg>
);



const AdminButtons = ({node}: {node: INode})  => {

    let { studentId } = useParams<{ studentId: string }>();
    const [infoActivite, setInfoActivite] = useState<{
        etudiantActivite :  any,
        activitePedagogique : any,
        moodleActivite: any,
    }>();
    const [graphData, setGraphData] = useRecoilState(graphAtom);
    const [, setSelectedNodeIDAtom] = useRecoilState(selectedNodeIDAtom);
    const [isLoad,setIsLoad] = useState(false);
    const [note, setNote] = useState<string>("");
    const [,setSettingPopUp] = useRecoilState(
        SettingPopUp
    );
    const relatedNodes = useRecoilValue(selectRelatedNodes);

    useEffect(() => {
        if(node.type == "QUESTIONNAIRE" && node.status != VALIDATED){
            ApiService.getInfoEtudiantActivite(node.id,studentId).then(( result ) => {
                setInfoActivite(result);
                setNote(result!.moodleActivite.cm.gradepass.toString().replace(',','.'));
                setIsLoad(true);
            });
        } else {
            setIsLoad(true);
        }
    },[]);

    const getRelatedNote = (nodeId : string) => {
        return graphData?.links
            .reduce((a: INode[], b: ILink) => {
                // @ts-ignore
                if (b.source === nodeId) {
                    return [...a, b.target]; // @ts-ignore
                } else if (b.target === nodeId) {
                    return [...a, b.source];
                }
                return a;
            }, []) // @ts-ignore
            .map((id) => graphData?.nodes.find((n) => n.id === id));
    }

    const valideNode = (nodeId? : string, validatedNode? : string[]) => {
        var nodeToValidate = nodeId == undefined ? [{id : node.id, statut: VALIDATED}] : [{id :nodeId, statut: VALIDATED}];
        var nodeRelated =  nodeId == undefined ? relatedNodes : getRelatedNote(nodeId);

        if(nodeRelated.length > 0){
            nodeRelated.forEach(n => {
                if(n.type == TOPIC){
                    if(n.requirementCompletion.length > 0){
                        if(
                            n.requirementCompletion.filter(c => (
                                graphData.nodes.find(x => x.id == c.node.id )!.status == VALIDATED
                                ||(nodeId == undefined ? node.id == c.node.id : nodeId == c.node.id)
                            )).length == n.requirementCompletion.length
                        ) {
                            nodeToValidate.push({id : n.id, statut: VALIDATED})
                        } else {
                            nodeToValidate.push({id : n.id, statut: IN_PROCESS})
                        }
                    }
                }
            })
        }

        setGraphData(
            Object.create(
                {
                    nodes:graphData.nodes.map(x => {
                        var n = nodeToValidate.find(n => n.id == x.id);
                        if(!n){
                            return x;
                        } else {
                            if(nodeId != undefined){
                                validatedNode = validatedNode == undefined ?
                                    [nodeId]
                                    :
                                    validatedNode.concat(nodeId)
                            };

                            if(x.type == TOPIC
                                && validatedNode?.indexOf(x.id) == -1
                                && x.status != VALIDATED){ valideNode(x.id,validatedNode)}

                            let copy = {...x};
                            copy.done = true;
                            copy.status = n.statut;
                            return copy
                        }
                    }),
                    links: graphData.links
                }
            )
        );

        let pourcentage = note != "" ? (parseFloat(note)/parseFloat(infoActivite!.moodleActivite.cm.grade))*100 : undefined;
        ApiService.etudiantEndActivite(studentId,nodeId == undefined ? node.id : nodeId, pourcentage);
    };

    const unvalideNode = (nodeId?: string[]) => {
        var nodeToReset = nodeId == undefined ? [node.id] : nodeId;
        var nodeRelated =  nodeId == undefined ? relatedNodes : getRelatedNote(nodeId[0]);

        if(nodeRelated.length > 0){
            nodeRelated.forEach(n => {
                if(n.type == TOPIC){
                    if(n.requirementCompletion.length > 0){
                        if(n.requirementCompletion.filter(c =>
                            graphData.nodes.find(x => x.id == c.node.id )!.status == VALIDATED
                            &&
                            (
                                nodeId == undefined ?
                                node.id != c.node.id
                                :
                                nodeId.indexOf(c.node.id) == -1
                            )
                        ).length != n.requirementCompletion.length){
                            nodeToReset.push(n.id);
                        }
                    }
                }
            })
        }

        setGraphData(
            Object.create(
                {
                    nodes:graphData.nodes.map(x => {
                        if(!nodeToReset.find(n => n == x.id)){
                            return x;
                        } else {
                            let copy = {...x};
                            if(x.type == TOPIC){
                                copy.status = IN_PROCESS;
                            } else {
                                copy.status = TODO;
                            }
                            copy.done = false;
                            return copy
                        }
                    }),
                    links: graphData.links
                }
            )
        );

        if(nodeId == undefined){
            ApiService.etudiantResetActivite(studentId, node.id );
        } else {
            nodeId.forEach(n => (
                ApiService.etudiantResetActivite(studentId, n)
            ))
        }

    };

    const resetObjectif = (nodeId?:string) => {
        var objectifToReset = nodeId == undefined ? node.id : nodeId;
        var currentNode = graphData.nodes.find(x => x.id == objectifToReset)!;

        var nodeToReset = currentNode.requirementCompletion!.filter(n => (n.type != "OBJECTIF")).map(n => { return n.node!.id});

        unvalideNode(nodeToReset);
    };

    return isLoad ?
        (
            <>
                {node.status != VALIDATED && node.type == QUESTIONNAIRE &&
                    <div className="quizButton">
                        <input className="noteQuiz" name="noteQuiz" min={infoActivite!.moodleActivite.cm.gradepass.toString().replace(',','.')} max={infoActivite!.moodleActivite.cm.grade.toString().replace(',','.')} type="number" value={note} onChange={(event) => {setNote(event.target.value)}}/>
                        <Button className="buttonEndQuiz"
                                color="success"
                                onClick={async () => {
                                    valideNode();
                                }}
                        >
                            Définir comme terminé
                        </Button>
                        <i>Note minimal pour valider  l'activité : {infoActivite!.moodleActivite.cm.gradepass}</i>

                    </div>
                }
                {node.status != VALIDATED && node.type != QUESTIONNAIRE && node.type != TOPIC &&
                <div className="quizButton">
                    <Button
                            color="success"
                            onClick={async () => {
                                valideNode();
                            }}
                    >
                        Définir comme terminé
                    </Button>
                </div>
                }

                {node.status == VALIDATED && node.type != TOPIC &&
                <div className="quizButton">
                    <Button
                            color="danger"
                            onClick={async () => {
                                setSettingPopUp({
                                    isOpen: true,
                                    message:"Confirmer la réinitialisation du statut de l'activité :"+ node.title,
                                    arrBtn : [
                                        {
                                            text: "Confirmer",
                                            action: () => {
                                                unvalideNode();
                                            },
                                            type: "secondary"
                                        },
                                        {
                                            text: "Annuler",
                                            action: () => {},
                                            type: "primary"
                                        }
                                    ]
                                });
                            }}
                    >
                        Réinitialiser le statut de l'activité
                    </Button>
                </div>
                }

                {node.status == VALIDATED && node.type == TOPIC &&
                <div className="buttonTopic">
                    <Button
                        color="danger"
                        onClick={async () => {
                            setSettingPopUp({
                                isOpen: true,
                                message:"Confirmer la réinitialisation de l'objectif et l'ensemble des activités requises ?",
                                arrBtn : [
                                    {
                                        text: "Confirmer",
                                        action: () => {
                                            resetObjectif();
                                        },
                                        type: "secondary"
                                    },
                                    {
                                        text: "Annuler",
                                        action: () => {},
                                        type: "primary"
                                    }
                                ]
                            });
                        }}
                    >
                        Réinitialiser le statut de l'objectif
                    </Button>
                </div>
                }
            </>
        )
        :
        (
            <div className="teaching-units-index-loader loader">
                <Spinner> </Spinner>
            </div>
        )
}
