import React from "react";

const SVGWebLink = () => {
  return (
    <g className="svg-node-icon" transform="translate(1.5,1)">
      <path
        className="link-top"
        d="M8.2,27.1c-1.9,0-3.8-0.8-5.2-2.1c-2.8-2.8-2.8-7.5,0-10.3L5,12.7C5.4,12.3,6,12,6.6,12l0,0
		c0.6,0,1.2,0.2,1.6,0.6c0.2,0.1,0.3,0.3,0.4,0.5C8.3,11,9,8.7,10.6,7.1l3.9-3.9c1.4-1.4,3.3-2.3,5.3-2.3c1.9,0,3.7,0.7,5,2
		C26.2,4.3,27,6.1,27.1,8s-0.7,3.8-2,5.2c-0.1,0.1-0.2,0.2-0.3,0.3l-1.9,1.9c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.2-0.2-1.6-0.6
		c-0.1-0.1-0.2-0.3-0.3-0.4c0.2,2.1-0.5,4.4-2.1,6l-3.9,3.9C12,26.3,10.2,27.1,8.2,27.1z M8.9,14.5c0,0.5-0.3,1-0.6,1.4l-1.9,1.9
		c-1,1.1-1,2.7,0,3.8c0.5,0.5,1.2,0.8,2,0.8c0.7,0,1.4-0.3,1.9-0.8l3.8-3.8c1.1-1.1,1.1-2.8,0-3.9c0,0-0.1-0.1-0.1-0.2
		c-0.4-0.5-0.6-1-0.6-1.6s0.3-1.2,0.8-1.6c0.4-0.4,1-0.6,1.5-0.6c0.7,0,1.3,0.3,1.7,0.8c0.8,0.8,1.4,1.7,1.7,2.7
		c0.1-0.5,0.3-0.9,0.6-1.3l1.9-1.9c0.5-0.5,0.8-1.2,0.8-1.9c0-0.7-0.3-1.4-0.8-1.9c-0.5-0.5-1.2-0.8-2-0.8c-0.7,0-1.4,0.3-1.9,0.8
		l-3.9,3.9c-1.1,1.1-1.1,2.8,0,3.9c0.4,0.4,0.7,1,0.7,1.6s-0.3,1.2-0.7,1.6c-0.4,0.4-1,0.6-1.6,0.6c-0.6,0-1.2-0.2-1.6-0.6
		C9.8,16.5,9.2,15.6,8.9,14.5z"
      />
      <path
        className="link-fill"
        stroke="none"
        d="M19.8,1.9c1.6,0,3.1,0.6,4.3,1.7c2.5,2.4,2.6,6.4,0.2,8.9c-0.1,0.1-0.2,0.2-0.2,0.2l-1.9,1.9
		c-0.3,0.3-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-0.5-0.5-0.5-1.3,0-1.8c0,0,0,0,0,0l1.9-1.9c1.5-1.4,1.5-3.8,0.1-5.3
		c-0.7-0.8-1.7-1.1-2.7-1.1c-0.9,0-1.9,0.3-2.6,1c0,0-0.1,0.1-0.1,0.1l-3.9,3.9c-1.5,1.5-1.5,3.8,0,5.3c0.5,0.5,0.5,1.3,0,1.8
		c-0.2,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4c-2.5-2.5-2.5-6.4,0-8.9l3.9-3.9C16.4,2.6,18.1,1.9,19.8,1.9 M15.6,11
		c0.4,0,0.7,0.1,1,0.4c2.5,2.5,2.5,6.4,0,8.9l-3.9,3.9c-1.2,1.2-2.8,1.8-4.5,1.8S5,25.4,3.8,24.2c-2.5-2.5-2.5-6.4,0-8.9l1.9-1.9
		C5.9,13.2,6.3,13,6.6,13s0.6,0.1,0.9,0.4c0.5,0.5,0.5,1.3,0,1.8l-1.9,1.9c-1.4,1.4-1.4,3.7,0,5.2c0.7,0.8,1.7,1.1,2.7,1.1
		c0.9,0,1.9-0.3,2.6-1l3.9-3.9c0,0,0,0,0,0c1.5-1.5,1.5-3.8,0-5.3c0,0-0.1-0.1-0.1-0.1c-0.5-0.5-0.4-1.3,0.1-1.8
		C15,11.1,15.3,11,15.6,11 M19.8-0.1c-2.3,0-4.4,0.9-6,2.6L9.9,6.3c-1.4,1.4-2.1,3.1-2.4,4.8c-0.3-0.1-0.6-0.1-1-0.1
		c-0.9,0-1.7,0.3-2.3,0.9l-1.9,1.9c-3.2,3.2-3.2,8.5,0,11.7c1.6,1.6,3.7,2.4,5.9,2.4s4.3-0.9,5.9-2.4l3.9-3.9c1.3-1.3,2.1-3,2.4-4.8
		c0.3,0.1,0.6,0.1,0.9,0.1c0.9,0,1.7-0.4,2.4-1l1.9-1.9c0.1-0.1,0.2-0.2,0.3-0.3c3.2-3.3,3-8.6-0.3-11.7C23.9,0.7,21.9-0.1,19.8-0.1
		L19.8-0.1z M16.4,9.1l2-2l0.1-0.1c0.3-0.3,0.8-0.5,1.2-0.5c0.5,0,0.9,0.2,1.3,0.5c0.7,0.7,0.6,1.8,0,2.5l-1.8,1.8
		c-0.3-0.5-0.7-0.9-1.1-1.3C17.6,9.6,17,9.3,16.4,9.1L16.4,9.1z M8.3,21.5c-0.5,0-0.9-0.2-1.3-0.5c-0.7-0.7-0.7-1.7,0-2.4l1.8-1.8
		c0.3,0.5,0.7,0.9,1.1,1.4c0.5,0.4,1,0.7,1.6,0.9l-2,2C9.2,21.3,8.7,21.5,8.3,21.5L8.3,21.5z"
      />
    </g>
  );
};
export default SVGWebLink;
