import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import "./QuestionForm.css";
import { Button } from "reactstrap";
import {useRecoilState, useRecoilValue} from "recoil";
import {selectNode, studentAtom, toggleModalQuestionFormAtom} from "../../recoil/atoms";
import {sendEmail} from "../LoginHandler/restServices";
import { selectedTeachingUnitAtom } from "../../recoil/graphFilters";

<link 
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function QuestionForm() {
  const [isModalQuestionFormOpen, setModalQuestionFormOpen] = useRecoilState(
    toggleModalQuestionFormAtom
  );
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const node = useRecoilValue(selectNode);
  const [isClickedPrecision, setIsClickedPrecision] = useState(false);
  const [isClickedExperienceValidation, setIsClickedExperienceValidation] = useState(false);
  const [isClickedComplementaryActivity, setIsClickedComplementaryActivity] = useState(false);
  const [isClickedOther, setIsClickedOther] = useState(false);
  const [student,] = useRecoilState(studentAtom);
  const [selectedTeachingUnit,] = useRecoilState(
    selectedTeachingUnitAtom
  );

  const handleClickType = (props: string) => {
    props === "Precision" ? setIsClickedPrecision(true) : setIsClickedPrecision(false);
    props === "experienceValidation" ? setIsClickedExperienceValidation(true) : setIsClickedExperienceValidation(false);
    props === "complementaryActivity" ? setIsClickedComplementaryActivity(true) : setIsClickedComplementaryActivity(false);
    props === "Other" ? setIsClickedOther(true) : setIsClickedOther(false);
  }


  // Send a mail to the teacher on submit
  const onSubmit = handleSubmit((data) => {
    sendEmail(selectedTeachingUnit!._URI, data.type, data.question, student!.firstName, student!.lastName, student!.email, node);
    handleClose();
  });
  
  const handleClose = () => {
    setModalQuestionFormOpen(false);
    setIsClickedPrecision(false);
    setIsClickedExperienceValidation(false);
    setIsClickedComplementaryActivity(false);
    setIsClickedOther(false);
    reset({ question: "" });
  }
  
  return (
    <div className={`questionForm-modal ${isModalQuestionFormOpen ? "questionForm-open" : ""}`}>
      <form className="questionForm-modal-content" onSubmit={onSubmit}>
        <div className="questionForm-header">
          <div className="questionForm-header-title">Question pour l'enseignant</div>
          <span className="questionForm-close" onClick={() => handleClose()}>
            <svg 
              width={20}
              height={20}
              viewBox="0 0 50 50"
            >
              <path
                d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
                stroke="rgba(0, 0, 0, 0.36)"
                fill="rgba(0, 0, 0, 0.36)"
                strokeWidth={7}
              />
            </svg>
          </span>
        </div>
        <div className="questionForm-body">
          <div className="questionForm-section">
            <div className="questionForm-section-title">Sélectionner un type de demande <em>*</em></div>
            <div className="questionForm-list">
              <label 
                className={`questionForm-input ${isClickedPrecision ? "questionForm-inputClicked" : "questionForm-inputNoClicked"}`} 
                onClick={() => { handleClickType('Precision') }}
              >
                <input 
                  type="radio" 
                  className="questionForm-radio" 
                  {...register("type", { required: "Ce champ est requis" })} 
                  value="precision"
                />
                Précision sur un concept
              </label>
              <label 
                className={`questionForm-input ${isClickedExperienceValidation ? "questionForm-inputClicked" : "questionForm-inputNoClicked"}`} 
                onClick={() => { handleClickType('experienceValidation') }}
              >
                <input 
                  type="radio" 
                  className="questionForm-radio" 
                  {...register("type", { required: "Ce champ est requis" })} 
                  value="experience_validation"
                />
                Demande de validation d'acquis
              </label>
              <label 
                className={`questionForm-input ${isClickedComplementaryActivity ? "questionForm-inputClicked" : "questionForm-inputNoClicked"}`} 
                onClick={() => { handleClickType('complementaryActivity') }}
              >
                <input 
                  type="radio" 
                  className="questionForm-radio" 
                  {...register("type", { required: "Ce champ est requis" })} 
                  value="complementary_activity"
                />
                Demande d'activité complémentaire
              </label>
              <label 
                className={`questionForm-input ${isClickedOther ? "questionForm-inputClicked" : "questionForm-inputNoClicked"}`} 
                onClick={() => { handleClickType('Other') }}
              >
                <input 
                  type="radio" 
                  className="questionForm-radio" 
                  {...register("type", { required: "Ce champ est requis" })} 
                  value="other"
                />
                Autre demande
              </label>
            </div>
            {errors.type && 
              <div className="questionForm-errors">
                {errors.type.message}
              </div>
            }
          </div>
          <div className="questionForm-section">
            <div className="questionForm-section-title">Question <em>*</em></div>
            <textarea 
              className="questionForm-fields" 
              placeholder="Vous pouvez préciser votre demande ici." 
              {...register("question", { required: "Ce champ est requis" })}
            />
            {errors.question && 
              <div className="questionForm-errors">
                {errors.question.message}
              </div>
            }
          </div>
          <div className="questionForm-mandatory"><em>*</em> champs obligatoires</div>
        </div>
        <div className="questionForm-footer">
          <Button 
            className="questionForm-btn questionForm-second" 
            onClick={() => handleClose()}
          >
            Annuler
          </Button>
          <Button className="questionForm-btn questionForm-primary">
            <input type="submit" />
            Envoyer
          </Button>
        </div>
      </form>
    </div>
  );
}

export default QuestionForm;