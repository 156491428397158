import React, { useEffect, useState } from "react";
import {
  AGGREGATOR,
  COURSE,
  DOCUMENT,
  ELEARNING,
  EXERCICE,
  GAME,
  IAdvancement, IFetchState, IGraphData, ILink,
  IN_PROCESS,
  INode,
  LINK,
  NOT_ACQUIRED,
  PODCAST,
  PRESENTATION,
  QUESTIONNAIRE,
  RESEARCH,
  SPREADSHEET,
  TOPIC, VALIDATED,
  VIDEO,
} from "../../interface/graph";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  getEnhancedGraphSelector, graphAtom, graphSelectorPO, rolesAtom, selectedNodeAtom,
  selectedNodeIDAtom,
  selectNode,
  selectRelatedNodes,
  toggleModalAtom,
  toggleModalQuestionFormAtom,
  toggleModalResourcesFormAtom,
  userAtom,
} from "../../recoil/atoms";

import "./SidePanel.css";
import StarPath from "../icons/StarPath";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import SVGShield from "../icons/SVGShield";
import CertifiedSvg from "../icons/CertifiedSvg";
import * as d3 from "d3";
import { useParams } from "react-router-dom";
import { api } from "./ApiLoader";
import classNames from "classnames";
import {
  ApiService,
  etudiantEndActivite,
  etudiantStartActivite,
  getStatistiqueActivite
} from "../LoginHandler/restServices";
import SVGGroupProgress from "../icons/SVGGroupProgress";
import {selectedTeachingUnitAtom, teachingUnitsAtom} from "../../recoil/graphFilters";
import {jsonToForceGraph} from "../../extractor/newDataExtractor";
import Document from "../svg/Document";
import Video from "../svg/Video";
import WebLink from "../svg/WebLink";
import Questionnaire from "../svg/Questionnaire";
import Game from "../svg/Game";
import Podcast from "../svg/Podcast"
import Exercice from "../svg/Exercice";
import Research from "../svg/Research";
import Elearning from "../svg/Elearning";
import Presentation from "../svg/Presentation";
import Spreadsheet from "../svg/Spreadsheet";
import Course from "../svg/Course";
import Topic from "../svg/Topic";

const nodeType = {
  [DOCUMENT]: "Activité - Document",
  [VIDEO]: "Activité - Vidéo",
  [LINK]: "Activité - Lien",
  [QUESTIONNAIRE]: "Activité - Quiz",
  [GAME]: "Activité - Jeu",
  [PODCAST]: "Activité - Podcast",
  [EXERCICE]: "Activité - Exercice",
  [RESEARCH]: "Activité - Recherche type",
  [ELEARNING]: "Activité - Module e-learning",
  [PRESENTATION]: "Activité - Présentation",
  [SPREADSHEET]: "Activité - Feuille de calcul",
  [COURSE]: "Activité - Cours",
  [TOPIC]: "Objectif pédagogique",
  [AGGREGATOR]: "...",
};
function SidePanel() {
  const [isModalOpen, setModalOpen] = useRecoilState(toggleModalAtom);
  const node = useRecoilValue(selectNode);

  return (
    <div className={`modal ${isModalOpen ? "open" : ""}`}>
      <div
        className={classNames("header", {
          done: node && node.done,
          activity: node?.type !== TOPIC,
          started:
            node &&
            (node.status === IN_PROCESS ||
              (node?.type !== TOPIC && node?.status === NOT_ACQUIRED)),
        })}
      >
        <span className="close" onClick={() => setModalOpen(false)}>
          <svg width={20} height={20} viewBox="0 0 50 50">
            <path
              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
              stroke="rgba(0, 0, 0, 0.36)"
              fill="rgba(0, 0, 0, 0.36)"
              strokeWidth={7}
            />
          </svg>
        </span>
        <svg
          className={classNames("icon", "node", {
            done: node && node.done,
            started: node && node.status === IN_PROCESS || 
              (node?.type !== TOPIC && node?.status === NOT_ACQUIRED),
          })}
          viewBox={`${node?.type === TOPIC ? "-10 -14 120 120" : "0 0 100 100"}`}
        >
          <g transform="translate(108,50)">
            {node?.type === TOPIC && <Topic node={node} />}
          </g>
          <g transform="translate(50,50)">
            {node?.type === DOCUMENT && <Document node={node} />}
            {node?.type === VIDEO && <Video node={node} />}
            {node?.type === LINK && <WebLink node={node} />}
            {node?.type === QUESTIONNAIRE && <Questionnaire node={node} />}
            {node?.type === GAME && <Game node={node} />}
            {node?.type === PODCAST && <Podcast node={node} />}
            {node?.type === EXERCICE && <Exercice node={node} />}
            {node?.type === RESEARCH && <Research node={node} />}
            {node?.type === ELEARNING && <Elearning node={node} />}
            {node?.type === PRESENTATION && <Presentation node={node} />}
            {node?.type === SPREADSHEET && <Spreadsheet node={node} />}
            {node?.type === COURSE && <Course node={node} />}
          </g>
        </svg>
        <div className="title">{node?.title}</div>
        <div className="type" title={node?.title}>
          {nodeType[node?.type || TOPIC]}
        </div>

        {node?.type != TOPIC &&
          <div className="creator" title={node?.title}>
            {node?.creators?.find(x => x.isStudent) != undefined ? (
                <span>Activité proposée par un étudiant.</span>
            ) : (
                <span>Activité proposée par vos enseignants.</span>
            )}
          </div>
        }

      </div>

      <div
        className={classNames("body", {
          objective: node?.type === TOPIC,
          activity: node?.type !== TOPIC,
        })}
      >
        {node?.type === TOPIC ? <TopicBody /> : <ActivityBody />}
      </div>
    </div>
  );
}

export default SidePanel;

const TopicBody = () => {
  const [, setSelectedNodeIDAtom] = useRecoilState(selectedNodeIDAtom);
  const node = useRecoilValue(selectNode);
  const [, setIsModalQuestionFormOpen] = useRecoilState(
    toggleModalQuestionFormAtom
  );
  const [, setIsModalResourcesFormOpen] = useRecoilState(
    toggleModalResourcesFormAtom
  );
  const relatedNodes = useRecoilValue(selectRelatedNodes).filter(
    (r: INode) => r.type !== TOPIC
  );
  const [teachingUnitAtom] = useRecoilState(selectedTeachingUnitAtom);
  // @ts-ignore
  const conditions = Array.isArray(node._aCombinaisonConditions._aConditionObtention) ?
      // @ts-ignore
      node._aCombinaisonConditions._aConditionObtention.map(x => {
        return x._conditionObtentionDeActivitePedagogique != undefined ? x._conditionObtentionDeActivitePedagogique._URI : false
      })
      :
      // @ts-ignore
      node._aCombinaisonConditions._aConditionObtention._conditionObtentionDeActivitePedagogique != undefined ? [node._aCombinaisonConditions._aConditionObtention._conditionObtentionDeActivitePedagogique._URI] : [false];

  const [rolesUser] = useRecoilState(rolesAtom);

  return (
    <>
      {node?.advancement && <div>Aquisition de la promo</div>}
      {node?.advancement && (
        <div className="advancement">
          {node?.advancement?.map((a: IAdvancement, index: number) => (
            <div key={`adv-${index}`}>
              <p>{a.title}</p>
              <PercentageBar percent={a.value} />
            </div>
          ))}
        </div>
      )}
      {/*<div className="spaced">{node?.text}</div>*/}
      {relatedNodes?.length > 0 && (
        <div className={"scrollbox scrollbox-activities"}>
          {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) != -1 ).length > 0 && (
              <>
                <h6>
                  Activité{relatedNodes.length > 1 ? "s " : " "}
                  Requise{relatedNodes.length > 1 ? "s " : " "}
                </h6>
                <Container
                    style={{ overflow: "scroll", bottom: "10px" }}
                    fluid
                >
                  {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) != -1 ).map((r: any, i: number) => {
                    return (
                        <Row
                            className="relatedNode"
                            key={`related-${i}`}
                            onClick={() => {
                              setSelectedNodeIDAtom(r.id);
                            }}
                        >
                          <Col className="spaced">
                            <Row>
                              <Col xs={3}>
                                <svg
                                    className={classNames("icons", "node", {
                                      done: r?.done,
                                      started:
                                          r?.status === NOT_ACQUIRED ||
                                          r?.status === IN_PROCESS,
                                    })}
                                    viewBox={"0 0 100 100"}
                                >
                                  <g transform="translate(50,50)">
                                    {r?.type === QUESTIONNAIRE && (
                                        <Questionnaire node={r} />
                                    )}
                                    {r?.type === DOCUMENT && <Document node={r}  />}
                                    {r?.type === VIDEO && <Video node={r} />}
                                    {r?.type === LINK && <WebLink node={r} />}
                                    {r?.type === QUESTIONNAIRE && <Questionnaire node={r} />}
                                    {r?.type === GAME && <Game node={r} />}
                                    {r?.type === PODCAST && <Podcast node={r} />}
                                    {r?.type === EXERCICE && <Exercice node={r} />}
                                    {r?.type === RESEARCH && <Research node={r} />}
                                    {r?.type === ELEARNING && <Elearning node={r} />}
                                    {r?.type === PRESENTATION && <Presentation node={r} />}
                                    {r?.type === SPREADSHEET && <Spreadsheet node={r} />}
                                    {r?.type === COURSE && <Course node={r} />}
                                    {r?.type === TOPIC && <Topic node={r} />}
                                  </g>
                                </svg>
                              </Col>
                              <Col>
                        <span className="" title={r?.text}>
                          {r?.text}
                        </span>
                              </Col>
                            </Row>
                          </Col>
                          {/*<span>{r.shortTitle}</span>*/}
                        </Row>
                    );
                  })}
                </Container>
              </>
          )}
          {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) == -1 ).length > 0 && (
              <>
                <h6>
                  Activité{relatedNodes.length > 1 ? "s " : " "}
                  Associée{relatedNodes.length > 1 ? "s " : " "}
                </h6>
                <Container
                    style={{ overflow: "scroll", bottom: "10px" }}
                    fluid
                >
                  {relatedNodes?.filter((x:any) => conditions.indexOf(x.id) == -1 ).map((r: any, i: number) => {
                    return (
                        <Row
                            className="relatedNode"
                            key={`related-${i}`}
                            onClick={() => {
                              setSelectedNodeIDAtom(r.id);
                            }}
                        >
                          <Col className="spaced">
                            <Row>
                              <Col xs={3}>
                                <svg
                                    className={classNames("icons", "node", {
                                      done: r?.done,
                                      started:
                                          r?.status === NOT_ACQUIRED ||
                                          r?.status === IN_PROCESS,
                                    })}
                                    viewBox={"0 0 100 100"}
                                >
                                  <g transform="translate(50,50)">
                                    {r?.type === QUESTIONNAIRE && (
                                        <Questionnaire node={r} />
                                    )}
                                    {r?.type === DOCUMENT && <Document node={r}  />}
                                    {r?.type === VIDEO && <Video node={r} />}
                                    {r?.type === LINK && <WebLink node={r} />}
                                    {r?.type === QUESTIONNAIRE && <Questionnaire node={r} />}
                                    {r?.type === GAME && <Game node={r} />}
                                    {r?.type === PODCAST && <Podcast node={r} />}
                                    {r?.type === EXERCICE && <Exercice node={r} />}
                                    {r?.type === RESEARCH && <Research node={r} />}
                                    {r?.type === ELEARNING && <Elearning node={r} />}
                                    {r?.type === PRESENTATION && <Presentation node={r} />}
                                    {r?.type === SPREADSHEET && <Spreadsheet node={r} />}
                                    {r?.type === COURSE && <Course node={r} />}
                                    {r?.type === TOPIC && <Topic node={r} />}
                                  </g>
                                </svg>
                              </Col>
                              <Col>
                        <span className="" title={r?.text}>
                          {r?.text}
                        </span>
                              </Col>
                            </Row>
                          </Col>
                          {/*<span>{r.shortTitle}</span>*/}
                        </Row>
                    );
                  })}
                </Container>
              </>
          )}
        </div>
      )}{" "}
      <Container fluid className="buttons">
        {/* <Row>
          <Col>
            <Button className="second spaced">
              Demande de validation d'acquis
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="second spaced">
              Demande d'activité complémentaire
            </Button>
          </Col>
        </Row> */}
        { rolesUser.includes('etudiant') && <Row>
          <Col>
            <Button
                className="second spaced"
                onClick={() => {
                  setIsModalQuestionFormOpen(true);
                }}
            >
              Question pour l'enseignant
            </Button>
          </Col>
        </Row> }
        { rolesUser.includes('etudiant') && (teachingUnitAtom?._contributionActive!.toString() == "true") && <Row>
          <Col>
            <Button
                className="primary spaced"
                onClick={() => {
                  setIsModalResourcesFormOpen(true);
                }}
            >
              Proposer une activité
            </Button>
          </Col>
        </Row> }
      </Container>
    </>
  );
};

async function startActivity(studentId: string, node: INode) {
  await etudiantStartActivite(studentId,node.id);
}

async function endActivity(studentId: string, node: INode) {
  await etudiantEndActivite(studentId,node.id);
}

const ActivityBody = () => {
  const [, setSelectedNodeIDAtom] = useRecoilState(selectedNodeIDAtom);
  const node = useRecoilValue(selectNode);
  const relatedNodes = useRecoilValue(selectRelatedNodes);
  const rNode = relatedNodes && relatedNodes[0];
  const [graphData, setGraphData] = useRecoilState(graphAtom);
  const [, setIsModalOpen] = useRecoilState(toggleModalAtom);
  const [teachingUnitAtom, setTeachingUnit] = useRecoilState(selectedTeachingUnitAtom);
  const [user] = useRecoilState(userAtom);


  const getRelatedNote = (nodeId : string) => {
    return graphData?.links
        .reduce((a: INode[], b: ILink) => {
          // @ts-ignore
          if (b.source === nodeId) {
            return [...a, b.target]; // @ts-ignore
          } else if (b.target === nodeId) {
            return [...a, b.source];
          }
          return a;
        }, []) // @ts-ignore
        .map((id) => graphData?.nodes.find((n) => n.id === id));
  }
  const valideNode = (nodeId? : string, validatedNode? : string[]) => {
    var nodeToValidate = nodeId == undefined ? [{id : node.id, statut: VALIDATED}] : [{id :nodeId, statut: VALIDATED}];
    var nodeRelated =  nodeId == undefined ? relatedNodes : getRelatedNote(nodeId);

    if(nodeRelated.length > 0){
      nodeRelated.forEach(n => {
        if(n.type == TOPIC){
          if(n.requirementCompletion.length > 0){
            if(
                n.requirementCompletion.filter(c => (
                    graphData.nodes.find(x => x.id == c.node.id )!.status == VALIDATED
                    ||(nodeId == undefined ? node.id == c.node.id : nodeId == c.node.id)
                )).length == n.requirementCompletion.length
            ) {
              nodeToValidate.push({id : n.id, statut: VALIDATED})
            } else {
              nodeToValidate.push({id : n.id, statut: IN_PROCESS})
            }
          }
        }
      })
    }

    setGraphData(
        Object.create(
            {
              nodes:graphData.nodes.map(x => {
                var n = nodeToValidate.find(n => n.id == x.id);
                if(!n){
                  return x;
                } else {
                  if(nodeId != undefined){
                    validatedNode = validatedNode == undefined ?
                        [nodeId]
                        :
                        validatedNode.concat(nodeId)
                  };

                  if(x.type == TOPIC
                      && validatedNode?.indexOf(x.id) == -1
                      && x.status != VALIDATED){ valideNode(x.id,validatedNode)}

                  let copy = {...x};
                  copy.done = true;
                  copy.status = n.statut;
                  return copy
                }
              }),
              links: graphData.links
            }
        )
    );
  };

  const startNode = (nodeId? : string, validatedNode? : string[]) => {
    var nodeToStart = nodeId == undefined ? [{id : node.id, statut: IN_PROCESS}] : [{id :nodeId, statut: IN_PROCESS}];
    var nodeRelated =  nodeId == undefined ? relatedNodes : getRelatedNote(nodeId);

    if(nodeRelated.length > 0){
      nodeRelated.forEach(n => {
        if(n.type == TOPIC){
          nodeToStart.push({id : n.id, statut: IN_PROCESS})
        }
      })
    }

    setGraphData(
        Object.create(
            {
              nodes:graphData.nodes.map(x => {
                var n = nodeToStart.find(n => n.id == x.id);
                if(!n){
                  return x;
                } else {
                  if(nodeId != undefined){
                    validatedNode = validatedNode == undefined ?
                        [nodeId]
                        :
                        validatedNode.concat(nodeId)
                  };

                  let copy = {...x};
                  copy.done = true;
                  copy.status = n.statut;
                  return copy
                }
              }),
              links: graphData.links
            }
        )
    );
  };

  // Note à -1 signifie que le chargement n'est pas effectué
  const [grades, setGrades] = useState<{
    activiteValidee: boolean,
    avisEtudiant: number,
    avisPromo: number,
    seuilReussite: number,
  } | undefined>(undefined);

  useEffect(() => {
    if (node) {
      getStatistiqueActivite(studentId, node?.id, teachingUnitAtom!._URI).then((e) => {
        setGrades(e);
      });
    }
  }, [node]);

  const setStudentGrade = (index: number) => {

    setGrades({
      activiteValidee: grades!.activiteValidee,
      avisEtudiant: index + 1,
      avisPromo: grades!.avisPromo,
      seuilReussite: grades!.seuilReussite,
    });

    (async function editStudentGrade() {

      // Modification de la note de l'étudiant
      await ApiService.editStudentActivite({
        _URI: node!.etudiantActivite!._URI, 
        _utiliteActivitePourEtudiant: index + 1
      });

    })();
  }

  useEffect(() => {
    if (!node) {
      setIsModalOpen(false);
    }
  }, [node, setIsModalOpen]);
  let { studentId } = useParams<{ studentId: string }>();

  return (
    <>
      {grades != undefined ?
        <Container fluid>
          <Row className="spaced">
            <Col
              xs={7}
              className="carded yellow"
              style={{ cursor: "pointer" }}
              onClick={() => {
                rNode && setSelectedNodeIDAtom(rNode.id);
              }}
            >
              <Row>
                <Col className="title">Progression de ma promo</Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <svg
                    height="60px"
                    className={classNames("node", {
                      done: rNode && rNode.done,
                      started: rNode && rNode.status === IN_PROCESS,
                    })}
                  >
                    <g
                      height="60px"
                      transform={"translate(20,15)"}
                      className=" gIcon svg-node-icon shield"
                    >
                      <SVGGroupProgress color="#e6b049" scale="scale(0.1)" />
                    </g>
                  </svg>
                </Col>
                <Col xs={8} className="percent">
                  {grades?.seuilReussite || 0} %
                </Col>
              </Row>
            </Col>
            {grades?.activiteValidee && (
              <Col xs={4} className="carded blue" style={{ height: "95px" }}>
                <>
                  <Row>
                    <Col>Confiance</Col>
                  </Row>
                  <Row>
                    <Col>
                      <CertifiedSvg />
                    </Col>
                  </Row>
                  <Row>
                    <Col>Activité validée par votre enseignant</Col>
                  </Row>
                </>
              </Col>
            )}
          </Row>
          {/*<Row className="spaced">*/}
          {/*  <Col>Date de péremption (facultatif)</Col>*/}
          {/*</Row>*/}
          {/*<Row className="spaced">*/}
          {/*  <Col>Obligatoire ou non pour acquisition de l'objectif</Col>*/}
          {/*</Row>*/}

          <hr />
          <Row className="ratingContainer ">
            <Col xs={6}>
              <Row>
                <Col>L'avis de ma promo</Col>
              </Row>
              <Row className="rating">
                <Col>{grades?.avisPromo || 0}</Col>
              </Row>
              <Row>
                <Col>sur 5</Col>
              </Row>
            </Col>
            <Col xs={6} className="rightContent">
              <Row>
                <Col>Votre avis</Col>
              </Row>
              <Row>
                <Col className={`rating ${node?.status === VALIDATED ? "editable" : ""}`}>
                  {d3
                    .range(0, 5)
                    .map((d: any) => d < (grades?.avisEtudiant || 0))
                    .map((b: any, i: any) => (
                      <svg width="24px" height="25px" onClick={() => (node?.status === VALIDATED && setStudentGrade(i))}>                        
                        <StarPath key={`star-${i}`} lighted={b} index={i} />
                      </svg>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
          {node?.status !== VALIDATED &&
            <div className="rating-info">
              <div className="triangle">
                ?
              </div>
              <div className="bubble">
                Vous pouvez donner votre avis une fois que vous avez terminé l'activité.
              </div>
            </div>
          }
        </Container> : 
        <div className="loading-activity">
          <Spinner> </Spinner>
        </div>
      }
      <Container fluid className="buttons">


        <Row className="spaced">
          <Col>
            {
              (node?.dateStarted != undefined) &&
              (new Date().getTime() - node?.dateStarted.getTime() > node.duration + 3600) &&
              (node.type != QUESTIONNAIRE ) &&
              (node.status == IN_PROCESS) &&
              (
                <Button
                    color="success"
                    onClick={async () => {
                      if (node && node.url) {
                        valideNode();
                        endActivity(studentId, node);
                      }
                    }}
                >
                  J'ai terminé l'activité
                </Button>
            )}
          </Col>
        </Row>

        <Row className="spaced">
          <Col>
            {node?.url && (
              <Button
                color="primary"
                onClick={async () => {
                  if (node && node.url) {
                    startActivity(studentId, node);
                    window?.open(node.url, "_blank")?.focus();
                    const {nodes, links} = graphData;
                    if(node.status != VALIDATED){
                      startNode();
                    }

                  }
                }}
              >
                Lancer L'activité
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <button className="noBG" hidden={true}>Signaler l'activité</button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const PercentageBar = ({ percent }: { percent: number }) => (
  <svg width="120px" height="15px">
    <rect x="0.5" width={119} rx={5} fill="#DFDFDF" height="10" />
    <rect width={percent * 1.2} rx={5} fill="#E8B149" height="10" />
    <rect
      x={5}
      y={2}
      width={Math.max(0, percent * 1.2 - 10)}
      rx={3}
      fill="#FFCF53"
      height="4"
    />
  </svg>
);
