import React, { useEffect, useState } from "react";
import {
  IFetchState,
  OBJECTIVE_FINISHED,
  OBJECTIVE_NOT_STARTED,
} from "../../interface/graph";
import { Spinner } from "reactstrap";
import { useRecoilState } from "recoil";
import {graphAtom, openNodesAtom, selectedLayerAtom, selectedNodeIDAtom, studentAtom} from "../../recoil/atoms";

import { jsonToForceGraph } from "../../extractor/newDataExtractor";
import { useParams } from "react-router-dom";
import * as d3 from "d3";
import {
  activityDurationFilterAtom,
  activityDurationOptionsDefault,
  activityProgressFilterAtom,
  activityProgressOptions,
  activityTypeFilterAtom,
  activityTypesOptions, filterActivityDuration, filterActivityProgress, filterActivityType, IActivityDurationOption,
  selectedTeachingUnitAtom,
  teachingUnitsAtom,
} from "../../recoil/graphFilters";

import PositionSimulation from "../PositionsForceGraph/PositionSimulation";
import { ApiService } from "../LoginHandler/restServices";

const parseStudentDate: (dateParam: any) => Date = (dateParam: string) => {
  return (
    d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ")(
      Array.isArray(dateParam) ? dateParam[0] : dateParam
    ) || new Date()
  );
};

export const api = process.env.REACT_APP_USE_REDIRECT
  ? process.env.REACT_APP_USE_REDIRECT
  : process.env.REACT_APP_ORCHESTRATEUR_ENDPOINT + "/api";

function ApiLoader({
  useApi,
  imposedStudentId,
}: {
  useApi?: boolean;
  imposedStudentId?: string;

}) {

  const [dataSync, setDataSync ] = useState<{
    objectives:any,
    activities:any,
    studentActivities:any,
    studentObjectives:any,
    oBjStats: any
  } | undefined >(undefined);

  const [fetchState, setFetchState] = useState<IFetchState>("loading");
  const [, setGraphData] = useRecoilState(graphAtom);
  const [, setOpenNodes] = useRecoilState(openNodesAtom);
  const [activityType, setActivityType] = useRecoilState(activityTypeFilterAtom);
  const [selectedNodeID, selectedNode] = useRecoilState<string | undefined>(
      selectedNodeIDAtom
  );
  const [, setTeachingUnits] = useRecoilState(teachingUnitsAtom);
  const [student, setStudent] = useRecoilState(studentAtom);

  const [teachingUnit, setSelectedTeachingUnit] = useRecoilState(
    selectedTeachingUnitAtom
  );
  const [activityProgress, setActivityProgressType] = useRecoilState(
    activityProgressFilterAtom
  );
  const [activityDuration, setActivityDurationFilter] = useRecoilState(
    activityDurationFilterAtom
  );
  const [selectedLayers] = useRecoilState(selectedLayerAtom);
  const { studentId } = useParams<{ studentId: string }>();

  useEffect(() => {

    const fetchStudentAndCourse = async () => {

      try {
        const [student, teachingUnits,currentObjectif] = await Promise.all([
          ApiService.getStudent(imposedStudentId || studentId),
          ApiService.getStudentTeachingUnits(studentId),
          ApiService.getCurrentObjective(studentId),
        ]);

        setStudent({
          id: student._idPassport,
          uri: student._URI,
          lastConnection: parseStudentDate(student._derniereConnexion),
          firstName: student._prenom,
          lastName: student._nom,
          URI: student._URI,
          email: student._email,
          isSync: student._moodleIsSync
        });

        setTeachingUnits(teachingUnits);

        if (teachingUnit == undefined) {
          if(currentObjectif.length > 0){
            var objectifSerie = currentObjectif[0]._objectifPedagogique._estUneVersionDe._URI;

            var selectedTU = teachingUnits.filter(x => {
              var r = x._porteUnObjectifPedagogique.filter( (o:any) => o._URI == objectifSerie);
              return r.length > 0;
            });

            if(selectedTU.length > 0){
              selectedNode(currentObjectif[0]._objectifPedagogique._URI);
              setSelectedTeachingUnit(selectedTU[0]);
            }
          } else {
            setSelectedTeachingUnit(teachingUnits[0]);
          }
        }

      } catch (e) {
        setFetchState("error");
      }
    };

    fetchStudentAndCourse();
  }, [setActivityType, setGraphData, setOpenNodes, studentId, useApi]);


  useEffect(() => {

    const fetchData = async () => {

      if(!student || !student.isSync){
          setFetchState("build");
          return true;
      }

      if (teachingUnit && student) {
        setFetchState("loading");

        // reset activities filters
        setActivityType(activityTypesOptions[0]);
        setActivityProgressType(activityProgressOptions[0]);
        setActivityDurationFilter(activityDurationOptionsDefault[0]);
        try {

          const [
            objectives,
            activities,
            studentObjectives,
            studentActivities,
            stats,
          ] = await Promise.all([
            ApiService.getAllObjectives(teachingUnit._URI),
            ApiService.getAllActivitiesFromUE(teachingUnit._URI),
            ApiService.getAllStudentObjectives(teachingUnit._URI, student.id),
            ApiService.getAllStudentActivities(teachingUnit._URI, student.id),
            ApiService.getObjectiveStatistics(teachingUnit._URI)
          ]);

          if(selectedNodeID == undefined) {
            const currentObjectif = await ApiService.getCurrentObjective(student.id,teachingUnit._URI);
            if(currentObjectif.length > 0){
              selectedNode(currentObjectif[0]._objectifPedagogique._URI);
            } else {
              var o = objectives.filter( x => {
                    var cond = Array.isArray(x._aCombinaisonConditions._aConditionObtention) ?
                        x._aCombinaisonConditions._aConditionObtention : [x._aCombinaisonConditions._aConditionObtention];
                    return cond.filter( (c:any) => c._necessiteAcquisitionObjectifPedagogique != undefined).length == 0
                  }
              );
              selectedNode(o.length == 0 ? undefined : o[0]._URI);
            }
          }

          setDataSync({
            objectives,
            activities,
            studentActivities : studentActivities.filter(
                (s: any) => !!s._activitePedagogiqueUtilisee
            ),
            studentObjectives : studentObjectives.filter((s: any) => !!s._objectifPedagogique),
            oBjStats: stats,
          });

          var defaultObjectif: string | undefined = undefined;
          if(selectedNodeID == undefined && objectives !== null){
            var o = objectives.filter( x => {
                  var cond = Array.isArray(x._aCombinaisonConditions._aConditionObtention) ?
                      x._aCombinaisonConditions._aConditionObtention : [x._aCombinaisonConditions._aConditionObtention];
                  return cond.filter( (c:any) => c._necessiteAcquisitionObjectifPedagogique != undefined).length == 0
                }
            );
            defaultObjectif = o.length == 0 ? undefined : o[0]._URI;
          }

          const extracted = jsonToForceGraph(
            objectives,
            activities,
            studentActivities.filter(
              (s: any) => !!s._activitePedagogiqueUtilisee
            ),
            studentObjectives.filter((s: any) => !!s._objectifPedagogique),
            student,
            stats,
            {
                activityDuration: activityDuration,
                activityProgress: activityProgress.status,
                activityTpe: activityType.type
            },
            selectedLayers,
            selectedNodeID
          );

          setGraphData(Object.create(extracted));

          const openNodes = studentObjectives
            /*.filter(
              (so: any) =>
                so._statutEtudiantPourObjectif !== OBJECTIVE_NOT_STARTED &&
                so._statutEtudiantPourObjectif !== OBJECTIVE_FINISHED
            )*/
            .map((so: any) => so._objectifPedagogique._URI);

          setOpenNodes(openNodes);

          setFetchState("done");
        } catch (e) {
          setFetchState("error");
        }
      }
    };
    if (teachingUnit && student) {
      fetchData();
    }
  }, [
    teachingUnit,
    setActivityType,
    setGraphData,
    setOpenNodes,
    studentId,
    useApi,
    student,
    setActivityProgressType,
    setActivityDurationFilter,
  ]);

  return (
    <>
      {fetchState === "loading" && (
        <div className="loader">
          <Spinner> </Spinner>
        </div>
      )}
      {fetchState === "done" && <PositionSimulation />}
      {fetchState === "build" && (
          <div className="error">
            <h2>La construction de votre graphe de connaissances est en cours...</h2>
            <h3>Veuillez patienter quelques instants.</h3>
          </div>
      )}
      {fetchState === "error" && (
        <div className="error">
          <h2>Une erreur est survenue lors du chargement des données</h2>
        </div>
      )}
    </>
  );
}

export default ApiLoader;
