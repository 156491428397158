import "./NotificationPanel.css";
import { useRecoilState } from "recoil";
import { toggleModalNotificationAtom } from "../../../../recoil/atoms";
<link 
  href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap" 
  rel="stylesheet"
/>;

function NotificationPanel() {
  const [isModalNotificationOpen, setModalNotificationOpen] = useRecoilState(
    toggleModalNotificationAtom
  );

  return (
    <div className={`notification-modal ${isModalNotificationOpen ? "notification-open" : ""}`}>
      <div className="notification-header">
        <h3 className="notification-title">Notifications</h3>
        <span 
          className="notification-close" 
          onClick={() => setModalNotificationOpen(false)}
        >
          <svg 
            width={20} 
            height={20} 
            viewBox="0 0 50 50"
          >
            <path
              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
              stroke="rgba(0, 0, 0, 0.36)"
              fill="rgba(0, 0, 0, 0.36)"
              strokeWidth={7}
            />
          </svg>
        </span>
      </div>
      <div className="notification-body">

      </div>
    </div>
  );
}

export default NotificationPanel;