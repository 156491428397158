import { matchPath, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import * as React from 'react'
import { useRecoilState } from "recoil";
import "./Breadcrumb.css"
import { selectedTeachingUnitAtom } from "../../../../recoil/graphFilters";
import { rolesAtom } from "../../../../recoil/atoms";

const Breadcrumb = () => {

    const [selectedTeachingUnit, setSelectedTeachingUnit] = useRecoilState(
        selectedTeachingUnitAtom
    );
    const [rolesUser,] = useRecoilState(rolesAtom);
    const location: string = useLocation().pathname;
    let { studentId } = useParams<{ studentId: string }>();

    return (
        <>
            {!matchPath(location, { path: ["/unites-enseignement/:teachingUnitTitle/graphe"], exact: true }) &&
                <nav className="admin-breadcrumb">
                    <div>
                        <Link to={"/"} onClick={() => setSelectedTeachingUnit(undefined)}>Accueil</Link>&nbsp;
                    </div>
                    {matchPath(location, { path: ["/unites-enseignement"], exact: true }) &&
                        <div>
                            <span>Unités d'enseignement</span>&nbsp;
                        </div>
                    }
                    {matchPath(location, { path: ["/etudiants"], exact: true }) &&
                        <div>
                            <span>Étudiants</span>&nbsp;
                        </div>
                    }
                    {matchPath(location, { path: ["/unites-enseignement/:teachingUnitTitle"], exact: true }) &&
                        <>
                            {rolesUser.includes("admin") &&
                                <div>
                                    <Link to="/unites-enseignement" onClick={() => setSelectedTeachingUnit(undefined)}>Unités d'enseignement</Link>&nbsp;
                                </div>
                            }
                            <div>
                                <span>{selectedTeachingUnit?._nom}</span>&nbsp;
                            </div>
                        </>
                    }
                    {matchPath(location, { path: ["/etudiants/:studentId/profil"], exact: true }) &&
                        <>
                            <div>
                                <Link to="/etudiants">Étudiants</Link>&nbsp;
                            </div>
                            <div>
                                <span>{studentId}</span>&nbsp;
                            </div>
                            <div>
                                <span>profil</span>&nbsp;
                            </div>
                        </>
                    }
                    {matchPath(location, { path: ["/configs"], exact: true }) &&
                        <>
                            <div>
                                <span>Configurations</span>&nbsp;
                            </div>
                        </>
                    }
                </nav>
            }
        </>
    );
}

export default Breadcrumb;