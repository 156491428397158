import * as React from 'react'
import HelpPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import AdminMenu from "../../../PageElement/AdminMenu/AdminMenu";
import IconEdit from "../../../../icons/SVGIconEdit";
import { ApiService } from "../../../../LoginHandler/restServices";
import { useEffect, useState } from "react";
import IconEye from "../../../../icons/SVGIconEye";
import { Link } from "react-router-dom";
import Sidebar from "../../../PageElement/Sidebar/Sidebar";
import IEtudiant from "../../../../../interface/Etudiant.interface";
import Breadcrumb from "../../../PageElement/Breadcrumb/Breadcrumb";
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Spinner } from "reactstrap";
import { useRecoilState } from "recoil";
import { rolesAtom, studentsList } from "../../../../../recoil/atoms";
import { teacherTeachingUnitsAtom } from "../../../../../recoil/graphFilters";
import IRecommandation from '../../../../../interface/Recommandation.interface';

function StudentsIndex() {

    const [dataIsLoad, setDataIsLoad] = useState<boolean>(false);
    const [students, setStudents] = useRecoilState(studentsList);
    const [allTeachingUnits] = useRecoilState(
        teacherTeachingUnitsAtom
    );
    const [rolesUser] = useRecoilState(rolesAtom);
    const [recommendations, setRecommendations] = useState<IRecommandation[]>();

    useEffect(() => {
        if (students.length == 0) {

            (async function loadStudentsAndRecommendations() {

                var allStudents: IEtudiant | IEtudiant[] = [];

                // Récupération des étudiants
                if (rolesUser.includes("admin")) {
                    allStudents = await ApiService.getAllStudent();
                    setStudents((Array.isArray(allStudents) ? allStudents : [allStudents]).filter(x =>
                        x.URI != "https://data.dauphine.psl.eu/resource/etudiant/craftai" &&
                        x.idPassport != undefined

                    ));
                    setDataIsLoad(true);

                } else {
                    allStudents = await ApiService.getStudentByCourse(allTeachingUnits.map(x => x._URI));
                    var moodleStudents = (Array.isArray(allStudents) ? allStudents : [allStudents]).filter(x =>
                        x.URI != "https://data.dauphine.psl.eu/resource/etudiant/craftai" &&
                        x.idPassport != undefined
                    )
                    setStudents(moodleStudents);
                    setDataIsLoad(true);
                }

                // Récupération des recommandations sauf celles du jour
                let allRecommendations = await ApiService.getLastStudentsRecommendations(Array.isArray(allStudents) ? allStudents : [allStudents]);
                setRecommendations(allRecommendations);

            })()

        } else {
            setDataIsLoad(true);
        }
    }, [allTeachingUnits]);

    const paginationComponentOptions = {
        rowsPerPageText: 'Lignes par page :',
        rangeSeparatorText: 'de',
    };

    const allTeachingUnitsColumns: TableColumn<IEtudiant>[] = [
        {
            id: 1,
            name: 'Nom',
            selector: e => e.nom != undefined && e.nom,
            sortable: true,
            grow: 1,
        },
        {
            id: 2,
            name: 'Prénom',
            selector: e => e.prenom != undefined && e.prenom,
            sortable: true,
            grow: 2,
        },
        {
            id: 3,
            name: 'Dernière connexion',
            selector: e => e.derniereConnexion != undefined &&
                new Date(e.derniereConnexion).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
            sortable: true,
            grow: 1,
        },
        {
            id: 4,
            name: 'Dernière recommandation',
            selector: e => e.URI != undefined && recommendations?.find(recommendation => recommendation.recommandationPourEtudiant.URI == e.URI) != undefined &&
                new Date(recommendations.find(recommendation => recommendation.recommandationPourEtudiant.URI == e.URI)!.dateCreation)
                .toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
            sortable: true,
            grow: 1,
        },
        {
            id: 5,
            name: 'Graphe',
            button: true,
            width: "140px",
            cell: (e) => (true == true &&
                <>
                    <Link to={`/etudiants/${e.idPassport}/graphe`}
                        className="teaching-units-index-link"
                        target={"_blank"}
                    >
                        <IconEye fill={"#6371B0"} />
                    </Link>
                </>
            )
        },
        {
            id: 6,
            name: 'Profil',
            button: true,
            width: "140px",
            cell: (e) => (true == true &&
                <>
                    <Link to={`/etudiants/${e.idPassport}/profil`}
                        className="teaching-units-index-link"
                        onClick={() => { }}
                    >
                        <IconEdit />
                    </Link>
                </>
            )
        },
        {
            width: "0px",
        }
    ];

    return (
        <div className="admin">
            <Sidebar />
            <div className="admin-body">
                <div className="admin-content">
                    <Breadcrumb />
                    <AdminMenu />
                    <div className="teaching-units-index">
                        <div className="teaching-units-index-title">Etudiants</div>

                        <div className="teaching-units-index-table">
                            {dataIsLoad ?
                                <DataTableExtensions
                                    columns={allTeachingUnitsColumns}
                                    data={students}
                                    print={false}
                                    export={false}
                                    responsive
                                    filterPlaceholder={'Recherche...'}
                                >
                                    <DataTable
                                        title={'Liste des étudiants'}
                                        noDataComponent={"Pas de données à afficher"}
                                        columns={allTeachingUnitsColumns}
                                        data={students}
                                        pagination
                                        paginationPerPage={15}
                                        paginationComponentOptions={paginationComponentOptions}
                                        responsive
                                        highlightOnHover
                                        striped
                                    />
                                </DataTableExtensions> :
                                <div className="teaching-units-index-loader loader">
                                    <Spinner> </Spinner>
                                </div>
                            }
                        </div>

                    </div>
                    <HelpPanel />
                    <AccountPanel />
                    <NotificationPanel />
                </div>
            </div>
        </div>

    );
};

export default StudentsIndex;
