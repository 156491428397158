export const SVGElearning = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
     width="30" height="30" viewBox="0 0 30 30" className="svg-node-icon">
    <style type="text/css">
      .st0{}
      .st1{}
    </style>
    <g>
      <path stroke-width="2" stroke-linejoin="round" d="M5.9,14.2v6.5c0,0,1.9,3.9,8.3,3.9s8.3-3.9,8.3-3.9v-6.5"/>
      <polygon stroke-width="2" stroke-linejoin="round" points="27.1,11.5 14.3,17.5 1.4,11.5 14.3,5.4 	"/>
      <line fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="14.3" y1="9.8" x2="14.3" y2="13.1"/>
      <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="14.3,11.5 27.1,11.5 27.1,16.9 	"/>
      <polyline fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="28.6,19.7 27.1,16.9 25.7,19.7 	"/>
    </g>
    </svg>
  );
};
