import React, { useEffect, useRef, useState} from "react";
import "./TeachingUnitEdit.css";
import { useRecoilState } from "recoil";
import {
  selectedTeachingUnitAtom,
  selectedObjectiveAtom,
  selectedTeachingUnitObjectivesAtom,
} from "../../../../recoil/graphFilters";
import { ApiService } from "../../../LoginHandler/restServices";
import { TeachingUnit } from "../../../../interface/teachingUnits";
import {
    SettingPopUp,
    toggleActivityEditModalAtom,
    toggleObjectiveEditModalAtom,
    toggleTeachingUnitModeLiveAtom,
    userAtom
} from "../../../../recoil/atoms";
import ObjectiveEditPanel from "./ObjectiveEditPanel/ObjectiveEditPanel";
import TeachingUnitEditObjectiveList from "./TeachingUnitEditObjectiveList";

import HelpPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import AdminMenu from "../../PageElement/AdminMenu/AdminMenu";
import Breadcrumb from "../../PageElement/Breadcrumb/Breadcrumb";
import Sidebar from "../../PageElement/Sidebar/Sidebar";
import {useParams} from "react-router-dom";
import PopUp from "../../PageElement/PopUp/PopUp";
import {
    ConfigContextMenu,
    EyeContextMenu,
    TeachingUnitDraftMenu
} from "../../PageElement/AdminMenu/ContextMenu";
import {Spinner} from "reactstrap";


function TeachingUnitEdit() {

  const [selectedTeachingUnit, setSelectedTeachingUnit] = useRecoilState(
    selectedTeachingUnitAtom
  );
  const [objectiveAtom,] = useRecoilState(
    selectedObjectiveAtom
  );
  const [settingPopUp,setSettingPopUp] = useRecoilState(
      SettingPopUp
  );
  const [teachingUnitModeLive,setTeachingUnitModeLive] = useRecoilState(
    toggleTeachingUnitModeLiveAtom
  );
  const [teachingUnitObjectives, ] = useRecoilState(
    selectedTeachingUnitObjectivesAtom
  );

  const [objectiveEditModalOpen, setObjectiveEditModalOpen] =
    useRecoilState(toggleObjectiveEditModalAtom);
  const [activityEditModalOpen, setActivityEditModalOpen] =
    useRecoilState(toggleActivityEditModalAtom);
  const [inReinitialisation, setInReinitialisation] = useState<boolean>(false);
  const { teachingUnitTitle } = useParams<{ teachingUnitTitle: string }>();
  const [user] = useRecoilState(userAtom);

  const [estContribuable, setEstContribuable] = useState<boolean>(false);
  const [delaiDeQuarantaineDesActivitesConsultees, setDelaiDeQuarantaineDesActivitesConsultees] = useState<number>(1);
  const [delaiDeQuarantaineDesActivitesProposees, setDelaiDeQuarantaineDesActivitesProposees] = useState<number>(1);
  const [nombreDeRecommandationParDefault, setNombreDeRecommandationParDefault] = useState<number>(1);


  // Récupération du nom de l'unité d'enseignement si ce dernier n'est pas défini
  // Redirection vers la page d'accueil si aucun match
  useEffect(() => {
    if (!selectedTeachingUnit ||
        selectedTeachingUnit._nom!.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") != teachingUnitTitle) {
      // Récupération des graph existants puis filtrage pour obtenir celui de l'url
      ApiService.getTeachingUnits().then(teachingUnits => {
        var currentTeachingUnit: TeachingUnit = teachingUnits.find(teachingUnit => 
          teachingUnit._nom && teachingUnit._nom.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") == teachingUnitTitle
        );
        if (currentTeachingUnit != undefined) {
          setSelectedTeachingUnit(currentTeachingUnit);

          setEstContribuable(currentTeachingUnit._contributionActive == undefined ?
              true
              :
              currentTeachingUnit._contributionActive.toString() == "true" ? true : false)

          setDelaiDeQuarantaineDesActivitesConsultees(currentTeachingUnit._delaiDeQuarantaineDesActivitesConsultees!);
          setDelaiDeQuarantaineDesActivitesProposees(currentTeachingUnit._delaiDeQuarantaineDesActivitesProposees!);
          setNombreDeRecommandationParDefault(currentTeachingUnit._nombreDeRecommandationParDefault!);

        } else {
          window.location.replace("/");
        }
      });
    } else {
        setEstContribuable(selectedTeachingUnit._contributionActive == undefined ?
            true
            :
            selectedTeachingUnit._contributionActive.toString() == "true" ? true : false)

        setDelaiDeQuarantaineDesActivitesConsultees(selectedTeachingUnit._delaiDeQuarantaineDesActivitesConsultees!);
        setDelaiDeQuarantaineDesActivitesProposees(selectedTeachingUnit._delaiDeQuarantaineDesActivitesProposees!);
        setNombreDeRecommandationParDefault(selectedTeachingUnit._nombreDeRecommandationParDefault!);
    }
  }, [selectedTeachingUnit]);

  /**
  * La Hook suivante s'exécute quand un click est fait en dehors du ref en paramètre
  */
  function useOutsideAlerterObjectivePanel(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && objectiveEditModalOpen) {
          activityEditModalOpen ? setActivityEditModalOpen(false) : setObjectiveEditModalOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [objectiveEditModalOpen, activityEditModalOpen]);
  }

  const objectiveEditPanelModalRef = useRef(null);
  useOutsideAlerterObjectivePanel(objectiveEditPanelModalRef);

  const checkErrorGraph = (): {code:string,context:any}[] => {

      var errors : {code:string,context:any}[] = [];

      teachingUnitObjectives.filter( obj => obj._aCombinaisonConditions == undefined || obj._aCombinaisonConditions._aConditionObtention == undefined).forEach(obj => {
          errors.push({code:"EMPTY_CONDITION_COMBINAISON",context:obj});
      });

      var conditions_circulaire = teachingUnitObjectives.map( obj => (
          checkConditionCirculaire(obj,teachingUnitObjectives)
      ));

      conditions_circulaire.filter(x => x.length != 0).forEach(obj => {
          errors.push({code:"CONDITION_CIRCULAIRE",context:obj});
      });
      
      if(errors.length != 0){
        setSettingPopUp({
          isOpen : true,
          message : "Des erreurs sont présentes dans le graphe",
          html :
              <ul className="errorList">
                  {
                      errors.map( error => {
                          switch (error.code) {
                              case "EMPTY_CONDITION_COMBINAISON":
                                  return <li className="error">L'objectif {error.context._titreCourt} ne contient aucune condition de validation.</li>;
                              case "CONDITION_CIRCULAIRE":
                                  return(
                                    <li className="error">
                                        L'objectif {error.context[0][0]._titreCourt} contient une condition de validation circulaire.
                                        <ol>
                                        {error.context
                                            .map( x => {
                                                var i = 1;
                                                return(
                                                    <li>
                                                        {x.map(o => {
                                                            var f = i != x.length ? "➡" : "";
                                                            i++;
                                                            return (
                                                                <i> {o._titreCourt} {f} </i>)
                                                        })
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                        </ol>
                                        <br/>
                                    </li>
                                  )
                          }
                      })
                  }
              </ul>,
          arrBtn: [
              {
                  type:"primary",
                  text:"Ok",
                  action: () => {
                  }
              }
          ],
      });
      }
      
      return errors;
  };

  const checkConditionCirculaire = (
      objToCheck,
      objectivesList,
      objChecked : any[] = [],
      objToAnalyse?,
  ) : string[][] => {

      if(objChecked.length == 0){
          objChecked.push(objToCheck);
      }

      if(objToAnalyse == undefined){
          objToAnalyse = objToCheck;
      }

      var arrObjUri : any[] = [];

      if (
          objToCheck._aCombinaisonConditions != undefined
          && objToCheck._aCombinaisonConditions._aConditionObtention != undefined
      ){

          var conditions = Array.isArray(objToCheck._aCombinaisonConditions._aConditionObtention) ?
              objToCheck._aCombinaisonConditions._aConditionObtention
              :
              [objToCheck._aCombinaisonConditions._aConditionObtention];

          conditions.filter( c => c._necessiteAcquisitionObjectifPedagogique != undefined).forEach( condition => {

              var path = objChecked.concat(objectivesList.find(x => x._estUneVersionDe._URI == condition._necessiteAcquisitionObjectifPedagogique._URI)!);

              if(
                  objToAnalyse._estUneVersionDe._URI != condition._necessiteAcquisitionObjectifPedagogique._URI
              ){
                  if( objChecked.find(x => x._estUneVersionDe._URI == condition._necessiteAcquisitionObjectifPedagogique._URI) == undefined){
                      var c = checkConditionCirculaire(
                          objectivesList.find(x => x._estUneVersionDe._URI == condition._necessiteAcquisitionObjectifPedagogique._URI)!,
                          objectivesList,
                          path,
                          objToAnalyse
                      );

                      if(c.length != 0){
                          arrObjUri = arrObjUri.concat(c);
                      }
                  }
              } else {
                  arrObjUri.push(path);
              }
          });
      }
      return arrObjUri;
  };



  return (
    <div className="admin">
      <Sidebar />
      <div className="admin-body">
        <div className="admin-content">
          {settingPopUp.isOpen &&
          <PopUp
              config={settingPopUp}
          />
          }
          <Breadcrumb />
          <AdminMenu  ContextMenu={
            <>
              <TeachingUnitDraftMenu />
              {selectedTeachingUnit && 
                <EyeContextMenu 
                  link={"/unites-enseignement/" + selectedTeachingUnit._nom!.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "/graphe"}
                  callBack={(e) => {
                    var errors = checkErrorGraph();
                    if (errors.length != 0) {
                      e.preventDefault();
                    }
                  }}
                />
              }
              <ConfigContextMenu
                  configPanelContent={
                      {
                      groupPanel : [
                          {
                              inputs: [
                                  {
                                      type:"link",
                                      text:"Analyser le graphe",
                                      description:"Vérifie si des erreurs sont présentes dans le graphe",
                                      callback : () => {
                                          var errors = checkErrorGraph();
                                          if(errors.length == 0){
                                              setSettingPopUp({
                                                  isOpen : true,
                                                  message : "Aucune erreur dans le graphe",
                                                  arrBtn: [
                                                      {
                                                          type:"primary",
                                                          text:"Ok",
                                                          action: () => {
                                                          }
                                                      }
                                                  ],
                                              });
                                          } 
                                      }
                                  }
                              ],
                          },
                          {
                              inputs: [
                                  {
                                      type:"link",
                                      text:"Désactiver l'unité d'enseignement",
                                      role: "admin",
                                      callback : () => {
                                          setSettingPopUp({
                                              isOpen : true,
                                              message : "Confirmer la désactivation de l'unité d'enseignement",
                                              arrBtn: [
                                                  {
                                                      type:"secondary",
                                                      text:"Confirmer",
                                                      action: () => {
                                                          ApiService.deactivateTeachingUnit(selectedTeachingUnit!).then(oldTeachingUnit => {
                                                              window.location.href = "/unites-enseignement";
                                                          });
                                                      }
                                                  },
                                                  {
                                                      type:"primary",
                                                      text:"Annuler",
                                                      action: () => { }
                                                  }
                                              ],
                                          });
                                      }
                                  }
                              ],
                          },
                          {
                              inputs: [
                                  {
                                      type:"link",
                                      text:"Réinitialiser la version de draft",
                                      callback : () => {
                                          setSettingPopUp({
                                              isOpen : true,
                                              message : "Confirmer la réinitialisation de la version de draft",
                                              arrBtn: [
                                                  {
                                                      type:"secondary",
                                                      text:"Confirmer",
                                                      action: () => {
                                                          setInReinitialisation(true);
                                                          ApiService.deleteGraphDraftVersion(selectedTeachingUnit!._URI).then(() => {
                                                                 setInReinitialisation(false);
                                                              }
                                                          )
                                                      }
                                                  },
                                                  {
                                                      type:"primary",
                                                      text:"Annuler",
                                                      action: () => { }
                                                  }
                                              ],
                                          });
                                      }
                                  }
                              ],
                          },
                          {
                              inputs: [
                                  {
                                      role:"admin",
                                      type:"link",
                                      text:"Mettre en ligne la version de draft",
                                      callback : () => {
                                          var errors = checkErrorGraph();
                                          if(errors.length == 0){
                                              setSettingPopUp({
                                                  isOpen : true,
                                                  message : "Confirmer la mise en ligne de la version de draft",
                                                  arrBtn: [
                                                      {
                                                          type:"secondary",
                                                          text:"Confirmer",
                                                          action: () => {

                                                              setInReinitialisation(true);

                                                              setSettingPopUp({
                                                                  isOpen : true,
                                                                  message : "Mise en ligne du graphe, veuillez patienter...",
                                                                  html: <Spinner> </Spinner>,
                                                                  arrBtn: [],
                                                              });

                                                              ApiService.uploadGraphDraftVersion(selectedTeachingUnit!._URI).then(() => {
                                                                      setTeachingUnitModeLive(true);
                                                                      setInReinitialisation(false);
                                                                      setSettingPopUp({
                                                                          isOpen : false,
                                                                          message : "Mise en ligne du graphe, veuillez patienter...",
                                                                          html: <Spinner> </Spinner>,
                                                                          arrBtn: [],
                                                                      });
                                                                  }
                                                              )
                                                          }
                                                      },
                                                      {
                                                          type:"primary",
                                                          text:"Annuler",
                                                          action: () => { }
                                                      }
                                                  ],
                                              });
                                          }
                                      }
                                  }
                              ],
                          },
                          {
                              inputs: [
                                  {
                                      role:"enseignant",
                                      type:"link",
                                      text:"Demander la mise en ligne de la version de draft",
                                      callback : () => {
                                          setSettingPopUp({
                                              isOpen : true,
                                              message : "Confirmer la demande de mise en ligne",
                                              arrBtn: [
                                                  {
                                                      type:"secondary",
                                                      text:"Confirmer",
                                                      action: () => {
                                                          ApiService.sendEmailToAdmin(
                                                              "Demande de mise en ligne du graphe "+selectedTeachingUnit!._nom,
                                                              "Un(e) enseignant(e) demande la mise en ligne du graphe : "+selectedTeachingUnit!._nom+"\r\n\r\n" +
                                                              "Pour effectuer la mise en ligne du graphe cliquer sur le lien suivant puis utiliser la fonctionnalité de mise en ligne. \r\n\r\n" +
                                                              "Lien : "+process.env.REACT_APP_ADDRESS+"/unites-enseignement/"+selectedTeachingUnit!._nom!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")+" "+
                                                              "\r\n\r\nMerci.",
                                                              user!._prenom!,
                                                              user!._nom!,
                                                              user!._email!
                                                          )
                                                      }
                                                  },
                                                  {
                                                      type:"primary",
                                                      text:"Annuler",
                                                      action: () => { }
                                                  }
                                              ],
                                          });

                                      }
                                  }
                              ],
                          },
                          {
                              title: "Configuration proposition étudiante",
                              inputs: [
                                  {
                                      type:"radio",
                                      text:"Les étudiants peuvent proposer des activités",
                                      value:estContribuable,
                                      callback : () => {
                                        ApiService.editTeachingUnit(
                                            {
                                                _URI: selectedTeachingUnit!._URI,
                                                _contributionActive: !estContribuable,
                                            }
                                        )
                                        setEstContribuable(!estContribuable);
                                      }
                                  }
                              ],
                          },
                          {
                              title: "Configuration recommandée",
                              inputs: [
                                  {
                                      type:"number",
                                      text:"Délai de quarantaine des activités consultées (en heures)",
                                      callback : (e) => {
                                          setDelaiDeQuarantaineDesActivitesConsultees(e.target.value);
                                          ApiService.editTeachingUnit(
                                              {
                                                  _URI: selectedTeachingUnit!._URI,
                                                  _delaiDeQuarantaineDesActivitesConsultees: e.target.value,
                                              }
                                          )
                                      },
                                      value:delaiDeQuarantaineDesActivitesConsultees
                                  },
                                  {
                                      type:"number",
                                      text:"Délai de quarantaine des activités proposées (en heures)",
                                      callback : (e) => {
                                          setDelaiDeQuarantaineDesActivitesProposees(e.target.value);
                                          ApiService.editTeachingUnit(
                                              {
                                                  _URI: selectedTeachingUnit!._URI,
                                                  _delaiDeQuarantaineDesActivitesProposees: e.target.value,
                                              }
                                          )
                                      },
                                      value: delaiDeQuarantaineDesActivitesProposees
                                  },
                                  {
                                      type:"number",
                                      text:"Nombre de recommandation par défaut",
                                      callback : (e) => {
                                          setNombreDeRecommandationParDefault(e.target.value);
                                          ApiService.editTeachingUnit(
                                              {
                                                  _URI: selectedTeachingUnit!._URI,
                                                  _nombreDeRecommandationParDefault: e.target.value,
                                              }
                                          )
                                      },
                                      value: nombreDeRecommandationParDefault
                                  }
                              ],
                          },
                      ]
                      }
                  }
              />
            </>
          }/>

          <>
            <div className="teaching-units-index">
              <h1 className="teaching-unit-edit-title">{selectedTeachingUnit?._nom}</h1>
              {
                teachingUnitModeLive &&
                <div className="teaching-unit-edit-warning-message">
                  Attention, les modifications faites en mode live ne sont pas reportées en mode draft
                </div>
              }
              <TeachingUnitEditObjectiveList 
                inReinitialisation={inReinitialisation}
              />
            </div>
            <div ref={objectiveEditPanelModalRef}>
              <ObjectiveEditPanel 
                objectiveEditModalOpen={objectiveEditModalOpen} 
                selectedObjective={objectiveAtom} 
              />
            </div>
          </>
          <HelpPanel />
          <AccountPanel />
          <NotificationPanel />
        </div>
      </div>
    </div>
  );
};

export default TeachingUnitEdit;
