import "./HelpPanel.css";
import { useRecoilState } from "recoil";
import {
  toggleModalHelpAtom,
  toggleModalHelpLegendsAtom,
  toggleModalHelpObjectivesAtom,
  toggleModalHelpActivitiesAtom,
  toggleModalHelpContributionsAtom,
} from "../../../../recoil/atoms";
import HelpLegends from "./HelpSections/HelpLegends";
import HelpObjectives from "./HelpSections/HelpObjectives";
import HelpActivities from "./HelpSections/HelpActivities";
import HelpContributions from "./HelpSections/HelpContributions";
<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function HelpPanel() {
  const [isModalHelpOpen, setModalHelpOpen] =
    useRecoilState(toggleModalHelpAtom);
  const [, setModalHelpLegendsOpen] = useRecoilState(
    toggleModalHelpLegendsAtom
  );
  const [, setModalHelpObjectivesOpen] = useRecoilState(
    toggleModalHelpObjectivesAtom
  );
  const [, setModalHelpActivitiesOpen] = useRecoilState(
    toggleModalHelpActivitiesAtom
  );
  const [, setModalHelpContributionsOpen] = useRecoilState(
    toggleModalHelpContributionsAtom
  );

  return (
    <div>
      <HelpLegends />
      <HelpObjectives />
      <HelpActivities />
      <HelpContributions />
      <div className={`help-modal ${isModalHelpOpen ? "help-open" : ""}`}>
        <div className="help-header">
          <div className="help-title">Aide</div>
          <span className="help-close" onClick={() => setModalHelpOpen(false)}>
            <svg width={20} height={20} viewBox="0 0 50 50">
              <path
                d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
                stroke="rgba(0, 0, 0, 0.36)"
                fill="rgba(0, 0, 0, 0.36)"
                strokeWidth={7}
              />
            </svg>
          </span>
        </div>
        <div className="help-body">
          <div
            className="help-section"
            onClick={() => setModalHelpLegendsOpen(true)}
          >
            <div>Légende du graphe</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div
            className="help-section"
            onClick={() => setModalHelpObjectivesOpen(true)}
          >
            <div>Objectifs pédagogiques</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div
            className="help-section"
            onClick={() => setModalHelpActivitiesOpen(true)}
          >
            <div>Activités</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div
            className="help-section"
            onClick={() => setModalHelpContributionsOpen(true)}
          >
            <div>Contributions</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpPanel;
