import React from "react";
import "./NavigationMenu.css";

import FoxLogo from "../../../images/fox.png";
import { useRecoilState } from "recoil";
import { toggleModalCourseListAtom, rolesAtom, selectedNodeIDAtom } from "../../../recoil/atoms";
import {
  selectedTeachingUnitAtom,
  teachingUnitsAtom,
} from "../../../recoil/graphFilters";
<link
  href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
  rel="stylesheet"
/>;

function NavigationMenu() {
  const [isModalCourseListOpen, setModalCourseListOpen] = useRecoilState(
    toggleModalCourseListAtom
  );
  const [selectedNodeID, selectedNode] = useRecoilState<string | undefined>(
      selectedNodeIDAtom
  );
  const [teachingUnitsOption] = useRecoilState(teachingUnitsAtom);
  const [selectedTeachingUnit, setSelectedTeachingUnit] = useRecoilState(
    selectedTeachingUnitAtom
  );
  const [rolesUser, ] = useRecoilState(rolesAtom);

  return (

    <div className={`navigationMenu-content ${rolesUser.includes("etudiant") ? "navigationMenu-content-fully-opened" : ".navigationMenu-content-partially-opened"}`}>
      {rolesUser.includes("etudiant") &&
        <div className="navigationMenu-logo">
          <img className="navigationMenu-image" alt="navigationMenu" src={FoxLogo} />
          <div className="navigationMenu-title">Tuteur Virtuel</div>
        </div>
      }
      <div className="navigationMenu-display">
        <div className="navigationMenu-course">Cours :</div>
        <div>
          <div
            className="navigationMenu-list"
            onClick={() => {
              isModalCourseListOpen
                ? setModalCourseListOpen(false)
                : setModalCourseListOpen(true);
            }}
          >
            <div className="navigationMenu-list-title">
              {selectedTeachingUnit?._nom}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              className="bi-chevron-down"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div
            className={`courseList-modal ${
              isModalCourseListOpen ? "courseList-open" : ""
            }`}
            onClick={() => setModalCourseListOpen(false)}
          >
            <div className="courseList-content">
              {teachingUnitsOption.map((t) => (
                <div
                  key={t._nom}
                  className="navigationMenuSelect"
                  onClick={() => {
                    selectedNode(undefined);
                    setSelectedTeachingUnit(t);
                  }}
                >
                  {t._nom}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationMenu;
