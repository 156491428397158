import React from "react";

const CertifiedSvg = () => {
  return (
    <svg width="38px" height="40px">
      <g transform={`translate(-3,-3) scale(0.22)`}>
        <path
          fill="#39AEFF"
          d="M79.913,25.033c3.312,-6.369 11.177,-11.269 20.087,-11.269c8.91,0 16.775,4.9 20.087,11.269c6.054,-3.86 15.314,-4.17 23.031,0.285c7.716,4.455 12.078,12.63 11.762,19.802c7.172,-0.316 15.347,4.046 19.802,11.762c4.455,7.717 4.145,16.977 0.285,23.031c6.369,3.312 11.269,11.177 11.269,20.087c-0,8.91 -4.9,16.775 -11.269,20.087c3.86,6.054 4.17,15.314 -0.285,23.031c-4.455,7.716 -12.63,12.078 -19.802,11.762c0.316,7.172 -4.046,15.347 -11.762,19.802c-7.717,4.455 -16.977,4.145 -23.031,0.285c-3.312,6.369 -11.177,11.269 -20.087,11.269c-8.91,-0 -16.775,-4.9 -20.087,-11.269c-6.054,3.86 -15.314,4.17 -23.031,-0.285c-7.716,-4.455 -12.078,-12.63 -11.762,-19.802c-7.172,0.316 -15.347,-4.046 -19.802,-11.762c-4.455,-7.717 -4.145,-16.977 -0.285,-23.031c-6.369,-3.312 -11.269,-11.177 -11.269,-20.087c0,-8.91 4.9,-16.775 11.269,-20.087c-3.86,-6.054 -4.17,-15.314 0.285,-23.031c4.455,-7.716 12.63,-12.078 19.802,-11.762c-0.316,-7.172 4.046,-15.347 11.762,-19.802c7.717,-4.455 16.977,-4.145 23.031,-0.285Zm4.28,88.494l-22.559,-22.559l-13.548,13.548l36.085,36.13l67.743,-67.743l-13.548,-13.549l-54.173,54.173Z"
        />
      </g>
    </svg>
  );
};
export default CertifiedSvg;
