export const SVGCours = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="30" height="30" viewBox="0 0 30 30" className="svg-node-icon">
      <g>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M2,8.9v15.6h26c0-0.6,0-14.9,0-15.6H2z"/>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M18.2,7.3c-1.8,0-3.2,1.5-3.2,3.2c0-1.8-1.5-3.2-3.2-3.2H5.2v14h6.5c1.8,0,3.2,1.5,3.2,3.2c0-0.1,0-0.2,0-0.3
            c0.1-1.7,1.5-3,3.2-3h6.5c0-3.1,0-11.5,0-14H18.2z"/>
        <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="15" y1="10.5" x2="15" y2="23.5"/>
        <g>
          <g>
              <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="18.3" y1="11.1" x2="21.4" y2="11.1"/>
              <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="18.3" y1="17.6" x2="21.4" y2="17.6"/>
              <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="18.3" y1="14.3" x2="21.4" y2="14.3"/>
          </g>
          <g>
              <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="8.6" y1="11.1" x2="11.7" y2="11.1"/>
              <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="8.6" y1="17.6" x2="11.7" y2="17.6"/>
              <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="8.6" y1="14.3" x2="11.7" y2="14.3"/>
          </g>
        </g>
      </g>
    </svg>
  );
};
