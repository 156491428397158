import { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import "./TeachingUnitEdit.css";
import { useRecoilState } from "recoil";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable, { TableColumn } from "react-data-table-component";
import IconEdit from "../../../icons/SVGIconEdit";
import IconDelete from "../../../icons/SVGIconDelete";
import StatutEvenement from "../../../../vocabulary/statutEvenement";
import { ObjectifPedagogique } from "../../../../interface/objectifPedagogique";
import { selectedObjectiveAtom, selectedTeachingUnitAtom, selectedTeachingUnitObjectivesAtom } from "../../../../recoil/graphFilters";
import { SettingPopUp, toggleObjectiveEditModalAtom, toggleTeachingUnitModeLiveAtom } from "../../../../recoil/atoms";
import { ApiService } from "../../../LoginHandler/restServices";

function TeachingUnitEditObjectiveList(props: { inReinitialisation: any }) {

  const [selectedTeachingUnit,] = useRecoilState(
    selectedTeachingUnitAtom
  );
  const [, setObjectiveAtom] = useRecoilState(
    selectedObjectiveAtom
  );
  const [teachingUnitModeLive,] = useRecoilState(
    toggleTeachingUnitModeLiveAtom
  );
  const [teachingUnitObjectives, setTeachingUnitObjectives] = useRecoilState(
    selectedTeachingUnitObjectivesAtom
  );

  const [, setSettingPopUp] = useRecoilState(
    SettingPopUp
  );

  const [objectiveEditModalOpen, setObjectiveEditModalOpen] = useRecoilState(toggleObjectiveEditModalAtom);
  const [currentGraphObjectivesLoad, setCurrentGraphObjectivesLoad] = useState(false);
  const [isCreatingDraftObjectives, setIsCreatingDraftObjectives] = useState<boolean>(false);


  const deleteObjective = (objectifPedagogique) => {
    setSettingPopUp({
      isOpen: true,
      message: "Valider la suppression de l'objectif :" + objectifPedagogique._titreCourt,
      arrBtn: [
        {
          text: "Valider",
          action: () => {
            setTeachingUnitObjectives(teachingUnitObjectives!.filter(x => x._URI != objectifPedagogique._URI).map(o => {
              if (Array.isArray(o._contributionObjectifPourObjectif)) {
                o._contributionObjectifPourObjectif = o._contributionObjectifPourObjectif.filter(contri => contri._pourObjectif._URI != objectifPedagogique._URI);
              } else {
                if (o._contributionObjectifPourObjectif != undefined && o._contributionObjectifPourObjectif._pourObjectif._URI == objectifPedagogique._URI) {
                  o._contributionObjectifPourObjectif = []
                }
              }
              return o;
            }));
            ApiService.removeObjectifPedagogique(objectifPedagogique._URI);
          },
          type: "secondary"
        },
        {
          text: "Annuler",
          action: () => { },
          type: "primary"
        }
      ]
    });
  };


  // Récupération des objectifs de l'unité d'enseignement
  useEffect(() => {
    if (selectedTeachingUnit && objectiveEditModalOpen == false && !props.inReinitialisation) {
      setCurrentGraphObjectivesLoad(false);

      (async function loadObjectives() {

        let objectives = await ApiService.getAllObjectives(selectedTeachingUnit._URI, teachingUnitModeLive ? StatutEvenement.inUse : StatutEvenement.draft);

        if (objectives.length === 0 && !teachingUnitModeLive && !isCreatingDraftObjectives) {

          // Création en mode draft
          console.log("test");
          setIsCreatingDraftObjectives(true);
          let createdDraftObjectives = await ApiService.getObjectifsStatutDraft(selectedTeachingUnit._URI);
          console.log(createdDraftObjectives);
          setTeachingUnitObjectives(createdDraftObjectives);
          setIsCreatingDraftObjectives(false);

        } else {

          // Cas du mode draft avec des objectifs existants OU du mode live
          setTeachingUnitObjectives(objectives);

        };

      })().then(() => {
        setCurrentGraphObjectivesLoad(true);
      });

    }
  }, [props.inReinitialisation, selectedTeachingUnit, objectiveEditModalOpen, teachingUnitModeLive]);

  useEffect(() => {
    if (selectedTeachingUnit && objectiveEditModalOpen == false) {
      if (props.inReinitialisation) {
        setCurrentGraphObjectivesLoad(false);
      }
    }
  }, [props.inReinitialisation])

  // Cellule de modification d'une propriété d'un objectif
  function EditableCell(params: {
    required: boolean,
    objective: ObjectifPedagogique,
    initialValue: any,
    changingProperty: string
  }) {
    const [value, setValue] = useState(params.initialValue);
    const onChange = e => {
      setValue(e.target.value)
    }
    const onBlur = () => {
      if (currentGraphObjectivesLoad) {
        // Test pour tenter d'empêcher le bug de suppression des données occasionnelles
        var objectif: ObjectifPedagogique = {
          _URI: params.objective._URI,
          _estUneVersionDe: params.objective._estUneVersionDe,
        }

        var currentObjectif = teachingUnitObjectives!.find(x => x._URI == params.objective._URI);
        objectif[`${params.changingProperty}`] = value;

        if (
          currentObjectif![`${params.changingProperty}`] != value
          && ((params.required && value != null && value != "") || !params.required)
        ) {
          ApiService.editObjective(objectif).then(editedObj => {
            setCurrentGraphObjectivesLoad(false);
            const editedObjIndex = teachingUnitObjectives!.findIndex(currentGraphObjective => {
              return currentGraphObjective._URI == params.objective._URI
            });
            var copyObjs = [...teachingUnitObjectives];
            copyObjs![editedObjIndex] = editedObj;
            if (copyObjs![editedObjIndex]._dateCreation != undefined) {
              var date = new Date(copyObjs![editedObjIndex]._dateCreation!);
              copyObjs![editedObjIndex]._dateCreation = date;
            }
            setTeachingUnitObjectives(copyObjs);
            setCurrentGraphObjectivesLoad(true);
          });
        } else {
          setValue(currentObjectif![`${params.changingProperty}`]);
        }
      }
    }
    useEffect(() => {
      setValue(params.initialValue)
    }, [params])

    return <input value={value} className="teaching-units-index-editable-cell" onChange={onChange} onBlur={onBlur} />
  }


  const currentGraphObjectivesColumns: TableColumn<ObjectifPedagogique>[] = [
    {
      id: 1,
      name: 'Titre court',
      selector: e => e._titreCourt != undefined && e._titreCourt,
      sortable: true,
      grow: 1,
    },
    {
      id: 2,
      name: 'Titre',
      selector: e => e._titre != undefined && e._titre,
      cell: (e) => (
        <EditableCell required={true} objective={e} initialValue={e._titre} changingProperty={"_titre"} />
      ),
      sortable: true,
      grow: 3,
    },
    {
      width: "0px",
    },
    {
      id: 4,
      name: 'Date de création',
      selector: e => e._dateCreation != undefined &&
        new Date(e._dateCreation).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }),
      sortable: true,
      grow: 1,
    },
    {
      id: 5,
      name: 'Actions',
      button: true,
      width: "80px",
      cell: (e) => (
        <>
          <a className="teaching-units-index-link" onClick={() => { setObjectiveAtom(e); setObjectiveEditModalOpen(true); }}>
            <IconEdit />
          </a>
          {!teachingUnitModeLive && <a className="teaching-units-index-link" onClick={() => { deleteObjective(e); }}>
            <IconDelete />
          </a>}
        </>
      )
    },
    {
      width: "0px",
    }
  ];


  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page :',
    rangeSeparatorText: 'de',
  };


  return (
    <>
      {currentGraphObjectivesLoad ?
        <div className="teaching-units-index-table">
          {!teachingUnitModeLive &&
            <div className="teaching-units-edit-objective-creation">
              <span className="btn btn-primary" onClick={() => { setObjectiveAtom(undefined); setObjectiveEditModalOpen(true); }}>
                <span className="teaching-units-edit-objective-creation-small">+ </span>
                <span className="teaching-units-edit-objective-creation-not-in-small">Créer un </span> objectif
                <span className="teaching-units-edit-objective-creation-big"> pédagogique</span>
              </span>
            </div>
          }
          <DataTableExtensions
            columns={currentGraphObjectivesColumns}
            data={teachingUnitObjectives}
            print={false}
            export={false}
            responsive
            filterPlaceholder={'Recherche...'}
          >
            <DataTable
              title={'Objectifs pédagogiques'}
              noDataComponent={"Pas d'objectif à afficher"}
              columns={currentGraphObjectivesColumns}
              data={teachingUnitObjectives}
              defaultSortFieldId={4}
              defaultSortAsc={false}
              pagination
              paginationPerPage={15}
              paginationComponentOptions={paginationComponentOptions}
              responsive
              highlightOnHover
              striped
            />
          </DataTableExtensions>
        </div>
        :
        <div className="teaching-units-index-loader loader">
          <Spinner> </Spinner>
        </div>
      }
    </>
  );
};

export default TeachingUnitEditObjectiveList;
