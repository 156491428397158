import * as React from 'react'
import TeachingUnitsGraphs from "./TeachingUnitsGraphs";
import HelpPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import AdminMenu from "../../PageElement/AdminMenu/AdminMenu";
import Breadcrumb from "../../PageElement/Breadcrumb/Breadcrumb";
import "./TeachingUnitsIndex.css";
import Sidebar from "../../PageElement/Sidebar/Sidebar";

function TeachingUnitsIndex() {
  return (
      <div className="admin">
          <Sidebar />
          <div className="admin-body">
              <div className="admin-content">
                  <Breadcrumb/>
                  <AdminMenu />
                  <div className="teaching-units-index">
                      <div className="teaching-units-index-title">Unités d'enseignement</div>
                      <TeachingUnitsGraphs />
                  </div>
                  <HelpPanel />
                  <AccountPanel />
                  <NotificationPanel />
              </div>
          </div>
      </div>

  );
};

export default TeachingUnitsIndex;
