import { useState, useEffect } from "react";
import "./ObjectiveEditPanel.css";
import "./ContributoryActivities.css";
import { ActivitePedagogique } from "../../../../../interface/activitePedagogique";
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { ApiService } from "../../../../LoginHandler/restServices";
import { Spinner } from "reactstrap";
import ContributoryActivitiesImport from "./ContributoryActivitiesImport";
import { toggleActivityEditModalAtom, toggleObjectiveEditModalAtom, toggleTeachingUnitModeLiveAtom } from "../../../../../recoil/atoms";
import { useRecoilState } from "recoil";
import { selectedActivityAtom, currentObjectiveActivitiesAtom } from "../../../../../recoil/graphFilters";
import { ConditionObtentionMedia } from "../../../../../interface/conditionObtentionMedia";
import { ConditionObtentionControleConnaissance } from "../../../../../interface/conditionObtentionControleConnaissance";
import IconEdit from "../../../../icons/SVGIconEdit";
import IconSurroundedDownArrow from "../../../../icons/SVGIconSurroundedDownArrow";
import IconFullStar from "../../../../icons/SVGIconFullStar";
import CategorieActivite from "../../../../../vocabulary/categorieActivite";
import IconStudentContribution from "../../../../icons/SVGIconStudentContribution";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ObjectiveActivities(props) {

  const [objectiveEditModalOpen,] = useRecoilState(toggleObjectiveEditModalAtom);
  const [activityAtom, setActivityAtom] = useRecoilState(selectedActivityAtom);
  const [activityEditModalOpen, setActivityEditModalOpen] = useRecoilState(toggleActivityEditModalAtom);
  const [currentObjectiveActivities, setCurrentObjectiveActivities] = useRecoilState(currentObjectiveActivitiesAtom);

  const [currentObjectiveActivitiesLoad, setCurrentObjectiveActivitiesLoad] = useState(false);
  const [currentObjectiveActivitiesMandatory, setCurrentObjectiveActivitiesMandatory] = useState<ActivitePedagogique[]>([]);


  const removeActivity = (activity) => {
    setCurrentObjectiveActivities(currentObjectiveActivities.filter(x => x._URI !== activity._URI));
    ApiService.removeActivityContributionToObjectif(props.selectedObjective._URI, activity._URI)
  };


  // Fonction de tri des activités pour placer celles qui conditionnent en haut du tableau
  const sortActivitiesByCondition = (allActivities: ActivitePedagogique[], conditionActivities: ActivitePedagogique[]) => {

    let sortedActivities: ActivitePedagogique[] = allActivities.sort((a: ActivitePedagogique, b: ActivitePedagogique) => {
      if (conditionActivities.some(activity => { return activity._URI === a._URI })
        && !conditionActivities.some(activity => { return activity._URI === b._URI })) {
        return -1;
      }
      if (!conditionActivities.some(activity => { return activity._URI === a._URI })
        && conditionActivities.some(activity => { return activity._URI === b._URI })) {
        return 1;
      }
      return 0;
    })

    return sortedActivities
  };


  // Récupération des conditions d'obtentions après modification au niveau du volet des activités
  useEffect(() => {

    if (props.isApiConditionObtentionActivitiesFetched) {

      setCurrentObjectiveActivitiesLoad(false);
      // Filtrage des activités pédagogiques conditionnelles pour l'obtention de l'objectif pédagogique
      var mandatoryActivities: Array<ActivitePedagogique> = currentObjectiveActivities.filter(activity =>
        props.conditionObtentionActivities.some(conditonObtention => {
          return conditonObtention._conditionObtentionDeActivitePedagogique._URI === activity._URI;
        })
      );
      setCurrentObjectiveActivitiesMandatory(mandatoryActivities);

      // Tri des activités pédagogiques contributives pour placer les activités conditionnelles en haut du tableau
      if (mandatoryActivities.length !== 0) {
        let sortedObjectivesActivities = sortActivitiesByCondition([...currentObjectiveActivities], mandatoryActivities);
        setCurrentObjectiveActivities(sortedObjectivesActivities);
      }

      setCurrentObjectiveActivitiesLoad(true);
    }
  }, [props.isApiConditionObtentionActivitiesFetched]);


  // Récupération des activités pédagogiques de l'objectif pédagogique sélectionné puis des conditions d'obtention à l'ouverture du volet
  useEffect(() => {

    if (props.selectedObjective !== undefined && objectiveEditModalOpen) {
      setCurrentObjectiveActivitiesLoad(false);
      ApiService.getAllActivitiesFromObjectif(props.selectedObjective._URI, false).then(activities => {

        if (props.selectedObjective._aCombinaisonConditions !== undefined &&
          props.selectedObjective._aCombinaisonConditions._aConditionObtention !== undefined) {

          // Récupération des conditions d'obtention de type activité pédagogique
          var activitiesConditionObtention: (ConditionObtentionMedia | ConditionObtentionControleConnaissance)[] = [];
          if (Array.isArray(props.selectedObjective._aCombinaisonConditions._aConditionObtention)) {
            activitiesConditionObtention = props.selectedObjective._aCombinaisonConditions._aConditionObtention.filter(conditionObtention =>
              conditionObtention.hasOwnProperty('_conditionObtentionDeActivitePedagogique')
            );
          } else {
            if (props.selectedObjective._aCombinaisonConditions._aConditionObtention.hasOwnProperty('_conditionObtentionDeActivitePedagogique')) {
              activitiesConditionObtention = [props.selectedObjective._aCombinaisonConditions._aConditionObtention];
            }
          }
          props.setConditionObtentionActivities(activitiesConditionObtention);

          // Récupération des activités pédagogiques qui conditionnent l'obtention de l'objectif pédagogique
          var mandatoryActivities: Array<ActivitePedagogique> = activities.filter(activity =>
            activitiesConditionObtention.some(conditonObtention => {
              return conditonObtention._conditionObtentionDeActivitePedagogique._URI === activity._URI;
            })
          );
          setCurrentObjectiveActivitiesMandatory(mandatoryActivities);

          // Tri des activités pédagogiques contributives pour placer les activités conditionnelles en haut du tableau
          if (mandatoryActivities.length !== 0) {
            let sortedObjectivesActivities = sortActivitiesByCondition(activities, mandatoryActivities);
            setCurrentObjectiveActivities(sortedObjectivesActivities);
          } else {
            setCurrentObjectiveActivities(activities);
          }

        } else {
          setCurrentObjectiveActivities(activities);
          props.setConditionObtentionActivities([]);
          setCurrentObjectiveActivitiesMandatory([]);
        }

        setCurrentObjectiveActivitiesLoad(true);

      });
    }
  }, [props.selectedObjective]);


  // Rafraichissement des activités du tableaux si modification depuis le volet des activités
  useEffect(() => {

    if (activityAtom !== undefined) {

      setCurrentObjectiveActivities(currentObjectiveActivities.map(objectiveActivities => {
        if (objectiveActivities._URI === activityAtom._URI) {
          return activityAtom;
        } else {
          return objectiveActivities;
        }
      }))
    }

  }, [activityAtom]);


  var currentObjectiveActivitiesColumns: TableColumn<ActivitePedagogique>[] = [
    {
      id: 1,
      name: "Titre",
      selector: e => e._titre !== undefined && e._titre,
      cell: (e) => (e._titre !== undefined && (
        e._URLMoodle !== undefined || e._URLRessource !== undefined ?
          <a
            className="contributory-activities-title-link"
            onClick={async () => { window?.open(e._URLMoodle !== undefined ? e._URLMoodle : e._URLRessource, "_blank")?.focus(); }}
          >
            {e._titre}
          </a> :
          <div>
            {e._titre}
          </div>
      )),
      sortable: true,
      grow: 3,
    },
    {
      id: 2,
      name: "Type",
      selector: e => (e._categorieActivite !== undefined) &&
        ((e._categorieActivite === CategorieActivite.document && "Document") ||
          (e._categorieActivite === CategorieActivite.video && "Vidéo") ||
          (e._categorieActivite === CategorieActivite.lien_web && "Lien web") ||
          (e._categorieActivite === CategorieActivite.quiz && "Quiz") ||
          (e._categorieActivite === CategorieActivite.jeu && "Jeu") ||
          (e._categorieActivite === CategorieActivite.podcast && "Podcast") ||
          (e._categorieActivite === CategorieActivite.exercice && "Exercice") ||
          (e._categorieActivite === CategorieActivite.recherche_type && "Recherche type") ||
          (e._categorieActivite === CategorieActivite.module_e_learning && "Module e-learning") ||
          (e._categorieActivite === CategorieActivite.presentation && "Présentation") ||
          (e._categorieActivite === CategorieActivite.feuille_de_calcul && "Feuille de calcul") ||
          (e._categorieActivite === CategorieActivite.cours && "Cours") ||
          (e._categorieActivite === CategorieActivite.sondage && "Sondage") ||
          (e._categorieActivite === CategorieActivite.forum && "Forum") ||
          (e._categorieActivite === CategorieActivite.wiki && "Wiki")),
      sortable: true,
      grow: 1,
    },
    {
      id: 3,
      name: "Contribution étudiante",
      button: true,
      width: "160px",
      cell: (e) => (e._auteur &&
        (Array.isArray(e._auteur) ? e._auteur[0]._URI.includes("etudiant") : e._auteur._URI.includes("etudiant")) &&
        <IconStudentContribution />
      ),
    },
    {
      id: 4,
      name: "Condition d'obtention",
      button: true,
      width: "160px",
      cell: (e) => (currentObjectiveActivitiesMandatory.some(activity => { return JSON.stringify(activity._URI) === JSON.stringify(e._URI) }) &&
        <IconFullStar width={"24px"} />
      ),
    },
    {
      id: 5,
      name: "Détacher",
      button: true,
      width: "80px",
      cell: (e) => (!currentObjectiveActivitiesMandatory.some(activity => { return JSON.stringify(activity._URI) === JSON.stringify(e._URI) }) &&
        <a className="teaching-units-index-link" onClick={() => { removeActivity(e) }}>
          <IconSurroundedDownArrow />
        </a>
      ),
    },
    // Possibilité de modifier la condition d'obtention activité en mode draft
    {
      id: 6,
      name: 'Editer',
      button: true,
      width: "80px",
      cell: (e) => (e._URI !== "" &&
        <a className={`teaching-units-index-link ${e === activityAtom && activityEditModalOpen && "teaching-units-index-link-activated"}`}
          onClick={() => {
            setActivityAtom(e);
            setActivityEditModalOpen(true);
          }}>
          <IconEdit />
        </a>
      ),
    },
    {
      width: "0px",
    }
  ];


  const paginationComponentOptions = {
    rowsPerPageText: 'Lignes par page :',
    rangeSeparatorText: 'de',
  };


  return (
    <>
      <div className="objective-edit-panel-index-table objective-edit-panel-contributory-activities">
        {currentObjectiveActivitiesLoad ?
          <DataTableExtensions
            columns={currentObjectiveActivitiesColumns}
            data={currentObjectiveActivities}
            print={false}
            export={false}
            responsive
            filterPlaceholder={'Recherche...'}
          >
            <DataTable
              title={"Activités contributives"}
              noDataComponent={"Pas d'activité à afficher"}
              columns={currentObjectiveActivitiesColumns}
              data={currentObjectiveActivities}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[3, 5, 10, 25, 50]}
              paginationComponentOptions={paginationComponentOptions}
              responsive
              highlightOnHover
              striped
            />
          </DataTableExtensions> :
          <div className="objective-edit-panel-index-loader loader">
            <Spinner> </Spinner>
          </div>
        }
      </div>
      <ContributoryActivitiesImport
        selectedObjective={props.selectedObjective}
        currentObjectiveActivities={currentObjectiveActivities}
        setCurrentObjectiveActivities={setCurrentObjectiveActivities}
        currentObjectiveActivitiesLoad={currentObjectiveActivitiesLoad}
        setCurrentObjectiveActivitiesLoad={setCurrentObjectiveActivitiesLoad}
      />
    </>
  );
}

export default ObjectiveActivities;
