import React from "react";

export const SvgDocument = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 200 200">
      <g
        transform={"translate(40,0) scale(7) translate(-5591.81 -2885.495) "}
        className="svg-node-icon document"
      >
        <path
          d="M5603.9,2886.5H5592.81V2910.7h18v-17.294Z"
          // fill="#e1e1e3"
          // stroke="#717884"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M5610.674,2893.407h6.912l-6.912-6.912Z"
          transform="translate(-6.776)"
          // fill="#e1e1e3"
          // stroke="#717884"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
