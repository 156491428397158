import CategorieActivite from "../vocabulary/categorieActivite";

export const categorieActivite = [
    { value: CategorieActivite.document, label: "Document" },
    { value: CategorieActivite.video, label: "Vidéo" },
    { value: CategorieActivite.lien_web, label: "Lien web" },
    { value: CategorieActivite.quiz, label: "Quiz" },
    { value: CategorieActivite.jeu, label: "Jeu" },
    { value: CategorieActivite.podcast, label: "Podcast" },
    { value: CategorieActivite.exercice, label: "Exercice" },
    { value: CategorieActivite.recherche_type, label: "Recherche type" },
    { value: CategorieActivite.module_e_learning, label: "Module e-learning" },
    { value: CategorieActivite.presentation, label: "Présentation" },
    { value: CategorieActivite.feuille_de_calcul, label: "Feuille de calcul" },
    { value: CategorieActivite.cours, label: "Cours" },
    { value: CategorieActivite.sondage, label: "Sondage" },
    { value: CategorieActivite.forum, label: "Forum" },
    { value: CategorieActivite.wiki, label: "Wiki" },
];

export default categorieActivite