import * as React from 'react'
import { useEffect, useState } from 'react';
import HelpPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/HelpPanel";
import AccountPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/AccountPanel";
import NotificationPanel from "../../../../VirtualTutor/Menus/HelpNotifUserMenu/NotificationPanel";
import AdminMenu from "../../../PageElement/AdminMenu/AdminMenu";
import Breadcrumb from "../../../PageElement/Breadcrumb/Breadcrumb";
import "./StudentProfile.css";
import Sidebar from "../../../PageElement/Sidebar/Sidebar";
import 'react-circular-progressbar/dist/styles.css';
import CourseBox from './CourseBox';
import ActivityBox from './ActivityBox';
import { ApiService } from '../../../../LoginHandler/restServices';
import { useParams } from 'react-router-dom';
import { TeachingUnit } from '../../../../../interface/teachingUnits';
import IEtudiantObjectif from '../../../../../interface/EtudiantObjectif.interface';
import StatutEtudiantPourObjectif from '../../../../../vocabulary/statutEtudiantPourObjectif.vocabulary';
import { ActivitePedagogique } from '../../../../../interface/activitePedagogique';
import StatutEtudiantPourActivite from '../../../../../vocabulary/statutEtudiantPourActivite.vocabulary';
import IEtudiant from '../../../../../interface/Etudiant.interface';
import * as d3 from 'd3';
import IInfoRecommandation from '../../../../../interface/InfoRecommandation.interface';
import IRecommandation from '../../../../../interface/Recommandation.interface';
import EtudiantActivite from '../../../../../interface/etudiantActivite';

function StudentProfile() {

  let { studentId } = useParams<{ studentId: string }>();

  const [teachingUnits, setTeachingUnits] = useState<TeachingUnit[]>();
  const [studentObjectives, setStudentObjectives] = useState<IEtudiantObjectif[]>();
  const [studentActivites, setStudentActivites] = useState<{ tu: string, studentActivities: EtudiantActivite[] }[]>();
  const [tuActivities, setTuActivities] = useState<{ tu: string, activities: ActivitePedagogique[] }[]>();
  const [contributions, setContributions] = useState<{ tuName: string | undefined, activity: ActivitePedagogique }[]>();
  const [student, setStudent] = useState<IEtudiant>();
  const [recommendations, setRecommendations] = useState<Array<{ infoRecoUri: string, recommendation: IRecommandation }>>();
  const [infoRecommendations, setInfoRecommendations] = useState<Array<IInfoRecommandation>>();


  const parseStudentDate: (dateParam: any) => Date = (dateParam: string) => {
    return (
      d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ")(
        Array.isArray(dateParam) ? dateParam[0] : dateParam
      ) || new Date()
    );
  };

  useEffect(() => {

    var dicActivities: { tu: string, activities: ActivitePedagogique[] }[] = [];
    var dicStudentActivities: { tu: string, studentActivities: EtudiantActivite[] }[] = [];
    var dicContributions: { tuName: string | undefined, activity: ActivitePedagogique }[] = [];

    // Récupération de données
    (async function bigLoader() {

      // Récupération des cours
      const courses: TeachingUnit[] = await ApiService.getStudentTeachingUnits(studentId);
      setTeachingUnits(courses);

      // Récupération des étudiant objectifs
      const stuObjectives: IEtudiantObjectif[] = await ApiService.getStudentObjectives(studentId);
      setStudentObjectives(stuObjectives);

      for (let i = 0; i < courses.length; i++) {

        // Récupération des activités pour chaque UE
        await ApiService.getAllActivitiesFromUE(courses[i]._URI).then(activities => {

          // Mise en place de l'objet contnant toutes les activités des unités d'enseignement
          dicActivities.push({ tu: courses[i]._URI, activities: activities });

          // Mise en place de l'objet contenant les contributions de l'étudiants
          let contribs: ActivitePedagogique[] = activities.filter(act =>
            act._auteur && (
              Array.isArray(act._auteur) ?
                act._auteur.find(personne => personne._idPassport === studentId) !== undefined
                :
                act._auteur._idPassport === studentId
            )
          );

          if (contribs.length > 0) {
            contribs.forEach(contrib => {
              dicContributions.push({ tuName: courses[i]._nom, activity: contrib });
            })
          }

        });

        // Récupération des étudiants activités pour chaque UE
        await ApiService.getAllStudentActivities(courses[i]._URI, studentId).then(stuActivities => {

          // Mise en place de l'objet contenant les étudiants activités
          dicStudentActivities.push({ tu: courses[i]._URI, studentActivities: stuActivities });

        });

      }

    })().then(() => {

      setTuActivities(dicActivities);
      setStudentActivites(dicStudentActivities);
      setContributions(dicContributions);

    });

    // Récupération de l'étudiant
    ApiService.getStudent(studentId).then(student => {
      setStudent(({
        derniereConnexion: student._derniereConnexion,
        prenom: student._prenom,
        nom: student._nom,
        URI: student._URI,
        email: student._email,
        idPassport: student._idPassport
      }));
    });

    // Récupération des recommandations
    ApiService.getStudentRecommendations(studentId, 10).then(recos => {

      var objReco: Array<{ infoRecoUri: string, recommendation: IRecommandation }> = [];
      var infoReco: Array<IInfoRecommandation> = [];
      // Création d'un array d'objet avec les recommandations associées à chaque info (pour ensuite map les info puis map les reco)
      recos.forEach(reco => {
        if (!objReco[reco.infoRecommandation.URI]) {
          objReco[reco.infoRecommandation.URI] = [];
          infoReco.push(reco.infoRecommandation);
        }
        objReco[reco.infoRecommandation.URI].push(reco.recommandationDeActivitePedagogique);
      })

      setRecommendations(objReco);
      setInfoRecommendations(infoReco);

    });

  }, []);


  return (
    <div className="admin">
      <Sidebar />
      <div className="admin-body">
        <div className="admin-content">
          <Breadcrumb />
          <AdminMenu />

          <div className="student-profile">
            <div className="student-profile-title">Profil de l'étudiant</div>
            <div className="student-profile-content">
              <div className="student-profile-left">

                <div>
                  <div className="student-profile-subtitle">Cours</div>
                  {teachingUnits &&
                    teachingUnits.map((teachingUnit) => (
                      <CourseBox
                        title={teachingUnit._nom}
                        // Objectifs terminés par l'étudiant par rapport aux objectifs totaux de chaque unité d'enseignement
                        objectiveProgress={studentObjectives && (
                          studentObjectives
                            .filter(stuObj => (stuObj.inscriptionPedagogique?.uniteEnseignement.URI === teachingUnit._URI))
                            .filter(stuObj => (stuObj.statutEtudiantPourObjectif === StatutEtudiantPourObjectif.ending))
                            .length
                          /
                          studentObjectives
                            .filter(stuObj => (stuObj.inscriptionPedagogique?.uniteEnseignement.URI === teachingUnit._URI))
                            .length
                        )}
                        // Activités terminées par l'étudiant par rapport aux activités totales de chaque unité d'enseignement
                        activityProgress={studentActivites && tuActivities && (
                          studentActivites
                            .filter(dicStuAct => (dicStuAct.tu === teachingUnit._URI))[0].studentActivities
                            .filter(stuAct => (stuAct._statutEtudiantPourActivite === StatutEtudiantPourActivite.validate))
                            .length
                          /
                          tuActivities
                            .find(dicStuAct => dicStuAct.tu === teachingUnit._URI)!.activities
                            .length
                        )}
                      />
                    ))
                  }
                </div>

                <div>
                  <div className="student-profile-subtitle">Contributions</div>

                  {contributions &&
                    <div className="student-profile-contributions-counter">
                      Nombre d'activité(s) proposée(s) : {contributions.length}
                    </div>
                  }

                  <div className="student-profile-contributions">

                    {contributions &&
                      contributions.map((contribution) => (
                        <ActivityBox
                          status={contribution.activity._statut}
                          type={contribution.activity._categorieActivite}
                          course={contribution.tuName}
                          title={contribution.activity._titre}
                        />
                      ))
                    }

                  </div>

                </div>

              </div>

              <div className="student-profile-right">
                <div>
                  <div className="student-profile-subtitle">Activité</div>

                  <div className="student-profile-activity">
                    <div className="student-profile-activity-info">
                      Dernière connexion : {parseStudentDate(student?.derniereConnexion)
                        .toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                    </div>
                  </div>

                </div>

                <div>
                  <div className="student-profile-subtitle">Recommandations</div>

                  <div className="student-profile-recommendations">

                    {infoRecommendations &&
                      infoRecommendations.map((info) => (

                        <div className="student-profile-recommendation-box">

                          {info.messageUtilisateur &&
                            <div className="student-profile-recommendation-bubble">
                              {info.messageUtilisateur}
                            </div>
                          }

                          {recommendations &&
                            recommendations[info.URI].map(reco => (
                              <ActivityBox
                                status={reco.status}
                                type={reco.categorieActivite}
                                course={tuActivities &&
                                  // Récupération du nom de l'ue en filtrant le dictionnaire des activités avec l'URI de la recommandation
                                  teachingUnits?.find(tu =>
                                    tu._URI === tuActivities.filter(dic =>
                                      dic.activities.filter(act =>
                                        act._URI === reco.URI
                                      )
                                    )[0].tu)?._nom
                                }
                                title={reco.titre}
                              />
                            ))
                          }

                        </div>

                      ))

                    }

                  </div>


                </div>
              </div>
            </div>
          </div>

          <HelpPanel />
          <AccountPanel />
          <NotificationPanel />
        </div>
      </div>
    </div>

  );
};

export default StudentProfile;
