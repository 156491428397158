import * as React from 'react'
import "./CourseBox.css";
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function CourseBox(props: {
  title: string | undefined,
  objectiveProgress: number | undefined,
  activityProgress: number | undefined
}) {

  return (
    <div className="student-profile-course-box">
      <div className="student-profile-course-box-title">{props.title ? props.title : "Nom de l'UE manquant"}</div>
      <div className="student-profile-course-box-content">
        <div className="student-profile-course-box-progress">
          {props.objectiveProgress !== undefined ?
            <CircularProgressbarWithChildren
              value={props.objectiveProgress * 100}
              counterClockwise
              styles={buildStyles({
                rotation: 0.75,
                pathColor: "#81BDB3",
              })}
            >
              <div>
                OBJECTIFS
              </div>
              <div className="student-profile-course-box-percent">
                <strong>
                  {Math.ceil(props.objectiveProgress * 100)}%
                </strong>
              </div>
            </CircularProgressbarWithChildren>
            :
            <div>
              Pas de données sur les objectifs
            </div>
          }
        </div>
        <div className="student-profile-course-box-progress">
          {props.activityProgress !== undefined ?
            <CircularProgressbarWithChildren
              value={props.activityProgress * 100}
              counterClockwise
              styles={buildStyles({
                rotation: 0.75,
                pathColor: "#EC6553",
              })}
            >
              <div>
                ACTIVITÉS
              </div>
              <div className="student-profile-course-box-percent">
                <strong>
                  {Math.ceil(props.activityProgress * 100)}%
                </strong>
              </div>
            </CircularProgressbarWithChildren>
            :
            <div>
              Pas de données sur les activités
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CourseBox;
