import { useState, useEffect } from "react";
import "./ActivityEditPanel.css";
import { useRecoilState } from "recoil";
import {
  selectedActivityAtom,
} from "../../../../../../recoil/graphFilters";
import {
  toggleActivityEditModalAtom,
} from "../../../../../../recoil/atoms";
import ActivityEditForm from "./ActivityEditForm";
import { ConditionObtentionMedia } from "../../../../../../interface/conditionObtentionMedia";
import { ConditionObtentionControleConnaissance } from "../../../../../../interface/conditionObtentionControleConnaissance";
import { Button } from "reactstrap";

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
  rel="stylesheet"
/>;

function ActivityEditPanel(props) {

  const [activityAtom,] = useRecoilState(
    selectedActivityAtom
  );
  const [activityEditModalOpen, setActivityEditModalOpen] = useRecoilState(toggleActivityEditModalAtom);

  const [isActivityMandatory, setIsActivityMandatory] = useState<boolean>(false);
  const [pourcentageReussiteAttendu, setPourcentageReussiteAttendu] = useState<number | undefined>(undefined);
  const [urlActivity, setUrlActivity] = useState<string | null>();

  // Récupération de l'activité
  useEffect(() => {
    if (activityAtom !== undefined && activityEditModalOpen) {
      document.getElementById('activity-edit-panel-modal-id')?.scrollTo({ top: 0, behavior: 'smooth' });
      if (props.conditionObtentionActivities !== undefined) {
        // Filtrage de activitiesConditionObtention pour récupérer celui correspondant à l'activité sélectionnée si la condition existe
        var activityConditionObtention: (ConditionObtentionMedia | ConditionObtentionControleConnaissance)[] = props.conditionObtentionActivities.filter(activityCondition =>
          activityCondition._conditionObtentionDeActivitePedagogique._URI === activityAtom._URI
        );
        var isMandatory: boolean = activityConditionObtention.length > 0 ? true : false;
        var expectedSuccessPercentage: number | undefined = undefined;
        if (isMandatory) {
          //@ts-ignore
          expectedSuccessPercentage = activityConditionObtention[0].hasOwnProperty('_pourcentageReussiteAttendu') && activityConditionObtention[0]._pourcentageReussiteAttendu;
        }
        setPourcentageReussiteAttendu(expectedSuccessPercentage);
        setIsActivityMandatory(isMandatory);
      }
      // Lien de l'activité
      if (activityAtom._URLMoodle !== undefined) {
        setUrlActivity(activityAtom._URLMoodle);
      } else if (activityAtom._URLRessource !== undefined) {
        setUrlActivity(activityAtom._URLRessource);
      }
    }
  }, [activityAtom, activityEditModalOpen]);

  return (
    <div
      id={"activity-edit-panel-modal-id"}
      className={`activity-edit-panel-modal ${activityEditModalOpen ? "activity-edit-panel-open" : "activity-edit-panel-notopen"}`}
    >
      <div className="activity-edit-panel-header">
        <div className="activity-edit-panel-title">
          {activityAtom?._titre}
        </div>
        <span className="activity-edit-panel-close" onClick={() => { setActivityEditModalOpen(false); }}>
          <svg width={20} height={20} viewBox="0 0 50 50">
            <path
              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
              stroke="#FFFFFF"
              fill="#FFFFFF"
              strokeWidth={7}
            />
          </svg>
        </span>
      </div>
      <div className="activity-edit-panel-body">
        <ActivityEditForm
          isActivityMandatory={isActivityMandatory}
          pourcentageReussiteAttendu={pourcentageReussiteAttendu}
          selectedObjective={props.selectedObjective}
          conditionObtentionActivities={props.conditionObtentionActivities}
          setConditionObtentionActivities={props.setConditionObtentionActivities}
          setIsApiConditionObtentionActivitiesFetched={props.setIsApiConditionObtentionActivitiesFetched}
        />
        {urlActivity != null && (
          <Button
            className="activity-edit-panel-moodle-link"
            color="primary"
            onClick={async () => { window?.open(urlActivity, "_blank")?.focus(); }}
          >
            Accéder à l'activité
          </Button>
        )}
      </div>
    </div>
  );
}

export default ActivityEditPanel;
