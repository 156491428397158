import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import "./ResourcesForm.css";
import {Button, Spinner} from "reactstrap";
import { useRecoilState , useRecoilValue } from "recoil";
import {
  toggleModalResourcesFormAtom,
  selectNode,
  studentAtom,
} from "../../recoil/atoms";
import { selectedTeachingUnitAtom } from "../../recoil/graphFilters";
import { INode, IStudent } from "../../interface/graph";
import { useParams } from "react-router-dom";
import {etudiantCreateActivite} from "../LoginHandler/restServices";

<link
    href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap"
    rel="stylesheet"
/>;

function ResourcesForm() {
  const [isModalResourcesFormOpen, setModalResourcesFormOpen] = useRecoilState(
      toggleModalResourcesFormAtom
  );
  const [selectedTeachingUnit, ] = useRecoilState(
      selectedTeachingUnitAtom
  );
  let { studentId } = useParams<{ studentId: string }>();
  const [student, setStudent] = useRecoilState(studentAtom);

  const node = useRecoilValue(selectNode);
  const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onBlur" });

  const [isClickedDocument, setIsClickedDocument] = useState(false);
  const [isClickedLink, setIsClickedLink] = useState(false);
  const [isClickedLessFiveMins, setIsClickedLessFiveMins] = useState(false);
  const [isClickedFiveMins, setIsClickedFiveMins] = useState(false);
  const [isClickedTenMins, setIsClickedTenMins] = useState(false);
  const [isClickedQuarterHour, setIsClickedQuarterHour] = useState(false);
  const [isClickedHalfHour, setIsClickedHalfHour] = useState(false);
  const [isClickedHour, setIsClickedHour] = useState(false);
  const [isEnterFile, setIsEnterFile] = useState("Aucun fichier choisi");
  const [onLoading, setOnLoading] = useState(false);

  const handleClickType = (props: string) => {
    props === "Document" ? setIsClickedDocument(true) : setIsClickedDocument(false);
    props === "Link" ? setIsClickedLink(true) : setIsClickedLink(false);
  }

  const handleClickDuration = (props: string) => {
    props === "LessFive" ? setIsClickedLessFiveMins(true) : setIsClickedLessFiveMins(false);
    props === "Five" ? setIsClickedFiveMins(true) : setIsClickedFiveMins(false);
    props === "Ten" ? setIsClickedTenMins(true) : setIsClickedTenMins(false);
    props === "Quarter" ? setIsClickedQuarterHour(true) : setIsClickedQuarterHour(false);
    props === "Half" ? setIsClickedHalfHour(true) : setIsClickedHalfHour(false);
    props === "Hour" ? setIsClickedHour(true) : setIsClickedHour(false);
  }

  // Call functions fetching resource creation API on submit
  const onSubmit = async (data : any, selectedTeachingUnit : any, node: INode, student : IStudent) => {
    setOnLoading(true);
    await apiBackendCreateResource(data, selectedTeachingUnit._idMoodle, node.id);
    setOnLoading(false);
    handleClose()
  };

  // Call for Back-End API to create the resource
  async function apiBackendCreateResource(data: any,idCourseMoodle: number,objectifPedagogiqueUri:string) {

    const newBackendResource = await etudiantCreateActivite (
        data['type'],
        data["name"],
        data["duration"],
        data["information"],
        idCourseMoodle,
        objectifPedagogiqueUri,
        data["link"] != undefined ? data["link"] : undefined,
        data['file'] != undefined ? data['file'][0] : undefined);
    return newBackendResource
  }

  const handleClose = () => {
    setModalResourcesFormOpen(false)
    setIsClickedDocument(false);
    setIsClickedLink(false);
    setIsClickedFiveMins(false);
    setIsClickedQuarterHour(false);
    setIsClickedHalfHour(false);
    setIsClickedHour(false);
    reset({ name: "" })
    reset({ link: "" })
    setIsEnterFile("Aucun fichier choisi");
    reset({ information: "" });
  }

  return (
      <div className={`resourcesForm-modal ${isModalResourcesFormOpen ? "resourcesForm-open" : ""}`}>
        {onLoading &&
        <div className="loading-spin">
          <div className="loader">
            <Spinner> </Spinner>
          </div>
        </div>}
        <form className="resourcesForm-modal-content" onSubmit={handleSubmit(data => onSubmit(data, selectedTeachingUnit, node, student!))}>
          <div className="resourcesForm-header">
            <div className="resourcesForm-header-title">Proposer une activité</div>
            <span className="resourcesForm-close" onClick={() => handleClose()}>
            <svg width={20} height={20} viewBox="0 0 50 50">
              <path
                  d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
                  stroke="rgba(0, 0, 0, 0.36)"
                  fill="rgba(0, 0, 0, 0.36)"
                  strokeWidth={7}
              />
            </svg>
          </span>
          </div>
          <div className="resourcesForm-body">
            <div className="resourcesForm-body-text-warning">
              Vous avez trouvé un article intéressant sur Internet, une ressource utile pour travailler ce concept, remplissez ce formulaire pour la partager avec vos camarades.
              Les autres étudiants pourront consulter le contenu. Votre nom ne sera pas mentionné. Vous retrouverez toutes vos contributions dans le calque "Mes contributions".
            </div>
            <div className="resourcesForm-section">
              <div className="resourcesForm-section-title">Type de ressource <em>*</em></div>
              <div className="resourcesForm-list">
                <label
                    className={`resourcesForm-input ${isClickedDocument ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickType('Document') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("type", { required: "Ce champ est requis" })}
                      value="file"
                  />
                  Document
                </label>
                <label
                    className={`resourcesForm-input ${isClickedLink ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickType('Link') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("type", { required: "Ce champ est requis" })}
                      value="url"
                  />
                  Lien web
                </label>
              </div>
              {errors.type &&
              <div className="resourcesForm-errors">
                {errors.type.message}
              </div>
              }
            </div>
            <div className="resourcesForm-section">
              <div className="resourcesForm-section-title">Nom de la ressource <em>*</em></div>
              <input
                  type="text"
                  className="resourcesForm-fields"
                  placeholder="Nom de la ressource"
                  {...register("name", { required: "Ce champ est requis" })}
              />
              {errors.name &&
              <div className="resourcesForm-errors">
                {errors.name.message}
              </div>
              }
            </div>
            {isClickedLink &&
            <div className="resourcesForm-section">
              <div className="resourcesForm-section-title">Lien vers l'activité <em>*</em></div>
              <input
                  type="text"
                  className="resourcesForm-fields"
                  placeholder="https://google.com/"
                  {...register("link", { required: "Ce champ est requis" })}
              />
              {errors.link &&
              <div className="resourcesForm-errors">
                {errors.link.message}
              </div>
              }
            </div>
            }
            {isClickedDocument &&
            <div className="resourcesForm-section">
              <div className="resourcesForm-section-title">Sélection du fichier <em>*</em></div>
              <div className="resourcesForm-fields">
                {isEnterFile}
                <input
                    type="file"
                    {...register("file", { required: "Ce champ est requis" })}
                    onChange={e => setIsEnterFile(e.target.value)}
                />
              </div>
              {errors.file &&
              <div className="resourcesForm-errors">
                {errors.file.message}
              </div>
              }
            </div>
            }
            <div className="resourcesForm-section">
              <div className="resourcesForm-section-title">Durée de l'activité <em>*</em></div>
              <div className="resourcesForm-list">
                <label
                    className={`resourcesForm-input ${isClickedLessFiveMins ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickDuration('LessFive') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("duration", { required: "Ce champ est requis" })}
                      value="P0H4M0S"
                  />
                  Moins de 5 min.
                </label>
                <label
                    className={`resourcesForm-input ${isClickedFiveMins ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickDuration('Five') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("duration", { required: "Ce champ est requis" })}
                      value="P0H10M0S"
                  />
                  Entre 5 et 10 min.
                </label>
                <label
                    className={`resourcesForm-input ${isClickedTenMins ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickDuration('Ten') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("duration", { required: "Ce champ est requis" })}
                      value="P0H15M0S"
                  />
                  10 et 15 min.
                </label>
                <label
                    className={`resourcesForm-input ${isClickedQuarterHour ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickDuration('Quarter') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("duration", { required: "Ce champ est requis" })}
                      value="P0H30M0S"
                  />
                  15 et 30 min.
                </label>
                <label
                    className={`resourcesForm-input ${isClickedHalfHour ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickDuration('Half') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("duration", { required: "Ce champ est requis" })}
                      value="P1H0M0S"
                  />
                  30 min. et une heure
                </label>
                <label
                    className={`resourcesForm-input ${isClickedHour ? "resourcesForm-inputClicked" : "resourcesForm-inputNoClicked"}`}
                    onClick={() => { handleClickDuration('Hour') }}
                >
                  <input
                      type="radio"
                      className="resourcesForm-radio"
                      {...register("duration", { required: "Ce champ est requis" })}
                      value="P2H0M0S"
                  />
                  Plus d'une heure
                </label>
              </div>
              {errors.duration &&
              <div className="resourcesForm-errors">
                {errors.duration.message}
              </div>
              }
            </div>
            <div className="resourcesForm-section">
              <div className="resourcesForm-section-title">Informations sur la ressource <em>*</em></div>
              <input
                  type="text"
                  className="resourcesForm-fields"
                  placeholder="Description, auteur, date de publication..."
                  {...register("information", { required: "Ce champ est requis" })}
              />
              <div className="resourcesForm-legend">
                Ajouter des informations à propos de la ressource
              </div>
            </div>
            <div className="resourcesForm-mandatory"><em>*</em> champs obligatoires</div>
          </div>
          <div className="resourcesForm-footer">
            <Button
                className="resourcesForm-btn resourcesForm-second"
                onClick={() => handleClose()}
            >
              Annuler
            </Button>
            <Button className="resourcesForm-btn resourcesForm-primary">
              <input type="submit" />
              Valider
            </Button>
          </div>
        </form>
      </div>
  );
}

export default ResourcesForm;