import "./HelpActivities.css";
import { useRecoilState } from "recoil";
import {
  toggleModalHelpActivitiesAtom,
} from "../../../../../recoil/atoms";
import { SvgDocument } from "../../../../icons/SVGDocument";
import SVGWebLink from "../../../../icons/SVGWebLink";
import SVGQuestionnaire from "../../../../icons/SVGQuestionnaire";
import SVGResearch from "../../../../icons/SVGResearch";
import SVGVideo from "../../../../icons/SVGVideo";
import * as d3 from "d3";
import { SVGElearning } from "../../../../icons/SVGElearning";
import { SVGPodcast } from "../../../../icons/SVGPodcast";
import { SVGJeux } from "../../../../icons/SVGJeux";
import { SVGExercice } from "../../../../icons/SVGExercice";
import { SVGPresentation } from "../../../../icons/SVGPresentation";
import { SVGExcel } from "../../../../icons/SVGExcel";
import { SVGCours } from "../../../../icons/SVGDauphineCours";
<link 
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap" 
  rel="stylesheet" 
/>;

function HelpActivities() {
  const [isModalHelpActivitiesOpen, setModalHelpActivitiesOpen] = useRecoilState(
    toggleModalHelpActivitiesAtom
  );

  return (
    <div className={`helpActivities-modal ${isModalHelpActivitiesOpen ? "helpActivities-open" : ""}`}>
      <div className="helpActivities-header">
        <div className="helpActivities-title">Activités</div>
        <span 
          className="helpActivities-close" 
          onClick={() => setModalHelpActivitiesOpen(false)}
        >
          <svg
            width={20}
            height={20}
            viewBox="0 0 50 50"
          >
            <path
              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
              stroke="rgba(0, 0, 0, 0.36)"
              fill="rgba(0, 0, 0, 0.36)"
              strokeWidth={7}
            />
          </svg>
        </span>
      </div>
      {/* <div className="helpActivities-intro">

      </div> */}
      <div className="helpActivities-body">
        <div className="helpActivities-section">
          <div className="helpActivities-section-title">
            Noeuds
          </div>
          <div className="helpActivities-section-nodes">
            <div className="helpActivities-section-status-item">
              <svg
                height="66px" 
                width="66px"
                className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                    fill={"rgb(199, 201, 206)"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                    className="circle"
                  />
                  <SvgDocument />
                </g>
              </svg>
              <span className="helpActivities-status">Document</span>
            </div>
            <div className="helpActivities-section-status-item">
              <svg
                height="66px" width="66px"
                className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                    fill={"rgb(199, 201, 206)"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                    className="circle"
                  />
                  <SVGWebLink />
                </g>
              </svg>
              <span className="helpActivities-status">Lien web</span>
            </div>
            <div className="helpActivities-section-status-item">
              <svg
                height="66px" 
                width="66px"
                className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                    fill={"rgb(199, 201, 206)"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                    className="circle"
                  />
                  <SVGQuestionnaire />
                </g>
              </svg>
              <span className="helpActivities-status">Quiz</span>
            </div>
            <div className="helpActivities-section-status-item">
              <svg
                height="66px" 
                width="66px"
                className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                    fill={"rgb(199, 201, 206)"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                    className="circle"
                  />
                  <SVGResearch />
                </g>
              </svg>
              <span className="helpActivities-status">Recherche Type</span>
            </div>
            <div className="helpActivities-section-status-item">
              <svg
                height="66px" 
                width="66px"
                className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                    fill={"rgb(199, 201, 206)"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                    className="circle"
                  />
                  <SVGVideo />
                </g>
              </svg>
              <span className="helpActivities-status">Vidéo</span>
            </div>

            <div className="helpActivities-section-status-item">
              <svg
                  height="66px"
                  width="66px"
                  className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                      fill={"rgb(199, 201, 206)"}
                      cx={15.5}
                      cy={15}
                      r={20}
                      stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                      className="circle"
                  />
                  <SVGCours />
                </g>
              </svg>
              <span className="helpActivities-status">Cours</span>
            </div>

            <div className="helpActivities-section-status-item">
              <svg
                  height="66px"
                  width="66px"
                  className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                      fill={"rgb(199, 201, 206)"}
                      cx={15.5}
                      cy={15}
                      r={20}
                      stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                      className="circle"
                  />
                  <SVGExcel />
                </g>
              </svg>
              <span className="helpActivities-status">Feuille de calcul</span>
            </div>

            <div className="helpActivities-section-status-item">
              <svg
                  height="66px"
                  width="66px"
                  className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                      fill={"rgb(199, 201, 206)"}
                      cx={15.5}
                      cy={15}
                      r={20}
                      stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                      className="circle"
                  />
                  <SVGPresentation />
                </g>
              </svg>
              <span className="helpActivities-status">Présentation</span>
            </div>

            <div className="helpActivities-section-status-item">
              <svg
                  height="66px"
                  width="66px"
                  className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                      fill={"rgb(199, 201, 206)"}
                      cx={15.5}
                      cy={15}
                      r={20}
                      stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                      className="circle"
                  />
                  <SVGExercice />
                </g>
              </svg>
              <span className="helpActivities-status">Exercice</span>
            </div>

            <div className="helpActivities-section-status-item">
              <svg
                  height="66px"
                  width="66px"
                  className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                      fill={"rgb(199, 201, 206)"}
                      cx={15.5}
                      cy={15}
                      r={20}
                      stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                      className="circle"
                  />
                  <SVGJeux />
                </g>
              </svg>
              <span className="helpActivities-status">Jeu</span>
            </div>

            <div className="helpActivities-section-status-item">
              <svg
                  height="66px"
                  width="66px"
                  className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                      fill={"rgb(199, 201, 206)"}
                      cx={15.5}
                      cy={15}
                      r={20}
                      stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                      className="circle"
                  />
                  <SVGPodcast />
                </g>
              </svg>
              <span className="helpActivities-status">Podcast</span>
            </div>


            <div className="helpActivities-section-status-item">
              <svg
                  height="66px"
                  width="66px"
                  className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                      fill={"rgb(199, 201, 206)"}
                      cx={15.5}
                      cy={15}
                      r={20}
                      stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                      className="circle"
                  />
                  <SVGElearning />
                </g>
              </svg>
              <span className="helpActivities-status">Module E-Learning</span>
            </div>

          </div>
          <div className="helpActivities-intro-nodes">
            Une <span className="helpActivities-bold">activité</span> est <span className="helpActivities-bold">validée</span> lorsque tu as réalisé <span className="helpActivities-bold">l'activité</span> en question et obtenu le <span className="helpActivities-bold">score attendu</span>, s'il s'agit d'un quiz.
          </div>
        </div>
        <div className="helpActivities-section">
          <div className="helpActivities-section-title">
            Statut
          </div>
          <div className="helpActivities-section-status">
            <div className="helpActivities-section-status-item not-started">
              <svg
                height="66px" 
                width="66px"
                className="node"
              >
                <g transform="translate(15, 15)">
                  <circle
                    fill={"rgb(199, 201, 206)"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke={`${d3.color("rgb(199, 201, 206)")?.darker()}`}
                    className="circle"
                  />
                  <SVGVideo />
                </g>
              </svg>
              <span className="helpActivities-status">Non commencée</span>
            </div>
            <div className="helpActivities-section-status-item being-acquired">
              <svg
                height="66px" 
                width="66px"
                className="node started"
              >
                <g
                  transform="translate(15, 15)"
                  className="node-box-topic">
                  <circle 
                    cx={15.5} 
                    cy={15} 
                    r={23} 
                    fill={`white`} 
                    stroke={'white'} 
                  />
                  <circle
                    fill={"#8A96C5"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke={`${d3.color("#2f4486")?.darker()}`}
                    className="circle"
                  />
                  <SVGVideo />
                </g>
              </svg>
              <span className="helpActivities-status">En cours</span>
            </div>
            <div className="helpActivities-section-status-item acquired">
              <svg
                height="66px" 
                width="66px"
                className="node done"
              >
                <g
                  transform="translate(15, 15)">
                  <circle
                    fill={"#81bdb3"}
                    cx={15.5}
                    cy={15}
                    r={20}
                    stroke="#15705B"
                    className="circle"
                  />
                  <SVGVideo />
                </g>
              </svg>
              <span className="helpActivities-status">Terminée</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpActivities;