import React from "react";

const IconEdit = () => {
  return (
      <svg fill="#6371B0" width="24px" viewBox="0 0 512.001 512.001">
          <path d="M413.463,207.603l-92.245,92.245c-7.872,8.172-6.728,6.467-99.45,48.153c-36.857,16.564-74.123-21.436-57.786-57.784
                c41.557-92.437,39.977-91.508,48.039-99.336l92.361-92.361c7.867-7.866,2.295-21.318-8.83-21.318H18.731
                C8.387,77.202,0,85.589,0,95.934v397.318c0,10.345,8.387,18.731,18.731,18.731h397.318c10.345,0,18.731-8.387,18.731-18.731
                V216.433C434.781,205.307,421.33,199.735,413.463,207.603z"/>
          <path d="M496.714,15.27c-20.336-20.336-53.426-20.336-73.762,0L229.523,208.698c-1.648,1.575-2.843,2.971-4.188,5.96
                c-3.772,7.449-12.003,26.697-38.573,85.797c-6.38,14.195,6.253,28.794,20.36,26.157c3.649-0.682,86.271-38.018,88.126-38.916
                c4.864-2.137,6.585-3.69,8.112-5.311c0.001-0.001,193.353-193.353,193.354-193.354C517.024,68.723,517.169,35.722,496.714,15.27z
                "/>
      </svg>
  );
};
export default IconEdit;
