import {
  ACTIVITY_FINISHED,
  ACTIVITY_NOT_ACQUIRED,
  ACTIVITY_NOT_STARTED,
  ACTIVITY_STARTED,
  COURSE,
  DOCUMENT,
  GAME,
  PODCAST,
  ELEARNING,
  EXERCICE,
  IGraphData, IIndividual,
  IN_PROCESS,
  NOT_ACQUIRED,
  IStudent,
  LINK,
  OBJECTIVE_FINISHED,
  OBJECTIVE_NOT_ACQUIRED,
  OBJECTIVE_NOT_STARTED,
  OBJECTIVE_STARTED,
  PRESENTATION,
  QUESTIONNAIRE, requirement,
  RESEARCH,
  SPREADSHEET,
  TODO,
  TOPIC,
  TProgress,
  VALIDATED,
  VIDEO,
  ALL, LayerKey, PREFILTER,
} from "../interface/graph";

import * as d3 from "d3";
import {node} from "prop-types";
import {
    activityDurationFilterAtom, activityDurationOptionsDefault, activityProgressFilterAtom, activityTypeFilterAtom,
    filterActivityDuration,
    filterActivityProgress,
    filterActivityType,
    IActivityDurationOption
} from "../recoil/graphFilters";

export const PROGRESS_MAPPER: any = {
  [OBJECTIVE_NOT_STARTED]: TODO,
  [OBJECTIVE_STARTED]: IN_PROCESS,
  [OBJECTIVE_FINISHED]: VALIDATED,
  [OBJECTIVE_NOT_ACQUIRED]: VALIDATED,
};

export const ACTIVITY_PROGRESS_MAPPER: any = {
  [ACTIVITY_NOT_STARTED]: TODO,
  [ACTIVITY_STARTED]: IN_PROCESS,
  [ACTIVITY_FINISHED]: VALIDATED,
  [ACTIVITY_NOT_ACQUIRED]: NOT_ACQUIRED,
};

const type = {
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/1": DOCUMENT,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/2": VIDEO,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/3": LINK,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/4": QUESTIONNAIRE,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/5": GAME,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/6": PODCAST,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/7": EXERCICE,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/8": RESEARCH,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/9": ELEARNING,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/10": PRESENTATION,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/11": SPREADSHEET,
  "https://data.dauphine.psl.eu/vocabulary/categorie-activite/12": COURSE,
};

type StringType =
  | "https://data.dauphine.psl.eu/vocabulary/categorie-activite/2"
  | "https://data.dauphine.psl.eu/vocabulary/categorie-activite/4"
  | "https://data.dauphine.psl.eu/vocabulary/categorie-activite/3";

/**
 * Transform api objectives / activities to d3 simultion graphAtom ndes/ links
 * Based on:
 * Activity -> Objective (through "_aPourActiviteContributive")
 * Objective -> Objective (through "_contributionObjectifPourObjectif")
 * @param objectives list of api objectives
 * @param activities lise of api activities
 * @param studentActivities
 * @param studentObjectives
 * @param student
 * @param oBjStats
 * @param filters
 * @param selectNodeId
 */
export const jsonToForceGraph = (
  objectives: any,
  activities: any,
  studentActivities: any,
  studentObjectives: any,
  student: IStudent,
  oBjStats: any,
  filters: { activityDuration: IActivityDurationOption, activityProgress: string, activityTpe: string },
  layers: LayerKey[] = [],
  selectNodeId? : string,
): IGraphData => {
  const { uri, lastConnection } = student;
  // retain only objectives with activities
  const objectivesWithActivities = objectives.filter(
    (o: any) => !!o._aPourActiviteContributive
  );
  // create link from objective <- activities
  const activityToObjectiveLinks = objectivesWithActivities.reduce(
      (accumulator: any, currentObj: any) => {

        const objectiveActivities = Array.isArray(
            currentObj._aPourActiviteContributive
        )
            ? currentObj._aPourActiviteContributive
            : [currentObj._aPourActiviteContributive];
        return [
          ...accumulator,
          ...objectiveActivities.map((acti: any) => ({
            source: acti._pourActivite._URI,
            target: currentObj._URI,
            type: "linkActivityToObjectif",
            active: false
          })),
        ];
      },
      []
  );

  const conditionObtentionObjectifCourant: {activities : string[],objectifs : string[]} = { activities: [], objectifs : []};
  const currentObjectif = objectives.find((x:any) => x._URI == selectNodeId);

  if(currentObjectif != undefined && currentObjectif._aCombinaisonConditions._aConditionObtention != undefined){
      var conditions = Array.isArray(currentObjectif._aCombinaisonConditions._aConditionObtention) ?
          currentObjectif._aCombinaisonConditions._aConditionObtention
          :
          [currentObjectif._aCombinaisonConditions._aConditionObtention];
      conditions.forEach( (c:any) => {
          if(c._conditionObtentionDeActivitePedagogique != undefined){
              conditionObtentionObjectifCourant.activities.push(c._conditionObtentionDeActivitePedagogique._URI);
          }
          if(c._necessiteAcquisitionObjectifPedagogique != undefined){
              conditionObtentionObjectifCourant.objectifs.push(c._necessiteAcquisitionObjectifPedagogique._URI);
          }
      });
  }

  const activitiesNodes = activityToObjectiveLinks
    .map((link: any) =>
      activities.find((activity: any) => activity._URI === link.source)
    )
    .map((activity: any) => {
      const value: StringType = activity?._categorieActivite;

      const typeElement = type[value];

      const id = activity._URI;

      let etudiantActivite = studentActivities.find(
        (s: any) => s._activitePedagogiqueUtilisee._URI === id
      );

      let status = etudiantActivite
        ? // @ts-ignore
          ACTIVITY_PROGRESS_MAPPER[etudiantActivite._statutEtudiantPourActivite]
        : TODO;
      let deltaDay = d3.timeDay.count(
        new Date(activity._datePublication),
        lastConnection
      );

      var isRequired = conditionObtentionObjectifCourant.activities.find((x:any) => x == activity._URI) != undefined;

      var active = filters != undefined ?
          (
              ( filters.activityProgress == ALL || filters.activityProgress == status ) &&
              ( filters.activityDuration.name == activityDurationOptionsDefault[0].name ||
                  (parseInt(activity._duree) || 0) >= filters.activityDuration.duration.min &&
                  (parseInt(activity._duree) || 0) <= filters.activityDuration.duration.max ) &&
              ( filters.activityTpe == ALL || typeElement === filters.activityTpe )
          )
          :
          true;

      var creator = Array.isArray(activity._auteur) ?
          activity._auteur.map((e:any) => {
            return {isStudent: (e._URI.indexOf("/enseignant/") == -1), URI : e._URI}  as IIndividual
          })
          :
          [{isStudent:(activity._auteur._URI.indexOf("/enseignant/") == -1), URI : activity?._auteur?._URI}  as IIndividual];

      return {
        id,
        type: typeElement ? typeElement : RESEARCH,
        text: activity._titre,
        shortTitle: activity._titreCourt,
        duration: parseInt(activity._duree) || 0,
        new: status === TODO && deltaDay < 10,
        url: activity._URLMoodle || activity._URLRessource,
        title: activity._titre,
        done: status === VALIDATED,
        dateStarted: activity._dateDebut?  new Date(activity._dateDebut) : undefined,
        dateEndend: activity._dateFin? new Date(activity._dateFin) : undefined,
        mine: activity?._auteur?._URI === uri,
        creators: creator,
        active:active,
        isRequired:isRequired,
        status,
        etudiantActivite
      };
    })
    .reduce((accumulator: any, link: any) => {
      return { ...accumulator, [link.id]: link };
    }, {});

  //retain only objectives that are linked to other objectives
  const objectivesWithObjectives = objectives.filter(
    (objective: any) => !!objective._contributionObjectifPourObjectif
  );

  // create links objective <- objective
  const objectiveToObjectiveLinks = objectivesWithObjectives.reduce(
    (accumulator: any[], currentObj: any) => {
      const objectiveObj = currentObj._contributionObjectifPourObjectif;
      const arrayOfObj = Array.isArray(objectiveObj)
        ? objectiveObj
        : [objectiveObj];
      return [
        ...accumulator,
        ...arrayOfObj.map((obj: any) => ({
          source: obj._pourObjectif._URI,
          target: currentObj._URI,
          type: "linkObjectifToObjectif",
          active: false
        })),
      ];
    },
    []
  );

  // map of activity objectives
  const activityNodeMap = activityToObjectiveLinks.reduce(
    (accumulator: any, currentLink: any) => {
      const obj = objectives.find((j: any) => j._URI === currentLink.target);
      return { ...accumulator, [obj._URI]: obj };
    },
    {}
  );

  // concatenate activity nodes and objective Nodes
  const objectiveNodeMap = {
    ...objectiveToObjectiveLinks.reduce((accumulator: any[], b: any) => {
      const sourceObj = objectives.find((j: any) => j._URI === b.source);
      const targetObj = objectives.find((j: any) => j._URI === b.target);
      return {
        ...accumulator,
        [sourceObj._URI]: sourceObj,
        [targetObj._URI]: targetObj,
      };
    }, {}),
    ...activityNodeMap,
  };

  const stats = oBjStats?.reduce(
    (a: any, b: any) => ({
      ...a,
      [b?.objectifPedagogique?._URI]: b?.seuilReussite,
    }),
    {}
  );
  // map to array of nodes
  const objectivesNodesArray = Object.keys(objectiveNodeMap)
    .map((k) => objectiveNodeMap[k])
    .map((g) => {
      let shortTitle = g._titreCourt || " ";
      let strStatus: TProgress = studentObjectives.find(
        (s: any) => s._objectifPedagogique._URI === g._URI
      )?._statutEtudiantPourObjectif;
      const status: string = PROGRESS_MAPPER[strStatus] || TODO;
      const done = status === VALIDATED;
      const progress = getProgressOfObjectif(g._URI);

      //Un objectif est actif si il est atteignable si le layer "prérequis" est activé SINON tout les objectifs sont affichés
      var active = layers.includes(PREFILTER) ?
          (
              status !== TODO || //Un objectif commencé / terminé est affiché
              progress.requirement!.filter(x => (x.type == "OBJECTIF")).length == 0 || //Un objectif sans condition d'obtention d'objectif pédagogique est affiché
              objectiveToObjectiveLinks.some(
                  (l: any) =>
                      l.target === g._URI &&
                      objectiveNodeMap[l.source].status !== VALIDATED
              ) //Un Objectif étant la condition ou contribution d'un autre objectif  non validé est affiché
          ) : true;

      if(active){
         active =
             !(filters.activityDuration.name == "Toutes les durées" &&
                filters.activityProgress == "ALL" &&
                filters.activityTpe == "ALL")
                ?
                (
                    Array.isArray(g._aPourActiviteContributive) ?
                        g._aPourActiviteContributive.filter((contri:any) => activitiesNodes[contri._pourActivite._URI].active ).length > 0
                        :
                        g._aPourActiviteContributive != undefined ?
                            activitiesNodes[g._aPourActiviteContributive._pourActivite._URI].active
                            :
                            false
                )
                :
                true;
      }

      var isRequired = conditionObtentionObjectifCourant.objectifs.find((x:any) => x == g._estUneVersionDe._URI) != undefined;

      return {
        ...g,
        id: g._URI,
        type: TOPIC,
        requirementCompletion : progress.requirement,
        progress: {'acquis':progress.acquis, 'total':progress.total},
        text: g._titre,
        title: g._titre,
        shortTitle,
        groupProgress: stats[g._URI],
        color: "#5768ac",
        status,
        done,
        isRequired:isRequired,
        active:active,
      };
    });

  function getProgressOfObjectif(objectifURI: string) : {total:number,acquis:number,requirement:requirement[]} {

    let progress : {total:number,acquis:number,requirement:requirement[]} = {total:0,acquis:0, requirement : []};

    const objectif = objectiveNodeMap[objectifURI];

    if( objectif != undefined ){
      if(objectif._aCombinaisonConditions != undefined){
        var conditions = Array.isArray(objectif._aCombinaisonConditions._aConditionObtention) ? objectif._aCombinaisonConditions._aConditionObtention : [objectif._aCombinaisonConditions._aConditionObtention];
        conditions.forEach(( el : {
          "_URI":string
          "_necessiteAcquisitionObjectifPedagogique" ? : {"_URI":string},
          "_pourcentageReussiteAttendu" ? : number,
          "_conditionObtentionDeActivitePedagogique"  ? : {"_URI":string}
        }) => {
          switch(el._URI.match("\/condition-obtention-(.*)\/")![0]){
            case "/condition-obtention-cc/":
              var cc = activitiesNodes[el._conditionObtentionDeActivitePedagogique!._URI];

              if(cc != undefined){
                if(cc.status == VALIDATED){
                  progress.total++;
                  progress.acquis++;
                } else {
                  progress.total++;
                }

              }
              progress.requirement.push({type:"CONTROLE_CONNAISSANCE",node:cc, isComplete: cc!.status == VALIDATED});

              break;
            case "/condition-obtention-media/":
                  var cc = activitiesNodes[el._conditionObtentionDeActivitePedagogique!._URI];

                  if(cc != undefined){
                      if(cc.status == VALIDATED){
                          progress.total++;
                          progress.acquis++;
                      } else {
                          progress.total++;
                      }

                  }
                  progress.requirement.push({type:"MEDIA",node:cc, isComplete: cc!.status == VALIDATED});

                  break;
            case "/condition-obtention-objectif/":

              var uriObjectifToCheck : string | undefined = undefined;

              Object.keys(objectiveNodeMap).forEach(uri => {
                 if (objectiveNodeMap[uri]._estUneVersionDe._URI == el._necessiteAcquisitionObjectifPedagogique!._URI){
                   uriObjectifToCheck = uri;
                 }
              });

              if(uriObjectifToCheck != undefined){

                var condObjectifProgress = getProgressOfObjectif(uriObjectifToCheck);

                progress.requirement.push({type:"OBJECTIF",node:uriObjectifToCheck,isComplete : condObjectifProgress.acquis == condObjectifProgress.total});
                progress.acquis = progress.acquis + condObjectifProgress.acquis;
                progress.total = progress.total + condObjectifProgress.total;
              }

              break;
            default :
              progress.requirement.push({type:"OTHER", node : undefined, isComplete : true});
              break;
          }
        })
      }
    }
    return progress
  }
  const activityNodesArray = Object.keys(activitiesNodes).map(
    (m) => activitiesNodes[m]
  )

  //désactivations des liens non affichés
    objectiveToObjectiveLinks.filter((l:any) => (
        objectivesNodesArray.find(n => n.active && n.id == l.target.toString()) &&
        objectivesNodesArray.find(n => n.active && n.id == l.source.toString())))
        .forEach((l:any) => l.active = true);

    activityToObjectiveLinks.filter((l:any) => (
        objectivesNodesArray.find(n => n.active && n.id == l.target.toString()) != undefined &&
        activityNodesArray.find(n => n.active && n.id == l.source.toString()) != undefined )
    ).forEach((l:any) => l.active = true);

  //Mise en place des requirements objectifs
    objectivesNodesArray.forEach( x => {
        x.requirementCompletion.filter(r => (r.type == "OBJECTIF")).forEach(c => {
            c.node = objectivesNodesArray.find(n => n.id == c.node);
        })
    });


  return {
    links: [...objectiveToObjectiveLinks, ...activityToObjectiveLinks],
    nodes: [...objectivesNodesArray, ...activityNodesArray],
  };
};
