import React, { ReactElement } from "react";
import "./AdminMenu.css";
import { HelpMenu } from "./../../../VirtualTutor/Menus/HelpNotifUserMenu";
import { NotifMenu } from "./../../../VirtualTutor/Menus/HelpNotifUserMenu";
import { UserMenu } from "./../../../VirtualTutor/Menus/HelpNotifUserMenu";


const AdminMenu = (props: { ContextMenu?: ReactElement }) => {

  return (
    <div className="admin-menu-content">
      {props.ContextMenu != undefined &&
        <div className="admin-menu-content-context">
          {props.ContextMenu != undefined && props.ContextMenu}
        </div>
      }
      <div className={"admin-menu-content-fixed"}>
        <HelpMenu />
        <NotifMenu />
        <UserMenu />
      </div>
    </div>
  );
};
export default AdminMenu;
