export const SVGExercice = () => {
  return (
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="30" height="30" viewBox="0 0 30 30" className="svg-node-icon">
      <g>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M27.7,4.1v18.4L25.8,27l-1.9-4.4V4.1c0-0.8,0.6-1.5,1.3-1.5h1.3C27.1,2.7,27.7,3.3,27.7,4.1z"/>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M27.7,22.3c0,0.3,0.3-0.4-1.9,4.6c-2.2-5.1-1.9-4.3-1.9-4.6C25.1,21.7,26.5,21.7,27.7,22.3z"/>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M25.2,2.7h1.3c0.7,0,1.3,0.7,1.3,1.5v3.7l0,0h-3.8l0,0V4.1C23.9,3.3,24.5,2.7,25.2,2.7z"/>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M23.9,7.8h3.8v2.9h-3.8V7.8z"/>
      </g>
      <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M4.6,24V6.6c0.7,0,1.3-0.9,1.3-2s-0.6-2-1.3-2H4.3C2.7,2.7,2,3.5,2,4.6V24H4.6z"/>
      <g>
        <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M21,4.9H4.6v22H21V4.9z"/>
      </g>
      <path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M21,2.7H4.6c0.7,0,1.3,0.9,1.3,2s-0.6,2-1.3,2H21c0.7,0,1.3-0.9,1.3-2S21.7,2.7,21,2.7z"/>
      <g>
        <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="8.5" y1="11.7" x2="17" y2="11.7"/>
        <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="8.5" y1="16" x2="17" y2="16"/>
        <line stroke-width="2" stroke-linecap="round" stroke-linejoin="round" x1="8.5" y1="19.9" x2="14" y2="19.9"/>
      </g>
    </svg>
  );
};