import EtudiantActivite from "./etudiantActivite";

export const DOCUMENT = "DOCUMENT";
export const VIDEO = "VIDEO";
export const LINK = "LINK";
export const QUESTIONNAIRE = "QUESTIONNAIRE";
export const GAME = "GAME";
export const PODCAST = "PODCAST";
export const EXERCICE = "EXERCICE";
export const RESEARCH = "RESEARCH";
export const ELEARNING = "ELEARNING";
export const PRESENTATION = "PRESENTATION";
export const SPREADSHEET = "SPREADSHEET";
export const COURSE = "COURSE";
export const OTHER = "OTHER";
export const TOPIC = "TOPIC";
export const AGGREGATOR = "AGGREGATOR";
export const ALL = "ALL";

export const TODO = "TODO";
export const IN_PROCESS = "IN_PROCESS";
export const VALIDATED = "VALIDATED";
export const NOT_ACQUIRED = "NOT_ACQUIRED";

export const OBJECTIVE_NOT_STARTED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-objectif/1";
export const OBJECTIVE_STARTED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-objectif/2";
export const OBJECTIVE_FINISHED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-objectif/3";
export const OBJECTIVE_NOT_ACQUIRED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-objectif/4";

export const ACTIVITY_NOT_STARTED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-activite/1";
export const ACTIVITY_STARTED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-activite/2";
export const ACTIVITY_FINISHED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-activite/3";
export const ACTIVITY_NOT_ACQUIRED =
  "https://data.dauphine.psl.eu/vocabulary/statut-etudiant-activite/4";

export const PROGRESS = "PROGRESS";
export const NEWS = "NEWS";
export const GROUP = "GROUP";
export const CONTRIBUTIONS = "CONTRIBUTIONS";
export const GLOBAL = "GLOBAL";
export const EXTENDED = "EXTENDED";
export const PREFILTER = "PREFILTER";

export type LayerKey =
  | typeof PROGRESS
  | typeof NEWS
  | typeof GROUP
  | typeof CONTRIBUTIONS
  | typeof GLOBAL
  | typeof EXTENDED
  | typeof PREFILTER;

export type TProgress =
  | typeof OBJECTIVE_NOT_STARTED
  | typeof OBJECTIVE_STARTED
  | typeof OBJECTIVE_FINISHED;

export type NodeType =
  | typeof DOCUMENT
  | typeof VIDEO
  | typeof LINK
  | typeof QUESTIONNAIRE
  | typeof GAME
  | typeof PODCAST
  | typeof EXERCICE
  | typeof RESEARCH
  | typeof ELEARNING
  | typeof PRESENTATION
  | typeof SPREADSHEET
  | typeof COURSE
  | typeof TOPIC
  | typeof AGGREGATOR;

export interface IAdvancement {
  title: string;
  value: number;
}
export interface INode {
  type: NodeType;
  number: number;
  id: string;
  group: number;
  title: string;
  shortTitle: string;
  text: string;
  advancement?: IAdvancement[];
  rating?: number;
  duration: number;
  requirementCompletion?: requirement[];
  classOpinion?: number;
  progress?: {total:number,acquis:number};
  done?: boolean;
  status?: string;
  groupProgress?: string;
  url?: string;
  mine?: boolean;
  dateStarted?: Date;
  dateEndend?: Date;
  creators?: IIndividual[];
  active:boolean;
  isRequired:boolean;
  etudiantActivite?: EtudiantActivite;
}

export interface requirement {
  type: "OBJECTIF" | "CONTROLE_CONNAISSANCE" | "MEDIA" | "OTHER",
  node?: INode,
  isComplete: boolean,
}

export interface IGraphNode extends INode {
  x: number;
  y: number;
  open: boolean;
}

export interface IIndividual {
  isStudent: boolean;
  URI: string;
}

export interface IStudent {
  lastConnection: Date;
  lastName: string;
  firstName: string;
  uri: string;
  id: string;
  URI: string;
  email: string,
  isSync: boolean,
}

// TODO fix pre node with id
export interface ILink {
  source: INode;
  target: INode;
  value: number;
  type: string;
  active: boolean;
}

export interface IGraphData {
  nodes: IGraphNode[];
  links: ILink[];
}

export type ActivityFilters = NodeType | typeof OTHER | typeof ALL;
export type ActivityProgressFilters =
  | typeof TODO
  | typeof IN_PROCESS
  | typeof VALIDATED
  | typeof NOT_ACQUIRED
  | typeof ALL;

export type IFetchState = "loading" | "done" | "error" | "build";
