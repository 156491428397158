import "./HelpLegends.css";
import { useRecoilState } from "recoil";
import { Button } from "reactstrap";
import progress from "../../../../../images/layer_progress.png";
import layer_new from "../../../../../images/layer_new.png";
import group from "../../../../../images/layer_group.png";
import piece from "../../../../../images/layer_piece.png";
import global from "../../../../../images/layer_global.png";
import extended from "../../../../../images/layer_extended.png";
import pre from "../../../../../images/layer_pre.png";
import FoxLogo from "../../../../../images/fox.png";
import {
  toggleModalHelpLegendsAtom,
} from "../../../../../recoil/atoms";

<link 
  href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@500&display=swap" 
  rel="stylesheet" 
/>;

function HelpLegends() {
  const [isModalHelpLegendsOpen, setModalHelpLegendsOpen] = useRecoilState(
    toggleModalHelpLegendsAtom
  );
  const items: {
    src: string;
    name: string;
  }[] = [
    {
      src: progress,
      name: "Ma progression affiche ton pourcentage d’activités réalisées pour atteindre l'objectif.",
    },
    {
      src: layer_new,
      name: "Les nouveautés te signale les éléments nouveaux ayant été ajoutés au graphe.",
    },
    {
      src: group,
      name: "Ma promo te permet de connaitre la proportion d'étudiants ayant atteint chaque objectif.",
    },
    {
      src: piece,
      name: "Mes contributions met en valeur les activités que tu as proposées",
    },
    {
      src: extended,
      name: "La vue détaillée expose les noms complets ainsi que les numéros des objectifs.",
    },
    {
      src: global,
      name: "La vue globale te permet de passer de la vue détaillée à la vue simplifiée.",
    },
    {
      src: pre,
      name: "Te permet de limiter l'affichage aux objectifs qui te sont accessibles. Tu peux désactiver ce calque pour voir l'ensemble des objectifs du cours.",
    },

  ];

  return (
    <div
      className={`helpLegends-modal ${
        isModalHelpLegendsOpen ? "helpLegends-open" : ""
      }`}
    >
      <div className="helpLegends-header">
        <div className="helpLegends-title">Légende du graphe</div>
        <span
          className="helpLegends-close"
          onClick={() => setModalHelpLegendsOpen(false)}
        >
          <svg width={20} height={20} viewBox="0 0 50 50">
            <path
              d="M0,0 L25,25 L50,50 M25,25 L0,50 M25,25 L50,0"
              stroke="rgba(0, 0, 0, 0.36)"
              fill="rgba(0, 0, 0, 0.36)"
              strokeWidth={7}
            />
          </svg>
        </span>
      </div>
      {/* <div className="helpLegends-intro">

      </div> */}
      <div className="helpLegends-body">
        <div className="helpLegends-section">
          <div className="helpLegends-section-title">Navigation</div>
          <div className="helpLegends-section-intro">
            Tu peux sélectionner le graphe d’une unité d’enseignement en
            utilisant le menu déroulant situé en haut à gauche de ton écran.
          </div>
          <div className="helpLegends-section-content">
            <div className="helpLegends-section-content-navigationMenu">
              <div className="helpLegends-section-content-navigationMenu-logo">
                <img 
                  alt="navigationMenu" 
                  src={FoxLogo}
                  height="20"
                />
                <div className="helpLegends-section-content-navigationMenu-logo-title">
                  Tuteur Virtuel
                </div>
              </div>
              <div className="helpLegends-section-content-navigationMenu-display">
                <div className="helpLegends-section-content-navigationMenu-display-course">
                  Cours :
                </div>
                <div className="helpLegends-section-content-navigationMenu-display-list">
                  ...
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  fill="currentColor"
                  className="bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="helpLegends-section">
          <div className="helpLegends-section-title">Zoom</div>
          <div className="helpLegends-section-intro">
            Pour zoomer dans le graphe, tu peux utiliser les boutons « plus » et
            « moins » situés en bas à droite de l’écran.
          </div>
          <div className="helpLegends-section-content">
            <div className="helpLegends-section-content-controls-rectangle">
              <Button className="helpLegends-plus-minus-button">-</Button>
              <span className="helpLegends-zoom-value">100%</span>
              <Button className="helpLegends-plus-minus-button">+</Button>
            </div>
          </div>
        </div>
        <div className="helpLegends-section">
          <div className="helpLegends-section-title">Filtres</div>
          <div className="helpLegends-section-intro">
            Tu peux filtrer le graphe par type d’activité, par état d’avancement
            sur les objectifs du cours et selon la durée des activités.
          </div>
          <div className="helpLegends-section-content">
            <div className="helpLegends-section-content-filter">
              <div className="helpLegends-section-content-filter-label activityFilterLabelType">
                Type d'activité
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
              <div className="helpLegends-section-content-filter-label activityFilterLabelState">
                Statut d'activité
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
              <div className="helpLegends-section-content-filter-label activityFilterLabelTime">
                Durée d'activité
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  fill="currentColor"
                  className="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="helpLegends-section">
          <div className="helpLegends-section-title">Calques</div>
          <div className="helpLegends-section-intro">
            D'autres informations peuvent être affichées via les calques.
          </div>
          <div className="helpLegends-section-content">
            <div className="helpLegends-section-content-layer">
              {items.map((item) => (
                <div
                  key={item.name}
                  className="helpLegends-section-content-layer-label"
                >
                  <div className="helpLegends-section-content-layer-label-image">
                    <img src={item.src} alt={`${item.name} icon`} />
                  </div>
                  <div className="helpLegends-section-content-layer-label-text">
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="helpLegends-section">
          <div className="helpLegends-section-title">Disposition</div>

          <div className="helpLegends-section-content-layer-label">
            <div className="helpLegends-section-content-layer-label-image">
              <img height="20px" alt="lock icon" src="/img/locked.png"/>
            </div>
            <div className="helpLegends-section-content-layer-label-text">
              Lorsque l'icone cadenas est fermée, les activités et objectifs sont figés, tu peux les ré-organiser selon tes préférences.
            </div>
          </div>
          <br/>
          <div className="helpLegends-section-content-layer-label">
            <div className="helpLegends-section-content-layer-label-image">
              <img height="20px" alt="lock icon" src="/img/lock.png"/>
            </div>
            <div className="helpLegends-section-content-layer-label-text">
              Lorsque l'icône cadenas est ouverte les activités et objectifs sont disposés automatiquement à l'écran de la manière la plus optimale possible.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpLegends;
