import { IPoint } from "../interface/points";

/**
 * Generate polyline points by removing a portion of the line while keeping it on the same direction
 * @param source line source IPoint
 * @param target line target IPoint
 * @param deltaSource ratio of removed line from source ( 0.1 will remove 10% of the line from the source (5% of total line)
 * @param deltaTarget ratio of removed line to target
 */
export const generatePolylinePoints = ({
  source,
  target,
  deltaSource,
  deltaTarget,
}: {
  source: IPoint;
  target: IPoint;
  deltaSource?: number;
  deltaTarget?: number;
}) => {
  const sx = source.x;
  const sy = source.y;
  const tx = target.x;
  const ty = target.y;
  const ds = deltaSource === undefined ? 0.15 : deltaSource;
  const dt = deltaTarget === undefined ? 0.15 : deltaTarget;
  const ax = sx + (tx - sx) * ds;
  const ay = sy + (ty - sy) * ds;
  const bx = tx - (tx - sx) * dt;
  const by = ty - (ty - sy) * dt;
  return `${ax},${ay} ${bx},${by}`;
};
