import React, { useState } from "react";
import "./ActivityFilter.css";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  rolesAtom,
  toggleModalActivityFilterStateAtom,
  toggleModalActivityFilterTimeAtom,
  toggleModalActivityFilterTypeAtom,
} from "../../../recoil/atoms";
import {ALL, NOT_ACQUIRED} from "../../../interface/graph";
import {
  activityTypeFilterAtom,
  activityProgressFilterAtom,
  activityProgressSelector,
  activityTypesSelector,
  IActivityProgressOption,
  IActivityTypeOption,
  IActivityDurationOption,
  activityDurationFilterAtom,
  activityDurationSelector,
  activityDurationOptionsDefault,
} from "../../../recoil/graphFilters";

<link
  href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
  rel="stylesheet"
/>;

function ActivityFilter() {
  const [isModalActivityFilterTypeOpen, setModalActivityFilterTypeOpen] =
    useRecoilState(toggleModalActivityFilterTypeAtom);
  const [isModalActivityFilterStateOpen, setModalActivityFilterStateOpen] =
    useRecoilState(toggleModalActivityFilterStateAtom);
  const [isModalActivityFilterTimeOpen, setModalActivityFilterTimeOpen] =
    useRecoilState(toggleModalActivityFilterTimeAtom);
  const [activityType, setActivityType] = useRecoilState(
    activityTypeFilterAtom
  );

  const activityProgressOptions = useRecoilValue<IActivityProgressOption[]>(
    activityProgressSelector
  );
  const activityTypeOptions = useRecoilValue<IActivityTypeOption[]>(
    activityTypesSelector
  );
  const [activityState, setActivityState] = useRecoilState(
    activityProgressFilterAtom
  );
  const activityDurationOptions = useRecoilValue<IActivityDurationOption[]>(
    activityDurationSelector
  );
  const [activityDurationFilter, setActivityDurationFilter] =
    useRecoilState<IActivityDurationOption>(activityDurationFilterAtom);

  const [isClickedType, setIsClickedType] = useState(false);
  const [isClickedState, setIsClickedState] = useState(false);
  const [isClickedTime, setIsClickedTime] = useState(false);
  const [rolesUser] = useRecoilState(rolesAtom);

  return (
    <>
      <div className="activityFilter">
        <div className={`activityFilterList`}>
          <div
            className={`activityFilterLabel activityFilterLabelType ${
              isClickedType
                ? "activityFilterLabelClicked"
                : "activityFilterLabelNoClicked"
            }
            `}
            onClick={() => {
                isModalActivityFilterTypeOpen
                    ? setModalActivityFilterTypeOpen(false)
                    : setModalActivityFilterTypeOpen(true);
                setModalActivityFilterStateOpen(false);
                setModalActivityFilterTimeOpen(false);
            }}
          >
            <div>
              {activityType.type === ALL
                ? "Type d'activité"
                :  activityType.name
              }
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={12}
              height={12}
              fill="currentColor"
              className="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </div>
          <div
            className={`activityFilterSelectModal ${
              isModalActivityFilterTypeOpen
                ? "activityFilterSelectModal-open"
                : ""
            }`}
            onClick={() => {
              setModalActivityFilterTypeOpen(false);
            }}
          >
            {activityTypeOptions.map((activityType) => (
              <div
                key={activityType.type}
                className="activityFilterSelect"
                onClick={() => {
                    setActivityType(activityType);
                    setIsClickedType(activityType.type !== ALL);
                }}
              >
                {activityType.name}
              </div>
            ))}
          </div>
        </div>
        { rolesUser.includes('etudiant') &&
            <>
              <div className={`activityFilterList`}>
                <div
                  className={`activityFilterLabel activityFilterLabelState ${
                    isClickedState
                      ? "activityFilterLabelClicked"
                      : "activityFilterLabelNoClicked"
                  }   
                 `}
                  onClick={() => {
                      isModalActivityFilterStateOpen
                          ? setModalActivityFilterStateOpen(false)
                          : setModalActivityFilterStateOpen(true);
                      setModalActivityFilterTypeOpen(false);
                      setModalActivityFilterTimeOpen(false);
                  }}
                >
                  <div>
                    {activityState.status === ALL
                      ? "Statut d'activité"
                      : activityState.name}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>

            <div
              className={`activityFilterSelectModal ${
                isModalActivityFilterStateOpen
                  ? "activityFilterSelectModal-open"
                  : ""
              }`}
              onClick={() => {
                setModalActivityFilterStateOpen(false);
              }}
            >
              {activityProgressOptions
                  .filter( (a) => a.status != NOT_ACQUIRED)
                  .map((a) => (
                <div
                  key={a.status}
                  className="activityFilterSelect"
                  onClick={() => {
                      setActivityState(a);
                      setIsClickedState(a.status !== ALL);
                  }}
                >
                  {a.name}
                </div>
              ))}
            </div>
          </div>
        </>
        }
        <div className={`activityFilterList`}>
          <div
            className={`activityFilterLabel activityFilterLabelTime ${
              isClickedTime
                ? "activityFilterLabelClicked"
                : "activityFilterLabelNoClicked"
            }
           `}
            onClick={() => {
                isModalActivityFilterTimeOpen
                    ? setModalActivityFilterTimeOpen(false)
                    : setModalActivityFilterTimeOpen(true);
                setModalActivityFilterTypeOpen(false);
                setModalActivityFilterStateOpen(false);
            }}
          >
            <div>
              {activityDurationFilter?.name ===
              activityDurationOptionsDefault[0]?.name
                ? "Durée d'activité"
                : activityDurationFilter?.name}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={12}
              height={12}
              fill="currentColor"
              className="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </div>
          <div
            className={`activityFilterSelectModal ${
              isModalActivityFilterTimeOpen
                ? "activityFilterSelectModal-open"
                : ""
            }`}
            onClick={() => {
              setModalActivityFilterTimeOpen(false);
            }}
          >
            {activityDurationOptions.map((a) => (
              <div
                key={a.name}
                className="activityFilterSelect"
                onClick={() => {
                    setActivityDurationFilter(a);
                    setIsClickedTime(a.name !== "Toutes les durées");
                }}
              >
                {a.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ActivityFilter;
