import React from "react";
import * as d3 from "d3";
import {useRecoilState} from "recoil";
import {selectedNodeIDAtom} from "../../recoil/atoms";

const maxSize = 8000;

/**
 * Background grid
 */

const BackgroundSvgGrid = () => {
  const [selectedNodeID, selectedNode] = useRecoilState<string | undefined>(
      selectedNodeIDAtom
  );
  const max = 50 * 5;
  const range = d3.range(0, max);
  return (
    <>
      <rect
        x={-maxSize / 2}
        y={-maxSize / 2}
        width={maxSize}
        height={maxSize}
        fill={"#f2f2f1"}
        onClick={ (e) => {
            if(e.target == e.currentTarget){
                selectedNode(undefined);
            }
        }}
      />
      <g className={"backgroundLines"}>
        {range.map((r, i) => (
          <line
            key={`v${i}`}
            y1={-maxSize / 2}
            y2={maxSize / 2}
            x1={i * (maxSize / max) - maxSize / 2}
            x2={i * (maxSize / max) - maxSize / 2}
            stroke={"grey"}
            strokeWidth={i % 5 === 0 ? 0.6 : 0.2}
          />
        ))}
        {range.map((r, i) => (
          <line
            key={`h${i}`}
            x1={-maxSize / 2}
            x2={maxSize / 2}
            y1={i * (maxSize / max) - maxSize / 2}
            y2={i * (maxSize / max) - maxSize / 2}
            stroke={"grey"}
            strokeWidth={i % 5 === 0 ? 0.6 : 0.2}
          />
        ))}
      </g>
    </>
  );
};
export default BackgroundSvgGrid;
