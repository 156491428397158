import React, {useEffect, useReducer, useRef, useState} from "react";
import * as d3 from "d3";
import {
    AGGREGATOR,
    CONTRIBUTIONS,
    COURSE,
    DOCUMENT,
    ELEARNING,
    EXERCICE,
    GAME,
    GROUP,
    IGraphData,
    IGraphNode,
    IN_PROCESS,
    LINK,
    NOT_ACQUIRED,
    PODCAST,
    PRESENTATION,
    PROGRESS,
    QUESTIONNAIRE,
    RESEARCH,
    SPREADSHEET, TODO,
    TOPIC,
    VALIDATED,
    VIDEO,
} from "../../interface/graph";
import classNames from "classnames";
import { generatePolylinePoints } from "../../helpers/graphics";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    areNodeExpendedAtom, graphAtom, lockedAtom,
    openNodesAtom, pollingState,
    selectedLayerAtom,
    selectedNodeIDAtom, selectedTopic,
    selectRelatedNodes,
    toggleModalAtom, zoomAtomT,
} from "../../recoil/atoms";
import SVGGroupProgress from "../icons/SVGGroupProgress";
import { getId, isActivity } from "../../helpers/domainDataHelpers";
import Document from "../svg/Document";
import Video from "../svg/Video";
import WebLink from "../svg/WebLink";
import Questionnaire from "../svg/Questionnaire";
import Game from "../svg/Game";
import Podcast from "../svg/Podcast";
import Exercice from "../svg/Exercice";
import Research from "../svg/Research";
import Elearning from "../svg/Elearning";
import Presentation from "../svg/Presentation";
import Spreadsheet from "../svg/Spreadsheet";
import Course from "../svg/Course";
import Aggregator from "../svg/Aggregator";
import Topic, { wordWrap } from "../svg/Topic";
import {
    activityDurationFilterAtom, activityProgressFilterAtom, activityTypeFilterAtom,
    filterActivityDuration,
    filterActivityProgress,
    filterActivityType,
    IActivityDurationOption
} from "../../recoil/graphFilters";

export const drag = () => {
  let dragging = false;
  function dragStart() {}

  function dragged(event: any) {
    let s =
      Math.abs(event.x - event.subject.x) > 5 ||
      Math.abs(event.y - event.subject.y) > 5;

    if (s && !dragging) {
      // simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
      dragging = true;
    }
    if (dragging) {
      // @ts-ignore
      d3.select(this).attr("transform", `translate(${event.x},${event.y})`);
      d3.selectAll(`.lt${getId(event.subject)}`)
        .attr("x2", event.x)
        .attr("y2", event.y);

      d3.selectAll(`.ls${getId(event.subject)}`)
        .attr("x1", event.x)
        .attr("y1", event.y);

      d3.selectAll(`.at${getId(event.subject)}`).attr("points", (d: any) =>
        generatePolylinePoints({
          source: d.source,
          target: event,
          deltaSource: 0,
          deltaTarget: 0.5,
        })
      );
      d3.selectAll(`.as${getId(event.subject)}`).attr("points", (d: any) =>
        generatePolylinePoints({
          source: event,
          target: d.target,
          deltaSource: 0,
          deltaTarget: 0.5,
        })
      );
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }
  }

  function dragEnd(event: any) {
    if (dragging) {
      event.subject.x = event.subject.fx;
      event.subject.y = event.subject.fy;
    }
    dragging = false;
  }

  return d3
    .drag()
    .on("start", dragStart)
    .on("drag", dragged)
    .on("end", dragEnd);
};

interface IProps {
  data: IGraphData;
  width?: number;
  height?: number;
  simulation?: any;
}

/**
 * Same component as KnowledgeGraph.tsx but first render is in react
 */

const sizes: any = {
  [DOCUMENT]: { width: 0, height: 0 },
  [VIDEO]: { width: 0, height: 0 },
  [LINK]: { width: 0, height: 0 },
  [QUESTIONNAIRE]: { width: 0, height: 0 },
  [GAME]: { width: 0, height: 0 },
  [PODCAST]: { width: 0, height: 0 },
  [EXERCICE]: { width: 0, height: 0 },
  [RESEARCH]: { width: 0, height: 0 },
  [ELEARNING]: { width: 0, height: 0 },
  [PRESENTATION]: { width: 0, height: 0 },
  [SPREADSHEET]: { width: 0, height: 0 },
  [COURSE]: { width: 0, height: 0 },
  [TOPIC]: { width: 430, height: 100 },
  [AGGREGATOR]: { width: 0, height: 0 },
};

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const PositionGraph = (props: IProps) => {

  const d3Container = useRef(null);
  const { data, simulation } = props;
  const [open] = useRecoilState(areNodeExpendedAtom);
  const save = false; //FIXME
  const [, setIsModalOpen] = useRecoilState(toggleModalAtom);
  const relatedNodes = useRecoilValue(selectRelatedNodes);
  const [selectedNodeID, selectedNode] = useRecoilState<string | undefined>(
    selectedNodeIDAtom
  );
  const [locked] = useRecoilState(lockedAtom);
  const links = data.links;
  const nodes = data.nodes;
  const [openNodes] = useRecoilState(openNodesAtom);
  const [selectedLayers] = useRecoilState(selectedLayerAtom);
  const showMine = selectedLayers.includes(CONTRIBUTIONS);
  const showProgress = selectedLayers.includes(PROGRESS);
  const showGroup = selectedLayers.includes(GROUP);
  const pSG = usePrevious(showGroup);
  const pSP = usePrevious(showProgress);
  const pOpen = usePrevious(open);
  const [,initZoom] = useRecoilState(zoomAtomT);
  const [activityDurationFilter] =
      useRecoilState<IActivityDurationOption>(activityDurationFilterAtom);
  const [activityState] = useRecoilState(
      activityProgressFilterAtom
  );
  const [activityType] = useRecoilState(
      activityTypeFilterAtom
  );
  let nodeActive : string[] = selectedNodeID ? [selectedNodeID] : [];
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  // Changement du noeud courant
  useEffect( () => {
      const selectedNode = nodes.find((x: any) => x.id == selectedNodeID && x.type == TOPIC);
      if (selectedNode == undefined) {
          return;
      }

      nodes.forEach((x: any) => x.isRequired = false);

      // @ts-ignore
      var conditions = Array.isArray(selectedNode._aCombinaisonConditions._aConditionObtention) ?
          // @ts-ignore
          selectedNode._aCombinaisonConditions._aConditionObtention
          :
          // @ts-ignore
          [selectedNode._aCombinaisonConditions._aConditionObtention];

      conditions.forEach((c: any) => {
          if (c._conditionObtentionDeActivitePedagogique != undefined) {
              const n = nodes.find((x: any) =>
                  x.id == c._conditionObtentionDeActivitePedagogique._URI);
              if (n != undefined) {
                  n.isRequired = true;
              }
          }
          if (c._necessiteAcquisitionObjectifPedagogique != undefined) {
              const n = nodes.find((x: any) =>
                  x._estUneVersionDe != undefined &&
                  x._estUneVersionDe._URI == c._necessiteAcquisitionObjectifPedagogique._URI
                  && x.type == TOPIC);
              if (n != undefined) {
                  n.isRequired = true;
              }
          }
      });

      forceUpdate();
  }, [selectedNodeID]);

  // Initialisation du zoom sur le noeud courant
  useEffect(() => {
      var currentNode = nodes.find((x:any) => x.id == selectedNodeID);
      if(currentNode != undefined){
          initZoom(
              d3.zoomIdentity.translate(
                  ((-1*currentNode.x) / 2) + 300,
                  ((-1*currentNode.y)  / 2) + 300,
              ).scale(1));
      }
  }, []);

  // Filtrage des activités
  useEffect( () => {
      nodes.forEach((x:any) => x.active = false);
      links.forEach((x:any) => x.active = false);

      filterActivityDuration({
          graph: filterActivityProgress({
              graph: filterActivityType({
                  graph: data,
                  filter: activityType.type,
              }),
              filter: activityState.status,
          }),
          filter: activityDurationFilter,
      }).nodes.forEach((x:any) => nodes.find(n => n.id == x.id)!.active = true);

      links.filter(l => ( nodes.find(n => n.id == l.target.id)!.active && nodes.find(n => n.id == l.source.id)!.active)).forEach( (l:any) => l.active = true );

      if( links.filter(l => l.target.id == selectedNodeID && l.source.active).length == 0 ){
          selectedNode(undefined);
      }
      forceUpdate();
  }, [activityType,activityState,activityDurationFilter]);

  nodeActive = nodeActive.concat(links.filter(l => l.target.id == selectedNodeID ).map(l => l.source.id));
  nodeActive = nodeActive.concat(links.filter(l => l.source.id == selectedNodeID ).map(l => l.target.id));

  useEffect(() => {
    d3.select(d3Container.current)
      .select(".links")
      .selectAll("line")
      .attr("stroke-width", (d: any) => {
        return d.target.type === AGGREGATOR || d.source.type === AGGREGATOR
          ? 2
          : d.target.id === selectedNodeID || d.source.id === selectedNodeID
          ? 5
          : 2;
      })

  }, [d3Container, selectedNodeID, nodes, links]);

  useEffect(() => {
    if (data && d3Container.current) {
      const svgGroup = d3.select(d3Container.current);
      const nodeGz = d3
        .select(d3Container.current)
        .selectAll(".node")
        .data(nodes)
        .attr("transform", (d: any) =>
          d.type === AGGREGATOR
            ? `translate(${d.x},${d.y})`
            : `translate(${d.px},${d.py})`
        )
        .attr("opacity", 1);
      nodeGz
        .transition()
        .duration(500)
        .attr("transform", (d) => {
          return `translate(${d.x},${d.y})`;
        });
      // @ts-ignore
      nodeGz.call(drag());
      svgGroup
        .select(".links")
        .selectAll("line")
        .data(links, (d: any) => `l${d?.source.id}${d?.target.id}`)
        .join("line")
        .attr("stroke-width", (d: any) => {
          return d.target.type === AGGREGATOR || d.source.type === AGGREGATOR
            ? 2
            : d.target.id === selectedNodeID || d.source.id === selectedNodeID
            ? 5
            : 2;
        })
        .attr("x1", (d: any) =>
          d.source.type === AGGREGATOR ? d.source.x : d.source.px
        )
        .attr("x2", (d: any) =>
          d.target.type === AGGREGATOR ? d.target.x : d.target.px
        )
        .attr("y1", (d: any) =>
          d.source.type === AGGREGATOR ? d.source.y : d.source.py
        )
        .attr("y2", (d: any) =>
          d.target.type === AGGREGATOR ? d.target.y : d.target.py
        )
        .attr("opacity", (d) =>
          d.target.type === AGGREGATOR || d.source.type === AGGREGATOR ?
              d.source.type ==  TOPIC ? 0.2 : 0
              :
              1
        )
        .transition()
        .duration(500)
        .attr("x1", (d: any) => d.source.x)
        .attr("x2", (d: any) => d.target.x)
        .attr("y1", (d: any) => d.source.y)
        .attr("y2", (d: any) => d.target.y);
      const arrows = svgGroup.select(".links").selectAll(".arrow").data(links);
      // simulation.on("tick", () => {

      arrows
        .attr("points", (d: any) =>
          generatePolylinePoints({
            source: {
              x: d.source.type === AGGREGATOR ? d.source.x : d.source.px,
              y: d.source.type === AGGREGATOR ? d.source.y : d.source.py,
            },
            target: {
              x: d.target.type === AGGREGATOR ? d.target.x : d.target.px,
              y: d.target.type === AGGREGATOR ? d.target.y : d.target.py,
            },
            deltaSource: 0,
            deltaTarget: 0.5,
          })
        )
        .transition()
        .duration(500)
        .attr("points", (d: any) =>
          generatePolylinePoints({
            source: d.source,
            target: d.target,
            deltaSource: 0,
            deltaTarget: 0.5,
          })
        );
      // nodeG
      //   .transition()
      //   .duration(500)
      //   .attr("transform", (d) => `translate(${d.x},${d.y})`);
      // });
    }
  }, [d3Container, save, nodes, links]);

  useEffect(() => {
    const svgGroup = d3.select(d3Container.current);
    svgGroup
      .select(".links")
      .selectAll("line")
      .data(links, (d: any) => `l${d?.source.id}${d?.target.id}`)
      .attr(
        "class",
        (d) =>
          `link lt${getId(d.target)} ls${getId(d.source)} ${
            showMine && d.source.mine 
              ? "mine"
              : d.source.status === VALIDATED
              ? "done"
              : d.source.status === IN_PROCESS ||
                d.source.status === NOT_ACQUIRED
              ? "started"
              : ""
          }
          ${d.type}
          ${ !(d.active) ? 'link_not_active' : '' }
          ${
              nodeActive.find(x => x == d.source.id ) != undefined 
              || (selectedNodeID == d.source.id || selectedNodeID == d.target.id)
              || d.type == "linkObjectifToObjectif"
              || (nodeActive.length == 0 && d.type == "linkObjectifToObjectif")
              && ( d.source.active && d.target.active )    
                  ?
              'link-current' : ""
          }
          ${
              d.target.id == selectedNodeID 
              || d.source.id == selectedNodeID
              && ( d.source.active && d.target.active ) 
                  ?
              'link-selected' : ""
          }`,


      );
  }, [d3Container, links, nodes, showMine,selectedNodeID,activityType,activityState,activityDurationFilter]);

  useEffect(() => {
    const nodeG = d3
      .select(d3Container.current)
      .selectAll(".node")
      .data(nodes, (d: any) => {
        return d?.id;
      }); // @ts-ignore

    nodeG
      /*.on("dblclick", function (e, d) {
        if (d.type === TOPIC) {
          if (openNodes.includes(d.id)) {
            setOpenNodes(openNodes.filter((s) => s !== d.id));
          } else {
            setOpenNodes([...openNodes, d.id]);
          }
        }
      })*/
      .on("mouseover", function (e, d) {
        if (
          (d.type === TOPIC && !open) ||
          (d.type !== TOPIC && d.type !== AGGREGATOR)
        ) {
          const tooltip = d3.select(".tooltipTitle");
          let isAct = isActivity(d);
          tooltip.select(".title").text(isAct ? "" : d.shortTitle);
          const text = wordWrap(d.text, isAct ? 42 : 32);
          tooltip.select(".textLines");
          tooltip
            .select(".textLine1")
            .attr("x", isAct ? 15 : 100)
            .attr("dy", text[2] ? "1.3em" : text[1] ? "1.7em" : "2.2em")
            .text(`${text[0] || ""}`);
          tooltip
            .select(".textLine2")
            .attr("x", isAct ? 15 : 100)
            .attr("dy", !text[2] ? "1.5em" : "1.2em")
            .text(`${text[1] || ""}`);
          tooltip
            .select(".textLine3")
            .attr("x", isAct ? 15 : 100)
            .attr("dy", "1.2em")
            .text(`${text[2] || ""}`);
          tooltip
            .attr("display", `block`)
            .attr("transform", `translate(${d.x + 45},${d.y - 30})`)
            .attr("opacity", 0)
            .transition()
            .duration(300)
            .attr("opacity", 1);
        }
      })
      .on("mouseout", function () {
        d3.select(".tooltipTitle").attr("display", `none`);
        // .attr("transform", `translate(40,50)`);
      });
    if (open) {
      let duration = !pOpen ? 500 : 0;
      nodeG.each(function (n: any) {
        let selection = d3.select(this);
        // selection
        //   .transition()
        //   .delay(added.includes(n.id) ? 200 : 0)
        //   .attr("opacity", 1);
        selection
          .select(".my-progress-bar")
          .transition()
          .delay(500)
          .attr("display", "block");

        selection
          .select(".node-box")
          .transition()
          .duration(duration)
          .attr("width", sizes[n.type].width)
          .attr(
            "height",
            sizes[n.type]?.height + (showProgress && n.type === TOPIC ? 20 : 0)
          )
          .attr("x", -sizes[n.type].width / 2);

        if (showProgress) {
          //my-progress-bar
          selection
            .select(".my-progress-bar")
            .attr("display", "block")
            // .attr("opacity", !pSP ? 0 : 1)
            .transition()
            .duration(500)
            .delay(!pSP ? 0 : 500)
            .attr("opacity", 1)
            .attr("display", "block");
        } else {
          selection
            .select(".my-progress-bar")
            .transition()
            .duration(500)
            .attr("opacity", 0)
            .attr("display", "none");
        }

        if (showGroup) {
          selection
            .select(".group-progress")
            .attr("display", "block")
            .attr(
              "transform",
              !pOpen || !pSG ? "translate(0,40)" : "translate(0,-8)"
            )
            .transition()
            .duration(500)
            .delay(pSG ? 500 : 0)
            .attr("opacity", 1)
            .attr("transform", "translate(0,-8)")
            .attr("display", "block");
        } else {
          selection
            .select(".group-progress")
            .transition()
            .duration(500)
            .attr("opacity", 0)
            .attr("transform", `translate(0,40)`);
          selection
            .select(".group-progress")
            .transition()
            .delay(500)
            .attr("display", "none");
        }

        selection
          .select(".svgIcon")
          .transition()
          .duration(duration)
          .attr(
            "transform",
            n.type === TOPIC ? "translate(10,0)" : "translate(100,0)"
          );

        selection
          .select(".svgIcon.shield")
          .transition()
          .duration(duration)
          .attr("transform", "translate(0,0)");
        selection
          .select(".gIcon")
          .transition()
          .duration(duration)
          .attr("transform", n.type === TOPIC ? "scale(1.8)" : "scale(2)");

        selection
          .select(".svgIcon")
          .selectAll("text")
          .attr("display", n.type === TOPIC ? "block" : "none")
          .transition()
          .duration(duration)
          .attr("opacity", 1)
          .attr("display", n.type === TOPIC ? "block" : "none");

        selection
          .select(".collapser")
          .attr("display", "block")
          .transition()
          .duration(duration)
          .attr("opacity", 1)
          .attr("display", "block");
      });
    } else if (!open) {
      nodeG.each(function (n: any) {
        const selection = d3.select(this);
        selection
          .select(".my-progress-bar")
          .transition()
          .duration(200)
          .attr("opacity", 0)

          .attr("display", "none");
        // @ts-ignore
        selection
          .select(".group-progress")
          .transition()
          .duration(200)
          .attr("opacity", 0)
          .attr("transform", "translate(0,40)");

        selection
          .select(".node-box")
          .transition()
          .duration(500)
          .attr("width", 0)
          .attr("x", 0);

        // @ts-ignore
        selection
          .select(".svgIcon")
          .transition()
          .duration(500)
          .attr(
            "transform",
            n.type === TOPIC ? "translate(172,0)" : "translate(100,0)"
          );
        // @ts-ignore
        selection
          .select(".collapser")
          .transition()
          .duration(500)
          .attr("opacity", 0)
          .attr("display", "none");
        // .attr("transform", "translate(50,0)");

        // @ts-ignore
        selection
          .select(".gIcon")
          .transition()
          .duration(500)
          .attr("transform", "scale(2)");

        // @ts-ignore
        selection
          .select(".svgIcon")
          .selectAll("text")
          .transition()
          .duration(500)
          .attr("opacity", 0)
          .attr("display", "none");
      });
    }
    // @ts-ignore
  }, [d3Container, links, nodes, open, openNodes, showProgress, showGroup]);

  return (
    <g ref={d3Container} transform={"scale(0.5)"}>
      <g className={"links"}>
        {links.map((lin: any) => {
          return (
            <polyline
              key={`a${lin?.source.id}${lin?.target.id}`}
              className={classNames(
                "arrow",
                `at${getId(lin.target)}`,
                `as${getId(lin.source)}`,
                  `arrow-${lin.type}`,
                  `${
                      nodeActive.find(x => x == lin.source.id ) != undefined 
                      || lin.target.id == selectedNodeID || lin.source.id == selectedNodeID
                      || (nodeActive.length == 0 && lin.type == "linkObjectifToObjectif") ?
                      'arrow-current' : ""
                  }`,
                  `${ !(lin.source.active && lin.target.active) ? 'link_not_active' : '' }`,
                  `${
                      lin.target.id == selectedNodeID 
                      || lin.source.id == selectedNodeID ? 'arrow-selected' : ""
                  }`

              )}
              markerEnd={`url(#arrowhead${
                (lin.source.mine || lin.target.mine) && showMine
                  ? "-mine"
                  : lin.source.done
                  ? "-done"
                  : lin.source.status === NOT_ACQUIRED ||
                    lin.source.status === IN_PROCESS
                  ? "-started"
                  : ""
              })`}
              opacity={
                lin.target.type === AGGREGATOR || lin.source.type === AGGREGATOR
                  ?
                    lin.source.type === TOPIC ? 0.0 : 0.0
                  :
                    1
              }
              stroke={"none"}
              strokeWidth={2}
            />
          );
        })}
      </g>
      <g className={"nodes"}>
        {nodes.map((node: IGraphNode) => (
          <g
            key={node.id}
            opacity={1}
            id={node.shortTitle}
            className={classNames("node", {
              mine: node.mine && showMine,
              todo : node.status === TODO,
              done: node.status === VALIDATED,
              started:
                (!(showMine && node.mine) && node.status === IN_PROCESS) ||
                node.status === NOT_ACQUIRED,
              selected:
                !!relatedNodes.find((r: any) => r.id === node.id) ||
                node.id === selectedNodeID,
              activite_required:  ((node.type != AGGREGATOR && node.type != TOPIC) && node.isRequired),
              activite_current: ((node.type != AGGREGATOR && node.type != TOPIC) && nodeActive.find(x => x == node.id ) != undefined),
              objectif_required:  (node.type == TOPIC && node.isRequired),
              objectif_current: (node.type == TOPIC && nodeActive.find(x => x == node.id) != undefined) || nodeActive.length == 0 ,
              aggregator_current: (node.type == AGGREGATOR && nodeActive.find(x => x == node.id) != undefined ),
              node_objectif : node.type == TOPIC,
              node_activite : (node.type != AGGREGATOR && node.type != TOPIC),
              node_aggregator : node.type == AGGREGATOR,
              node_not_active: !node.active,

            })}
            // transform={`translate(${node.x},${node.y})`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (node.type !== AGGREGATOR) {
                selectedNode(node.id);
                setIsModalOpen(true);
              }
            }}
          >
            {node.type === TOPIC && (
              <g className="group-progress">
                <rect
                  width={60}
                  height={40}
                  x={-130}
                  y={-68}
                  rx={5}
                  fill="#1e9b7e"
                  stroke="none"
                />
                <rect
                  width={40}
                  height={40}
                  x={-111}
                  y={-67}
                  rx={4}
                  fill="white"
                  stroke="none"
                />
                <rect
                  width={20}
                  height={40}
                  x={-120}
                  y={-68}
                  rx={0}
                  fill="#1e9b7e"
                  stroke="none"
                />
                <text
                  textAnchor="middle"
                  fill="#1e9b7e"
                  stroke="none"
                  className="gp-text"
                  fontSize={11}
                  fontWeight={900}
                  x={-85}
                  y={-50}
                >
                  {node.groupProgress || 0}%
                </text>
                <g transform="translate(-125,-60)">
                  <SVGGroupProgress color="white" scale="scale(0.04)" />
                </g>
              </g>
            )}
            <rect
              className={`node-box node-box-${
                node.type === TOPIC ? "topic" : "activity"
              }`}
              width={0}
              x={0}
              y={-sizes[node.type]?.height / 2}
              height={sizes[node.type]?.height}
              rx={3}
              display={node.type !== TOPIC ? "none" : ""}
              stroke={selectedNodeID === node.id ? "#3b62ff" : "none"}
              strokeWidth={2}
            />
            {node.type === DOCUMENT && <Document node={node} />}
            {node.type === VIDEO && <Video node={node} />}
            {node.type === LINK && <WebLink node={node} />}
            {node.type === QUESTIONNAIRE && <Questionnaire node={node} />}
            {node.type === GAME && <Game node={node} />}
            {node.type === PODCAST && <Podcast node={node} />}
            {node.type === EXERCICE && <Exercice node={node} />}
            {node.type === RESEARCH && <Research node={node} />}
            {node.type === ELEARNING && <Elearning node={node} />}
            {node.type === PRESENTATION && <Presentation node={node} />}
            {node.type === SPREADSHEET && <Spreadsheet node={node} />}
            {node.type === COURSE && <Course node={node} />}
            {node.type === TOPIC && <Topic node={node} />}
            {node.type === AGGREGATOR && (
              <>
                <Aggregator />
                {/*<title>*/}
                {/*  /!*{node.id}{" "}*!/*/}
                {/*  {*/}
                {/*    // @ts-ignore*/}
                {/*    node.cluster.join(" ")*/}
                {/*  }*/}
                {/*</title>*/}
              </>
            )}
          </g>
        ))}
      </g>
      <g className={"tooltipTitle node"} display="none">
        <rect x={0} y={0} width={400} rx={3} height={90} fill="white" />
        <text
          y={54}
          x={50}
          stroke="#e1e1e1"
          fontSize={30}
          strokeWidth={1.5}
          textAnchor="middle"
          fontWeight={900}
          fill="#5768ac"
          className={"title"}
        >
          01
        </text>
        <text y={7} fontSize={18} className={"textLines"}>
          <tspan className={"textLine1"} x={60} dy="1.2em" />
          <tspan className={"textLine2"} x={60} dy="1.2em" />
          <tspan className={"textLine3"} x={60} dy="1.2em" />
        </text>
      </g>
    </g>
  );
};
export default PositionGraph;
