import React from "react";
import SVGShield from "../icons/SVGShield";
import { INode } from "../../interface/graph";
import { useRecoilState } from "recoil";
import {openNodesAtom, selectedLayerAtom} from "../../recoil/atoms";
import classNames from "classnames";

interface IProps {
  node: INode;
  open?: boolean;
}
export const wordWrap = (w: string, x: number) => {
  const flags = [false, false, false];

  const words = w.split(" ").reduce(
    (a, b) => {

      if (!flags[0] && a[0].length + b.length <= x) {
        a[0] += `${a[0].length === 0 ? "" : " "}${b}`;
      } else {
        flags[0] = true;
      }

      if (flags[0] && !flags[1] && a[1].length + b.length <= x) {
        a[1] += `${a[1].length === 0 ? "" : " "}${b}`;
      } else if (flags[0]) {
        flags[1] = true;
      }

      if (flags[0] && flags[1] && !flags[2] && a[2].length + b.length <= x) {
        a[2] += `${a[2].length === 0 ? "" : " "}${b}`;
      } else if (flags[0] && flags[1]) {
        flags[2] = true;
      }
      return a;
    },
    ["", "", ""]
  );

  return flags[2]
      ? words.map((a, i) => {
          if (i === 2) {
              let w = a;
              if (a.length >= x - 3) {
                  return w.slice(0, x - 3) + "...";
              }
              return w + "...";
          }
          return a;
      })
      : words;
};
const Topic = ({ node }: IProps) => {
  const [openNodes, toggleOpenNodes] = useRecoilState(openNodesAtom);
  const [selectedLayers] = useRecoilState(selectedLayerAtom);

  const myProgressPercent = node.progress || {acquis:0, total:100};
  const textWrap = wordWrap(node.text, 40);
  const nbLine = textWrap.filter((t: string, i: number, g) => t != "").length;
  return (
    <g className="svgIcon topic" transform="translate(205,0)">
      <text y={
          nbLine == 1 ? -25 : -36
      } fontSize={17} opacity={0}>
        {textWrap
            .filter((t: string, i: number, g) => t != "")
            .map((t: string, i: number, g) => (
          <tspan
            key={`text${i}`}
            x="-125"
            dy={!g[2] && i === 0 ? "1.8em" : "1.2em"}
          >
            {t}
          </tspan>
        ))}
      </text>
      <g className={"gIcon svg-node-icon shield"} transform="scale(3)">
        <g transform={
            selectedLayers.find( x => x == "GLOBAL" ) != undefined ?
                "translate(-119,-33)"
                :
                "translate(-130,-33)"
        }>
          <SVGShield />
          <text textAnchor="middle" fontWeight={900} opacity={0} x={33} y={38}>
            {node.shortTitle}
          </text>
        </g>
      </g>
      {/*{showProgress && (*/}
      <g className="my-progress-bar" display="none">
        <rect
          x={-125}
          y={45}
          rx={5}
          height={10}
          width={290}
          fill="black"
          opacity={0.4}
        />
        <rect
          x={-125}
          y={45}
          rx={5}
          height={10}
          width={290 * ((myProgressPercent.acquis/myProgressPercent.total))}
          fill="#81bdb3"
          stroke="none"
        />
        <rect />
        <text x={125} y={53.5} fontSize={10} fontWeight={700} textAnchor="end">
          {Number.parseFloat(((myProgressPercent.acquis/myProgressPercent.total)*100).toFixed(2))} %
        </text>
      </g>
      {/*)}*/}
    </g>
  );
};
export default Topic;
